import { useEffect, useState } from "react";
import "./Breeders.scss";
import { Loading } from "../Loading/Loading";
import { useNavigate } from "react-router";
import moment from "moment";
import "moment/locale/ar"; // Import the Arabic locale
import { ar, enUS } from "date-fns/locale";
import { format } from "date-fns";
import API from "../../Api";
import { setMainHeaderName } from "../../redux/Slices/MainHeaderSlice";
import { useDispatch, useSelector } from "react-redux";
import { TotalCount } from "../../SubComponents/TotalCount/TotalCount";
import { Link } from "react-router-dom";
import userIcon from "../../Assets/Icons/user.jpeg";
import Pagination from "../../SubComponents/Paginator/Pagination";
import { toast } from "react-toastify";
import { ListContent } from "../../SubComponents/ListContent/ListContent";
import { ExportExcel } from "../../SubComponents/Export/ExcelExport";
import { ExcelExportDetails } from "../../SubComponents/Export/ExcelExportDetails";
interface Breeder {
  id: string;
  account_status?: number;
  created_at: string;
  created_by_id: number;
  created_by_role: number;
  name: string;
  village: string | null;
  governorate_id: string;
  city_id: string;
  full_address: string;
  mobile_number: string;
  whatsapp_number: string;
  avatar: string | null;
  approx_breeding_size: number;
  farms_count: number;
  activity_type_id: string;
  status_id: string;
  is_efb: boolean;
  home_breeding: number;
  incubator: number;
  pay_method: string;
  creator: any; // You might want to replace `any` with an appropriate type
  governorate: {
    created_at: string;
    updated_at: string;
    id: string;
    name: string;
  };
  city: {
    id: string;
    name: string;
  };
  specialty: any; // You might want to replace `any` with an appropriate type
  farms_capacity: any; // You might want to replace `any` with an appropriate type
  farms: any[]; // You might want to replace `any[]` with an appropriate type
}

interface DajinTypeArray {
  id: string;
  type: number;
}
interface FeedTypeArray {
  id: string;
  name: string;
}
interface companyArray {
  id: string;
  type: string;
}
interface breedTypeArray {
  id: string;
  breed_type: string;
}

export const Breeders = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [breeders, setBreeders] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isSorted, setIsSorted] = useState(false);
  const [sortedKey, setSortedKey] = useState("&sort_by=id&sort_order=DESC");
  const [Dajins, setDajins] = useState<DajinTypeArray[]>([]);
  const [feedTypes, setFeedTypes] = useState<FeedTypeArray[]>([]);
  const [companies, setCompanies] = useState<companyArray[]>([]);
  const [breedTypes, setBreedTypes] = useState<breedTypeArray[]>([]);
  const [showListContentModal, setShowListContentModal] = useState(false);
  const [showBreedTypeListContentModal, setShowBreedTypeListContentModal] =
    useState(false);
  const [showListFeedTypeContent, setShowListFeedTypeContent] = useState(false);
  const [showListCompanyContent, setShowListCompanyContent] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const stateFromBreedersModalSlice = useSelector(
    (state: any) => state.breedersModal
  );

  const handleShowDajinListContentModal = (id: number) => {
    //Get All Housing List Data From Farms State
    setIsLoading(true);
    let data = breeders.find((c: any) => c.id === id);
    if (data !== undefined) {
      let anyData = data as any;
      if (
        anyData.farmsStatistics == null &&
        anyData.farmsStatistics.allDajinBreeds.length === 0 &&
        anyData.farmsStatistics.allDajinBreeds.dajinType != null
      ) {
        toast.warn("لا يوجد نوع داجن متوفر لهذا المربي");
        setIsLoading(false);
      } else if (
        anyData.farmsStatistics != null &&
        anyData.farmsStatistics.allDajinBreeds.length === 0
      ) {
        toast.warn("لا يوجد نوع داجن متوفر لهذا المربي");
        setIsLoading(false);
      } else {
        const dajinArray: DajinTypeArray[] = [];

        anyData.farmsStatistics.allDajinBreeds.map((item: any) => {
          dajinArray.push(item.dajinType as DajinTypeArray);
        });

        setDajins(dajinArray);
        setShowListContentModal(true);
        setIsLoading(false);
      }
    } else {
      toast.warn("لا يوجد نوع داجن متوفر لهذا المربي");

      setIsLoading(false);
    }
  };

  const handleShowBreedTypesListContentModal = (id: number) => {
    //Get All Housing List Data From Farms State
    setIsLoading(true);
    let data = breeders.find((c: any) => c.id === id);
    if (data !== undefined) {
      let anyData = data as any;
      if (
        anyData.farmsStatistics == null &&
        anyData.farmsStatistics.allDajinBreeds.length === 0 &&
        anyData.farmsStatistics.allDajinBreeds != null
      ) {
        toast.warn("لا يوجد نوع سلالة متوفر لهذا المربي");
        setIsLoading(false);
      } else if (
        anyData.farmsStatistics != null &&
        anyData.farmsStatistics.allDajinBreeds.length === 0
      ) {
        toast.warn("لا يوجد نوع سلالة متوفر لهذا المربي");
        setIsLoading(false);
      } else {
        const breed_type_Array: breedTypeArray[] = [];

        anyData.farmsStatistics.allDajinBreeds.map((item: any) => {
          breed_type_Array.push(item as breedTypeArray);
        });

        setBreedTypes(breed_type_Array);
        setShowBreedTypeListContentModal(true);
        setIsLoading(false);
      }
    } else {
      toast.warn("لا يوجد نوع سلالة متوفر لهذا المربي");

      setIsLoading(false);
    }
  };
  const handleShowCompaniesListContentModal = (id: number) => {
    //Get All Housing List Data From Farms State
    setIsLoading(true);
    let data = breeders.find((c: any) => c.id === id);
    if (data !== undefined) {
      let anyData = data as any;
      if (
        anyData.farmsStatistics == null &&
        anyData.farmsStatistics.allBrooderCompanies.length === 0 &&
        anyData.farmsStatistics.allBrooderCompanies != null
      ) {
        toast.warn("لا يوجد شركات متوفرة لهذا المربي");
        setIsLoading(false);
      } else if (
        anyData.farmsStatistics != null &&
        anyData.farmsStatistics.allBrooderCompanies.length === 0
      ) {
        toast.warn("لا يوجد شركات متوفرة لهذا المربي");
        setIsLoading(false);
      } else {
        const companiesArray: companyArray[] = [];

        anyData.farmsStatistics.allBrooderCompanies.map((item: any) => {
          companiesArray.push(item as companyArray);
        });

        setCompanies(companiesArray);
        setShowListCompanyContent(true);
        setIsLoading(false);
      }
    } else {
      toast.warn("لا يوجد شركات متوفرة لهذا المربي");

      setIsLoading(false);
    }
  };

  const handleShowFeedTypeListContentModal = (id: number) => {
    //Get All farmsStatistics Data From Farms State
    setIsLoading(true);
    let data = breeders.find((c: any) => c.id === id);
    if (data !== undefined) {
      let anyData = data as any;
      if (
        anyData.farmsStatistics == null &&
        anyData.farmsStatistics.allFeedCompanies.length === 0 &&
        anyData.farmsStatistics.allFeedCompanies != null
      ) {
        toast.warn("لا يوجد أنواع أعلاف متوفرة لهذا المربي");
        setIsLoading(false);
      } else if (
        anyData.farmsStatistics != null &&
        anyData.farmsStatistics.allFeedCompanies.length === 0
      ) {
        toast.warn("لا يوجد أنواع أعلاف  متوفرة لهذا المربي");
        setIsLoading(false);
      } else {
        const feedArray: FeedTypeArray[] = [];

        anyData.farmsStatistics.allFeedCompanies.map((item: any) => {
          feedArray.push(item as FeedTypeArray);
        });

        setFeedTypes(feedArray);
        setShowListFeedTypeContent(true);
        setIsLoading(false);
      }
    } else {
      toast.warn("لا يوجد نوع داجن متوفر لهذا المربي");

      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    let mainHeaderName = "المربيين";
    dispatch(setMainHeaderName({ mainHeaderName }));
    moment.locale("ar");
    if (
      stateFromBreedersModalSlice.isFiltered === true &&
      stateFromBreedersModalSlice.headerType === 1 &&
      stateFromBreedersModalSlice.filterDataObj.length > 0
    ) {
      if (stateFromBreedersModalSlice.filterDataObj[0].length > 0) {
        setBreeders(stateFromBreedersModalSlice.filterDataObj[0]);
        setTotalRows(stateFromBreedersModalSlice.filterTotalCount);
        setTotalPages(
          Math.ceil(stateFromBreedersModalSlice.filterTotalCount / pageSize)
        );
        setIsLoading(false);
      } else {
        setBreeders([]);
        setTotalRows(0);
        setTotalPages(1);
        setIsLoading(false);
      }
    } else {
      setPageNumber(1);
      API.get(`breeders?page=${pageNumber}&limit=${pageSize}`).then((res) => {
        if (res) {
          setBreeders(res.data.items);
          setTotalRows(res.data.totalCount);
          setTotalPages(Math.ceil(res.data.totalCount / pageSize));
          setIsLoading(false);
        }
      });
    }
  }, [
    dispatch,
    stateFromBreedersModalSlice.filterDataObj,
    stateFromBreedersModalSlice.filterTotalCount,
    stateFromBreedersModalSlice.headerType,
    stateFromBreedersModalSlice.isFiltered,
  ]);

  const hanldeChangePage = (targetPN: number) => {
    setIsLoading(true);
    if (
      stateFromBreedersModalSlice.isFiltered === true &&
      stateFromBreedersModalSlice.headerType === 1 &&
      stateFromBreedersModalSlice.filterDataObj.length > 0
    ) {
      if (stateFromBreedersModalSlice.filterDataObj[0].length > 0) {
        API.get(
          `${stateFromBreedersModalSlice.filterUrl}page=${targetPN}&limit=${pageSize}`
        ).then((res) => {
          if (res) {
            if (res.status === 403) {
              toast.error(" عفوا انت ليس لديك صلاحية الوصول لهذه الصفحة ");
            } else {
              setBreeders(res.data.items);
              setTotalRows(res.data.totalCount);
              setTotalPages(Math.ceil(res.data.totalCount / pageSize));
              setPageNumber(targetPN);
              setIsLoading(false);
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }
          }
        });
      }
    } else if (isSorted === true) {
      let key = sortedKey;

      API.get(`breeders?page=${targetPN}&limit=${pageSize}&${key}`).then(
        (res) => {
          if (res.status === 200) {
            setBreeders(res.data.items);
            setPageNumber(targetPN);
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          }
        }
      );
    } else {
      API.get(`breeders?page=${targetPN}&limit=${pageSize}`).then((res) => {
        if (res) {
          if (res.status === 403) {
            toast.error(" عفوا انت ليس لديك صلاحية الوصول لهذه الصفحة ");
          } else {
            setBreeders(res.data.items);
            setTotalRows(res.data.totalCount);
            setTotalPages(Math.ceil(res.data.totalCount / pageSize));
            setPageNumber(targetPN);
            setIsLoading(false);
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }
        }
      });
    }
  };

  const handleActivateAccount = (breederId: number) => {
    setIsLoading(true);
    API.post(`users/${breederId}/activate`)
      .then((res) => {
        if (res.status === 201) {
          const breedersArray: Breeder[] = [...breeders]; // Array of Breeder objects

          const targetObj = breedersArray.find(
            (c: Breeder) => Number(c.id) === breederId
          );

          if (targetObj !== undefined) {
            // Check if targetObj is not undefined
            targetObj.account_status = 2;

            const index = breedersArray.findIndex(
              (c: Breeder) => Number(c.id) === breederId
            );

            if (index !== -1) {
              // Replace the original object with the modified one
              breedersArray[index] = targetObj!;
            }
          }

          toast.success("تم تفعيل الحساب بنجاح");
          setIsLoading(false);
        } else {
          toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSuspendAccount = (breederId: number) => {
    setIsLoading(true);
    API.post(`users/${breederId}/suspend`)
      .then((res) => {
        if (res.status === 201) {
          const breedersArray: Breeder[] = [...breeders]; // Array of Breeder objects

          const targetObj = breedersArray.find(
            (c: Breeder) => Number(c.id) === breederId
          );

          if (targetObj !== undefined) {
            // Check if targetObj is not undefined
            targetObj.account_status = 3;

            const index = breedersArray.findIndex(
              (c: Breeder) => Number(c.id) === breederId
            );

            if (index !== -1) {
              // Replace the original object with the modified one
              breedersArray[index] = targetObj!;
            }
          }

          toast.success("تم ايقاف الحساب بنجاح");
          setIsLoading(false);
        } else {
          toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const sortData = (property: string) => {
    setIsLoading(true);
    setIsSorted(true);

    switch (property) {
      case "approx_breeding_size_desc":
        setSortedKey(`sort_by=approx_breeding_size&sort_order=DESC`);
        API.get(
          `breeders?page=${pageNumber}&limit=${pageSize}&sort_by=approx_breeding_size&sort_order=DESC`
        ).then((res) => {
          if (res.status === 200) {
            setBreeders(res.data.items);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          }
        });
        break;

      case "approx_breeding_size_asc":
        setSortedKey(`sort_by=approx_breeding_size&sort_order=ASC`);
        API.get(
          `breeders?page=${pageNumber}&limit=${pageSize}&sort_by=approx_breeding_size&sort_order=ASC`
        ).then((res) => {
          if (res.status === 200) {
            setBreeders(res.data.items);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          }
        });
        break;

      case "farms_count_desc":
        setSortedKey(`sort_by=farms_count&sort_order=DESC`);
        API.get(
          `breeders?page=${pageNumber}&limit=${pageSize}&sort_by=farms_count&sort_order=DESC`
        ).then((res) => {
          if (res.status === 200) {
            setBreeders(res.data.items);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          }
        });
        break;

      case "farms_count_asc":
        setSortedKey(`sort_by=farms_count&sort_order=ASC`);
        API.get(
          `breeders?page=${pageNumber}&limit=${pageSize}&sort_by=farms_count&sort_order=ASC`
        ).then((res) => {
          if (res.status === 200) {
            setBreeders(res.data.items);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          }
        });
        break;

      case "home_breeding_desc":
        setSortedKey(`sort_by=home_breeding&sort_order=DESC`);
        API.get(
          `breeders?page=${pageNumber}&limit=${pageSize}&sort_by=home_breeding&sort_order=DESC`
        ).then((res) => {
          if (res.status === 200) {
            setBreeders(res.data.items);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          }
        });
        break;

      case "incubator_desc":
        setSortedKey(`sort_by=incubator&sort_order=DESC`);
        API.get(
          `breeders?page=${pageNumber}&limit=${pageSize}&sort_by=incubator&sort_order=DESC`
        ).then((res) => {
          if (res.status === 200) {
            setBreeders(res.data.items);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          }
        });
        break;

      case "pay_method_desc":
        setSortedKey(`sort_by=pay_method&sort_order=DESC`);
        API.get(
          `breeders?page=${pageNumber}&limit=${pageSize}&sort_by=pay_method&sort_order=DESC`
        ).then((res) => {
          if (res.status === 200) {
            setBreeders(res.data.items);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          }
        });
        break;

      case "farms_capacity_id_desc":
        setSortedKey(`sort_by=farms_capacity_id&sort_order=DESC`);
        API.get(
          `breeders?page=${pageNumber}&limit=${pageSize}&sort_by=farms_capacity_id&sort_order=DESC`
        ).then((res) => {
          if (res.status === 200) {
            setBreeders(res.data.items);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          }
        });
        break;

      case "farms_capacity_id_asc":
        setSortedKey(`sort_by=farms_capacity_id&sort_order=ASC`);
        API.get(
          `breeders?page=${pageNumber}&limit=${pageSize}&sort_by=farms_capacity_id&sort_order=ASC`
        ).then((res) => {
          if (res.status === 200) {
            setBreeders(res.data.items);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          }
        });
        break;

      default:
        break;
    }
  };

  const loadCreatorName = (item: any) => {
    let name =
      item.created_by_role === 900 ? (
        <>
          {item.creator != null ? (
            <>
              <Link to={`/Users/Profile/PersonalData/${item.creator.id}`}>
                {item?.creator != null ? item?.creator.name : ""} - (مندوب)
              </Link>
            </>
          ) : (
            ""
          )}
        </>
      ) : item.created_by_role === 1000 ? (
        <>
          {item.creatorAdmin != null ? (
            <>
              {item.creatorAdmin != null ? item.creatorAdmin.name : ""} - (ادمن)
            </>
          ) : (
            ""
          )}
        </>
      ) : null;
    return name;
  };

  return (
    <div className="Breeders grid grid-cols-2 my-4 pt-5">
      {/* Total Count */}
      <div className="lg:col-start-1 md:col-start-1 sm:col-start-1 xs:col-start-1 sm:mb-3 xs:mb-3 mt-4">
        <TotalCount count={totalRows} title="المربيين" />
      </div>

      {/* Sorting && Export */}
      <div className="lg:col-start-2 md:col-start-1 sm:col-start-1 xs:col-start-1 flex lg:flex-row md:flex-col sm:flex-col xs:flex-col xs:items-center lg:justify-end md:justify-start sm:justify-start xs:justify-start">
        <div className="dropdown">
          <label
            tabIndex={0}
            className="flex items-center justify-end gap-1 rounded-full bg-white p-3"
          >
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M16.0686 15H7.9313C7.32548 15 7.02257 15 6.88231 15.1198C6.76061 15.2238 6.69602 15.3797 6.70858 15.5393C6.72305 15.7232 6.93724 15.9374 7.36561 16.3657L11.4342 20.4344C11.6323 20.6324 11.7313 20.7314 11.8454 20.7685C11.9458 20.8011 12.054 20.8011 12.1544 20.7685C12.2686 20.7314 12.3676 20.6324 12.5656 20.4344L16.6342 16.3657C17.0626 15.9374 17.2768 15.7232 17.2913 15.5393C17.3038 15.3797 17.2392 15.2238 17.1175 15.1198C16.9773 15 16.6744 15 16.0686 15Z"
                  stroke="#949494"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>{" "}
                <path
                  d="M7.9313 9.00005H16.0686C16.6744 9.00005 16.9773 9.00005 17.1175 8.88025C17.2393 8.7763 17.3038 8.62038 17.2913 8.46082C17.2768 8.27693 17.0626 8.06274 16.6342 7.63436L12.5656 3.56573C12.3676 3.36772 12.2686 3.26872 12.1544 3.23163C12.054 3.199 11.9458 3.199 11.8454 3.23163C11.7313 3.26872 11.6323 3.36772 11.4342 3.56573L7.36561 7.63436C6.93724 8.06273 6.72305 8.27693 6.70858 8.46082C6.69602 8.62038 6.76061 8.7763 6.88231 8.88025C7.02257 9.00005 7.32548 9.00005 7.9313 9.00005Z"
                  stroke="#949494"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>{" "}
              </g>
            </svg>
            <span className="sorting">الترتيب حسب</span>
          </label>
          <div className="dropdown-menu bg-white z-50">
            <a
              tabIndex={-1}
              className={`dropdown-item cursor-pointer rounded-md border-2 p-1 mt-2 w-full text-sm text-right ${
                sortedKey == "sort_by=approx_breeding_size&sort_order=DESC"
                  ? "sorting-item-active"
                  : "sorting-item"
              } `}
              onClick={() => sortData("approx_breeding_size_desc")}
            >
              حجم التربية التقريبي الأكبر
            </a>

            <a
              tabIndex={-1}
              className={`dropdown-item cursor-pointer rounded-md border-2 p-1 mt-2 w-full text-sm text-right ${
                sortedKey == "sort_by=approx_breeding_size&sort_order=ASC"
                  ? "sorting-item-active"
                  : "sorting-item"
              } `}
              onClick={() => sortData("approx_breeding_size_asc")}
            >
              حجم التربية التقريبي الأصغر
            </a>

            <a
              tabIndex={-1}
              className={`dropdown-item cursor-pointer rounded-md border-2 p-1 mt-2 w-full text-sm text-right ${
                sortedKey == "sort_by=farms_count&sort_order=DESC"
                  ? "sorting-item-active"
                  : "sorting-item"
              } `}
              onClick={() => sortData("farms_count_desc")}
            >
              عدد العنابر الأكبر
            </a>

            <a
              tabIndex={-1}
              className={`dropdown-item cursor-pointer rounded-md border-2 p-1 mt-2 w-full text-sm text-right ${
                sortedKey == "sort_by=farms_count&sort_order=ASC"
                  ? "sorting-item-active"
                  : "sorting-item"
              } `}
              onClick={() => sortData("farms_count_asc")}
            >
              عدد العنابر الأصغر
            </a>
            <a
              tabIndex={-1}
              className={`dropdown-item cursor-pointer rounded-md border-2 p-1 mt-2 w-full text-sm text-right ${
                sortedKey == "sort_by=home_breeding&sort_order=DESC"
                  ? "sorting-item-active"
                  : "sorting-item"
              } `}
              onClick={() => sortData("home_breeding_desc")}
            >
              تربية منزلية
            </a>
            <a
              tabIndex={-1}
              className={`dropdown-item cursor-pointer rounded-md border-2 p-1 mt-2 w-full text-sm text-right ${
                sortedKey == "sort_by=incubator&sort_order=DESC"
                  ? "sorting-item-active"
                  : "sorting-item"
              } `}
              onClick={() => sortData("incubator_desc")}
            >
              مربي أعمار
            </a>

            <a
              tabIndex={-1}
              className={`dropdown-item cursor-pointer rounded-md border-2 p-1 mt-2 w-full text-sm text-right ${
                sortedKey == "sort_by=pay_method&sort_order=DESC"
                  ? "sorting-item-active"
                  : "sorting-item"
              } `}
              onClick={() => sortData("pay_method_desc")}
            >
              طريقة الدفع
            </a>

            <a
              tabIndex={-1}
              className={`dropdown-item cursor-pointer rounded-md border-2 p-1 mt-2 w-full text-sm text-right ${
                sortedKey == "sort_by=farms_capacity_id&sort_order=DESC"
                  ? "sorting-item-active"
                  : "sorting-item"
              } `}
              onClick={() => sortData("farms_capacity_id_desc")}
            >
              سعة العنابر الأكبر{" "}
            </a>
            <a
              tabIndex={-1}
              className={`dropdown-item cursor-pointer rounded-md border-2 p-1 mt-2 w-full text-sm text-right ${
                sortedKey == "sort_by=farms_capacity_id&sort_order=ASC"
                  ? "sorting-item-active"
                  : "sorting-item"
              } `}
              onClick={() => sortData("farms_capacity_id_asc")}
            >
              سعة العنابر الأصغر
            </a>
          </div>
        </div>
        <div>
          <ExcelExportDetails
            data={breeders}
            title="Breeders"
            url={`breeders?page=1&limit=10000000000000`}
            type={5}
          />
        </div>
      </div>

      {isLoading === true ? (
        <>
          <Loading />
        </>
      ) : null}

      {showListContentModal === true ? (
        <ListContent data={Dajins} title="نوع الداجن" />
      ) : null}

      {showListFeedTypeContent === true ? (
        <ListContent data={feedTypes} title="نوع الأعلاف" />
      ) : null}

      {showListCompanyContent === true ? (
        <ListContent data={companies} title="الشركات" />
      ) : null}

      {showBreedTypeListContentModal === true ? (
        <ListContent data={breedTypes} title="نوع السلالة" />
      ) : null}

      {breeders.map((item: any) => {
        const createdAtDate = moment(item.created_at);
        moment.locale("ar");
        const createdAtDate_Arabic = createdAtDate.format("DD MMM YYYY");
        return (
          <>
            <div className="grid grid-cols-2 col-span-full mb-4 mr-4 ml-4 mt-4 p-4 mission-content">
              {/* Name && Id   */}
              <div className="lg:col-span-1 md:col-span-full sm:col-span-full xs:col-span-full grid grid-cols-2 p-4 mb-2 rounded-md border-2">
                <div className="col-span-1">
                  <Link to={`/Breeders/${item.id}/AccountDetails`}>
                    <span className="title wrap cursor-pointer">
                      {item.name}
                    </span>
                  </Link>
                </div>
                <div className="lg:col-span-1 md:col-span-1 sm:col-span-1 xs:col-span-1 pr-4 flex lg:justify-center md:justify-start sm:justify-start xs:justify-start">
                  <span className="title">{item.id}</span>
                </div>
              </div>
              {/* المحافظة / المدينة /  رقم الهاتف / رقم الواتساب/ القرية */}
              <div className="col-span-2 grid grid-cols-3 gap-4 p-3 mb-2 rounded-md border-2 w-full">
                {/* المحافظة */}
                <div className="lg:col-span-1 md:col-span-full sm:col-span-full xs:col-span-full mt-2 flex gap-4 justify-start">
                  <span className="title">المحافظة :</span>
                  <span className="mission-type">
                    {item.governorate != null ? item.governorate.name : ""}
                  </span>
                </div>

                {/* المدينة */}
                <div className="lg:col-span-1 md:col-span-full sm:col-span-full xs:col-span-full mt-2 flex justify-start">
                  <span className="title">المدينة :</span>
                  <span className="mission-type">
                    {item.city != null ? item.city.name : ""}
                  </span>
                </div>

                {/* القرية */}
                <div className="lg:col-span-1 md:col-span-full sm:col-span-full xs:col-span-full mt-2 flex gap-4 justify-start">
                  <span className="title">القرية :</span>

                  <span className="mission-type">{item.full_address}</span>
                </div>
                {/* رقم الهاتف */}
                <div className="lg:col-span-1 md:col-span-full sm:col-span-full xs:col-span-full">
                  <div className="inline-flex justify-start gap-2">
                    <span className="title">رقم الهاتف :</span>
                    <span className="mission-type">{item.mobile_number}</span>
                  </div>
                </div>

                {/* رقم الواتساب */}
                <div className="lg:col-span-1 md:col-span-full sm:col-span-full xs:col-span-full">
                  <div className="inline-flex justify-start gap-2">
                    <span className="title">رقم الواتساب :</span>
                    <span className="mission-type">{item.whatsapp_number}</span>
                  </div>
                </div>
              </div>
              {/* حجم التربية التقريبي/دورات التسكين/دورات التسكين النشطة/ عدد الطيور النشطة / اجمالي عنابر التربية / اجمالي العنابر النشطة /السعة القصوي للعنابر / نوع الاعلاف /الشركات/ نوع السلالة / نوع الداجن */}
              <div className="col-span-2 grid grid-cols-3 gap-4 p-3 mb-2 rounded-md border-2 w-full">
                {/* حجم التربية التقريبي */}
                <div className="lg:col-span-1 md:col-span-full sm:col-span-full xs:col-span-full">
                  <div className="inline-flex gap-2">
                    <span className="title">حجم التربية التقريبي :</span>

                    <span className="mission-type">
                      {" "}
                      {item.approx_breeding_size}
                    </span>
                  </div>
                </div>

                {/* دورات التسكين */}
                <div className="lg:col-span-1 md:col-span-full sm:col-span-full xs:col-span-full">
                  <span className="title">دورات التسكين :</span>
                  <span className="mission-type">
                    {" "}
                    {item.farmsStatistics != null
                      ? item.farmsStatistics.totalHousingCount
                      : 0}
                  </span>
                </div>

                {/* دورات التسكين النشطة */}
                <div className="lg:col-span-1 md:col-span-full sm:col-span-full xs:col-span-full">
                  <span className="title">دورات التسكين النشطة :</span>
                  <span className="mission-type">
                    {" "}
                    {item.farmsStatistics != null
                      ? item.farmsStatistics.activeHousingCount
                      : 0}
                  </span>
                </div>

                {/* عدد الطيور النشطة */}
                <div className="lg:col-span-1 md:col-span-full sm:col-span-full xs:col-span-full">
                  <span className="title">عدد الطيور النشطة :</span>
                  <span className="mission-type">
                    {" "}
                    {item.farmsStatistics != null
                      ? item.farmsStatistics.housedBirdsCount
                      : 0}
                  </span>
                </div>

                <div className="lg:col-span-1 md:col-span-full sm:col-span-full xs:col-span-full">
                  <span className="title">اجمالي عنابر التربية :</span>
                  <span className="mission-type"> {item.farms_count}</span>
                </div>
                {/* اجمالي العنابر النشطة */}
                <div className="lg:col-span-1 md:col-span-full sm:col-span-full xs:col-span-full">
                  <span className="title">اجمالي العنابر النشطة :</span>
                  <span className="mission-type">
                    {" "}
                    {item.farmsStatistics != null
                      ? item.farmsStatistics.activeFarmsCount
                      : 0}
                  </span>
                </div>

                {/* السعه القصوي للعنابر */}
                <div className="lg:col-span-1 md:col-span-full sm:col-span-full xs:col-span-full">
                  <span className="title">السعة القصوي للعنابر :</span>
                  <span className="mission-type">
                    {" "}
                    {item.farms_capacity != null
                      ? item.farms_capacity.capacity
                      : 0}
                  </span>
                </div>

                {/* نوع الأعلاف */}
                <div className="lg:col-span-1 md:col-span-full sm:col-span-full xs:col-span-full">
                  <label htmlFor="modal-نوع الأعلاف">
                    <div
                      className="inline-flex justify-start gap-2"
                      onClick={() =>
                        handleShowFeedTypeListContentModal(item.id)
                      }
                    >
                      <span className="title cursor-pointer">نوع الأعلاف</span>
                    </div>
                  </label>
                </div>

                {/* الشركات */}
                <div className="lg:col-span-1 md:col-span-full sm:col-span-full xs:col-span-full">
                  <label htmlFor="modal-الشركات">
                    <div
                      className="inline-flex justify-start gap-2"
                      onClick={() =>
                        handleShowCompaniesListContentModal(item.id)
                      }
                    >
                      <span className="title cursor-pointer">الشركات</span>
                    </div>
                  </label>
                </div>

                {/* نوع السلالة */}
                <div className="lg:col-span-1 md:col-span-full sm:col-span-full xs:col-span-full">
                  <label htmlFor="modal-نوع السلالة">
                    <div
                      className="inline-flex justify-start gap-2"
                      onClick={() =>
                        handleShowBreedTypesListContentModal(item.id)
                      }
                    >
                      <span className="title cursor-pointer">نوع السلالة</span>
                    </div>
                  </label>
                </div>

                {/* نوع الداجن */}
                <div className="lg:col-span-1 md:col-span-full sm:col-span-full xs:col-span-full">
                  <label htmlFor="modal-نوع الداجن">
                    <div
                      className="inline-flex justify-start gap-2"
                      onClick={() => handleShowDajinListContentModal(item.id)}
                    >
                      <span className="title cursor-pointer">نوع الداجن</span>
                    </div>
                  </label>
                </div>
              </div>
              {/* تربية منزلية / كاش /الحالة / الغاء التفعيل */}
              <div className="col-span-2 grid grid-cols-3 gap-4 p-3 mb-2 rounded-md border-2 w-full">
                {item.home_breeding === 1 ? (
                  <>
                    <div className="lg:col-span-1 md:col-span-full sm:col-span-full xs:col-span-full">
                      <div className="home_breeding">
                        <span> تربية منزلية </span>
                      </div>
                    </div>
                  </>
                ) : null}

                {item.pay_method != null ? (
                  <>
                    {item.pay_method === "cash" ? (
                      <>
                        <div className="lg:col-span-1 md:col-span-full sm:col-span-full xs:col-span-full">
                          <div className="paymentMethod">
                            <div>
                              <svg
                                width="22px"
                                height="22px"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g
                                  id="SVGRepo_tracerCarrier"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></g>
                                <g id="SVGRepo_iconCarrier">
                                  {" "}
                                  <path
                                    d="M12 17V17.5V18"
                                    stroke="#70D44B"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                  ></path>{" "}
                                  <path
                                    d="M12 6V6.5V7"
                                    stroke="#70D44B"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                  ></path>{" "}
                                  <path
                                    d="M15 9.5C15 8.11929 13.6569 7 12 7C10.3431 7 9 8.11929 9 9.5C9 10.8807 10.3431 12 12 12C13.6569 12 15 13.1193 15 14.5C15 15.8807 13.6569 17 12 17C10.3431 17 9 15.8807 9 14.5"
                                    stroke="#70D44B"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                  ></path>{" "}
                                  <path
                                    d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7"
                                    stroke="#70D44B"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                  ></path>{" "}
                                </g>
                              </svg>
                            </div>
                            <span> كاش </span>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {" "}
                        <div className="lg:col-span-1 md:col-span-full sm:col-span-full xs:col-span-full">
                          <div className="paymentMethod">
                            <div>
                              <svg
                                width="22px"
                                height="22px"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g
                                  id="SVGRepo_tracerCarrier"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></g>
                                <g id="SVGRepo_iconCarrier">
                                  {" "}
                                  <path
                                    d="M12 17V17.5V18"
                                    stroke="#70D44B"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                  ></path>{" "}
                                  <path
                                    d="M12 6V6.5V7"
                                    stroke="#70D44B"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                  ></path>{" "}
                                  <path
                                    d="M15 9.5C15 8.11929 13.6569 7 12 7C10.3431 7 9 8.11929 9 9.5C9 10.8807 10.3431 12 12 12C13.6569 12 15 13.1193 15 14.5C15 15.8807 13.6569 17 12 17C10.3431 17 9 15.8807 9 14.5"
                                    stroke="#70D44B"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                  ></path>{" "}
                                  <path
                                    d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7"
                                    stroke="#70D44B"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                  ></path>{" "}
                                </g>
                              </svg>
                            </div>
                            <span> إئتمان </span>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ) : null}

                {item.incubator === 1 ? (
                  <>
                    <div className="lg:col-span-1 md:col-span-full sm:col-span-full xs:col-span-full">
                      <div className="incubator">
                        <span> مربي أعمار </span>
                      </div>
                    </div>
                  </>
                ) : null}

                <div className="lg:col-span-1 md:col-span-full sm:col-span-full xs:col-span-full">
                  <div className="flex gap-2 items-center">
                    <span className="title">الحالة :</span>
                    <span
                      className={
                        item.account_status === 2
                          ? "breeder-active"
                          : "breeder-disable"
                      }
                    >
                      {item.account_status === 2 ? "مفعل" : "غير مفعل"}
                    </span>
                  </div>
                </div>
                <div className="lg:col-span-1 md:col-span-full sm:col-span-full xs:col-span-full">
                  {item.account_status === 2 ? (
                    <button
                      onClick={() => handleSuspendAccount(Number(item.id))}
                      type="button"
                      className="suspend_btn"
                    >
                      الغاء التفعيل
                    </button>
                  ) : (
                    <button
                      onClick={() => handleActivateAccount(Number(item.id))}
                      type="button"
                      className="activate_btn"
                    >
                      تفعيل
                    </button>
                  )}
                </div>
              </div>
              {/* Created by */}
              <div className="col-span-2 grid grid-cols-3 gap-4 p-3 mb-2 rounded-md border-2 w-full">
                <div className="lg:col-span-1 md:col-span-full sm:col-span-full xs:col-span-full">
                  <div className="created-at">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="16"
                      viewBox="0 0 15 16"
                      fill="none"
                    >
                      <path
                        d="M1.875 6.6875C1.875 4.47836 3.66586 2.6875 5.875 2.6875H9.125C11.3341 2.6875 13.125 4.47836 13.125 6.6875V10.25C13.125 12.4591 11.3341 14.25 9.125 14.25H5.875C3.66586 14.25 1.875 12.4591 1.875 10.25V6.6875Z"
                        stroke="#9BA0B1"
                        stroke-width="1.5"
                      />
                      <path
                        d="M1.875 6.125H13.125"
                        stroke="#9BA0B1"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                      <path
                        d="M5 1.75L5 3.625"
                        stroke="#9BA0B1"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10 1.75V3.625"
                        stroke="#9BA0B1"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <circle cx="7.5" cy="9.875" r="0.625" fill="#9BA0B1" />
                      <circle cx="10" cy="9.875" r="0.625" fill="#9BA0B1" />
                      <circle cx="5" cy="9.875" r="0.625" fill="#9BA0B1" />
                    </svg>
                    انشئت في : {createdAtDate_Arabic}
                  </div>
                </div>
                <div className="lg:col-span-1 md:col-span-full sm:col-span-full xs:col-span-full">
                  <div className="created-at">
                    <svg
                      width="15"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path
                          d="M20 18L14 18M17 15V21M4 21C4 17.134 7.13401 14 11 14C11.695 14 12.3663 14.1013 13 14.2899M15 7C15 9.20914 13.2091 11 11 11C8.79086 11 7 9.20914 7 7C7 4.79086 8.79086 3 11 3C13.2091 3 15 4.79086 15 7Z"
                          stroke="#9BA0B1"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>{" "}
                      </g>
                    </svg>
                    انشئت بواسطة : {loadCreatorName(item)}
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      })}

      {/* Pagination */}

      <div className="col-start-2 flex justify-end pt-4 pl-12">
        <Pagination
          currentPage={pageNumber}
          lastPage={totalPages}
          maxLength={7}
          setCurrentPage={hanldeChangePage}
        />
      </div>
    </div>
  );
};
