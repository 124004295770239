import "./DatePicker.scss";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  setDueDateValue,
  setExpectedDateValue,
  setHousingDateValue,
} from "../../../../../redux/Slices/MissionSlice";
type DatePickerComponentProps = {
  activation: boolean;
  isEdit: boolean;
  due_date?: Date;
  component?: string;
  minDate?: Date;
};
export const DatePickerComponent: React.FC<DatePickerComponentProps> = ({
  activation,
  isEdit,
  due_date = new Date(),
  minDate = new Date(),
  component,
}) => {
  const [startDate, setStartDate] = useState(new Date());
  const [changed, setChanged] = useState(false);
  const [componentName, setComponentName] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    setComponentName(component ?? "");
  }, []);
  const handleChangeDate = (date: any) => {
    setStartDate(date);
    setChanged(true);
    let dueDate = date;
    let isDateChanged = true;
    dispatch(setDueDateValue({ dueDate, isDateChanged }));
    if (component === "expectedDate") {
      dispatch(setExpectedDateValue({ dueDate, isDateChanged }));
    } else if (component === "housingDate") {
      dispatch(setHousingDateValue({ dueDate, isDateChanged }));
    }
  };
  return (
    <div className="date-picker-container-component">
      <DatePicker
        selected={isEdit === true && changed === false ? due_date : startDate}
        onChange={(event) => handleChangeDate(event)}
        className="date-picker-input"
        disabled={activation}
        minDate={
          component === "expectedDate" || component === "housingDate"
            ? null
            : new Date()
        }
      />
    </div>
  );
};
