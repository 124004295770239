import { useState, useEffect } from "react";
import "./AccountStatement.scss";
import DatePicker from "react-multi-date-picker";
import { DateObject } from "react-multi-date-picker";
import { setFilterDate } from "../../../../redux/Slices/WalletsSlice";
import { useDispatch, useSelector } from "react-redux";
import { Loading } from "../../../Loading/Loading";
import { toast } from "react-toastify";
import moment from "moment";
import API from "../../../../Api";
import { useParams } from "react-router-dom";
import Pagination from "../../../../SubComponents/Paginator/Pagination";
import { ExcelExportDetails } from "../../../../SubComponents/Export/ExcelExportDetails";
export const AccountStatement = () => {
  const [values, setValues] = useState([new DateObject(), new DateObject()]);
  const [isLoading, setIsLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const { userId } = useParams<{ userId: string }>();
  const [endPoint, setEndPoint] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [showFilter, setShowFilter] = useState(false);

  const dispatch = useDispatch();
  const stateFromWalletsSlice = useSelector((state: any) => state.wallets);

  useEffect(() => {
    setIsLoading(true);
    if (fromDate === "" || toDate === "") {
      setPageNumber(1);
      //Get Current Day
      const currentDate = new Date();
      moment.locale("en");
      // Format the date as a string (you can customize the format as needed)
      const formattedDate = moment(currentDate).format("YYYY-MM-DD");
      let uRL = `dashboard/wallets/transactions?user_id=${userId}&date_from=${formattedDate}&date_to=${formattedDate}`;
      setEndPoint(uRL);

      API.get(`${uRL}&page=${pageNumber}&limit=${pageSize}`).then((res) => {
        if (res) {
          setTransactions(res.data.items);
          setTotalRows(res.data.totalCount);
          setTotalPages(Math.ceil(res.data.totalCount / pageSize));
          setIsLoading(false);
        } else {
          toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          setIsLoading(false);
        }
      });
    } else {
      let uRL = `dashboard/wallets/transactions?date_from=${fromDate}&date_to=${toDate}&user_id=${userId}`;
      setEndPoint(uRL);

      API.get(`${uRL}&page=${pageNumber}&limit=${pageSize}`).then((res) => {
        if (res) {
          setTransactions(res.data.items);
          setTotalRows(res.data.totalCount);
          setTotalPages(Math.ceil(res.data.totalCount / pageSize));
          setIsLoading(false);
        } else {
          toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          setIsLoading(false);
        }
      });
    }
  }, [toDate, userId]);

  const hanldeChangePage = (targetPN: number) => {
    setIsLoading(true);

    API.get(`${endPoint}&page=${targetPN}&limit=${pageSize}`).then((res) => {
      if (res) {
        if (res.status === 403) {
          toast.error(" عفوا انت ليس لديك صلاحية الوصول لهذه الصفحة ");
        } else {
          setTransactions(res.data.items);
          setTotalRows(res.data.totalCount);
          setTotalPages(Math.ceil(res.data.totalCount / pageSize));
          setPageNumber(targetPN);
          setIsLoading(false);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }
      }
    });
  };

  const handleDateChange = (newValues: any) => {
    // Handle newValues as needed
    setValues(newValues);
    let fromDate = newValues[0];
    setFromDate(newValues[0]);
    let toDate = newValues[1];

    if (toDate !== undefined) {
      setToDate(newValues[1]);
      setShowFilter(true);
    }
  };

  const handleResetFilter = () => {
    let newValues = [new DateObject(), new DateObject()];
    setValues(newValues);
    setFromDate("");
    setToDate("");
    setShowFilter(false);
  };

  return (
    <div className="AccountStatement pr-10 pl-10 mt-4">
      {isLoading === true ? (
        <>
          <Loading />
        </>
      ) : null}
      <div className="grid grid-cols-2">
        <h2 className="title col-start-1">كشف حساب</h2>

        <div className="col-start-2 flex justify-end">
          <div className="flex justify-between items-center gap-4">
            {showFilter ? (
              <>
                <svg
                  onClick={() => handleResetFilter()}
                  width="24px"
                  height="24px"
                  viewBox="-1 0 18 18"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#A7AEC1"
                  stroke="#A7AEC1"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <title>arrow_repeat [#A7AEC1]</title>{" "}
                    <desc>Created with Sketch.</desc> <defs> </defs>{" "}
                    <g
                      id="Page-1"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      {" "}
                      <g
                        id="Dribbble-Light-Preview"
                        transform="translate(-302.000000, -7080.000000)"
                        fill="#A7AEC1"
                      >
                        {" "}
                        <g
                          id="icons"
                          transform="translate(56.000000, 160.000000)"
                        >
                          {" "}
                          <path
                            d="M260,6930 C260,6933 257.308,6936 254,6936 C250.692,6936 248,6933.308 248,6930 C248,6926.692 250.692,6924 254,6924 L254,6926 L259,6923 L254,6920 L254,6922 C249.582,6922 246,6925.582 246,6930 C246,6934.418 249.582,6938 254,6938 C258.418,6938 262,6935 262,6930 L260,6930 Z"
                            id="arrow_repeat-[#A7AEC1]"
                          >
                            {" "}
                          </path>{" "}
                        </g>{" "}
                      </g>{" "}
                    </g>{" "}
                  </g>
                </svg>
              </>
            ) : null}
            <DatePicker
              inputClass="filterWallet"
              value={values}
              dateSeparator="الي"
              onChange={handleDateChange}
              range
              // rangeHover
              format="YYYY-MM-DD"
            />
          </div>
        </div>

        <h2 className="title flex justify-start">
          <ExcelExportDetails
            data={transactions}
            title={`All Transaction For ${userId}`}
            url={`${endPoint}&page=1&limit=100000000000000`}
            type={4}
          />
        </h2>

        <div className="col-span-full mt-10">
          <table
            style={{
              width: "100%",
            }}
          >
            <thead>
              <tr>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                  }}
                >
                  <th className="bannerText">التاريخ</th>
                  <th className="bannerText">المندوب</th>
                  <th className="bannerText">مبلغ دائن</th>
                  <th className="bannerText">مبلغ مدين</th>
                  <th className="bannerText">الرصيد</th>
                  <th className="bannerText">البيان</th>
                </div>
              </tr>
            </thead>
            <div
              style={{
                visibility: "hidden",
                marginTop: "20px",
                marginBottom: "80px",
              }}
            ></div>
            <tbody>
              {transactions.map((item: any) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      backgroundColor: "#FFF",
                      borderRadius: "20px",
                      height: "70px",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <span className="wallet-date">
                      {moment(item.created_at).format("YYYY/MM/DD")}
                    </span>
                    <span className="wallet-client-name">
                      {" "}
                      {item.user !== null ? item.user.name : ""}
                    </span>
                    {/* مبلغ دائن */}
                    <span className="wallet-price">
                      {" "}
                      {item.type === "مدينة" ? item.amount : "٠"} جم
                    </span>
                    {/* مبلغ مدين */}
                    <span className="wallet-price">
                      {" "}
                      {item.type === "دائنة" ? item.amount : "٠"} جم
                    </span>
                    <span className="wallet-price"> ١000 جم</span>
                    <span className="wallet-price">
                      <span className="mission-number">
                        قيمة مهمة <span className="number">رقم</span>{" "}
                        {item.mission !== null ? item.mission.id : "٠"}
                      </span>
                    </span>
                  </div>
                );
              })}
            </tbody>
          </table>
        </div>
        {/* Pagination */}
        {totalRows === 0 ? null : (
          <>
            <div className="col-start-2 flex justify-end pt-4 pl-12">
              <Pagination
                currentPage={pageNumber}
                lastPage={totalPages}
                maxLength={7}
                setCurrentPage={hanldeChangePage}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
