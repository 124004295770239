import { useEffect, useState } from "react";
import { setMainHeaderName } from "../../../redux/Slices/MainHeaderSlice";
import "./Housing.scss";
import { toast } from "react-toastify";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { HenType } from "../SubComponents/HenType";
import { BreedType } from "../SubComponents/BreedType";
import { useNavigate } from "react-router-dom";
import arrow from "../../../Assets/Icons/arrow.jpeg";
import { Companies } from "../SubComponents/Companies";
import { Loading } from "../../Loading/Loading";
import { useParams } from "react-router-dom";
import API, { URL } from "../../../Api";
import DatePicker from "react-datepicker";
import {
  setSelectedBreedType,
  setSelectedCompany,
  setSelectedFeedType,
  setSelectedHenType,
} from "../../../redux/Slices/FarmSlice";
import { FeedType } from "../SubComponents/FeedType";
const housingData = {
  farmId: 0,
  breedId: 0,
  companyId: 0,
  chick_price: 1,
  count: 0,
  feed_co_id: 0,
  other_feed_co: "", //انواع علف اخري
  other_brooder_co: "", //شركات اخري
  housingDate: new Date(),
  expectedSaleDate: new Date(),
};

interface ApiResponse {
  id: string;
  created_at: string;
  name: string;
  village: string;
  city_id: string;
  full_address: string;
  mobile_number: string;
  whatsapp_number: string;
  facebook_account: string;
  email: string;
  avatar: string;
  approx_breeding_size: number;
  farms_count: number;
  activity_type_id: string;
  account_status: number;
  is_efb: boolean;
  national_id: string;
  specialty_id: string;
  level_id: string;
  ewallet_number: string;
  wallet_balance: number;
  activity_type: {
    id: string;
    name: string;
  };
  governorate: {
    created_at: string;
    updated_at: string;
    id: string;
    name: string;
  };
  city: {
    id: string;
    name: string;
  };
  password: string | null;
  re_password: string | null;
  incubator: number;
}

interface FarmResponse {
  id: string;
  user_id: string;
  name: string;
  area: number;
  housing_count: number;
  license_number: number;
  photo: string;
  gov_id: string;
  city_id: string;
  village: string;
  full_address: string;
  rented: number;
  latitude: number;
  longitude: number;
  dajin_breed_id: string;
  created_at: string;
  updated_at: string;
  created_by_role: number;
  incubator: number;
  creatorAdmin: {
    id: string;
    name: string;
    type: number;
    mobile_number: string;
  };
  user: {
    id: string;
    name: string;
    mobile_number: string;
  };
  dajinBreed: string;
  status: {
    id: string;
    status: string;
  };
  capacity: {
    id: string;
    capacity: string;
  };
  farmingType: {
    id: string;
    type: string;
  };
  ventilationType: {
    id: string;
    type: string;
  };
  governorate: {
    id: string;
    name: string;
  };
  city: {
    id: string;
    name: string;
  };
  creator: {
    id: string;
    name: string;
    mobile_number: string;
  };
  currentHousing: {
    id: string;
    other_brooder_co: string;
    other_feed_co: string;
    count: number;
    housing_date: string;
    expected_sale_date: string;
    company: {
      id: string;
      type: string;
    };
    feedCompany: {
      id: string;
      name: string;
    };
    dajinBreed: {
      id: string;
      dajin_id: string;
      breed_type: string;
      poultry_farming_average: number;
      poultry_farming_maximum: number;
      created_at: string;
      updated_at: string;
      dajinType: {
        id: string;
        type: string;
        trader_commission: number;
        farmer_commission: number;
        image: string;
        deleted_at: string;
        created_at: string;
        updated_at: string;
      };
    };
  };
}
export const Housing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stateFromFarmsSlice = useSelector((state: any) => state.farms);

  const { farmId } = useParams<{ farmId: string }>();
  const { breederId } = useParams<{ breederId: string }>();
  const [apiResponse, setApiResponse] = useState<ApiResponse | null>(null);
  const [farmResponse, setFarmResponse] = useState<FarmResponse | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [showTypePopUp, setShowTypePopUp] = useState(false);
  const [showFeedTypePopUp, setShowFeedTypePopUp] = useState(false);
  const [showBreedTypePopUp, setShowBreedTypePopUp] = useState(false);
  const [showCompanyPopUp, setShowCompanyPopUp] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [expectedDate, setExpectedDate] = useState(new Date());
  const [isStopped, setIsStopped] = useState(false);

  const handleShowTypePopUp = () => {
    setShowTypePopUp(!showTypePopUp);
  };

  const handleShowFeedTypePopUp = () => {
    setShowFeedTypePopUp(!showFeedTypePopUp);
  };

  const handleBreedTypePopUp = () => {
    setShowBreedTypePopUp(!showBreedTypePopUp);
  };

  const handleCompanyPopUp = () => {
    setShowCompanyPopUp(!showCompanyPopUp);
  };
  useEffect(() => {
    setIsLoading(true);
    let mainHeaderName = "تسكين عنبر";
    ResetLabels();
    dispatch(setMainHeaderName({ mainHeaderName }));
    API.get(`farms/${farmId}`).then((res) => {
      if (res.status === 200) {
        setFarmResponse(res.data);
        if (res.data.status_id != null) {
          if (Number(res.data.status_id) === 6) {
            setIsStopped(true);
          }
        }

        setIsLoading(false);
      }
    });
    API.get(`breeders/${breederId}`).then((res) => {
      if (res.status === 200) {
        setApiResponse(res.data);
        setIsLoading(false);
      }
    });
  }, []);

  const ResetLabels = () => {
    let isChanged = false;
    let submited = true;
    let breedTypeId = 0;
    let breedTypeName = "";
    dispatch(setSelectedBreedType({ breedTypeId, breedTypeName, isChanged }));
    let companyId = 0;
    let companyText = "";
    dispatch(setSelectedCompany({ companyId, companyText, isChanged }));
    let henTypeId = 0;
    let henTypeName = "";
    dispatch(
      setSelectedHenType({ henTypeId, henTypeName, isChanged, submited })
    );
    let feedTypeId = 0;
    let feedTypeName = "";
    dispatch(setSelectedFeedType({ feedTypeId, feedTypeName, isChanged }));
  };

  const handleChangeInputDate = (date: any) => {
    setStartDate(date);
  };

  const handleChangeExpectedDate = (date: any) => {
    setExpectedDate(date);
  };

  const handleChangeInput = (e: any, field: string) => {
    if (field === "count") {
      housingData.count = Number(e);
    }
  };

  const calculateDays = (startDate: any, housingDate: any): number => {
    // Convert both dates to milliseconds
    // Parse the date string into a Date object

    startDate = moment(startDate).locale("en").format("YYYY-MM-DD");

    const dateParts = startDate.split("-");
    const year = parseInt(dateParts[0]);
    const month = parseInt(dateParts[1]) - 1; // Months are 0-based in JavaScript
    const day = parseInt(dateParts[2]);
    const parsedDate = new Date(year, month, day);
    const startMilliseconds = parsedDate.getTime();
    const endMilliseconds = new Date(housingDate).getTime();

    // Calculate the difference in milliseconds
    const differenceMilliseconds = Math.abs(
      endMilliseconds - startMilliseconds
    );

    // Convert milliseconds to days
    const millisecondsInADay = 1000 * 60 * 60 * 24;
    const days = Math.floor(differenceMilliseconds / millisecondsInADay);

    return days;
  };
  const handleSubmitHousing = () => {
    setIsLoading(true);
    if (housingData.count === 0) {
      toast.error("من فضلك قم بأدخال العدد ");
      setIsLoading(false);
    } else if (stateFromFarmsSlice.selectedHenType === 0) {
      toast.error("من فضلك قم بأختيار نوع الداجن");
      setIsLoading(false);
    } else if (stateFromFarmsSlice.selectedBreedType === 0) {
      toast.error("من فضلك قم بأختيار نوع السلالة");
      setIsLoading(false);
    } else if (stateFromFarmsSlice.selectedCompanyId === 0) {
      toast.error("من فضلك قم بأختيار الشركة");
      setIsLoading(false);
    } else {
      confirmCreateHousing();
    }
  };
  const confirmCreateHousing = () => {
    setIsLoading(true);

    var dayRange = calculateDays(startDate, expectedDate);
    //لو العنبر مربي أعمار
    if (farmResponse?.incubator === 1) {
      if (dayRange <= 7 || dayRange >= 20) {
        toast.error(
          "لا يمكن تسكين عنبر لاكثر من 7 ايام ولا اقل من 20 يوم لعنبر مربي أعمار"
        );
        setIsLoading(false);

        return;
      }
    }
    //لو مختار فراخ حمرا او رومي
    else if (stateFromFarmsSlice.selectedHenType === 7) {
      if (dayRange > 90) {
        toast.error(" لا يمكن تسكين عنبر لاكثر من 90 يوم لهذا النوع من الداجن");
        setIsLoading(false);

        return;
      }
    } else {
      if (dayRange < 30 || dayRange > 40) {
        toast.error("لا يمكن تسكين عنبر لاكثر من 40 يوم ولا اقل من 30 يوم");
        setIsLoading(false);

        return;
      }
    }

    housingData.breedId = Number(stateFromFarmsSlice.selectedBreedType);
    housingData.farmId = Number(farmId);
    housingData.companyId = Number(stateFromFarmsSlice.selectedCompanyId);
    housingData.feed_co_id = Number(stateFromFarmsSlice.selectedFeedType);
    housingData.other_feed_co =
      Number(stateFromFarmsSlice.selectedFeedType) === 14
        ? stateFromFarmsSlice.selectedFeedTypeName
        : null;

    housingData.other_brooder_co =
      Number(stateFromFarmsSlice.selectedCompanyId) === 14
        ? stateFromFarmsSlice.selectedCompanyText
        : null;
    housingData.expectedSaleDate = expectedDate;
    housingData.housingDate = startDate;

    API.post("breed-housing", housingData)
      .then((res) => {
        if (res.status === 400) {
          toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");

          setIsLoading(false);
        } else {
          toast.success("تم تسكين العنبر بنجاح");
          if (isStopped === true) {
            //farm is stopped and we will activat it by adding housing cycle
            API.post(`farms/${farmId}/activate`).then((res) => {
              if (res) {
                toast.success("تم إعادة تنشيط العنبر ");
              }
            });
          }
          ResetLabels();
          navigate(`/AllFarms/${farmId}`);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (err.message === "العنبر قيد التشغيل") {
          toast.error("العنبر قيد التشغيل");
          setIsLoading(false);
        } else {
          toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          setIsLoading(false);
        }
      })
      .finally(() => {});
  };

  const handleCancelMethod = () => {
    ResetLabels();
    navigate(`/AllFarms`);
  };

  return (
    <div className="Housing">
      {isLoading === true ? (
        <>
          <Loading />
        </>
      ) : null}

      {showTypePopUp === true ? <HenType isEdit={false} /> : null}
      {showFeedTypePopUp === true ? <FeedType isEdit={false} /> : null}
      {showBreedTypePopUp === true ? <BreedType isEdit={false} /> : null}
      {showCompanyPopUp === true ? <Companies isEdit={false} /> : null}
      <div className="farm-info-section mt-4 ">
        <div className="grid grid-cols-2 pr-4 pl-4 gap-4">
          {/* Header */}
          <div className="col-span-full pr-4 pt-4 flex items-center ">
            <div className="farm-info-title w-1/2">تسكين العنبر</div>
          </div>
          <div className="col-span-full">
            <div className="divider"></div>
          </div>
          {/* نوع الداجن  */}
          <div className="col-start-1 pr-4  mt-4">
            <p className="title">نوع الداجن</p>
          </div>

          {/* نوع السلالة */}
          <div className="col-start-2 pr-4 mt-4">
            <p className="title">نوع السلالة</p>
          </div>

          {/* :input نوع الداجن  */}
          <div className="col-start-1">
            <label htmlFor="modal-80">
              <div
                className="flex justify-between select-mission-container"
                onClick={() => handleShowTypePopUp()}
              >
                <div className="select-mission-type ">
                  {stateFromFarmsSlice.selectedHenType !== 0
                    ? stateFromFarmsSlice.selectedHenTypeName
                    : "اختر نوع الداجن"}
                </div>

                <div className="arrow">
                  <img src={arrow} alt="arrow" />
                </div>
              </div>
            </label>
          </div>

          {/* نوع السلالة  input   */}
          <div className="col-start-2">
            <label htmlFor="modal-90">
              <div
                className="flex justify-between select-mission-container"
                onClick={() => handleBreedTypePopUp()}
              >
                <div className="select-mission-type ">
                  {stateFromFarmsSlice.selectedBreedType === 0
                    ? "اختر نوع السلالة"
                    : stateFromFarmsSlice.selectedBreedTypeName}
                </div>
                <div className="arrow">
                  <img src={arrow} alt="arrow" />
                </div>
              </div>
            </label>
          </div>

          {/* نوع العلف  */}
          <div className="col-start-1 pr-4  mt-4">
            <p className="title">نوع العلف</p>
          </div>

          {/* الشركة  */}
          <div className="col-start-2 pr-4 mt-4">
            <p className="title">الشركة</p>
          </div>

          {/* :input نوع العلف  */}
          <div className="col-start-1 ">
            <label htmlFor="modal-feedType">
              <div
                className="flex justify-between select-mission-container"
                onClick={() => handleShowFeedTypePopUp()}
              >
                <div className="select-mission-type">
                  {stateFromFarmsSlice.selectedFeedType !== 0
                    ? stateFromFarmsSlice.selectedFeedTypeName
                    : "اختر نوع العلف"}
                </div>

                <div className="arrow">
                  <img src={arrow} alt="arrow" />
                </div>
              </div>
            </label>
          </div>

          {/* الشركة input */}
          <div className="col-start-2">
            <label htmlFor="modal-15">
              <div
                className="flex justify-between select-mission-container"
                onClick={() => handleCompanyPopUp()}
              >
                <div className="select-mission-type">
                  {stateFromFarmsSlice.selectedCompanyId === 0
                    ? "اختر الشركة"
                    : stateFromFarmsSlice.selectedCompanyText}
                </div>
                <div className="arrow">
                  <img src={arrow} alt="arrow" />
                </div>
              </div>
            </label>
          </div>

          {/* العدد */}
          <div className="col-start-1 pr-4  mt-4">
            <p className="title">العدد </p>
          </div>

          {/* العدد input */}
          <div className="col-span-full lg:w-1/2 md:w-full sm:w-full xs:w-full">
            <div>
              <input
                type="number"
                placeholder="0000"
                className="mission-address-input"
                id="count"
                name="count"
                onChange={(e) => handleChangeInput(e.target.value, "count")}
              />
            </div>
          </div>

          {/* تاريخ الادخال */}
          <div className="col-start-1 pr-4 mt-4">
            <p className="title">تاريخ الادخال </p>
          </div>
          {/* تاريخ البيع المتوقع* */}
          <div className="col-start-2 pr-4 mt-4">
            <p className="title">تاريخ البيع المتوقع</p>
          </div>

          <div className="col-start-1 pr-4 mt-4">
            <DatePicker
              selected={startDate}
              onChange={(event) => handleChangeInputDate(event)}
              className="date-picker-input"
              maxDate={new Date()}
            />
          </div>

          <div className="col-start-2 pr-4 mt-4">
            <DatePicker
              selected={expectedDate}
              onChange={(event) => handleChangeExpectedDate(event)}
              className="date-picker-input"
              minDate={startDate}
            />
          </div>
        </div>
      </div>

      <div className="add-actions p-5">
        <div className="grid grid-cols-2">
          <div className="col-start-1">
            <div className="flex gap-4">
              <button className="add-btn" onClick={() => handleSubmitHousing()}>
                تسكين
              </button>
              <div className="cancel-btn" onClick={() => handleCancelMethod()}>
                الغاء
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
