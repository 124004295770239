import "./MissionFilter.scss";
import Fuse from "fuse.js";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import API from "../../../Api";
import { Loading } from "../../Loading/Loading";
import { useLocation } from "react-router-dom";
import { handleSubmitFilterResult } from "../../../redux/Slices/MissionSlice";
import DatePicker from "react-datepicker";
import moment from "moment";

// Define an interface for the filter object
interface FilterObject {
  salesManId: number;
  breederId: number;
  type_id: number[];
  createdBy: string;
  created_by_roles: string[];
  has_breeder: boolean;
  has_housing: boolean;
  has_farm: boolean;
  gov_id: number[];
  city_id: number[];
  assigned?: boolean;
  from_created_at?: Date;
  to_created_at?: Date;
}
export const MissionFilter = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setLocationData] = useState([]);
  const [salesManData, setSalesManData] = useState([]);
  const [breedersData, setBreedersData] = useState([]);
  const [missionTypeData, setMissionTypeData] = useState([]);
  const [salesManAgreement, setSalesManAgreement] = useState(false);
  const [selectedMissionTypeId, setSelectedMissionTypeId] = useState(0);
  const [selectedItemId, setSelectedItemId] = useState(1);
  const [selectedLocationId, setSelectedLocationId] = useState(1);
  const [selectedItemName, setSelectedItemName] = useState("");
  const [selectedTypeId, setSelectedTypeId] = useState(0);
  const [selectedTypeName, setSelectedTypeName] = useState("");
  const [selectedAddedById, setSelectedAddedById] = useState(0);
  const [selectedAddedByName, setSelectedAddedByName] = useState("");
  const [selectedSalesManId, setSelectedSalesManId] = useState(0);
  const [selectedBreederId, setSelectedBreederId] = useState(0);
  const [createdAt, setCreatedAt] = useState(new Date());
  const [createdAtChanged, setCreatedAtChanged] = useState(false);
  const [finishedAtChanged, setFinishedAtChanged] = useState(false);
  const [finishedAt, setFinishedAt] = useState(new Date());

  const [locationSearchResults, setLocationSearchResults] = useState<any[]>([
    {},
  ]);
  const [salesManSearchResults, setSalesManSearchResults] = useState<any[]>([
    {},
  ]);
  const [breederSearchResults, setBreederSearchResults] = useState<any[]>([{}]);
  const [filterObject, setFilterObject] = useState<{
    gov_id: number[];
    city_id: number[];
    specialty_id: number;
    salesManId: number;
    breederId: number;
    type_id: number[];
    created_by_roles: string[];
    createdBy: string;
    has_breeder: boolean;
    has_housing: boolean;
    has_farm: boolean;
  }>({
    gov_id: [],
    city_id: [],
    specialty_id: 0,
    salesManId: 0,
    breederId: 0,
    type_id: [],
    created_by_roles: [],
    createdBy: "",
    has_breeder: false,
    has_housing: false,
    has_farm: false,
  });

  const options = {
    includeScore: true,
    includeMatches: true,
    threshold: 0.2,
    keys: ["name"],
  };
  const fuse = new Fuse(locationSearchResults, options);
  const fuseSalesMan = new Fuse(salesManSearchResults, options);
  const fuseBreeders = new Fuse(salesManSearchResults, options);

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    setIsLoading(true);
    moment.locale("en");

    //governorates API
    API.get(`governorates`).then((res) => {
      if (res) {
        if (res.status === 403) {
          toast.error(" عفوا انت ليس لديك صلاحية الوصول لهذه الصفحة ");
        } else {
          setLocationSearchResults(res.data);
          setLocationData(res.data);
          setIsLoading(false);
        }
      }
    });
    //Mission Types
    API.get(`mission-types`).then((res) => {
      if (res) {
        if (res.status === 403) {
          toast.error(" عفوا انت ليس لديك صلاحية الوصول لهذه الصفحة ");
        } else {
          setMissionTypeData(res.data);
          setIsLoading(false);
        }
      }
    });
    //Sales Man Api
    API.get("salesman?page=1&limit=500000000").then((res) => {
      setIsLoading(true);
      if (res) {
        if (res.status === 403) {
          toast.error(" عفوا انت ليس لديك صلاحية الوصول لهذه الصفحة ");
        } else {
          setSalesManSearchResults(res.data.items);
          setSalesManData(res.data.items);

          setIsLoading(false);
        }
      }
    });
    //Breeders API
    API.get(`breeders?page=1&limit=500000000`).then((res) => {
      setIsLoading(true);
      if (res) {
        if (res.status === 403) {
          toast.error(" عفوا انت ليس لديك صلاحية الوصول لهذه الصفحة ");
        } else {
          setBreederSearchResults(res.data.items);
          setBreedersData(res.data.items);
          setIsLoading(false);
        }
      }
    });
  }, []);

  const handleLocationSearch = (event: any) => {
    const { value } = event.target;

    // If the user searched for an empty string,
    // display all data.
    if (value.length === 0) {
      setLocationSearchResults(data);
      return;
    }

    const results = fuse.search(value);
    const items = results.map((result) => result.item);
    setLocationSearchResults(items);
  };

  const handleSalesManSearch = (event: any) => {
    setIsLoading(true);
    const { value } = event.target;

    // If the user searched for an empty string,
    // display all data.
    if (value.length === 0) {
      setIsLoading(false);
      setSalesManSearchResults(salesManData);
      return;
    }
    API.get(
      `dashboard/salesman?sort_by=id&sort_order=DESC&page=1&limit=10000000000&search=${event.target.value}`
    ).then((res) => {
      setSalesManSearchResults(res.data.items);
      setIsLoading(false);
    });
  };

  const handleBreedersSearch = (event: any) => {
    setIsLoading(true);
    const { value } = event.target;

    // If the user searched for an empty string,
    // display all data.
    if (value.length === 0) {
      setBreederSearchResults(breedersData);
      return;
    }
    API.get(
      `breeders?sort_by=id&sort_order=DESC&page=1&limit=10000000000&search=${event.target.value}`
    ).then((res) => {
      setBreederSearchResults(res.data.items);
      setIsLoading(false);
    });
  };

  const handleChangeLocationType = (itemId: number, itemName: string) => {
    setIsLoading(true);
    setSelectedLocationId(0);
    setSelectedItemId(itemId === selectedItemId ? 0 : itemId);
    setSelectedItemName(itemId === selectedItemId ? "null" : itemName);
    if (itemId === 2) {
      API.get(`cities`).then((res) => {
        if (res) {
          if (res.status === 403) {
            toast.error(" عفوا انت ليس لديك صلاحية الوصول لهذه الصفحة ");
          } else {
            setLocationSearchResults(res.data);
            setLocationData(res.data);
            setIsLoading(false);
          }
        }
      });
    } else {
      API.get(`governorates`).then((res) => {
        if (res) {
          if (res.status === 403) {
            toast.error(" عفوا انت ليس لديك صلاحية الوصول لهذه الصفحة ");
          } else {
            setLocationSearchResults(res.data);
            setLocationData(res.data);
            setIsLoading(false);
          }
        }
      });
    }
  };

  const handleChangeLocationContent = (itemId: number, itemName: string) => {
    const isSelected = itemId === selectedLocationId;
    setSelectedLocationId(isSelected ? 0 : itemId);
    if (selectedItemId === 1) {
      //محافظات
      setFilterObject((prevState) => ({
        ...prevState,
        gov_id: isSelected
          ? prevState.gov_id.filter((id) => id !== itemId)
          : [...prevState.gov_id, itemId],
        city_id: [],
      }));
    } else {
      //مناطق
      setFilterObject((prevState) => ({
        ...prevState,
        city_id: isSelected
          ? prevState.city_id.filter((id) => id !== itemId)
          : [...prevState.city_id, itemId],
        gov_id: [],
      }));
    }
  };

  const handleChangeCreatedAt = (date: any) => {
    setCreatedAtChanged(true);
    setCreatedAt(date);
  };

  const handleChangeFinishedAt = (date: any) => {
    setFinishedAtChanged(true);
    setFinishedAt(date);
  };

  const handleChangeSalesManContent = (itemId: number, itemName: string) => {
    setSelectedSalesManId(itemId === selectedSalesManId ? 0 : itemId);
    setFilterObject((prevState) => ({
      ...prevState,
      ["salesManId"]: Number(itemId),
    }));
  };

  const handleChangeMissionTypeContent = (itemId: number, itemName: string) => {
    const isSelected = itemId === selectedMissionTypeId;
    setSelectedMissionTypeId(isSelected ? 0 : itemId);
    setFilterObject((prevState) => ({
      ...prevState,
      type_id: isSelected
        ? prevState.type_id.filter((id) => id !== itemId)
        : [...prevState.type_id, itemId],
    }));
  };

  const handleChangeBreederContent = (itemId: number, itemName: string) => {
    setSelectedBreederId(itemId === selectedBreederId ? 0 : itemId);
    setFilterObject((prevState) => ({
      ...prevState,
      ["breederId"]: Number(itemId),
    }));
  };

  const handleChangeStageType = (itemId: number, itemName: string) => {
    setSelectedTypeId(itemId === selectedTypeId ? 0 : itemId);
    setSelectedTypeName(itemId === selectedTypeId ? "null" : itemName);
    switch (itemId) {
      case 1:
        setFilterObject((prevState) => ({
          ...prevState,
          ["has_breeder"]: true,
        }));
        break;

      case 2:
        setFilterObject((prevState) => ({
          ...prevState,
          ["has_housing"]: true,
          ["has_breeder"]: false,
          ["has_farm"]: false,
        }));
        break;

      case 3:
        setFilterObject((prevState) => ({
          ...prevState,
          ["has_farm"]: true,
          ["has_housing"]: false,
          ["has_breeder"]: false,
        }));
        break;

      default:
        break;
    }
  };

  const handleChangeAddedByType = (itemId: number, itemName: string) => {
    const isSelected = itemId === selectedAddedById;
    setSelectedAddedById(isSelected ? 0 : itemId);

    setFilterObject((prevState) => ({
      ...prevState,
      created_by_roles: isSelected
        ? prevState.created_by_roles.filter((name) => name !== itemName)
        : [...prevState.created_by_roles, itemName],
    }));
  };

  // Define a function to generate query parameters
  const generateParam = (key: string, value: any): string => {
    if (Array.isArray(value)) {
      const params = value.map((val) => `${key}=${val}`).join("&");
      return params ? `${params}&` : "";
    }
    return value !== 0 && value !== "" && value !== false
      ? `${key}=${value}&`
      : "";
  };
  // Define a function to generate the filter URL
  // Define a function to generate the filter URL
  const generateFilterUrl = (
    status: string | null,
    filterObject: FilterObject,
    salesManAgreement: boolean,
    createdAtChanged: boolean,
    createdAt: Date,
    finishedAtChanged: boolean,
    finishedAt: Date
  ): string => {
    const params: string[] = [
      generateParam("salesman_id", filterObject.salesManId),
      generateParam("breeder_id", filterObject.breederId),
      generateParam("created_by", filterObject.createdBy),
      generateParam("has_breeder", filterObject.has_breeder),
      generateParam("has_housing", filterObject.has_housing),
      generateParam("has_farm", filterObject.has_farm),
      generateParam("gov_ids", filterObject.gov_id),
      generateParam("city_ids", filterObject.city_id),
      generateParam("type_id", filterObject.type_id),
      generateParam("created_by_roles", filterObject.created_by_roles),
      salesManAgreement ? "assigned=true&" : "",
      createdAtChanged
        ? `from_created_at=${moment(createdAt).format("YYYY-MM-DD")}&`
        : "",
      finishedAtChanged
        ? `to_created_at=${moment(finishedAt).format("YYYY-MM-DD")}&`
        : "",
    ];

    // Filter out empty parameters and join them with '&'
    const queryParams = params.filter((param) => param !== "").join("");

    return status === null
      ? `dashboard/missions?sort_by=id&sort_order=DESC&${queryParams}`
      : `dashboard/missions?status=${status}&sort_by=id&sort_order=DESC&${queryParams}`;
  };

  const handleSubmitFilter = () => {
    setIsLoading(true);

    let status = `pending`;
    let type = 0;
    let filterObj: {}[] = [];
    let isFiltered = true;
    let totalCount = 0;

    if (location.pathname.includes("Pending")) {
      type = 1;
      status = `pending`;
      const filterUrl = generateFilterUrl(
        status,
        filterObject,
        salesManAgreement,
        createdAtChanged,
        createdAt,
        finishedAtChanged,
        finishedAt
      );
      API.get(filterUrl).then((res: any) => {
        totalCount = res.data.totalCount;
        filterObj.push(res.data.items);
        setIsLoading(false);
        dispatch(
          handleSubmitFilterResult({
            type,
            filterObj,
            isFiltered,
            totalCount,
            filterUrl,
          })
        );
      });
    } else if (location.pathname.includes("InProgress")) {
      type = 2;
      status = `in_progress`;
      const filterUrl = generateFilterUrl(
        status,
        filterObject,
        salesManAgreement,
        createdAtChanged,
        createdAt,
        finishedAtChanged,
        finishedAt
      );
      API.get(filterUrl).then((res: any) => {
        totalCount = res.data.totalCount;
        filterObj.push(res.data.items);
        setIsLoading(false);
        dispatch(
          handleSubmitFilterResult({
            type,
            filterObj,
            isFiltered,
            totalCount,
            filterUrl,
          })
        );
      });
    } else if (location.pathname.includes("Late")) {
      type = 3;
      status = `late`;
      const filterUrl = generateFilterUrl(
        status,
        filterObject,
        salesManAgreement,
        createdAtChanged,
        createdAt,
        finishedAtChanged,
        finishedAt
      );
      API.get(filterUrl).then((res: any) => {
        totalCount = res.data.totalCount;
        filterObj.push(res.data.items);
        setIsLoading(false);
        dispatch(
          handleSubmitFilterResult({
            type,
            filterObj,
            isFiltered,
            totalCount,
            filterUrl,
          })
        );
      });
    } else if (location.pathname.includes("Evaluation")) {
      type = 4;
      status = `revision`;
      const filterUrl = generateFilterUrl(
        status,
        filterObject,
        salesManAgreement,
        createdAtChanged,
        createdAt,
        finishedAtChanged,
        finishedAt
      );
      API.get(filterUrl).then((res: any) => {
        totalCount = res.data.totalCount;
        filterObj.push(res.data.items);
        setIsLoading(false);
        dispatch(
          handleSubmitFilterResult({
            type,
            filterObj,
            isFiltered,
            totalCount,
            filterUrl,
          })
        );
      });
    } else if (location.pathname.includes("Finished")) {
      type = 5;
      status = `completed`;
      const filterUrl = generateFilterUrl(
        status,
        filterObject,
        salesManAgreement,
        createdAtChanged,
        createdAt,
        finishedAtChanged,
        finishedAt
      );
      API.get(filterUrl).then((res: any) => {
        totalCount = res.data.totalCount;
        filterObj.push(res.data.items);
        setIsLoading(false);
        dispatch(
          handleSubmitFilterResult({
            type,
            filterObj,
            isFiltered,
            totalCount,
            filterUrl,
          })
        );
      });
    } else if (location.pathname.includes("Refused")) {
      type = 6;
      status = `rejected`;
      const filterUrl = generateFilterUrl(
        status,
        filterObject,
        salesManAgreement,
        createdAtChanged,
        createdAt,
        finishedAtChanged,
        finishedAt
      );
      API.get(filterUrl).then((res: any) => {
        totalCount = res.data.totalCount;
        filterObj.push(res.data.items);
        setIsLoading(false);
        dispatch(
          handleSubmitFilterResult({
            type,
            filterObj,
            isFiltered,
            totalCount,
            filterUrl,
          })
        );
      });
    } else if (location.pathname.includes("All")) {
      type = 7;
      const filterUrl = generateFilterUrl(
        null,
        filterObject,
        salesManAgreement,
        createdAtChanged,
        createdAt,
        finishedAtChanged,
        finishedAt
      );
      API.get(filterUrl).then((res: any) => {
        totalCount = res.data.totalCount;
        isFiltered = true;
        filterObj.push(res.data.items);
        setIsLoading(false);
        dispatch(
          handleSubmitFilterResult({
            type,
            filterObj,
            isFiltered,
            totalCount,
            filterUrl,
          })
        );
      });
    }
  };

  return (
    <div className="MissionFilter">
      {isLoading === true ? <Loading /> : null}
      <input className="modal-state" id="modal-2" type="checkbox" />
      <div className="modal w-screen " style={{ direction: "rtl" }}>
        <label className="modal-overlay" htmlFor="modal-2"></label>
        <div
          className="modal-content flex flex-col gap-5 max-w-3xl "
          style={{ width: "580px" }}
        >
          <div className="accordion-group">
            {/* نوع المهمة */}
            <div className="accordion">
              <input
                type="checkbox"
                id="accordion-1"
                className="accordion-toggle"
              />
              <label htmlFor="accordion-1" className="head ">
                نوع المهمة
              </label>
              <div className="divider"></div>

              <div className="accordion-content">
                <div className="min-h-0">
                  <ul className="list-none scrollable-list">
                    {missionTypeData.map((item: any) => {
                      return (
                        <>
                          {isLoading === true ? (
                            <div className="skeleton-pulse h-5 rounded-md mb-2"></div>
                          ) : (
                            <li className="flex justify-between pl-4 py-2">
                              <span className="list-text">{item.name}</span>
                              <div>
                                <input
                                  type="checkbox"
                                  className="checkbox checkbox-bordered-success"
                                  onChange={() =>
                                    handleChangeMissionTypeContent(
                                      item.id,
                                      item.name
                                    )
                                  }
                                  checked={filterObject.type_id.includes(
                                    item.id
                                  )}
                                />
                              </div>
                            </li>
                          )}
                        </>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            {/* المكان */}
            <div className="accordion">
              <input
                type="checkbox"
                id="accordion-2"
                className="accordion-toggle"
              />
              <label htmlFor="accordion-2" className="head mt-2">
                المكان
              </label>
              <div className="divider"></div>
              <div className="accordion-content">
                <div className="min-h-0">
                  <div className="flex gap-8 items-center">
                    <div className="flex gap-3 items-center">
                      <input
                        type="checkbox"
                        className="checkbox checkbox-bordered-success"
                        onChange={() =>
                          handleChangeLocationType(1, "governorate")
                        }
                        checked={1 === selectedItemId}
                      />
                      <span className="txt">المحافظة</span>
                    </div>

                    <div className="flex gap-3 items-center">
                      <input
                        type="checkbox"
                        className="checkbox checkbox-bordered-success"
                        onChange={() => handleChangeLocationType(2, "city")}
                        checked={2 === selectedItemId}
                      />
                      <span className="txt">المنطقة</span>
                    </div>
                  </div>

                  <div className="form-control relative w-full mt-2">
                    <input
                      type="text"
                      className="input input-lg max-w-full input-search"
                      onChange={handleLocationSearch}
                      placeholder={
                        selectedItemId === 1
                          ? "ابحث عن المحافظة"
                          : "ابحث عن المنطقة"
                      }
                    />

                    <span className="absolute inset-y-0 right-4 inline-flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
                          stroke="#64748B"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M17.4998 17.4998L14.1665 14.1665"
                          stroke="#64748B"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                  <div className=" h-32 overflow-y-auto mt-4">
                    <ul className="list-none scrollable-list ">
                      {locationSearchResults.map((item) => {
                        return (
                          <>
                            {isLoading === true ? (
                              <div className="skeleton-pulse h-5 rounded-md mb-2"></div>
                            ) : (
                              <li className="flex justify-between pl-4 py-2">
                                <span className="list-text">{item.name}</span>
                                <div>
                                  <input
                                    type="checkbox"
                                    className="checkbox checkbox-bordered-success"
                                    onChange={() =>
                                      handleChangeLocationContent(
                                        item.id,
                                        item.name
                                      )
                                    }
                                    checked={
                                      selectedItemId === 1
                                        ? filterObject.gov_id.includes(item.id)
                                        : filterObject.city_id.includes(item.id)
                                    }
                                  />
                                </div>
                              </li>
                            )}
                          </>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* المندوب */}
            <div className="accordion">
              <input
                type="checkbox"
                id="accordion-3"
                className="accordion-toggle"
              />
              <label htmlFor="accordion-3" className="head mt-2">
                المندوب
              </label>
              <div className="divider"></div>
              <div className="accordion-content">
                <div className="min-h-0">
                  <div className="form-control relative w-full mt-2">
                    <input
                      type="text"
                      className="input input-lg max-w-full input-search"
                      onChange={handleSalesManSearch}
                      placeholder="ابحث عن المندوب"
                    />

                    <span className="absolute inset-y-0 right-4 inline-flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
                          stroke="#64748B"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M17.4998 17.4998L14.1665 14.1665"
                          stroke="#64748B"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                  <div className="h-32 overflow-y-auto mt-4">
                    <ul className="list-none scrollable-list">
                      {salesManSearchResults.map((item) => {
                        return (
                          <>
                            {isLoading === true ? (
                              <div className="skeleton-pulse h-5 rounded-md mb-2"></div>
                            ) : (
                              <li className="flex justify-between pl-4 py-2">
                                <span className="list-text">{item.name}</span>
                                <div>
                                  <input
                                    type="checkbox"
                                    className="checkbox checkbox-bordered-success"
                                    onChange={() =>
                                      handleChangeSalesManContent(
                                        item.id,
                                        item.name
                                      )
                                    }
                                    checked={item.id === selectedSalesManId}
                                  />
                                </div>
                              </li>
                            )}
                          </>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* المربي */}
            <div className="accordion">
              <input
                type="checkbox"
                id="accordion-4"
                className="accordion-toggle"
              />
              <label htmlFor="accordion-4" className="head mt-2">
                المربي
              </label>
              <div className="divider"></div>
              <div className="accordion-content">
                <div className="min-h-0">
                  <div className="form-control relative w-full mt-2">
                    <input
                      type="text"
                      className="input input-lg max-w-full input-search"
                      onChange={handleBreedersSearch}
                      placeholder="ابحث عن المربي"
                    />

                    <span className="absolute inset-y-0 right-4 inline-flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
                          stroke="#64748B"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M17.4998 17.4998L14.1665 14.1665"
                          stroke="#64748B"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                  <div className="h-32 overflow-y-auto mt-4">
                    <ul className="list-none scrollable-list">
                      {breederSearchResults.map((item) => {
                        return (
                          <>
                            {isLoading === true ? (
                              <div className="skeleton-pulse h-5 rounded-md mb-2"></div>
                            ) : (
                              <li className="flex justify-between pl-4 py-2">
                                <span className="list-text">{item.name}</span>
                                <div>
                                  <input
                                    type="checkbox"
                                    className="checkbox checkbox-bordered-success"
                                    onChange={() =>
                                      handleChangeBreederContent(
                                        item.id,
                                        item.name
                                      )
                                    }
                                    checked={item.id === selectedBreederId}
                                  />
                                </div>
                              </li>
                            )}
                          </>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* المرحلة */}
            <div className="accordion">
              <input
                type="checkbox"
                id="accordion-5"
                className="accordion-toggle"
              />
              <label htmlFor="accordion-5" className="head mt-2">
                المرحلة
              </label>
              <div className="divider"></div>
              <div className="accordion-content">
                <div className="min-h-0">
                  <div className="flex gap-8 items-center">
                    <div className="flex gap-3 items-center">
                      <input
                        type="checkbox"
                        className="checkbox checkbox-bordered-success"
                        onChange={() => handleChangeStageType(1, "farmer")}
                        checked={1 === selectedTypeId}
                      />
                      <span className="txt">مرحلة المربي</span>
                    </div>
                    <div className="flex gap-3 items-center">
                      <input
                        type="checkbox"
                        className="checkbox checkbox-bordered-success"
                        onChange={() => handleChangeStageType(2, "housing")}
                        checked={2 === selectedTypeId}
                      />
                      <span className="txt">مرحلة التسكين</span>
                    </div>

                    <div className="flex gap-3 items-center">
                      <input
                        type="checkbox"
                        className="checkbox checkbox-bordered-success"
                        onChange={() => handleChangeStageType(3, "farm")}
                        checked={3 === selectedTypeId}
                      />
                      <span className="txt">مرحلة المزرعة </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* الأضافة من */}
            <div className="accordion">
              <input
                type="checkbox"
                id="accordion-6"
                className="accordion-toggle"
              />
              <label htmlFor="accordion-6" className="head mt-2">
                الاضافة من
              </label>
              <div className="divider"></div>
              <div className="accordion-content">
                <div className="min-h-0">
                  <div className="flex gap-8 items-center">
                    <div className="flex gap-3 items-center">
                      <input
                        type="checkbox"
                        className="checkbox checkbox-bordered-success"
                        checked={filterObject.created_by_roles.includes(
                          "admin"
                        )}
                        onChange={() => handleChangeAddedByType(1, "admin")}
                      />
                      <span className="txt">الأدمن</span>
                    </div>
                    <div className="flex gap-3 items-center">
                      <input
                        type="checkbox"
                        className="checkbox checkbox-bordered-success"
                        onChange={() => handleChangeAddedByType(2, "salesman")}
                        checked={filterObject.created_by_roles.includes(
                          "salesman"
                        )}
                      />
                      <span className="txt">السيلز</span>
                    </div>

                    <div className="flex gap-3 items-center">
                      <input
                        type="checkbox"
                        className="checkbox checkbox-bordered-success"
                        onChange={() => handleChangeAddedByType(3, "organic")}
                        checked={filterObject.created_by_roles.includes(
                          "organic"
                        )}
                      />
                      <span className="txt">طبيعي</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* موافقة المندوب */}
            <div className="accordion">
              <input
                type="checkbox"
                id="accordion-7"
                className="accordion-toggle"
              />
              <label htmlFor="accordion-7" className="head mt-2">
                موافقة المندوب{" "}
              </label>
              <div className="divider"></div>
              <div className="accordion-content">
                <div className="min-h-0">
                  <div className="flex gap-8 items-center">
                    <div className="flex gap-3 items-center">
                      <input
                        type="checkbox"
                        className="checkbox checkbox-bordered-success"
                        onChange={() =>
                          setSalesManAgreement(!salesManAgreement)
                        }
                        checked={salesManAgreement}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* تاريخ المهمة */}
            <div className="accordion">
              <input
                type="checkbox"
                id="accordion-8"
                className="accordion-toggle"
              />
              <label htmlFor="accordion-8" className="head mt-2">
                تاريخ المهمة
              </label>
              <div className="divider"></div>
              <div className="accordion-content">
                <div className="min-h-0 grid grid-cols-2">
                  <div className="col-start-1 flex gap-2 justify-start pr-4">
                    <div className="head-wrap">انشئت في :</div>
                    <DatePicker
                      selected={createdAt}
                      onChange={(event) => handleChangeCreatedAt(event)}
                      className="date-picker-input-filter"
                    />
                  </div>

                  <div className="col-start-2 flex gap-2 justify-start pr-4">
                    <div className="head-wrap">تنتهي في :</div>
                    <DatePicker
                      selected={finishedAt}
                      onChange={(event) => handleChangeFinishedAt(event)}
                      className="date-picker-input-filter"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-4">
            <div className="grid grid-cols-2">
              <div className="col-span-full">
                <label htmlFor="modal-2">
                  <div
                    className="add-btn w-full p-4"
                    onClick={() => handleSubmitFilter()}
                  >
                    تأكيد
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
