import { useDispatch } from "react-redux";
import "./AddCompetitions.scss";
import { useEffect, useState } from "react";
import { setMainHeaderName } from "../../../redux/Slices/MainHeaderSlice";
import { Loading } from "../../Loading/Loading";
import { AddCompetitionDetails } from "./CompetitionDetails/AddCompetitionDetails";
import { useLocation } from "react-router-dom";
import { AddCompetitionQuestions } from "./CompetitionQuestions/AddCompetitionQuestions";

interface Choice {
  c_Id: number;
  q_Id: number;
  c_title: string;
  c_IsCorrect: boolean;
}

interface Question {
  q_Id: number;
  q_title: string;
  q_mark: number;
  choices: Choice[];
}

export const AddCompetitions = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [firstQuestion, setFirstQuestion] = useState<Choice[]>([]);

  const [questions, setQuestions] = useState<Question[]>([
    {
      q_Id: 1,
      q_title: "What is your question?",
      q_mark: 10,
      choices: [
        { c_Id: 1, q_Id: 1, c_title: "Choice 1", c_IsCorrect: true },
        { c_Id: 2, q_Id: 1, c_title: "Choice 2", c_IsCorrect: false },
        // Add more choices as needed
      ],
    },
    // Add more objects as needed
  ]);

  useEffect(() => {
    let mainHeaderName = "اضافة مسابقة";
    dispatch(setMainHeaderName({ mainHeaderName }));
    let firstObj = questions.find((c) => c.q_Id === 1);
    if (firstObj != null) {
      let choises = firstObj.choices;
      setFirstQuestion(choises);
    }
  }, [firstQuestion, questions]);

  const addQuestion = () => {
    const newQuestion: Question = {
      // q_Id: questions.length + 1,
      q_Id: 1,
      q_title: "New Question",
      q_mark: 5,
      choices: [
        { c_Id: 1, q_Id: 1, c_title: "New Choice 1", c_IsCorrect: false },
        { c_Id: 2, q_Id: 1, c_title: "New Choice 2", c_IsCorrect: true },
        // Add more choices as needed
      ],
    };
    // Update the state by adding the new question
    setQuestions([...questions, newQuestion]);
  };
  const addChoise = (qId: number) => {
    const questionChoiseObj = questions.find((c) => c.q_Id === qId)?.choices;
    const newChoise: Choice = {
      c_Id: 3,
      q_Id: qId,
      c_title: "",
      c_IsCorrect: false,
    };
    firstQuestion.push(newChoise);

    setFirstQuestion([...firstQuestion, newChoise]);
  };
  const handleDeleteChoise = (questionId: number, choiceId: number) => {
    // Assuming 'questions' and 'firstQuestion' are state variables
    let updatedQuestions = [...questions]; // Copy the questions array to avoid mutating the state directly

    const questionIndex = updatedQuestions.findIndex(
      (q) => q.q_Id === questionId
    );
    if (questionIndex !== -1) {
      const questionChoices = updatedQuestions[questionIndex].choices;

      if (questionChoices != null && questionChoices.length > 1) {
        // Filter out the choice with the given 'choiceId'
        const updatedChoices = questionChoices.filter(
          (item) => item.c_Id !== choiceId
        );

        // Update the choices for the specific question
        updatedQuestions[questionIndex].choices = updatedChoices;

        // Update the state with the modified 'firstQuestion'
        setFirstQuestion(updatedChoices);
      }
    }
  };
  function getArabicRanking(number: number): string {
    const arabicRankings = [
      "الأول",
      "الثاني",
      "الثالث",
      "الرابع",
      "الخامس",
      "السادس",
      "السابع",
      "الثامن",
      "التاسع",
      "العاشر",
    ];

    if (number >= 1 && number <= arabicRankings.length) {
      return arabicRankings[number - 1];
    } else {
      return "غير معروف"; // Return this for out-of-range numbers
    }
  }
  // const firstQuestion = questions.find(c => c.q_Id === 1);
  return (
    <div className="AddCompetitions">
      {isLoading === true ? (
        <>
          <Loading />
        </>
      ) : null}

      {location.pathname === "/Competitions/Add/Details" ? (
        <AddCompetitionDetails />
      ) : (
        <AddCompetitionQuestions />
      )}
    </div>
  );
};
