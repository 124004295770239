import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectedWalletType,
  setFilterDate,
} from "../../../redux/Slices/WalletsSlice";
import { DateObject } from "react-multi-date-picker";
import DatePicker from "react-multi-date-picker";

import "./WalletsHeader.scss";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
export const WalletsHeader = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [values, setValues] = useState([new DateObject(), new DateObject()]);
  const [showFilter, setShowFilter] = useState(false);
  const [activeElement, setActiveElement] = useState(1);

  const handleActiveElement = (id: number) => {
    setActiveElement(id);
    switch (id) {
      case 1:
        navigate("/Wallets/AllTransactions");
        break;
      case 2:
        navigate("/Wallets/DepitTransactions");
        break;

      case 3:
        navigate("/Wallets/CreditTransactions");
        break;

      case 4:
        navigate("/Wallets/CreditBalanceOnly");
        break;

      case 5:
        navigate("/Wallets/SettledUsers");
        break;

      default:
        break;
    }
  };
  const handleResetFilter = () => {
    let fromDate = null;
    let toDate = null;
    let newValues = [new DateObject(), new DateObject()];
    setValues(newValues);
    dispatch(setFilterDate({ fromDate, toDate }));
    setShowFilter(false);
  };
  const handleDateChange = (newValues: any) => {
    setValues(newValues);
    setShowFilter(true);
    let fromDate = newValues[0];

    let toDate = newValues[1];

    if (toDate !== undefined) {
      let currentDate = moment(new Date()).format("YYYY-MM-DD");
      let formatedToDate = moment(new Date(toDate)).format("YYYY-MM-DD");
      let formatedFromDate = moment(new Date(fromDate)).format("YYYY-MM-DD");
      //if chosen date = current date then remove filter
      if (formatedFromDate === currentDate && formatedToDate === currentDate) {
        handleResetFilter();
      }
      dispatch(setFilterDate({ fromDate, toDate }));
    }
  };

  return (
    <div className="grid grid-cols-2 xs:grid-col-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 p-4  WalletsHeader">
      <div className="col-start-1 flex  sm:col-start-1">
        <div
          onClick={() => handleActiveElement(1)}
          className={
            location.pathname === `/Wallets/AllTransactions`
              ? "main-element-active"
              : "main-element"
          }
        >
          كل الحركات
        </div>
        <div
          onClick={() => handleActiveElement(2)}
          className={
            location.pathname === `/Wallets/DepitTransactions`
              ? "main-element-active"
              : "main-element"
          }
        >
          الحركات المدينة فقط
        </div>

        <div
          onClick={() => handleActiveElement(3)}
          className={
            location.pathname === `/Wallets/CreditTransactions`
              ? "main-element-active"
              : "main-element"
          }
        >
          الحركات الدائنة فقط
        </div>
        <div
          onClick={() => handleActiveElement(4)}
          className={
            location.pathname === `/Wallets/CreditBalanceOnly`
              ? "main-element-active"
              : "main-element"
          }
        >
          بإنتظار التسوية
        </div>
        <div
          onClick={() => handleActiveElement(5)}
          className={
            location.pathname === `/Wallets/SettledUsers`
              ? "main-element-active"
              : "main-element"
          }
        >
          تمت تسويتهم
        </div>
      </div>
      <div className="col-start-2  flex justify-end">
        {location.pathname === `/Wallets/CreditBalanceOnly` ||
        location.pathname === `/Wallets/SettledUsers` ? null : (
          <>
            <div className="flex gap-4 justify-center items-center">
              {showFilter ? (
                <>
                  <svg
                    onClick={() => handleResetFilter()}
                    width="24px"
                    height="24px"
                    viewBox="-1 0 18 18"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#A7AEC1"
                    stroke="#A7AEC1"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <title>arrow_repeat [#A7AEC1]</title>{" "}
                      <desc>Created with Sketch.</desc> <defs> </defs>{" "}
                      <g
                        id="Page-1"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        {" "}
                        <g
                          id="Dribbble-Light-Preview"
                          transform="translate(-302.000000, -7080.000000)"
                          fill="#A7AEC1"
                        >
                          {" "}
                          <g
                            id="icons"
                            transform="translate(56.000000, 160.000000)"
                          >
                            {" "}
                            <path
                              d="M260,6930 C260,6933 257.308,6936 254,6936 C250.692,6936 248,6933.308 248,6930 C248,6926.692 250.692,6924 254,6924 L254,6926 L259,6923 L254,6920 L254,6922 C249.582,6922 246,6925.582 246,6930 C246,6934.418 249.582,6938 254,6938 C258.418,6938 262,6935 262,6930 L260,6930 Z"
                              id="arrow_repeat-[#A7AEC1]"
                            >
                              {" "}
                            </path>{" "}
                          </g>{" "}
                        </g>{" "}
                      </g>{" "}
                    </g>
                  </svg>
                </>
              ) : null}

              <DatePicker
                inputClass="filterWallet"
                value={values}
                dateSeparator="الي"
                onChange={handleDateChange}
                range
                // rangeHover
                format="YYYY-MM-DD"
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
