import "./FarmsFilter.scss";
import Fuse from "fuse.js";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import API from "../../../Api";
import { Loading } from "../../Loading/Loading";
import { useLocation } from "react-router-dom";
import { handleSubmitFilterResult } from "../../../redux/Slices/FarmSlice";
import DatePicker from "react-datepicker";
import moment from "moment";

const FarmCapacityList = [
  {
    id: 1,
    name: "اققل من 1000",
  },
  {
    id: 2,
    name: "اققل من 5000",
  },
  {
    id: 3,
    name: "اققل من 10000",
  },
  {
    id: 4,
    name: "اققل من 25000",
  },
  {
    id: 5,
    name: "اققل من 50000",
  },
  {
    id: 6,
    name: "اققل من 100000",
  },
  {
    id: 7,
    name: "اققل من 200000",
  },
  {
    id: 8,
    name: "اققل من 500000",
  },

  {},
];

interface FilterObject {
  gov_id: number[];
  city_id: number[];
  farms_capacity_id: number;
  status_id: number;
  suspension_reason_id: number;
  suspended_days: number;
  name: string;
  farming_type_id: number;
  dajin_id: number;
  userId: number;
}
export const FarmsFilter = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [for_sale, setForSale] = useState(false);
  const [incubator, setIncubator] = useState(false);
  const [data, setLocationData] = useState([]);
  const [farmingTypeData, setFarmnigTypeData] = useState([]);
  const [breeders, setBreeders] = useState([]);
  const [stoppedReasons, setStoppedReasons] = useState([]);
  const [dajinTypes, setDajinTypesData] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState(1);
  const [selectedReasonId, setSelectedReasonId] = useState(1);
  const [selectedLocationId, setSelectedLocationId] = useState(1);
  const [selectedDajinId, setSelectedDajinId] = useState(1);
  const [selectedFarmCapacityId, setSelectedFarmCapacityId] = useState(0);
  const [selectedFarmStatusId, setSelectedFarmStatusId] = useState(0);
  const [selectedFarmingTypeId, setSelectedFarmingTypeId] = useState(1);
  const [fromExpectedDate, setFromExpectedDate] = useState(new Date());
  const [fromExpectedDateChanged, setFromExpectedDateChanged] = useState(false);
  const [toExcpectedDateChanged, setToExcpectedDateChanged] = useState(false);
  const [toExcpectedDate, setToExcpectedDate] = useState(new Date());
  const [expected_sale_date, set_Expected_sale_date] = useState(new Date());
  const [excpectedDateChanged, setExcpectedDateChanged] = useState(false);
  const [selectedItemName, setSelectedItemName] = useState("");
  const [selectedBreederId, setSelectedBreederId] = useState(0);
  const [locationSearchResults, setLocationSearchResults] = useState<any[]>([
    {},
  ]);

  const [stoppedReasonsSearchResults, setStoppedReasonsSearchResults] =
    useState<any[]>([{}]);
  const [farmStatuses, setFarmStatuses] = useState([]);
  const [filterObject, setFilterObject] = useState<{
    gov_id: number[];
    city_id: number[];
    farms_capacity_id: number;
    status_id: number;
    name: string;
    suspension_reason_id: number;
    suspended_days: number;
    farming_type_id: number;
    dajin_id: number;
    userId: number;
  }>({
    gov_id: [],
    city_id: [],
    farms_capacity_id: 0,
    status_id: 0,
    name: "",
    suspension_reason_id: 0,
    suspended_days: 0,
    farming_type_id: 0,
    dajin_id: 0,
    userId: 0,
  });

  const options = {
    includeScore: true,
    includeMatches: true,
    threshold: 0.2,
    keys: ["name"],
  };

  const ReasonsOptions = {
    includeScore: true,
    includeMatches: true,
    threshold: 0.2,
    keys: ["reason"],
  };
  const fuse = new Fuse(locationSearchResults, options);
  const fuseSuspendReasons = new Fuse(
    stoppedReasonsSearchResults,
    ReasonsOptions
  );

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    setIsLoading(true);
    moment.locale("en");
    //governorates API
    API.get(`governorates`).then((res) => {
      if (res) {
        if (res.status === 403) {
          toast.error(" عفوا انت ليس لديك صلاحية الوصول لهذه الصفحة ");
        } else {
          setLocationSearchResults(res.data);
          setLocationData(res.data);
          setIsLoading(false);
        }
      }
    });
    //Breeders API
    API.get(`breeders?page=1&limit=10000000`).then((res) => {
      setIsLoading(true);

      if (res) {
        setBreeders(res.data.items);

        setIsLoading(false);
      }
    });
    //Farm Statuses
    API.get(`farm-statuses`).then((res) => {
      if (res.status === 200) {
        setFarmStatuses(res.data);
      }
    });
    // Farms Suspend Reasons
    API.get(`farm-suspension-reasons`).then((res) => {
      if (res) {
        if (res.status === 403) {
          toast.error(" عفوا انت ليس لديك صلاحية الوصول لهذه الصفحة ");
        } else {
          setStoppedReasonsSearchResults(res.data);
          setStoppedReasons(res.data);
          setIsLoading(false);
        }
      }
    });
    //Farming Types
    API.get(`farming-types`).then((res) => {
      if (res) {
        if (res.status === 403) {
          toast.error(" عفوا انت ليس لديك صلاحية الوصول لهذه الصفحة ");
        } else {
          if (res.data.length > 0) {
            setFarmnigTypeData(res.data);
          }
          setIsLoading(false);
        }
      }
    });

    //Dagins typs (نوع الطائر)
    API.get(`dajins`).then((res) => {
      if (res) {
        if (res.status === 403) {
          toast.error(" عفوا انت ليس لديك صلاحية الوصول لهذه الصفحة ");
        } else {
          if (res.data.length > 0) {
            setDajinTypesData(res.data);
          }
          setIsLoading(false);
        }
      }
    });
  }, []);

  const handleLocationSearch = (event: any) => {
    const { value } = event.target;

    // If the user searched for an empty string,
    // display all data.
    if (value.length === 0) {
      setLocationSearchResults(data);
      return;
    }

    const results = fuse.search(value);
    const items = results.map((result) => result.item);
    setLocationSearchResults(items);
  };

  const handleSuspenReasonsSearch = (event: any) => {
    const { value } = event.target;

    // If the user searched for an empty string,
    // display all data.
    if (value.length === 0) {
      setStoppedReasonsSearchResults(data);
      return;
    }

    const results = fuseSuspendReasons.search(value);
    const items = results.map((result) => result.item);
    setStoppedReasonsSearchResults(items);
  };
  const handleChangeLocationType = (itemId: number, itemName: string) => {
    setIsLoading(true);
    setSelectedLocationId(0);
    setSelectedItemId(itemId === selectedItemId ? 0 : itemId);
    setSelectedItemName(itemId === selectedItemId ? "null" : itemName);
    if (itemId === 2) {
      API.get(`cities`).then((res) => {
        if (res) {
          if (res.status === 403) {
            toast.error(" عفوا انت ليس لديك صلاحية الوصول لهذه الصفحة ");
          } else {
            setLocationSearchResults(res.data);
            setLocationData(res.data);
            setIsLoading(false);
          }
        }
      });
    } else {
      API.get(`governorates`).then((res) => {
        if (res) {
          if (res.status === 403) {
            toast.error(" عفوا انت ليس لديك صلاحية الوصول لهذه الصفحة ");
          } else {
            setLocationSearchResults(res.data);
            setLocationData(res.data);
            setIsLoading(false);
          }
        }
      });
    }
  };

  const handleChangeLocationContent = (itemId: number, itemName: string) => {
    const isSelected = itemId === selectedLocationId;
    setSelectedLocationId(isSelected ? 0 : itemId);
    if (selectedItemId === 1) {
      //محافظات
      setFilterObject((prevState) => ({
        ...prevState,
        gov_id: isSelected
          ? prevState.gov_id.filter((id) => id !== itemId)
          : [...prevState.gov_id, itemId],
        city_id: [],
      }));
    } else {
      //مناطق
      setFilterObject((prevState) => ({
        ...prevState,
        city_id: isSelected
          ? prevState.city_id.filter((id) => id !== itemId)
          : [...prevState.city_id, itemId],
        gov_id: [],
      }));
    }
  };

  // const handleChangeFarmingType = (itemId: number) => {
  //   const isSelected = itemId === selectedFarmingTypeId;
  //   setSelectedFarmingTypeId(isSelected ? 0 : itemId);
  //   setFilterObject((prevState) => ({
  //     ...prevState,
  //     farming_type_id: isSelected
  //       ? prevState.farming_type_id.filter((id) => id !== itemId)
  //       : [...prevState.farming_type_id, itemId],
  //   }));
  // };

  const handleChangeFarmingType = (itemId: number) => {
    setSelectedFarmingTypeId(itemId === selectedFarmingTypeId ? 0 : itemId);
    setFilterObject((prevState) => ({
      ...prevState,
      ["farming_type_id"]: Number(itemId),
    }));
  };

  const handleChangeDajinType = (itemId: number) => {
    setSelectedDajinId(itemId === selectedDajinId ? 0 : itemId);
    setFilterObject((prevState) => ({
      ...prevState,
      ["dajin_id"]: Number(itemId),
    }));
  };

  const handleChangeFarmCapacity = (itemId: number, itemName: string) => {
    setSelectedFarmCapacityId(itemId === selectedFarmCapacityId ? 0 : itemId);

    setFilterObject((prevState) => ({
      ...prevState,
      ["farms_capacity_id"]: Number(itemId),
    }));
  };

  const handleChangeSuspendReason = (itemId: number, itemName: string) => {
    setSelectedReasonId(itemId === selectedReasonId ? 0 : itemId);

    setFilterObject((prevState) => ({
      ...prevState,
      ["suspension_reason_id"]: Number(itemId),
    }));
  };

  const handleChangeBreeder = (itemId: number, itemName: string) => {
    setSelectedBreederId(itemId === selectedBreederId ? 0 : itemId);
    setFilterObject((prevState) => ({
      ...prevState,
      ["userId"]: Number(itemId),
    }));
  };

  const handleBreederSearch = (event: any) => {
    setIsLoading(true);
    const { value } = event.target;

    // If the user searched for an empty string,
    // display all data.
    if (value.length === 0) {
      setIsLoading(false);
      setBreeders(breeders);
      return;
    }
    API.get(
      `breeders?sort_by=id&sort_order=DESC&page=1&limit=10000000000&search=${event.target.value}`
    ).then((res) => {
      setBreeders(res.data.items);
      setIsLoading(false);
    });
  };

  const handleChangeFromExpectedDate = (date: any) => {
    setFromExpectedDateChanged(true);
    setFromExpectedDate(date);
  };

  const handleChangeToExcpectedDate = (date: any) => {
    setToExcpectedDateChanged(true);
    setToExcpectedDate(date);
  };
  const handleChangeExcpectedDate = (date: any) => {
    setExcpectedDateChanged(true);
    set_Expected_sale_date(date);
  };
  const handleChangeFarmStatusId = (itemId: number, itemName: string) => {
    setSelectedFarmStatusId(itemId === selectedFarmStatusId ? 0 : itemId);
    setFilterObject((prevState) => ({
      ...prevState,
      ["status_id"]: Number(itemId),
    }));
  };

  const handleChangeFarmName = (name: string) => {
    setFilterObject((prevState) => ({
      ...prevState,
      ["name"]: name,
    }));
  };

  const handleChangeSuspendDays = (days: number) => {
    setFilterObject((prevState) => ({
      ...prevState,
      ["suspended_days"]: days,
    }));
  };
  // Define a function to generate query parameters
  const generateParam = (key: string, value: any): string => {
    if (Array.isArray(value)) {
      const params = value.map((val) => `${key}=${val}`).join("&");
      return params ? `${params}&` : "";
    }
    return value !== 0 && value !== "" && value !== false
      ? `${key}=${value}&`
      : "";
  };

  const generateFilterUrl = (
    filterObject: FilterObject,
    type: number,
    for_sale: boolean,
    incubator: boolean
  ): string => {
    const params: string[] = [
      generateParam("name", filterObject.name),
      generateParam("status_id", filterObject.status_id),
      generateParam("farms_capacity_id", filterObject.farms_capacity_id),
      generateParam("gov_id", filterObject.gov_id),
      generateParam("city_id", filterObject.city_id),
      generateParam("suspension_reason_id", filterObject.suspension_reason_id),
      generateParam("suspended_days", filterObject.suspended_days),
      generateParam("farming_type_id", filterObject.farming_type_id),
      generateParam("dajin_id", filterObject.dajin_id),
      generateParam("user_id", filterObject.userId),
      for_sale ? "for_sale=true&" : "",
      incubator ? "incubator=true&" : "",
      excpectedDateChanged
        ? `expected_sale_date=${moment(expected_sale_date).format(
            "YYYY-MM-DD"
          )}&`
        : "",
      fromExpectedDateChanged
        ? `expected_sale_date_from=${moment(fromExpectedDate).format(
            "YYYY-MM-DD"
          )}&`
        : "",
      toExcpectedDateChanged
        ? `expected_sale_date_to=${moment(toExcpectedDate).format(
            "YYYY-MM-DD"
          )}&`
        : "",
    ];

    // Filter out empty parameters and join them with '&'
    const queryParams = params.filter((param) => param !== "").join("");

    return type === 1
      ? `farms?sort_by=id&sort_order=DESC&${queryParams}`
      : type === 2
      ? `farms?for_sale=true&sort_by=id&sort_order=DESC&${queryParams}`
      : type === 3
      ? `farms?status_id=3&sort_by=id&sort_order=DESC&${queryParams}`
      : type === 4
      ? `farms?status_id=2&sort_by=id&sort_order=DESC&${queryParams}`
      : type === 5
      ? `farms?status_id=6&sort_by=id&sort_order=DESC&${queryParams}`
      : `farms?sort_by=id&sort_order=DESC&${queryParams}`;
  };

  const handleSubmitFilter = () => {
    setIsLoading(true);

    let type = 0;
    let filterObj: {}[] = [];
    let isFiltered = true;
    let totalCount = 0;
    if (location.pathname.includes("AllFarms")) {
      type = 1;
      const filterUrl = generateFilterUrl(
        filterObject,
        type,
        for_sale,
        incubator
      );

      API.get(filterUrl).then((res: any) => {
        totalCount = res.data.meta.totalItems;
        filterObj.push(res.data.items);
        setIsLoading(false);
        dispatch(
          handleSubmitFilterResult({
            type,
            filterObj,
            isFiltered,
            totalCount,
            filterUrl,
          })
        );
      });
    } else if (location.pathname.includes("FarmsReadyToSale")) {
      type = 2;
      const filterUrl = generateFilterUrl(
        filterObject,
        type,
        for_sale,
        incubator
      );

      API.get(filterUrl).then((res: any) => {
        totalCount = res.data.totalCount;
        filterObj.push(res.data.items);
        setIsLoading(false);
        dispatch(
          handleSubmitFilterResult({
            type,
            filterObj,
            isFiltered,
            totalCount,
            filterUrl,
          })
        );
      });
    } else if (location.pathname.includes("FarmsInprogress")) {
      type = 3;
      const filterUrl = generateFilterUrl(
        filterObject,
        type,
        for_sale,
        incubator
      );
      API.get(filterUrl).then((res: any) => {
        totalCount = res.data.totalCount;
        filterObj.push(res.data.items);
        setIsLoading(false);
        dispatch(
          handleSubmitFilterResult({
            type,
            filterObj,
            isFiltered,
            totalCount,
            filterUrl,
          })
        );
      });
    } else if (location.pathname.includes("FarmsEmpty")) {
      type = 4;
      const filterUrl = generateFilterUrl(
        filterObject,
        type,
        for_sale,
        incubator
      );

      API.get(filterUrl).then((res: any) => {
        totalCount = res.data.totalCount;
        filterObj.push(res.data.items);
        setIsLoading(false);
        dispatch(
          handleSubmitFilterResult({
            type,
            filterObj,
            isFiltered,
            totalCount,
            filterUrl,
          })
        );
      });
    } else if (location.pathname.includes("FarmsSuspend")) {
      type = 5;
      const filterUrl = generateFilterUrl(
        filterObject,
        type,
        for_sale,
        incubator
      );

      API.get(filterUrl).then((res: any) => {
        totalCount = res.data.totalCount;
        filterObj.push(res.data.items);
        setIsLoading(false);
        dispatch(
          handleSubmitFilterResult({
            type,
            filterObj,
            isFiltered,
            totalCount,
            filterUrl,
          })
        );
      });
    }
  };
  return (
    <div className="FarmsFilter">
      {isLoading === true ? <Loading /> : null}
      <input className="modal-state" id="modal-2456" type="checkbox" />
      <div className="modal w-screen " style={{ direction: "rtl" }}>
        <label className="modal-overlay" htmlFor="modal-2456"></label>
        <div
          className="modal-content flex flex-col gap-5 max-w-3xl "
          style={{ width: "580px" }}
        >
          <div className="accordion-group">
            {/* اسم العنبر */}
            <div className="accordion">
              <input
                type="checkbox"
                id="accordion-name"
                className="accordion-toggle"
              />
              <label htmlFor="accordion-name" className="head ">
                اسم العنبر
              </label>
              <div className="divider"></div>

              <div className="accordion-content">
                <div className="min-h-0">
                  <div className="flex gap-3 items-center">
                    <input
                      type="text"
                      className="mission-map-input"
                      onChange={(e) => handleChangeFarmName(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* المربي */}
            <div className="accordion">
              <input
                type="checkbox"
                id="accordion-breeder"
                className="accordion-toggle"
              />
              <label htmlFor="accordion-breeder" className="head mt-2">
                المربي
              </label>
              <div className="divider"></div>
              <div className="accordion-content">
                <div className="min-h-0">
                  <div className="form-control relative w-full mt-2">
                    <input
                      type="text"
                      className="input input-lg max-w-full input-search"
                      onChange={handleBreederSearch}
                      placeholder="ابحث عن مربي"
                    />

                    <span className="absolute inset-y-0 right-4 inline-flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
                          stroke="#64748B"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M17.4998 17.4998L14.1665 14.1665"
                          stroke="#64748B"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                  <div className="h-32 overflow-y-auto mt-4">
                    <ul className="list-none scrollable-list">
                      {breeders.map((item: any) => {
                        return (
                          <>
                            {isLoading === true ? (
                              <div className="skeleton-pulse h-5 rounded-md mb-2"></div>
                            ) : (
                              <li className="flex justify-between pl-4 py-2">
                                <span className="list-text">{item.name}</span>
                                <div>
                                  <input
                                    type="checkbox"
                                    className="checkbox checkbox-bordered-success"
                                    onChange={() =>
                                      handleChangeBreeder(item.id, item.name)
                                    }
                                    checked={item.id === selectedBreederId}
                                  />
                                </div>
                              </li>
                            )}
                          </>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {location.pathname.includes(`AllFarms`) ? (
              <>
                {/* نوع العنبر */}
                <div className="accordion">
                  <input
                    type="checkbox"
                    id="accordion-1"
                    className="accordion-toggle"
                  />
                  <label htmlFor="accordion-1" className="head ">
                    نوع العنبر
                  </label>
                  <div className="divider"></div>

                  <div className="accordion-content">
                    <div className="min-h-0">
                      <ul className="list-none scrollable-list">
                        {farmStatuses.map((item: any) => {
                          return (
                            <>
                              {isLoading === true ? (
                                <div className="skeleton-pulse h-5 rounded-md mb-2"></div>
                              ) : (
                                <li className="flex justify-between pl-4 py-2">
                                  <span className="list-text">
                                    {item.status}
                                  </span>
                                  <div>
                                    <input
                                      type="checkbox"
                                      className="checkbox checkbox-bordered-success"
                                      onChange={() =>
                                        handleChangeFarmStatusId(
                                          item.id,
                                          item.name
                                        )
                                      }
                                      checked={item.id === selectedFarmStatusId}
                                    />
                                  </div>
                                </li>
                              )}
                            </>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
            {/* نوع التربية */}
            <>
              <div className="accordion">
                <input
                  type="checkbox"
                  id="accordion-farmingType"
                  className="accordion-toggle"
                />
                <label htmlFor="accordion-farmingType" className="head ">
                  نوع التربية
                </label>
                <div className="divider"></div>

                <div className="accordion-content">
                  <div className="min-h-0">
                    <ul className="list-none scrollable-list">
                      {farmingTypeData.map((item: any) => {
                        return (
                          <>
                            {isLoading === true ? (
                              <div className="skeleton-pulse h-5 rounded-md mb-2"></div>
                            ) : (
                              <li className="flex justify-between pl-4 py-2">
                                <span className="list-text">{item.type}</span>
                                <div>
                                  <input
                                    type="checkbox"
                                    className="checkbox checkbox-bordered-success"
                                    onChange={() =>
                                      handleChangeFarmingType(item.id)
                                    }
                                    checked={item.id === selectedFarmingTypeId}
                                  />
                                </div>
                              </li>
                            )}
                          </>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </>
            {/* نوع الطائر */}
            <>
              <div className="accordion">
                <input
                  type="checkbox"
                  id="accordion-dajin_id"
                  className="accordion-toggle"
                />
                <label htmlFor="accordion-dajin_id" className="head ">
                  نوع الطائر
                </label>
                <div className="divider"></div>

                <div className="accordion-content">
                  <div className="min-h-0">
                    <ul className="list-none scrollable-list">
                      {dajinTypes.map((item: any) => {
                        return (
                          <>
                            {isLoading === true ? (
                              <div className="skeleton-pulse h-5 rounded-md mb-2"></div>
                            ) : (
                              <li className="flex justify-between pl-4 py-2">
                                <span className="list-text">{item.type}</span>
                                <div>
                                  <input
                                    type="checkbox"
                                    className="checkbox checkbox-bordered-success"
                                    onChange={() =>
                                      handleChangeDajinType(item.id)
                                    }
                                    checked={item.id === selectedDajinId}
                                  />
                                </div>
                              </li>
                            )}
                          </>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </>
            {/* تاريخ البيع المتوقع */}
            {location.pathname.includes(`/FarmsReadyToSale`) ? (
              <>
                <div className="accordion">
                  <input
                    type="checkbox"
                    id="accordion-expectedDate"
                    className="accordion-toggle"
                  />
                  <label htmlFor="accordion-expectedDate" className="head mt-2">
                    تاريخ البيع المتوقع
                  </label>
                  <div className="divider"></div>
                  <div className="accordion-content">
                    <div className="min-h-0 grid grid-cols-2">
                      <div className="col-start-1 flex gap-2 justify-start  pr-4">
                        <div className="head-wrap">اليوم :</div>
                        <DatePicker
                          selected={expected_sale_date}
                          minDate={(() => {
                            const newDate = new Date(); // Create a new Date instance
                            newDate.setDate(newDate.getDate() - 3); // Minus 3 days to startDate
                            return newDate;
                          })()}
                          maxDate={(() => {
                            const newDate = new Date(); // Create a new Date instance
                            newDate.setDate(newDate.getDate() + 3); // Add3 days to startDate
                            return newDate;
                          })()}
                          onChange={(event) => handleChangeExcpectedDate(event)}
                          className="date-picker-input-filter"
                        />
                      </div>
                      <div className="col-span-full">
                        <div className="divider"></div>
                      </div>

                      <div className="col-start-1 flex gap-2 justify-start pr-4">
                        <div className="head-wrap">من :</div>
                        <DatePicker
                          selected={fromExpectedDate}
                          onChange={(event) =>
                            handleChangeFromExpectedDate(event)
                          }
                          className="date-picker-input-filter"
                        />
                      </div>

                      <div className="col-start-2 flex gap-2 justify-start pr-4">
                        <div className="head-wrap">إلي :</div>
                        <DatePicker
                          selected={toExcpectedDate}
                          onChange={(event) =>
                            handleChangeToExcpectedDate(event)
                          }
                          className="date-picker-input-filter"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : null}

            {/* المكان */}
            <div className="accordion">
              <input
                type="checkbox"
                id="accordion-2"
                className="accordion-toggle"
              />
              <label htmlFor="accordion-2" className="head mt-2">
                المكان
              </label>
              <div className="divider"></div>
              <div className="accordion-content">
                <div className="min-h-0">
                  <div className="flex gap-8 items-center">
                    <div className="flex gap-3 items-center">
                      <input
                        type="checkbox"
                        className="checkbox checkbox-bordered-success"
                        onChange={() =>
                          handleChangeLocationType(1, "governorate")
                        }
                        checked={1 === selectedItemId}
                      />
                      <span className="txt">المحافظة</span>
                    </div>

                    <div className="flex gap-3 items-center">
                      <input
                        type="checkbox"
                        className="checkbox checkbox-bordered-success"
                        onChange={() => handleChangeLocationType(2, "city")}
                        checked={2 === selectedItemId}
                      />
                      <span className="txt">المنطقة</span>
                    </div>
                  </div>

                  <div className="form-control relative w-full mt-2">
                    <input
                      type="text"
                      className="input input-lg max-w-full input-search"
                      onChange={handleLocationSearch}
                      placeholder={
                        selectedItemId === 1
                          ? "ابحث عن المحافظة"
                          : "ابحث عن المنطقة"
                      }
                    />

                    <span className="absolute inset-y-0 right-4 inline-flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
                          stroke="#64748B"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M17.4998 17.4998L14.1665 14.1665"
                          stroke="#64748B"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                  <div className=" h-32 overflow-y-auto mt-4">
                    <ul className="list-none scrollable-list ">
                      {locationSearchResults.map((item) => {
                        return (
                          <>
                            {isLoading === true ? (
                              <div className="skeleton-pulse h-5 rounded-md mb-2"></div>
                            ) : (
                              <li className="flex justify-between pl-4 py-2">
                                <span className="list-text">{item.name}</span>
                                <div>
                                  <input
                                    type="checkbox"
                                    className="checkbox checkbox-bordered-success"
                                    onChange={() =>
                                      handleChangeLocationContent(
                                        item.id,
                                        item.name
                                      )
                                    }
                                    checked={
                                      selectedItemId === 1
                                        ? filterObject.gov_id.includes(item.id)
                                        : filterObject.city_id.includes(item.id)
                                    }
                                  />
                                </div>
                              </li>
                            )}
                          </>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* farms_capacity_id */}
            <div className="accordion">
              <input
                type="checkbox"
                id="accordion-3"
                className="accordion-toggle"
              />
              <label htmlFor="accordion-3" className="head mt-2">
                سعة العنبر
              </label>
              <div className="divider"></div>
              <div className="accordion-content">
                <div className="min-h-0">
                  <div className="h-32 overflow-y-auto mt-4">
                    <ul className="list-none scrollable-list">
                      {FarmCapacityList.map((item: any) => {
                        return (
                          <>
                            {isLoading === true ? (
                              <div className="skeleton-pulse h-5 rounded-md mb-2"></div>
                            ) : (
                              <li className="flex justify-between pl-4 py-2">
                                <span className="list-text">{item.name}</span>
                                <div>
                                  <input
                                    type="checkbox"
                                    className="checkbox checkbox-bordered-success"
                                    onChange={() =>
                                      handleChangeFarmCapacity(
                                        item.id,
                                        item.name
                                      )
                                    }
                                    checked={item.id === selectedFarmCapacityId}
                                  />
                                </div>
                              </li>
                            )}
                          </>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* مربي أعمار */}
            <div className="accordion">
              <input
                type="checkbox"
                id="accordion-7"
                className="accordion-toggle"
              />
              <label htmlFor="accordion-7" className="head mt-2">
                مربي أعمار{" "}
              </label>
              <div className="divider"></div>
              <div className="accordion-content">
                <div className="min-h-0">
                  <div className="flex gap-8 items-center">
                    <div className="flex gap-3 items-center">
                      <input
                        type="checkbox"
                        className="checkbox checkbox-bordered-success"
                        onChange={() => setIncubator(!incubator)}
                        checked={incubator}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* سبب إيقاف العنبر */}
            {location.pathname.includes("/FarmsSuspend") ||
            location.pathname.includes("/AllFarms") ? (
              <>
                <div className="accordion">
                  <input
                    type="checkbox"
                    id="accordion-8"
                    className="accordion-toggle"
                  />
                  <label htmlFor="accordion-8" className="head mt-2">
                    سبب الإيقاف
                  </label>
                  <div className="divider"></div>
                  <div className="accordion-content">
                    <div className="min-h-0">
                      <div className="form-control relative w-full mt-2">
                        <input
                          type="text"
                          className="input input-lg max-w-full input-search"
                          onChange={handleSuspenReasonsSearch}
                          placeholder={"أختر سبب الإيقاف"}
                        />

                        <span className="absolute inset-y-0 right-4 inline-flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
                              stroke="#64748B"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M17.4998 17.4998L14.1665 14.1665"
                              stroke="#64748B"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                      </div>
                      <div className=" h-32 overflow-y-auto mt-4">
                        <ul className="list-none scrollable-list ">
                          {stoppedReasonsSearchResults.map((item) => {
                            return (
                              <>
                                {isLoading === true ? (
                                  <div className="skeleton-pulse h-5 rounded-md mb-2"></div>
                                ) : (
                                  <li className="flex justify-between pl-4 py-2">
                                    <span className="list-text">
                                      {item.reason}
                                    </span>
                                    <div>
                                      <input
                                        type="checkbox"
                                        className="checkbox checkbox-bordered-success"
                                        onChange={() =>
                                          handleChangeSuspendReason(
                                            item.id,
                                            item.reason
                                          )
                                        }
                                        checked={item.id === selectedReasonId}
                                      />
                                    </div>
                                  </li>
                                )}
                              </>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                {/* عدد ايام إيقاف العنبر */}
                <div className="accordion">
                  <input
                    type="checkbox"
                    id="accordion-9"
                    className="accordion-toggle"
                  />
                  <label htmlFor="accordion-9" className="head ">
                    عدد أيام إيقاف العنبر
                  </label>
                  <div className="divider"></div>

                  <div className="accordion-content">
                    <div className="min-h-0">
                      <div className="flex gap-3 items-center">
                        <input
                          type="number"
                          className="mission-map-input"
                          onChange={(e) =>
                            handleChangeSuspendDays(Number(e.target.value))
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </div>

          <div className="pt-4">
            <div className="grid grid-cols-2">
              <div className="col-span-full">
                <label htmlFor="modal-2456">
                  <div
                    className="add-btn w-full p-4"
                    onClick={() => handleSubmitFilter()}
                  >
                    تأكيد
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
