import { useLocation } from "react-router-dom";
import { Farms } from "../../Components/Farms/Farms";
import { MainHeader } from "../../Components/MainHeader/MainHeader";
import { ModuleHeader } from "../../Components/ModuleHeader/ModuleHeader";
import { Sidebar } from "../../Components/Sidebar/Sidebar";
import { AllFarms } from "../../Components/Farms/All-Farms/AllFarms";
import { FarmsReadyToSale } from "../../Components/Farms/FarmsReadyToSale/FarmsReadyToSale";
import { CommingSoon } from "../../Components/CommingSoon/CommingSoon";
import { FarmsEmpty } from "../../Components/Farms/FarmsEmpty/FarmsEmpty";
import { FarmsSuspend } from "../../Components/Farms/FarmsSuspend/FarmsSuspend";
import { FarmsInprogress } from "../../Components/Farms/FarmsInprogress/FarmsInprogress";

export const FarmsPage = () => {
  const location = useLocation();
  return (
    <div className="flex flex-row " style={{ direction: "rtl" }}>
      <div className="lg:w-full md:w-full sm:w-auto sm:max-w-[18rem]">
        <input
          type="checkbox"
          id="sidebar-mobile-fixed"
          className="sidebar-state"
        />
        <label
          htmlFor="sidebar-mobile-fixed"
          className="sidebar-overlay"
        ></label>
        {/* Side bar*/}
        <Sidebar />
      </div>
      <div className="flex w-full flex-col p-4 bg-white">
        <div className="page-container" style={{ backgroundColor: "#FFF" }}>
          <div className="col-span-12 row-span-1">
            {/* Second Header */}
            <MainHeader />

            <ModuleHeader />
          </div>
        </div>
        {/* Content Will Be Here */}

        <>
          <div
            className="h-full"
            style={{ backgroundColor: "var(--Greyscale-50, #F8FAFC)" }}
          >
            {location.pathname.includes("AllFarms") ? (
              <>
                <AllFarms />
              </>
            ) : location.pathname.includes("FarmsReadyToSale") ? (
              <>
                <FarmsReadyToSale />
              </>
            ) : location.pathname.includes("FarmsEmpty") ? (
              <FarmsEmpty />
            ) : location.pathname.includes("FarmsSuspend") ? (
              <FarmsSuspend />
            ) : location.pathname.includes("FarmsInprogress") ? (
              <FarmsInprogress />
            ) : (
              <CommingSoon />
            )}
          </div>
        </>
      </div>
    </div>
  );
};
