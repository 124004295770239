import "./CompetitionsHeader.scss";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  handleSelectedCompetitonType,
  toggleShowAddCompetition,
} from "../../../redux/Slices/CompetitionsSlice";
import { useLocation, useNavigate } from "react-router-dom";

export const AddCompetitionHeader = () => {
  const dispatch = useDispatch();
  const [activeElement, setActiveElement] = useState(1);
  const navigate = useNavigate();
  const location = useLocation();
  const handleActiveElement = (id: number) => {
    setActiveElement(id);
    switch (id) {
      case 1:
        navigate("/Competitions/Add/Details");
        break;
      case 2:
        navigate("/Competitions/Add/competition-questions");
        break;

      // case 3:
      //   navigate("/FarmsActive");
      //   break;

      default:
        break;
    }
  };

  return (
    <div
      className="grid grid-cols-2 xs:grid-col-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 p-4 CompetitionsHeader"
      style={{ backgroundColor: "#FFF" }}
    >
      <div className="col-start-1 flex  sm:col-start-1">
        <div
          onClick={() => handleActiveElement(1)}
          className={
            location.pathname === "/Competitions/Add/Details"
              ? "main-element-active"
              : "main-element"
          }
        >
          تفاصيل المسابقة
        </div>
        <div
          onClick={() => handleActiveElement(2)}
          className={
            location.pathname === "/Competitions/Add/competition-questions"
              ? "main-element-active"
              : "main-element"
          }
        >
          اسئلة الاختبار
        </div>

        <div
          onClick={() => handleActiveElement(3)}
          className={
            location.pathname === "/FinishedCompetitions"
              ? "main-element-active"
              : "main-element"
          }
        >
          الرعاه
        </div>
        <div
          onClick={() => handleActiveElement(3)}
          className={
            location.pathname === "/FinishedCompetitions"
              ? "main-element-active"
              : "main-element"
          }
        >
          المرفقات
        </div>
      </div>
    </div>
  );
};
