import "../Farms.scss";
import chicken from "../../../Assets/Icons/Chicken.jpg";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import API from "../../../Api";
import { Loading } from "../../Loading/Loading";
import { Link, useNavigate } from "react-router-dom";
import { setMainHeaderName } from "../../../redux/Slices/MainHeaderSlice";
import { TotalCount } from "../../../SubComponents/TotalCount/TotalCount";
import moment from "moment";
import { toast } from "react-toastify";
import Pagination from "../../../SubComponents/Paginator/Pagination";
import { ExportExcel } from "../../../SubComponents/Export/ExcelExport";

export const FarmsReadyToSale = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [farms, setFarms] = useState([]);
  const [pageSize, setPageSize] = useState(12);
  const [totalPages, setTotalPages] = useState(1);

  const [pageNumber, setPageNumber] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [isSorted, setIsSorted] = useState(false);
  const [sortedKey, setSortedKey] = useState("&sort_by=id&sort_order=DESC");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stateFromFarmsSlice = useSelector((state: any) => state.farms);

  useEffect(() => {
    setIsLoading(true);
    let mainHeaderName = "العنابر";
    dispatch(setMainHeaderName({ mainHeaderName }));

    if (
      stateFromFarmsSlice.isFiltered === true &&
      stateFromFarmsSlice.headerType === 2 &&
      stateFromFarmsSlice.filterDataObj.length > 0
    ) {
      if (stateFromFarmsSlice.filterDataObj[0].length > 0) {
        setFarms(stateFromFarmsSlice.filterDataObj[0]);
        setTotalRows(stateFromFarmsSlice.filterTotalCount);
        setTotalPages(
          Math.ceil(stateFromFarmsSlice.filterTotalCount / pageSize)
        );
        setIsLoading(false);
      } else {
        setTotalRows(0);
        setTotalPages(1);
        setFarms([]);
        setIsLoading(false);
      }
    } else {
      setPageNumber(1);
      let url = `farms?for_sale=true&page=${pageNumber}&limit=${pageSize}&sort_by=id&sort_order=DESC`;
      API.get(url).then((res) => {
        if (res) {
          setTotalRows(res.data.meta.totalItems);
          setTotalPages(Math.ceil(res.data.meta.totalItems / pageSize));
          const updatedFarmersData = res.data.items.map((farmer: any) => ({
            ...farmer,
            photo: farmer.photo !== null ? farmer.photo : { chicken },
          }));
          setFarms(updatedFarmersData);

          setIsLoading(false);
        }
      });
    }
  }, [
    dispatch,
    stateFromFarmsSlice.filterDataObj,
    stateFromFarmsSlice.filterTotalCount,
    stateFromFarmsSlice.headerType,
    stateFromFarmsSlice.isFiltered,
  ]);

  const hanldeChangePage = (targetPN: number) => {
    setIsLoading(true);
    if (
      stateFromFarmsSlice.isFiltered === true &&
      stateFromFarmsSlice.headerType === 2 &&
      stateFromFarmsSlice.filterDataObj.length > 0
    ) {
      if (stateFromFarmsSlice.filterDataObj[0].length > 0) {
        API.get(
          `${stateFromFarmsSlice.filterUrl}page=${targetPN}&limit=${pageSize}`
        ).then((res) => {
          if (res) {
            setFarms(res.data.items);
            setTotalRows(res.data.meta.totalItems);
            setTotalPages(Math.ceil(res.data.meta.totalItems / pageSize));
            setPageNumber(targetPN);

            setIsLoading(false);
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }
        });
      }
    } else if (isSorted === true) {
      let key = sortedKey;

      API.get(
        `farms?for_sale=true&page=${targetPN}&limit=${pageSize}&${key}`
      ).then((res) => {
        if (res.status === 200) {
          setFarms(res.data.items);
          setPageNumber(targetPN);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          setIsLoading(false);
        } else {
          setIsLoading(false);
          toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
        }
      });
    } else {
      API.get(`farms?for_sale=true&page=${pageNumber}&limit=${pageSize}`).then(
        (res) => {
          if (res) {
            setFarms(res.data.items);
            setTotalRows(res.data.meta.totalItems);
            setTotalPages(Math.ceil(res.data.meta.totalItems / pageSize));
            setPageNumber(targetPN);

            setIsLoading(false);
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }
        }
      );
    }
  };

  const handleImageError = (event: any) => {
    // Set a fallback image source when the original image fails to load
    event.target.src = chicken;
  };

  const sortData = (property: string) => {
    setIsLoading(true);
    setIsSorted(true);

    switch (property) {
      case "farms_capacity_id_desc":
        setSortedKey(`sort_by=farms_capacity_id&sort_order=DESC`);
        API.get(
          `farms?for_sale=true&page=${pageNumber}&limit=${pageSize}&sort_by=farms_capacity_id&sort_order=DESC`
        ).then((res) => {
          if (res.status === 200) {
            setFarms(res.data.items);

            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          }
        });
        break;

      case "farms_capacity_id_asc":
        setSortedKey(`sort_by=farms_capacity_id&sort_order=ASC`);
        API.get(
          `farms?for_sale=true&page=${pageNumber}&limit=${pageSize}&sort_by=farms_capacity_id&sort_order=ASC`
        ).then((res) => {
          if (res.status === 200) {
            setFarms(res.data.items);

            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          }
        });
        break;

      case "created_at_desc":
        setSortedKey(`sort_by=created_at&sort_order=DESC`);
        API.get(
          `farms?for_sale=true&page=${pageNumber}&limit=${pageSize}&sort_by=created_at&sort_order=DESC`
        ).then((res) => {
          if (res.status === 200) {
            setFarms(res.data.items);

            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          }
        });
        break;

      case "created_at_asc":
        setSortedKey(`sort_by=created_at&sort_order=ASC`);
        API.get(
          `farms?for_sale=true&page=${pageNumber}&limit=${pageSize}&sort_by=created_at&sort_order=ASC`
        ).then((res) => {
          if (res.status === 200) {
            setFarms(res.data.items);

            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          }
        });
        break;

      case "expected_sale_date_desc":
        setSortedKey(`expected_sale_date&sort_order=DESC`);
        API.get(
          `farms?for_sale=true&page=${pageNumber}&limit=${pageSize}&sort_by=expected_sale_date&sort_order=DESC`
        ).then((res) => {
          if (res.status === 200) {
            setFarms(res.data.items);

            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          }
        });
        break;

      case "expected_sale_date_asc":
        setSortedKey(`expected_sale_date&sort_order=ASC`);
        API.get(
          `farms?for_sale=true&page=${pageNumber}&limit=${pageSize}&sort_by=expected_sale_date&sort_order=ASC`
        ).then((res) => {
          if (res.status === 200) {
            setFarms(res.data.items);

            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          }
        });
        break;

      case "housing_date_desc":
        setSortedKey(`housing_date&sort_order=DESC`);
        API.get(
          `farms?for_sale=true&page=${pageNumber}&limit=${pageSize}&sort_by=housing_date&sort_order=DESC`
        ).then((res) => {
          if (res.status === 200) {
            setFarms(res.data.items);

            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          }
        });
        break;

      case "housing_date_asc":
        setSortedKey(`housing_date&sort_order=ASC`);
        API.get(
          `farms?for_sale=true&page=${pageNumber}&limit=${pageSize}&sort_by=housing_date&sort_order=ASC`
        ).then((res) => {
          if (res.status === 200) {
            setFarms(res.data.items);

            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          }
        });
        break;

      default:
        break;
    }
  };

  const loadCreatorName = (item: any) => {
    let name =
      item.created_by_role === 900 ? (
        <>
          {item.creator != null ? (
            <>
              <Link to={`/Users/Profile/PersonalData/${item.creator.id}`}>
                {item?.creator != null ? item?.creator.name : ""} - (مندوب)
              </Link>
            </>
          ) : (
            ""
          )}
        </>
      ) : item.created_by_role === 1000 ? (
        <>
          {item.creatorAdmin != null ? (
            <>
              {item.creatorAdmin != null ? item.creatorAdmin.name : ""} - (ادمن)
            </>
          ) : (
            ""
          )}
        </>
      ) : null;
    return name;
  };

  return (
    <>
      {isLoading === true ? <Loading /> : null}
      <div
        className={`${
          stateFromFarmsSlice.displayTypeId === 1 ? "FarmList" : "Farms"
        }  grid grid-cols-2 my-4 pt-5`}
      >
        <>
          {stateFromFarmsSlice.displayTypeId === 1 ? (
            <>
              {/* Total Count */}
              <div className="lg:col-start-1 md:col-start-1 sm:col-start-1 xs:col-start-1 sm:mb-3 xs:mb-3 mt-4">
                <TotalCount count={totalRows} title="العنابر" />
              </div>
              {/* Sorting && Export */}
              <div className="lg:col-start-2 md:col-start-1 sm:col-start-1 xs:col-start-1 flex lg:flex-row md:flex-col sm:flex-col xs:flex-col xs:items-center lg:justify-end md:justify-start sm:justify-start xs:justify-start">
                <div className="flex items-center justify-end gap-1 rounded-full bg-white p-3">
                  <svg
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        d="M16.0686 15H7.9313C7.32548 15 7.02257 15 6.88231 15.1198C6.76061 15.2238 6.69602 15.3797 6.70858 15.5393C6.72305 15.7232 6.93724 15.9374 7.36561 16.3657L11.4342 20.4344C11.6323 20.6324 11.7313 20.7314 11.8454 20.7685C11.9458 20.8011 12.054 20.8011 12.1544 20.7685C12.2686 20.7314 12.3676 20.6324 12.5656 20.4344L16.6342 16.3657C17.0626 15.9374 17.2768 15.7232 17.2913 15.5393C17.3038 15.3797 17.2392 15.2238 17.1175 15.1198C16.9773 15 16.6744 15 16.0686 15Z"
                        stroke="#949494"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>{" "}
                      <path
                        d="M7.9313 9.00005H16.0686C16.6744 9.00005 16.9773 9.00005 17.1175 8.88025C17.2393 8.7763 17.3038 8.62038 17.2913 8.46082C17.2768 8.27693 17.0626 8.06274 16.6342 7.63436L12.5656 3.56573C12.3676 3.36772 12.2686 3.26872 12.1544 3.23163C12.054 3.199 11.9458 3.199 11.8454 3.23163C11.7313 3.26872 11.6323 3.36772 11.4342 3.56573L7.36561 7.63436C6.93724 8.06273 6.72305 8.27693 6.70858 8.46082C6.69602 8.62038 6.76061 8.7763 6.88231 8.88025C7.02257 9.00005 7.32548 9.00005 7.9313 9.00005Z"
                        stroke="#949494"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>{" "}
                    </g>
                  </svg>
                  <div className="dropdown-container ">
                    <div className="dropdown">
                      <label tabIndex={0}>
                        <span className="sorting">الترتيب حسب</span>
                      </label>
                      <div className="dropdown-menu bg-white z-50">
                        <a
                          className={`cursor-pointer rounded-md border-2 p-1 mt-2 w-full text-sm text-right ${
                            sortedKey ==
                            "sort_by=farms_capacity_id&sort_order=DESC"
                              ? "sorting-item-active"
                              : "sorting-item"
                          } `}
                          onClick={() => sortData("farms_capacity_id_desc")}
                        >
                          السعة الأكبر
                        </a>

                        <a
                          className={`cursor-pointer rounded-md border-2 p-1 mt-2 w-full text-sm text-right ${
                            sortedKey ==
                            "sort_by=farms_capacity_id&sort_order=ASC"
                              ? "sorting-item-active"
                              : "sorting-item"
                          } `}
                          onClick={() => sortData("farms_capacity_id_asc")}
                        >
                          السعة الاقل
                        </a>

                        <a
                          className={`cursor-pointer rounded-md border-2 p-1 mt-2 w-full text-sm text-right ${
                            sortedKey == "sort_by=created_at&sort_order=DESC"
                              ? "sorting-item-active"
                              : "sorting-item"
                          } `}
                          onClick={() => sortData("created_at_desc")}
                        >
                          تاريخ الأضافة الأحدث
                        </a>

                        <a
                          className={`cursor-pointer rounded-md border-2 p-1 mt-2 w-full text-sm text-right ${
                            sortedKey == "sort_by=created_at&sort_order=ASC"
                              ? "sorting-item-active"
                              : "sorting-item"
                          } `}
                          onClick={() => sortData("created_at_asc")}
                        >
                          تاريخ الأضافة الأقدم
                        </a>
                        <a
                          className={`cursor-pointer rounded-md border-2 p-1 mt-2 w-full text-sm text-right ${
                            sortedKey == "expected_sale_date&sort_order=DESC"
                              ? "sorting-item-active"
                              : "sorting-item"
                          } `}
                          onClick={() => sortData("expected_sale_date_desc")}
                        >
                          تاريخ البيع المتوقع الأحدث
                        </a>
                        <a
                          className={`cursor-pointer rounded-md border-2 p-1 mt-2 w-full text-sm text-right ${
                            sortedKey == "expected_sale_date&sort_order=ASC"
                              ? "sorting-item-active"
                              : "sorting-item"
                          } `}
                          onClick={() => sortData("expected_sale_date_asc")}
                        >
                          تاريخ البيع المتوقع الأقدم
                        </a>

                        <a
                          className={`cursor-pointer rounded-md border-2 p-1 mt-2 w-full text-sm text-right ${
                            sortedKey == "housing_date&sort_order=DESC"
                              ? "sorting-item-active"
                              : "sorting-item"
                          } `}
                          onClick={() => sortData("housing_date_desc")}
                        >
                          تاريخ التسكين الأحدث
                        </a>
                        <a
                          className={`cursor-pointer rounded-md border-2 p-1 mt-2 w-full text-sm text-right ${
                            sortedKey == "housing_date&sort_order=ASC"
                              ? "sorting-item-active"
                              : "sorting-item"
                          } `}
                          onClick={() => sortData("housing_date_asc")}
                        >
                          تاريخ التسكين الأقدم
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <ExportExcel
                  url={`farms?for_sale=true&page=1&limit=100000000&sort_by=id&sort_order=DESC`}
                  fileName="عنابر جاهزة للبيع"
                  page="FarmsReadyToSale"
                />
              </div>

              {farms.map((item: any, index: any) => {
                const createdAtDate = moment(item.created_at);
                moment.locale("ar");
                const createdAtDate_Arabic =
                  createdAtDate.format("DD MMM YYYY");
                return (
                  <div className="grid grid-cols-2 col-span-full mb-4 mr-4 ml-4 mt-4 mission-content">
                    {/* Name && ID */}
                    <div className="col-span-2 md:col-span-1 grid grid-cols-2 p-4 mb-2 rounded-md border-2">
                      <div className="col-start-1">
                        <span
                          className="title no-wrap cursor-pointer"
                          onClick={() => navigate(`/AllFarms/${item.id}`)}
                        >
                          {item.user != null ? item.user.name : ""} -{" "}
                          {item.name} -{" "}
                          {item.city != null && item.governorate != null ? (
                            <>
                              {item.governorate.name} - {item.city.name}
                            </>
                          ) : null}
                        </span>
                      </div>
                      <div className="lg:col-start-2 md:col-start-1 sm:col-start-1 xs:col-start-1 pr-4 flex lg:justify-center md:justify-start sm:justify-start xs:justify-start">
                        <span className="title">{item.id}</span>
                      </div>
                    </div>
                    {/* المربي / مربي أعمار */}
                    <div className="col-span-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-2 mb-2 rounded-md border-2 w-full">
                      <div className="col-start-1 mt-2 flex gap-4 justify-start">
                        <div className="flex gap-2">
                          <span className="title">المربي :</span>
                          <span className="mission-type">
                            {item.user != null ? (
                              <Link
                                to={`/Breeders/${item.user.id}/AccountDetails`}
                              >
                                <a>{item.user.name}</a>
                              </Link>
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="lg:col-start-2 col-start-1 mt-2 flex gap-4 justify-start">
                        <div className="flex gap-2">
                          <span className="title">مربي أعمار :</span>
                          <span className="mission-type">
                            {item.incubator === 1 ? <>نعم</> : <>لا</>}
                          </span>
                        </div>
                      </div>
                    </div>

                    {/* المساحة / نوع التربية /السعة / نوع التهوئة/ السعة الحالية */}
                    <div className="col-span-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-3 mb-2 rounded-md border-2 w-full">
                      <div className="col-start-1 mt-2 flex gap-4 justify-start">
                        <span className="title">المساحة :</span>
                        <span className="mission-type">{item.area}</span>
                      </div>
                      <div className="lg:col-start-2 col-start-1 mt-2 flex gap-4 justify-start">
                        <span className="title">نوع التربية :</span>
                        <span className="mission-type">
                          {item.farmingType != null
                            ? item.farmingType.type
                            : ""}
                        </span>
                      </div>
                      <div className="lg:col-start-3 col-start-1 mt-2 flex gap-4 justify-start">
                        <span className="title">السعة :</span>
                        <span className="mission-type">
                          {item.capacity != null ? item.capacity.capacity : ""}
                        </span>
                      </div>
                      <div className="lg:col-start-1 col-start-1 mt-2 flex gap-4 justify-start">
                        <span className="title">نوع التهوئة :</span>
                        <span className="mission-type">
                          {item.ventilationType != null
                            ? item.ventilationType.type
                            : ""}
                        </span>
                      </div>
                      <div className="lg:col-start-2 mt-2 flex gap-4 justify-start">
                        <span className="title">السعة الحالية :</span>
                        <span className="mission-type">
                          {item.currentHousing != null
                            ? item.currentHousing.count
                            : ""}
                        </span>
                      </div>
                    </div>
                    {/*   العلف المستخدم / الكتكوت المستخدم */}
                    <div className="col-span-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-2 mb-2 rounded-md border-2 w-full">
                      <div className="col-start-1 mt-2 flex gap-4 justify-start">
                        <div className="flex gap-2">
                          <span className="title">الكتكوت المستخدم :</span>
                          <span className="mission-type">
                            {item.currentHousing != null &&
                            item.currentHousing.dajinBreed != null &&
                            item.currentHousing.dajinBreed.dajinType != null ? (
                              <a>{`${item.currentHousing.dajinBreed.dajinType.type} - ${item.currentHousing.dajinBreed.breed_type}`}</a>
                            ) : (
                              item.currentHousing?.dajinBreed?.breed_Type || ""
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="lg:col-start-2 col-start-1 mt-2 flex gap-4 justify-start">
                        <div className="flex gap-2">
                          <span className="title">العلف المستخدم :</span>
                          <span className="mission-type">
                            {item.currentHousing != null &&
                            item.currentHousing.feedCompany != null ? (
                              <div className="flex gap-4 assigned-to">
                                {item.currentHousing.feedCompany.id === "14"
                                  ? item.currentHousing.other_feed_co
                                  : item.currentHousing.feedCompany.name}
                              </div>
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* تاريخ التسكين / العدد / تاريخ البيع المتوقع / تاريخ بداية دورة التسكين // عدد دورات التسكين */}
                    <div className="col-span-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-3 mb-2 rounded-md border-2 w-full">
                      <div className="col-start-1 mt-2 flex gap-4 justify-start">
                        <span className="title">تاريخ التسكين :</span>
                        <span className="mission-type wrap">
                          {item.currentHousing != null
                            ? moment(item.currentHousing.housing_date).format(
                                "DD MMM YYYY"
                              )
                            : ""}
                        </span>
                      </div>
                      <div className="lg:col-start-2 col-start-1 mt-2 flex gap-2 justify-start">
                        <span className="title">العدد :</span>
                        <span className="mission-type">
                          {item.currentHousing != null
                            ? item.currentHousing.count
                            : 0}
                        </span>
                      </div>
                      <div className="lg:col-start-3 col-start-1 mt-2 flex gap-4 justify-start">
                        <div className="flex gap-2">
                          <span className="title">تاريخ البيع المتوقع :</span>
                          <span className="mission-type wrap">
                            {item.currentHousing != null
                              ? moment(
                                  item.currentHousing.expected_sale_date
                                ).format("DD MMM YYYY")
                              : ""}
                          </span>
                        </div>
                      </div>
                      <div className="lg:col-start-1 col-start-1 mt-2 flex gap-2 justify-start">
                        <span className="title">
                          تاريخ بداية دورة التسكين :
                        </span>
                        <span className="mission-type">
                          {item.currentHousing != null
                            ? moment(item.currentHousing.housing_date).format(
                                "DD MMM YYYY"
                              )
                            : ""}
                        </span>
                      </div>
                      <div className="lg:col-start-2 col-start-1 mt-2 flex gap-4 justify-start">
                        <div className="flex gap-2">
                          <span className="title">عدد دورات التسكين :</span>
                          <span className="mission-type">
                            {item.housing_count != null
                              ? item.housing_count
                              : 0}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="col-span-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-2 mb-2 rounded-md border-2 w-full">
                      <div className="col-start-1 mt-2 flex gap-4 justify-start">
                        <span className="title">العنوان علي الخريطة :</span>
                      </div>
                      <div className="lg:col-start-2 col-start-1 mt-2 flex justify-start">
                        {item.latitude != null && item.longitude ? (
                          <a
                            href={`https://www.google.com/maps/dir/?api=1&destination=${item.latitude},${item.longitude}`}
                            target="_blank"
                            className="link link-success"
                            rel="noreferrer"
                          >
                            Link
                          </a>
                        ) : null}
                      </div>
                    </div>

                    {/* انشئت  في / انشئت بواسطة/ الحالة */}
                    <div className="col-span-2 grid grid-cols-1 md:grid-cols-2 gap-4 p-3 mb-2 rounded-md border-2 w-full">
                      <div className="col-span-1 mt-2">
                        <div className="flex flex-col md:flex-row gap-2">
                          <div className="created-at flex items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="16"
                              viewBox="0 0 15 16"
                              fill="none"
                            >
                              <path
                                d="M1.875 6.6875C1.875 4.47836 3.66586 2.6875 5.875 2.6875H9.125C11.3341 2.6875 13.125 4.47836 13.125 6.6875V10.25C13.125 12.4591 11.3341 14.25 9.125 14.25H5.875C3.66586 14.25 1.875 12.4591 1.875 10.25V6.6875Z"
                                stroke="#9BA0B1"
                                strokeWidth="1.5"
                              />
                              <path
                                d="M1.875 6.125H13.125"
                                stroke="#9BA0B1"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                              />
                              <path
                                d="M5 1.75L5 3.625"
                                stroke="#9BA0B1"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M10 1.75V3.625"
                                stroke="#9BA0B1"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <circle
                                cx="7.5"
                                cy="9.875"
                                r="0.625"
                                fill="#9BA0B1"
                              />
                              <circle
                                cx="10"
                                cy="9.875"
                                r="0.625"
                                fill="#9BA0B1"
                              />
                              <circle
                                cx="5"
                                cy="9.875"
                                r="0.625"
                                fill="#9BA0B1"
                              />
                            </svg>
                            انشئت في : {createdAtDate_Arabic}
                          </div>
                          <div className="created-at flex items-center">
                            <svg
                              width="15"
                              height="16"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                              <g
                                id="SVGRepo_tracerCarrier"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></g>
                              <g id="SVGRepo_iconCarrier">
                                <path
                                  d="M20 18L14 18M17 15V21M4 21C4 17.134 7.13401 14 11 14C11.695 14 12.3663 14.1013 13 14.2899M15 7C15 9.20914 13.2091 11 11 11C8.79086 11 7 9.20914 7 7C7 4.79086 8.79086 3 11 3C13.2091 3 15 4.79086 15 7Z"
                                  stroke="#9BA0B1"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                              </g>
                            </svg>
                            انشئت بواسطة : {loadCreatorName(item)}
                          </div>
                        </div>
                      </div>
                      <div className="col-span-1 mt-2  flex justify-start">
                        <>
                          {item.status_id === "1" ? (
                            <>
                              <div className="status-revision lg:w-1/4 md:w-full sm:w-full xs:w-full">
                                <span> قيد المراجعة </span>
                              </div>
                            </>
                          ) : item.status_id === "2" ? (
                            <>
                              <div className="status-empty lg:w-1/4 md:w-full sm:w-full xs:w-full">
                                <span> فارغ </span>
                              </div>
                            </>
                          ) : item.status_id === "3" ? (
                            <>
                              <div className="status-inprogress lg:w-1/4 md:w-full sm:w-full xs:w-full">
                                <span> في التشغيل </span>
                              </div>
                            </>
                          ) : item.status_id === "4" ? (
                            <>
                              <div className="status-sale  lg:w-1/4 md:w-full sm:w-full xs:w-full">
                                <span> معروض للبيع </span>
                              </div>
                            </>
                          ) : item.status_id === "5" ? (
                            <>
                              <div className="status-refused lg:w-1/4 md:w-full sm:w-full xs:w-full">
                                <span> مرفوض </span>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              <div className="mt-4">
                {/* Total Count */}
                <TotalCount count={totalRows} title="العنابر" />
              </div>

              {/* Sorting */}
              <div className="col-start-2 flex justify-end pl-40 mt-4">
                <div className="flex items-center justify-end gap-1 rounded-full bg-white p-3">
                  <svg
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        d="M16.0686 15H7.9313C7.32548 15 7.02257 15 6.88231 15.1198C6.76061 15.2238 6.69602 15.3797 6.70858 15.5393C6.72305 15.7232 6.93724 15.9374 7.36561 16.3657L11.4342 20.4344C11.6323 20.6324 11.7313 20.7314 11.8454 20.7685C11.9458 20.8011 12.054 20.8011 12.1544 20.7685C12.2686 20.7314 12.3676 20.6324 12.5656 20.4344L16.6342 16.3657C17.0626 15.9374 17.2768 15.7232 17.2913 15.5393C17.3038 15.3797 17.2392 15.2238 17.1175 15.1198C16.9773 15 16.6744 15 16.0686 15Z"
                        stroke="#949494"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>{" "}
                      <path
                        d="M7.9313 9.00005H16.0686C16.6744 9.00005 16.9773 9.00005 17.1175 8.88025C17.2393 8.7763 17.3038 8.62038 17.2913 8.46082C17.2768 8.27693 17.0626 8.06274 16.6342 7.63436L12.5656 3.56573C12.3676 3.36772 12.2686 3.26872 12.1544 3.23163C12.054 3.199 11.9458 3.199 11.8454 3.23163C11.7313 3.26872 11.6323 3.36772 11.4342 3.56573L7.36561 7.63436C6.93724 8.06273 6.72305 8.27693 6.70858 8.46082C6.69602 8.62038 6.76061 8.7763 6.88231 8.88025C7.02257 9.00005 7.32548 9.00005 7.9313 9.00005Z"
                        stroke="#949494"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>{" "}
                    </g>
                  </svg>
                  <div className="dropdown-container ">
                    <div className="dropdown">
                      <label tabIndex={0}>
                        <span className="sorting">الترتيب حسب</span>
                      </label>
                      <div className="dropdown-menu   bg-white">
                        <a
                          className="cursor-pointer rounded-md border-2 p-1 mt-2 w-full text-sm text-right sorting-item"
                          onClick={() => sortData("farms_capacity_id_desc")}
                        >
                          السعة الأكبر
                        </a>

                        <a
                          className="cursor-pointer rounded-md border-2 p-1 mt-2 w-full text-sm text-right sorting-item"
                          onClick={() => sortData("farms_capacity_id_asc")}
                        >
                          السعة الاقل
                        </a>

                        <a
                          className="cursor-pointer rounded-md border-2 p-1 mt-2 w-full text-sm text-right sorting-item"
                          onClick={() => sortData("created_at_desc")}
                        >
                          تاريخ الأضافة الأحدث
                        </a>

                        <a
                          className="cursor-pointer border-2 p-1 mt-2 w-full text-sm text-right sorting-item"
                          onClick={() => sortData("created_at_asc")}
                        >
                          تاريخ الأضافة الأقدم
                        </a>
                        <a
                          className="cursor-pointer rounded-md border-2 p-1 mt-2 w-full text-sm text-right sorting-item"
                          onClick={() => sortData("expected_sale_date_desc")}
                        >
                          تاريخ البيع المتوقع الأحدث
                        </a>
                        <a
                          className="cursor-pointer rounded-md border-2 p-1 mt-2 w-full text-sm text-right sorting-item"
                          onClick={() => sortData("expected_sale_date_asc")}
                        >
                          تاريخ البيع المتوقع الأقدم
                        </a>

                        <a
                          className="cursor-pointer rounded-md border-2 p-1 mt-2 w-full text-sm text-right sorting-item"
                          onClick={() => sortData("housing_date_desc")}
                        >
                          تاريخ التسكين الأحدث
                        </a>
                        <a
                          className="cursor-pointer rounded-md border-2 p-1 mt-2 w-full text-sm text-right sorting-item z-50"
                          onClick={() => sortData("housing_date_asc")}
                        >
                          تاريخ التسكين الأقدم
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-3 gap-6 mt-5 pr-4 pl-4 col-span-full">
                {isLoading === true ? (
                  <>
                    <Loading />
                  </>
                ) : null}
                {farms.map((item: any, index: any) => {
                  return (
                    <div className="big-section mb-2 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300">
                      <div className="course-cover relative">
                        <>
                          {item.status_id === "1" ? (
                            <>
                              <div className="status-revision flex absolute bottom-4 left-3  p-2">
                                <span> قيد المراجعة </span>
                              </div>
                            </>
                          ) : item.status_id === "2" ? (
                            <>
                              <div className="status-empty flex absolute bottom-4 left-3  p-2">
                                <span> فارغ </span>
                              </div>
                            </>
                          ) : item.status_id === "3" ? (
                            <>
                              <div className="status-inprogress flex absolute bottom-4 left-3  p-2">
                                <span> في التشغيل </span>
                              </div>
                            </>
                          ) : item.status_id === "4" ? (
                            <>
                              <div className="status-sale flex absolute bottom-4 left-3  p-2">
                                <span> معروض للبيع </span>
                              </div>
                            </>
                          ) : item.status_id === "5" ? (
                            <>
                              <div className="status-refused flex absolute bottom-4 left-3  p-2">
                                <span> مرفوض </span>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </>

                        <img
                          src={item.photo}
                          onClick={() => navigate(`/AllFarms/${item.id}`)}
                          alt="course-cover"
                          className="course-img"
                          onError={handleImageError}
                          loading="lazy"
                        />
                      </div>
                      <div className="grid grid-cols-2 pl-4 ">
                        <div className="col-start-1">
                          <p
                            className="farm-name mb-2 mt-2 cursor-pointer"
                            onClick={() => navigate(`/AllFarms/${item.id}`)}
                          >
                            {item.user != null ? item.user.name : ""}-
                            {item.name}-{" "}
                            {item.city != null && item.governorate != null ? (
                              <>
                                {item.governorate.name}-{item.city.name}
                              </>
                            ) : null}
                          </p>
                        </div>
                        <div className="col-start-2 flex justify-end">
                          <p className="farm-name mt-2">{item.id}</p>
                        </div>
                      </div>
                      <div className="col-span-full">
                        <div className="divider"></div>
                      </div>
                      <div className="flex gap-2">
                        <span className="title">تمت الاضافة بواسطة:</span>
                        <span className="title-content cursor-pointer">
                          {item.creator != null ? (
                            <>
                              <Link
                                to={`/Users/Profile/PersonalData/${item.creator.id}`}
                              >
                                <a>{item.creator.name}</a>
                              </Link>
                            </>
                          ) : (
                            ""
                          )}
                        </span>
                      </div>

                      <div className="grid grid-cols-2 pl-4 ">
                        <div className="col-span-full mt-2">
                          <div className="flex gap-2">
                            <span className="title">المساحة :</span>
                            <span className="title-content">{item.area}</span>
                          </div>
                        </div>
                        <div className="col-span-full mt-2 ">
                          <div className="flex gap-2">
                            <span className="title">السعة :</span>
                            <span className="title-content">
                              {item.capacity != null
                                ? item.capacity.capacity
                                : ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-span-full mt-2">
                          <div className="flex gap-2">
                            <span className="title">نوع التربية :</span>
                            <span className="title-content">
                              {item.farmingType != null
                                ? item.farmingType.type
                                : ""}
                            </span>
                          </div>
                        </div>
                        <div className="col-span-full  mt-2">
                          <div className="flex gap-2">
                            <span className="title">نوع التهوئة :</span>
                            <span className="title-content">
                              {item.ventilationType != null
                                ? item.ventilationType.type
                                : ""}
                            </span>
                          </div>
                        </div>

                        <div className="col-span-full mt-2">
                          <div className="flex gap-2">
                            <span className="title">السعة الحالية :</span>
                            <span className="title-content">
                              {item.currentHousing != null
                                ? item.currentHousing.count
                                : ""}
                            </span>
                          </div>
                        </div>
                        <div className="col-span-full  mt-2">
                          <div className="gap-2">
                            <span className="title">تاريخ البيع المتوقع :</span>
                            <span className="title-content">
                              {item.currentHousing != null
                                ? moment(
                                    item.currentHousing.expected_sale_date
                                  ).format("DD MMM YYYY")
                                : ""}
                            </span>
                          </div>
                        </div>
                        {/* نوع الكتكوت المستخدم */}
                        <div className="col-span-full mt-2">
                          <div className="flex gap-2">
                            <span className="title">
                              نوع الكتكوت المستخدم :
                            </span>
                            <span className="title-content">
                              {item.currentHousing != null ? (
                                <>
                                  {item.currentHousing.dajinBreed != null ? (
                                    <>
                                      {item.currentHousing.dajinBreed
                                        .dajinType != null ? (
                                        <a>
                                          {`${item.currentHousing.dajinBreed.dajinType.type} -
                                          ${item.currentHousing.dajinBreed.breed_type}`}
                                        </a>
                                      ) : (
                                        <a>
                                          {
                                            item.currentHousing.dajinBreed
                                              .breed_Type
                                          }
                                        </a>
                                      )}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ) : null}
                            </span>
                          </div>
                        </div>
                        {/* نوع العلف المستخدم */}
                        <div className="col-span-full mt-2">
                          <div className="flex gap-2">
                            <span className="title">نوع العلف المستخدم :</span>
                            <span className="title-content">
                              {item.currentHousing != null ? (
                                <>
                                  {item.currentHousing.feedCompany != null ? (
                                    <>
                                      <div className="flex gap-4 assigned-to">
                                        {item.currentHousing.feedCompany.id ===
                                        "14"
                                          ? item.currentHousing.other_feed_co
                                          : item.currentHousing.feedCompany
                                              .name}
                                      </div>
                                    </>
                                  ) : null}
                                </>
                              ) : null}
                            </span>
                          </div>
                        </div>
                        <div className="col-span-full mt-2">
                          <div className="flex gap-2">
                            <span className="title">عدد دورات التسكين :</span>
                            <span className="title-content">
                              {item.housing_count != null
                                ? item.housing_count
                                : 0}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </>

        {/* Pagination */}
        <div className="lg:col-start-2 md:col-span-full md:justify-center sm:justify-center xs:justify-center sm:col-span-full xs:col-span-full flex lg:justify-end pt-4 pl-12">
          <Pagination
            currentPage={pageNumber}
            lastPage={totalPages}
            maxLength={7}
            setCurrentPage={hanldeChangePage}
          />
        </div>
      </div>

      {isLoading === true ? <Loading /> : null}
    </>
  );
};
