import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  selectedTraderGovernorateId: 0,

  selectedTraderGovernorateName: "",

  selectedTraderCityId: 0,
  selectedTraderCityName: "",

  tradersFilterData: [],
  tradersIsFiltered: false,
  tradersFilterTotalCount: 0,
  traderGovernorateChanged: false,
  cityChanged: false,
  traderCityChanged: false,
  headerType: 0,
  filterDataObj: [],
  isFiltered: false,
  filterTotalCount: 0,
  filterUrl: "",
  governorateModule: 0,
  cityModule: 0,
  workAreas_ids: [],
  workAreaChanged: false,
  workAreas_text: "",
};
export const TraderSlice = createSlice({
  name: "farm",
  initialState,
  reducers: {
    setSelectedTraderGovernorates: (state, action) => {
      state.selectedTraderGovernorateId = action.payload.governorateId;
      state.selectedTraderGovernorateName = action.payload.governorateName;
      state.traderGovernorateChanged = action.payload.isChanged;
      state.governorateModule = action.payload.module;
    },
    setSelectedTraderCity: (state, action) => {
      state.selectedTraderCityId = action.payload.cityId;
      state.selectedTraderCityName = action.payload.cityName;
      state.traderCityChanged = action.payload.isChanged;
      state.cityModule = action.payload.module;
    },
    setTradersSearchResult: (state, action) => {
      state.tradersFilterData = action.payload.searchResult;
      state.tradersIsFiltered = action.payload.isFiltered;
      state.tradersFilterTotalCount = action.payload.totalCount;
    },
    handleSubmitFilterResult: (state, action) => {
      state.headerType = action.payload.type;
      state.filterDataObj = action.payload.filterObj;
      state.isFiltered = action.payload.isFiltered;
      state.filterTotalCount = action.payload.totalCount;
      state.filterUrl = action.payload.filterUrl;
    },
    setSelectedTraderWorkAreas: (state, action) => {
      state.workAreas_ids = action.payload.rangeIds;
      state.workAreas_text = action.payload.rangeTitle;
      state.workAreaChanged = action.payload.workAreaChanged;
    },
  },
});
export const {
  setSelectedTraderGovernorates,
  setSelectedTraderCity,
  setTradersSearchResult,
  handleSubmitFilterResult,
  setSelectedTraderWorkAreas,
} = TraderSlice.actions;

export default TraderSlice.reducer;
