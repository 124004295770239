import { useEffect, useState } from "react";
import "./FarmsHeader.scss";
import { useLocation, useNavigate } from "react-router";
import { Loading } from "../../Loading/Loading";
import { useDispatch, useSelector } from "react-redux";
import {
  handleSubmitFilterResult,
  setSelectedFarmDisplayType,
  setSelectedFarmHeader,
} from "../../../redux/Slices/FarmSlice";
import { FarmsFilter } from "../Filter/FarmsFilter";
export const FarmsHeader = () => {
  const [activeElement, setActiveElement] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const stateFromFarmsSlice = useSelector((state: any) => state.farms);

  useEffect(() => {
    return () => {
      let filterObj: {}[] = [];
      let isFiltered = false;
      let totalCount = 0;
      let filterUrl = "";
      let type = 0;
      dispatch(
        handleSubmitFilterResult({
          type,
          filterObj,
          isFiltered,
          totalCount,
          filterUrl,
        })
      );
    };
  }, []);

  const handleActiveElement = (id: number) => {
    setActiveElement(id);
    let type = id;
    let isFiltered = false;
    let filterUrl = "";
    dispatch(handleSubmitFilterResult({ isFiltered, filterUrl }));
    dispatch(setSelectedFarmHeader({ type }));
    switch (id) {
      case 1:
        navigate("/AllFarms");
        break;
      case 2:
        navigate("/FarmsInprogress");
        break;

      case 3:
        navigate("/FarmsEmpty");
        break;

      case 4:
        navigate("/FarmsReadyToSale");
        break;

      case 5:
        navigate("/FarmsSuspend");
        break;

      default:
        break;
    }
  };
  const setDisplayType = (id: number) => {
    setIsLoading(true);
    let displayTypeId = id;
    dispatch(setSelectedFarmDisplayType({ displayTypeId }));
    setIsLoading(false);
  };

  const handleShowFilterModal = () => setShowFilter(!showFilter);

  const deleteFilter = () => {
    let filterObj: {}[] = [];
    let isFiltered = false;
    let totalCount = 0;
    let filterUrl = "";
    let type = 0;
    dispatch(
      handleSubmitFilterResult({
        type,
        filterObj,
        isFiltered,
        totalCount,
        filterUrl,
      })
    );
  };
  return (
    <div className="FarmsHeader">
      {isLoading === true ? <Loading /> : null}
      {showFilter === true ? <FarmsFilter /> : null}
      <div className="grid grid-cols-2 xs:grid-col-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 p-4">
        <div className="lg:col-start-1 lg:col-end-2 md:col-span-full sm:col-span-full xs:col-span-full  flex md:overflow-x-auto sm:overflow-x-auto xs:overflow-x-auto sm:mb-3 xs:mb-3">
          <div
            onClick={() => handleActiveElement(1)}
            className={
              location.pathname === "/AllFarms"
                ? "main-element-active"
                : "main-element"
            }
          >
            كل العنابر
          </div>
          <div
            onClick={() => handleActiveElement(2)}
            className={
              location.pathname === "/FarmsInprogress"
                ? "main-element-active"
                : "main-element"
            }
          >
            في التشغيل
          </div>
          <div
            onClick={() => handleActiveElement(3)}
            className={
              location.pathname === "/FarmsEmpty"
                ? "main-element-active"
                : "main-element"
            }
          >
            فارغ
          </div>
          <div
            onClick={() => handleActiveElement(4)}
            className={
              location.pathname === "/FarmsReadyToSale"
                ? "main-element-active"
                : "main-element"
            }
          >
            جاهز للبيع
          </div>
          <div
            onClick={() => handleActiveElement(5)}
            className={
              location.pathname === "/FarmsSuspend"
                ? "main-element-active"
                : "main-element"
            }
          >
            متوقف
          </div>
        </div>
        <div className="lg:col-start-2 lg:col-end-5 md:col-span-full sm:col-span-full xs:col-span-full flex lg:justify-end md:justify-start sm:justify-start xs:justify-start">
          <div className="flex items-center">
            {/* Remove Filter  */}
            {stateFromFarmsSlice.isFiltered === true &&
            stateFromFarmsSlice.headerType !== 0 ? (
              <>
                <div className="created-at" onClick={() => deleteFilter()}>
                  <svg
                    width="24px"
                    height="24px"
                    viewBox="-1 0 18 18"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#A7AEC1"
                    stroke="#A7AEC1"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <title>arrow_repeat [#A7AEC1]</title>{" "}
                      <desc>Created with Sketch.</desc> <defs> </defs>{" "}
                      <g
                        id="Page-1"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        {" "}
                        <g
                          id="Dribbble-Light-Preview"
                          transform="translate(-302.000000, -7080.000000)"
                          fill="#A7AEC1"
                        >
                          {" "}
                          <g
                            id="icons"
                            transform="translate(56.000000, 160.000000)"
                          >
                            {" "}
                            <path
                              d="M260,6930 C260,6933 257.308,6936 254,6936 C250.692,6936 248,6933.308 248,6930 C248,6926.692 250.692,6924 254,6924 L254,6926 L259,6923 L254,6920 L254,6922 C249.582,6922 246,6925.582 246,6930 C246,6934.418 249.582,6938 254,6938 C258.418,6938 262,6935 262,6930 L260,6930 Z"
                              id="arrow_repeat-[#A7AEC1]"
                            >
                              {" "}
                            </path>{" "}
                          </g>{" "}
                        </g>{" "}
                      </g>{" "}
                    </g>
                  </svg>
                </div>
              </>
            ) : null}
            {/* Filter Btn */}
            <label htmlFor="modal-2456">
              <div
                className="filter-section flex"
                onClick={() => handleShowFilterModal()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M4.58342 4.16669H15.4168C15.5368 4.20878 15.6456 4.27778 15.7348 4.36841C15.824 4.45903 15.8913 4.56888 15.9315 4.68954C15.9718 4.81019 15.9838 4.93845 15.9668 5.06449C15.9498 5.19053 15.9042 5.31101 15.8334 5.41669L11.6668 10V15.8334L8.33342 13.3334V10L4.16676 5.41669C4.096 5.31101 4.05037 5.19053 4.03336 5.06449C4.01636 4.93845 4.02842 4.81019 4.06864 4.68954C4.10886 4.56888 4.17616 4.45903 4.26539 4.36841C4.35462 4.27778 4.46341 4.20878 4.58342 4.16669Z"
                    stroke="#64748B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                الفلتر
              </div>
            </label>
            {/* Display Toggle  */}
            <div className="filter-section flex ">
              <div onClick={() => setDisplayType(1)}>
                <svg
                  width="48"
                  height="40"
                  viewBox="0 0 48 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M36.375 7.625H11.625C10.3875 7.625 9.375 8.6375 9.375 9.875V30.125C9.375 31.3625 10.3875 32.375 11.625 32.375H36.375C37.6125 32.375 38.625 31.3625 38.625 30.125V9.875C38.625 8.6375 37.6125 7.625 36.375 7.625Z"
                    stroke="#124734"
                    stroke-width="2.7"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9.375 12.125H38.625"
                    stroke="#124734"
                    stroke-width="2.7"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9.375 18.875H38.625"
                    stroke="#124734"
                    stroke-width="2.7"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9.375 25.625H38.625"
                    stroke="#124734"
                    stroke-width="2.7"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M29.625 12.125V32.375"
                    stroke="#124734"
                    stroke-width="2.7"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div onClick={() => setDisplayType(2)}>
                <svg
                  width="48"
                  height="40"
                  viewBox="0 0 48 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.5"
                    y="0.5"
                    width="47"
                    height="39"
                    rx="3.5"
                    fill="#FDFDFD"
                  />
                  <rect
                    x="0.5"
                    y="0.5"
                    width="47"
                    height="39"
                    rx="3.5"
                    stroke="#F3F3F3"
                  />
                  <g clip-path="url(#clip0_3676_23140)">
                    <path
                      d="M19.6375 12.725H28.3625V4H19.6375V12.725ZM31.275 4V12.725H40V4H31.275ZM8 12.725H16.725V4H8V12.725ZM19.6375 24.3625H28.3625V15.6375H19.6375V24.3625ZM31.275 24.3625H40V15.6375H31.275V24.3625ZM8 24.3625H16.725V15.6375H8V24.3625ZM19.6375 36H28.3625V27.275H19.6375V36ZM31.275 36H40V27.275H31.275V36ZM8 36H16.725V27.275H8V36Z"
                      fill="#64748B"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_3676_23140">
                      <rect
                        width="32"
                        height="32"
                        fill="white"
                        transform="translate(8 4)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
            {/* Filter Button */}
            {/* <div className="filter-section flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M4.58342 4.16669H15.4168C15.5368 4.20878 15.6456 4.27778 15.7348 4.36841C15.824 4.45903 15.8913 4.56888 15.9315 4.68954C15.9718 4.81019 15.9838 4.93845 15.9668 5.06449C15.9498 5.19053 15.9042 5.31101 15.8334 5.41669L11.6668 10V15.8334L8.33342 13.3334V10L4.16676 5.41669C4.096 5.31101 4.05037 5.19053 4.03336 5.06449C4.01636 4.93845 4.02842 4.81019 4.06864 4.68954C4.10886 4.56888 4.17616 4.45903 4.26539 4.36841C4.35462 4.27778 4.46341 4.20878 4.58342 4.16669Z"
                stroke="#64748B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            الفلتر
          </div> */}
            {/* Add Button */}
            {location.pathname === "/Breeders" ? (
              <>
                <div
                  className="add-farm-btn"
                  onClick={() => navigate("/Breeders/Add")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                  >
                    <path
                      d="M10.5049 1.02539C6.02189 1.02539 2.375 4.67228 2.375 9.15527C2.375 13.6383 6.02189 17.2852 10.5049 17.2852C14.9879 17.2852 18.6348 13.6383 18.6348 9.15527C18.6348 4.67228 14.9879 1.02539 10.5049 1.02539ZM14.0617 9.83272H11.1823V12.7121C11.1823 13.0861 10.8789 13.3895 10.5049 13.3895C10.1309 13.3895 9.82743 13.0861 9.82743 12.7121V9.83272H6.94806C6.57404 9.83272 6.27061 9.52929 6.27061 9.15527C6.27061 8.78126 6.57404 8.47782 6.94806 8.47782H9.82743V5.59845C9.82743 5.22443 10.1309 4.921 10.5049 4.921C10.8789 4.921 11.1823 5.22443 11.1823 5.59845V8.47782H14.0617C14.4357 8.47782 14.7392 8.78126 14.7392 9.15527C14.7392 9.52929 14.4357 9.83272 14.0617 9.83272Z"
                      fill="white"
                    />
                  </svg>
                  أضافة مربي
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
