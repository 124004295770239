import { Login } from "../Components/Login/Login";
import { Competitions } from "../Pages/Competitions/Competitions";
import { FarmDetailsPage } from "../Pages/Farms/FarmDetailsPage";
import { FarmsPage } from "../Pages/Farms/FarmsPage";
import { BreedersPage } from "../Pages/Breeders/BreedersPage";
import { MainPage } from "../Pages/MainPage/MainPage";
import { AddMissionPage } from "../Pages/Missions/AddMissionPage";
import { EditMissionPage } from "../Pages/Missions/EditMissionPage";
import { MissionsPage } from "../Pages/Missions/MissionsPage";
import { AddUserPage } from "../Pages/Users/AddUserPage";
import { EditUserPage } from "../Pages/Users/EditUserPage";
import { UserProfilePage } from "../Pages/Users/UserProfilePage";
import { Users } from "../Pages/Users/Users";
import { SettlementWalletsPage } from "../Pages/Wallets/SettlementWalletsPage";
import { Wallets } from "../Pages/Wallets/Wallets";
import { AuthProvider, useAuth } from "./auth";
import { Route, Routes } from "react-router-dom";
import { BreederDetailsPage } from "../Pages/Breeders/BreederDetailsPage";
import { AddFarmPage } from "../Pages/Farms/AddFarmPage";
import { EditFarmPage } from "../Pages/Farms/EditFarmPage";
import { AddBreedersPage } from "../Pages/Breeders/AddBreedersPage";
import { HousingPage } from "../Pages/Farms/HousingPage";
import { EditBreedersPage } from "../Pages/Breeders/EditBreedersPage";
import { CoursesPage } from "../Pages/Courses/CoursesPage";
import { ReportsPage } from "../Pages/Reports/ReportsPage";
import { EditHousingPage } from "../Pages/Farms/EditHousingPage";
import { FarmSaleOfferPage } from "../Pages/Farms/FarmSaleOfferPage";
import { FarmMovementRecordPage } from "../Pages/Farms/FarmMovementRecordPage";
import { NotFound } from "../Pages/NotFound/NotFound";
import { AcademyPage } from "../Pages/Academy/AcademyPage";
import { AddCoursePage } from "../Pages/Academy/AddCoursePage";
import { EditCoursePage } from "../Pages/Academy/EditCoursePage";
import { DetailsCoursePage } from "../Pages/Academy/DetailsCoursePage";
import { AddLecturePage } from "../Pages/Academy/AddLecturePage";
import { EditLecturePage } from "../Pages/Academy/EditLecturePage";
import { AddTrainerPage } from "../Pages/Academy/AddTrainerPage";
import { EditTrainerPage } from "../Pages/Academy/EditTrainerPage";
import { AddForumsPage } from "../Pages/Academy/AddForumsPage";
import { EditForumsPage } from "../Pages/Academy/EditForumsPage";
import { MissionDetailsPage } from "../Pages/Missions/MissionDetailsPage";
import { SuppliersPage } from "../Pages/Suppliers/SuppliersPage";
import { CommingSoon } from "../Components/CommingSoon/CommingSoon";
import { AllSupplierDetailsPage } from "../Pages/Suppliers/AllSupplierDetailsPage";
import { AddSuppliersPage } from "../Pages/Suppliers/AddSuppliersPage";
import { EditSupplierPage } from "../Pages/Suppliers/EditSupplierPage";
import { MarketNewsPage } from "../Pages/MarketNews/MarketNewsPage";
import { MarketNewsDetailsPage } from "../Pages/MarketNews/MarketNewsDetailsPage";
import { AddMarketNewsPage } from "../Pages/MarketNews/AddMarketNewsPage";
import { EditMarketNewsPage } from "../Pages/MarketNews/EditMarketNewsPage";
import { ScientificArticlesPage } from "../Pages/Scientific_Articles/Scientific_Articles_Page";
import { AddCompetitionPage } from "../Pages/Competitions/AddCompetitionPage";
import { TradersPage } from "../Pages/Traders/TradersPage";
import { AddTraderPage } from "../Pages/Traders/AddTraderPage";

export const Views = () => {
  const auth = useAuth();
  return (
    <AuthProvider>
      <Routes>
        {!auth?.user && <Route path="/login" element={<Login />} />}
        <Route path="/Home" element={<MainPage />} />

        <Route
          path="/Missions/:MissionId"
          element={<MissionDetailsPage />}
        ></Route>
        <Route path="/Missions/All" element={<MissionsPage />}></Route>
        <Route path="/Missions/Pending" element={<MissionsPage />}></Route>
        <Route path="/Missions/InProgress" element={<MissionsPage />}></Route>
        <Route path="/Missions/Late" element={<MissionsPage />}></Route>
        <Route path="/Missions/Evaluation" element={<MissionsPage />}></Route>
        <Route path="/Missions/Finished" element={<MissionsPage />}></Route>
        <Route path="/Missions/Refused" element={<MissionsPage />}></Route>
        <Route path="/Missions/Add" element={<AddMissionPage />} />
        <Route path="/Missions/Edit/:MissionId" element={<EditMissionPage />} />

        <Route path="/Exams" element={<NotFound />}></Route>

        <Route path="/AllFarms" element={<FarmsPage />}></Route>
        <Route path="/FarmsInprogress" element={<FarmsPage />}></Route>
        <Route path="/FarmsEmpty" element={<FarmsPage />}></Route>
        <Route path="/FarmsReadyToSale" element={<FarmsPage />}></Route>
        <Route path="/FarmsSuspend" element={<FarmsPage />}></Route>
        <Route path="/AllFarms/:farmId" element={<FarmDetailsPage />} />
        <Route path="/AllFarms/:farmId/Edit" element={<EditFarmPage />} />
        <Route
          path="/AllFarms/:farmId/:breederId/Housing"
          element={<HousingPage />}
        />
        <Route
          path="/AllFarms/:farmId/:housingId/HousingEdit"
          element={<EditHousingPage />}
        />
        <Route
          path="/AllFarms/:farmId/SalesOffer"
          element={<FarmSaleOfferPage />}
        />
        <Route
          path="/AllFarms/:farmId/MovementRecord"
          element={<FarmMovementRecordPage />}
        />
        {/* Breeders */}
        <Route path="/Breeders" element={<BreedersPage />}></Route>
        <Route path="/Breeders/:breederId" element={<BreederDetailsPage />} />
        <Route
          path="/Breeders/:breederId/AccountDetails"
          element={<BreederDetailsPage />}
        />
        <Route
          path="/Breeders/:breederId/Farms"
          element={<BreederDetailsPage />}
        />
        <Route
          path="/Breeders/:breederId/Offers"
          element={<BreederDetailsPage />}
        />
        <Route
          path="/Breeders/:breederId/Evaluation"
          element={<BreederDetailsPage />}
        />
        <Route
          path="/Breeders/:breederId/Notes"
          element={<BreederDetailsPage />}
        />
        <Route
          path="/Breeders/:breederId/AddNotes"
          element={<BreederDetailsPage />}
        />
        <Route path="/Breeders/:breederId/Add/Farm" element={<AddFarmPage />} />
        <Route path="/Breeders/Add" element={<AddBreedersPage />} />
        <Route
          path="/Breeders/:breederId/Edit"
          element={<EditBreedersPage />}
        />

        {/* Traders */}
        <Route path="/Traders" element={<TradersPage />}></Route>
        <Route path="/Traders/:Traders" element={<BreederDetailsPage />} />
        <Route path="/Traders/Add" element={<AddTraderPage />} />

        {/* Suppliers Module */}
        <Route path="/AllSuppliers" element={<SuppliersPage />}></Route>
        <Route
          path="/AllSuppliers/AccountData/:supplierId"
          element={<AllSupplierDetailsPage />}
        />
        <Route
          path="/AllSuppliers/Papers&Documents/:supplierId"
          element={<AllSupplierDetailsPage />}
        />
        <Route
          path="AllSuppliers/CallsHistory/:supplierId"
          element={<AllSupplierDetailsPage />}
        />
        <Route
          path="/Suppliers/NewRequests"
          element={<SuppliersPage />}
        ></Route>
        <Route path="/Suppliers/Archive" element={<SuppliersPage />}></Route>
        <Route path="/Suppliers/Archive" element={<SuppliersPage />}></Route>
        <Route path="/Suppliers/Add" element={<AddSuppliersPage />}></Route>
        <Route
          path="/AllSuppliers/AccountData/:supplierId/Edit"
          element={<EditSupplierPage />}
        ></Route>
        <Route
          path="/AllSuppliers/Papers&Documents/:supplierId/Edit"
          element={<EditSupplierPage />}
        ></Route>
        <Route
          path="/AllSuppliers/CallsHistory/1/Edit"
          element={<EditSupplierPage />}
        ></Route>
        {/* Suppliers Module */}

        {/* Market News */}
        <Route path="/MarketNews" element={<MarketNewsPage />}></Route>
        <Route path="/MarketNews/Items" element={<MarketNewsPage />}></Route>
        <Route path="/MarketNews/Add" element={<AddMarketNewsPage />}></Route>
        <Route
          path="/MarketNews/:newId/Details"
          element={<MarketNewsDetailsPage />}
        ></Route>
        <Route
          path="/MarketNews/:newId/Edit"
          element={<EditMarketNewsPage />}
        ></Route>

        {/* Market News */}

        {/* Scientific Articles */}
        <Route
          path="/ScientificArticles"
          element={<ScientificArticlesPage />}
        ></Route>
        <Route
          path="/ScientificArticles/Items"
          element={<ScientificArticlesPage />}
        ></Route>
        <Route
          path="/ScientificArticles/Add"
          element={<AddMarketNewsPage />}
        ></Route>
        <Route
          path="/ScientificArticles/:newId/Details"
          element={<MarketNewsDetailsPage />}
        ></Route>
        <Route
          path="/ScientificArticles/:newId/Edit"
          element={<EditMarketNewsPage />}
        ></Route>
        {/* Scientific Articles */}

        <Route path="/AvailableCompetitions" element={<Competitions />}></Route>
        <Route path="/FinishedCompetitions" element={<Competitions />}></Route>
        <Route
          path="/Competitions/Add"
          element={<AddCompetitionPage />}
        ></Route>
        <Route
          path="/Competitions/Add/Details"
          element={<AddCompetitionPage />}
        ></Route>
        <Route
          path="/Competitions/Add/competition-questions"
          element={<AddCompetitionPage />}
        ></Route>

        <Route path="/Courses" element={<CoursesPage />}></Route>

        <Route path="/DaginAcademy/Course" element={<AcademyPage />}></Route>
        <Route
          path="/DaginAcademy/Course/:courseId"
          element={<DetailsCoursePage />}
        ></Route>
        <Route
          path="/DaginAcademy/Course/:courseId/:trainerId/trainer"
          element={<DetailsCoursePage />}
        ></Route>
        <Route
          path="/DaginAcademy/Course/:courseId/:trainerId/lectures"
          element={<DetailsCoursePage />}
        ></Route>
        <Route
          path="/DaginAcademy/Course/:courseId/:trainerId/lecture/Details/:lectureId"
          element={<DetailsCoursePage />}
        ></Route>
        <Route
          path="/DaginAcademy/Course/:courseId/lecture/Add"
          element={<AddLecturePage />}
        ></Route>
        <Route
          path="/DaginAcademy/Course/:courseId/lecture/:lectureId/Edit"
          element={<EditLecturePage />}
        ></Route>
        <Route
          path="/DaginAcademy/Course/Add"
          element={<AddCoursePage />}
        ></Route>
        <Route
          path="/DaginAcademy/Course/Edit/:CourseId"
          element={<EditCoursePage />}
        ></Route>
        <Route
          path="/DaginAcademy/Categories"
          element={<AcademyPage />}
        ></Route>
        <Route path="/DaginAcademy/Trainers" element={<AcademyPage />}></Route>
        <Route
          path="/DaginAcademy/Trainers/Add"
          element={<AddTrainerPage />}
        ></Route>
        <Route
          path="/DaginAcademy/Trainers/:trainerId/Edit"
          element={<EditTrainerPage />}
        ></Route>
        <Route path="/DaginAcademy/Forums" element={<AcademyPage />}></Route>
        <Route
          path="/DaginAcademy/Forums/Add"
          element={<AddForumsPage />}
        ></Route>
        <Route
          path="/DaginAcademy/Forums/:forumId/Edit"
          element={<EditForumsPage />}
        ></Route>

        <Route path="/Users/ActiveSalesMan" element={<Users />}></Route>
        <Route path="/Users/DisabledSalesMan" element={<Users />}></Route>
        <Route path="/Users/StoppedSalesMan" element={<Users />}></Route>
        <Route path="/Users/Add" element={<AddUserPage />} />
        <Route path="/Users/Edit/:userId" element={<EditUserPage />} />
        <Route path="/Users/Profile/:userId" element={<UserProfilePage />} />
        <Route
          path="/Users/Profile/Certificates/:userId"
          element={<UserProfilePage />}
        />
        <Route
          path="/Users/Profile/Competions/:userId"
          element={<UserProfilePage />}
        />
        <Route
          path="/Users/Profile/Exams/:userId"
          element={<UserProfilePage />}
        />
        <Route
          path="/Users/Profile/Courses/:userId"
          element={<UserProfilePage />}
        />
        <Route
          path="/Users/Profile/AccountStatement/:userId"
          element={<UserProfilePage />}
        />
        <Route
          path="/Users/Profile/MissionHistory/:userId"
          element={<UserProfilePage />}
        />
        <Route
          path="/Users/Profile/MissionHistory/All/:userId"
          element={<UserProfilePage />}
        />
        <Route
          path="/Users/Profile/MissionHistory/Pending/:userId"
          element={<UserProfilePage />}
        />
        <Route
          path="/Users/Profile/MissionHistory/InProgress/:userId"
          element={<UserProfilePage />}
        />
        <Route
          path="/Users/Profile/MissionHistory/Evaluation/:userId"
          element={<UserProfilePage />}
        />
        <Route
          path="/Users/Profile/MissionHistory/Finished/:userId"
          element={<UserProfilePage />}
        />
        <Route
          path="/Users/Profile/MissionHistory/Late/:userId"
          element={<UserProfilePage />}
        />
        <Route
          path="/Users/Profile/MissionHistory/Refused/:userId"
          element={<UserProfilePage />}
        />
        <Route
          path="/Users/Profile/CurrentEvaluation/:userId"
          element={<UserProfilePage />}
        />
        <Route
          path="/Users/Profile/PersonalData/:userId"
          element={<UserProfilePage />}
        />
        <Route
          path="/Users/Profile/Notes/:userId"
          element={<UserProfilePage />}
        />
        <Route
          path="/Users/Profile/AddNotes/:userId"
          element={<UserProfilePage />}
        />

        <Route path="/Wallets/AllTransactions" element={<Wallets />}></Route>
        <Route path="/Wallets/DepitTransactions" element={<Wallets />}></Route>
        <Route path="/Wallets/CreditTransactions" element={<Wallets />}></Route>
        <Route path="/Wallets/CreditBalanceOnly" element={<Wallets />}></Route>
        <Route path="/Wallets/SettledUsers" element={<Wallets />}></Route>
        <Route
          path="/Wallets/Settlement/:userId"
          element={<SettlementWalletsPage />}
        />

        <Route path="/Reports" element={<ReportsPage />}></Route>
        <Route path="/Settings" element={<NotFound />}></Route>
        <Route path="/Notification" element={<NotFound />}></Route>
        <Route path="*" element={<Login />} />
      </Routes>
    </AuthProvider>
  );
};
