import { useEffect, useState } from "react";
import "./SettledUsers.scss";
import Pagination from "../../../SubComponents/Paginator/Pagination";
import API from "../../../Api";
import { setMainHeaderName } from "../../../redux/Slices/MainHeaderSlice";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { Loading } from "../../Loading/Loading";
import { TotalCount } from "../../../SubComponents/TotalCount/TotalCount";
import { ExportExcel } from "../../../SubComponents/Export/ExcelExport";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";

export const SettledUsers = () => {
  const [data, setData] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);
    let mainHeaderName = "ادارة المحافظ";
    dispatch(setMainHeaderName({ mainHeaderName }));

    setPageNumber(1);
    API.get(
      `dashboard/wallets?balance_status=0&page=${pageNumber}&limit=${pageSize}`
    ).then((res) => {
      if (res) {
        setData(res.data.items);
        setTotalRows(res.data.totalCount);
        setTotalPages(Math.ceil(res.data.totalCount / pageSize));
        setIsLoading(false);
      }
    });
  }, []);
  const hanldeChangePage = (targetPN: number) => {
    setIsLoading(true);

    API.get(
      `dashboard/wallets?balance_status=0&page=${targetPN}&limit=${pageSize}`
    ).then((res) => {
      if (res) {
        if (res.status === 403) {
          toast.error(" عفوا انت ليس لديك صلاحية الوصول لهذه الصفحة ");
        } else {
          setData(res.data.items);
          setTotalRows(res.data.totalCount);
          setTotalPages(Math.ceil(res.data.totalCount / pageSize));
          setPageNumber(targetPN);
          setIsLoading(false);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }
      }
    });
  };
  return (
    <div className="SettledUsers">
      <div className="grid grid-cols-2">
        {loading === true ? (
          <>
            <Loading />
          </>
        ) : null}
        {/* Total Count */}
        <div className="mt-4">
          <TotalCount count={totalRows} title="الذين تم تسويتهم" />
        </div>
        <div className="mt-4">
          <ExportExcel
            url={`dashboard/wallets?balance_status=0&limit=1000000&sort_by=id&sort_order=DESC`}
            fileName="الذين تم تسويتهم"
            page="SettledUsers"
          />
        </div>
        {/* Header */}
        <div className="col-span-full">
          <div className="grid grid-cols-7 gap-6 mt-5 pt-4">
            <div className="col-start-1 flex justify-center">
              <p className="head_title"> الرقم</p>
            </div>
            <div className="col-start-3 flex justify-center">
              <p className="head_title"> الأسم</p>
            </div>
          </div>
        </div>
        {data.map((item: any, index: number) => (
          <>
            <div className="col-span-full data-row rounded-full m-4">
              <div className="grid grid-cols-7">
                <div className="col-start-1 mr-6">
                  <p className="content"> {item.id}</p>
                </div>
                <div className="col-start-3 flex justify-center">
                  <Link to={`/Users/Profile/AccountStatement/${item.id}`}>
                    <p className="content cursor-pointer"> {item.name}</p>
                  </Link>
                </div>
              </div>
            </div>
          </>
        ))}
        {/* Pagination */}
        {totalRows === 0 ? null : (
          <>
            <div className="col-start-2 flex justify-end pt-4 pl-12">
              <Pagination
                currentPage={pageNumber}
                lastPage={totalPages}
                maxLength={7}
                setCurrentPage={hanldeChangePage}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
