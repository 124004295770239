import { useDispatch } from "react-redux";
import "../AddCompetitions.scss";
import { useEffect, useState } from "react";
import { setMainHeaderName } from "../../../../redux/Slices/MainHeaderSlice";
import { Loading } from "../../../Loading/Loading";
import { DatePickerComponent } from "../../../Missions/AddMission/SubComponents/DatePicker/DatePickerComponent";
import arrow from "../../../../Assets/Icons/arrow.jpeg";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { toast } from "react-toastify";

interface Choice {
  c_Id: number;
  q_Id: number;
  c_title: string;
  c_IsCorrect: boolean;
}

interface Question {
  q_Id: number;
  q_title: string;
  q_mark: number;
  choices: Choice[];
}

export const AddCompetitionDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [addObject, setAddObject] = useState({
    name: "",
    farmArea: 0,
    farmTypeId: "",
    farmCapacityId: 0,
    ventilationTypeId: "",
    cityId: "",
    village: "",
    detailedDescription: "",
    userId: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [finishedDate, setFinishedData] = useState(new Date());
  const [firstQuestion, setFirstQuestion] = useState<Choice[]>([]);

  const [questions, setQuestions] = useState<Question[]>([
    {
      q_Id: 1,
      q_title: "What is your question?",
      q_mark: 10,
      choices: [
        { c_Id: 1, q_Id: 1, c_title: "Choice 1", c_IsCorrect: true },
        { c_Id: 2, q_Id: 1, c_title: "Choice 2", c_IsCorrect: false },
        // Add more choices as needed
      ],
    },
    // Add more objects as needed
  ]);

  useEffect(() => {
    let mainHeaderName = "اضافة مسابقة";
    dispatch(setMainHeaderName({ mainHeaderName }));
    let firstObj = questions.find((c) => c.q_Id === 1);
    if (firstObj != null) {
      let choises = firstObj.choices;
      setFirstQuestion(choises);
    }
  }, [firstQuestion, questions]);

  const handleChangeStartDate = (date: any) => {
    setStartDate(date);
  };

  const handleChangeFinishedDate = (date: any) => {
    setFinishedData(date);
  };

  const addQuestion = () => {
    const newQuestion: Question = {
      // q_Id: questions.length + 1,
      q_Id: 1,
      q_title: "New Question",
      q_mark: 5,
      choices: [
        { c_Id: 1, q_Id: 1, c_title: "New Choice 1", c_IsCorrect: false },
        { c_Id: 2, q_Id: 1, c_title: "New Choice 2", c_IsCorrect: true },
        // Add more choices as needed
      ],
    };
    // Update the state by adding the new question
    setQuestions([...questions, newQuestion]);
  };
  const addChoise = (qId: number) => {
    const questionChoiseObj = questions.find((c) => c.q_Id === qId)?.choices;
    const newChoise: Choice = {
      c_Id: 3,
      q_Id: qId,
      c_title: "",
      c_IsCorrect: false,
    };
    firstQuestion.push(newChoise);

    setFirstQuestion([...firstQuestion, newChoise]);
  };
  const handleDeleteChoise = (questionId: number, choiceId: number) => {
    // Assuming 'questions' and 'firstQuestion' are state variables
    let updatedQuestions = [...questions]; // Copy the questions array to avoid mutating the state directly

    const questionIndex = updatedQuestions.findIndex(
      (q) => q.q_Id === questionId
    );
    if (questionIndex !== -1) {
      const questionChoices = updatedQuestions[questionIndex].choices;

      if (questionChoices != null && questionChoices.length > 1) {
        // Filter out the choice with the given 'choiceId'
        const updatedChoices = questionChoices.filter(
          (item) => item.c_Id !== choiceId
        );

        // Update the choices for the specific question
        updatedQuestions[questionIndex].choices = updatedChoices;

        // Update the state with the modified 'firstQuestion'
        setFirstQuestion(updatedChoices);
      }
    }
  };
  function getArabicRanking(number: number): string {
    const arabicRankings = [
      "الأول",
      "الثاني",
      "الثالث",
      "الرابع",
      "الخامس",
      "السادس",
      "السابع",
      "الثامن",
      "التاسع",
      "العاشر",
    ];

    if (number >= 1 && number <= arabicRankings.length) {
      return arabicRankings[number - 1];
    } else {
      return "غير معروف"; // Return this for out-of-range numbers
    }
  }
  // const firstQuestion = questions.find(c => c.q_Id === 1);

  const handleChangeValues = (value: any, fieldName: string) => {
    // Update addObject with the new value
    setAddObject((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
    console.log(addObject);
  };

  const handleDetailsSubmit = () => {
    //Check If Details Has been submited successfully
    //Then route to the next step
    navigate("/Competitions/Add/competition-questions");
  };

  return (
    <div className="AddCompetitions">
      {isLoading === true ? (
        <>
          <Loading />
        </>
      ) : null}

      <Formik
        onSubmit={() => handleDetailsSubmit()}
        initialValues={addObject}
        validationSchema={null}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              {/* First Section [Competition Details] */}
              <div className="add-section mt-8 mb-4">
                <div className="grid grid-cols-2">
                  <div className="col-start-1 mt-4 mb-4 pr-4">
                    <h2 className="flex justify-start mission-details">
                      تفاصيل المهمة
                    </h2>
                  </div>
                  <div className="col-span-full mb-4">
                    <div className="divider"></div>
                  </div>
                  {/* نوع المسابقة */}
                  <div className="col-start-1 mt-4 mb-4 pr-4">
                    <h3 className="mission-type"> نوع المسابقة</h3>
                  </div>
                  <div className="col-span-full pl-4 pr-4">
                    <label htmlFor="modal-8">
                      <div className="flex justify-between select-mission-container">
                        <div className="select-mission-type pr-4">
                          اختر نوع المسابقة
                        </div>
                        <div className="arrow">
                          <img
                            src={arrow}
                            alt="arrow"
                            // onClick={() => handleShowTypePopUp()}
                          />
                        </div>
                      </div>
                    </label>
                  </div>
                  {/* عنوان المسابقة */}
                  <div className="col-start-1 mt-4  pr-4">
                    <h3 className="mission-type"> عنوان المسابقة</h3>
                  </div>
                  {/* نوع المتسابق */}
                  <div className="col-start-2 mt-4  pr-4">
                    <h3 className="mission-type"> نوع المتسابق</h3>
                  </div>
                  {/* عنوان المسابقة  input*/}
                  <div className="col-start-1 mt-4 mb-4 pr-4">
                    <div>
                      <input
                        type="text"
                        placeholder="اكتب عنوان هنا"
                        className="mission-address-input"
                        // onChange={(e) => handleChangeMission(e.target.value, "name")}
                      />
                    </div>
                  </div>

                  {/* نوع المتسابق input */}
                  <div className="col-start-2  mt-4 pr-4 pl-4">
                    <label htmlFor="modal-13">
                      <div
                        className="flex justify-between select-mission-container"
                        // onClick={() => handleCityPopUp()}
                      >
                        <input
                          className="select-mission-type pr-4"
                          // value={
                          //   stateFromFarmsSlice.selectedCityId === 0
                          //     ? "نوع المتسابق"
                          //     : stateFromFarmsSlice.selectedCityName
                          // }
                          value={`نوع المتسابق`}
                          disabled
                          id="cityId"
                          name="cityId"
                        />

                        <div className="arrow">
                          <img src={arrow} alt="arrow" />
                        </div>
                      </div>
                    </label>
                  </div>

                  {/* تاريخ البدء */}
                  <div className="col-start-1 mt-4  pr-4">
                    <h3 className="mission-type"> تاريخ البدء</h3>
                  </div>
                  {/* تاريخ الأنتهاء */}
                  <div className="col-start-2 mt-4  pr-4">
                    <h3 className="mission-type"> تاريخ الأنتهاء</h3>
                  </div>

                  {/* تاريخ البدء  input*/}
                  <div className="col-start-1 mt-4 mb-4 pr-4">
                    <DatePicker
                      selected={startDate}
                      onChange={(event) => handleChangeStartDate(event)}
                      className="date-picker-input"
                      minDate={new Date()}
                    />
                  </div>

                  {/* تاريخ الأنتهاء  input*/}
                  <div className="col-start-2 mt-4 mb-4 pr-4">
                    <DatePicker
                      selected={finishedDate}
                      onChange={(event) => handleChangeFinishedDate(event)}
                      className="date-picker-input"
                      minDate={new Date()}
                    />
                  </div>

                  {/* الحافز */}
                  <div className="col-start-1 mt-4  pr-4">
                    <h3 className="mission-type"> الحافز</h3>
                  </div>
                  {/* قيمة الحافز */}
                  <div className="col-start-2 mt-4  pr-4">
                    <h3 className="mission-type"> قيمة الحافز</h3>
                  </div>

                  {/* الحافز  input*/}
                  <div className="col-start-1 mt-4 mb-4 pr-4">
                    <label htmlFor="modal-13">
                      <div
                        className="flex justify-between select-mission-container"
                        // onClick={() => handleCityPopUp()}
                      >
                        <input
                          className="select-mission-type pr-4"
                          // value={
                          //   stateFromFarmsSlice.selectedCityId === 0
                          //     ? "نوع المتسابق"
                          //     : stateFromFarmsSlice.selectedCityName
                          // }
                          value={`أختر نوع الحافز`}
                          disabled
                          id="cityId"
                          name="cityId"
                        />

                        <div className="arrow">
                          <img src={arrow} alt="arrow" />
                        </div>
                      </div>
                    </label>
                  </div>

                  {/* قيمة الحافز  input*/}
                  <div className="col-start-2 mt-4 mb-4 pr-4">
                    <input
                      type="number"
                      placeholder="اكتب قيمة الحافز هنا"
                      className="mission-address-input"
                      // onChange={(e) => handleChangeMission(e.target.value, "name")}
                    />
                  </div>

                  {/* المقابل المادي */}
                  <div className="col-start-1 mt-4  pr-4">
                    <h3 className="mission-type"> المقابل المادي</h3>
                  </div>
                  {/* معايير النجاح */}
                  <div className="col-start-2 mt-4  pr-4">
                    <h3 className="mission-type"> معايير النجاح</h3>
                  </div>

                  {/* المقابل المادي  input*/}
                  <div className="col-start-1 mt-4 mb-4 pr-4">
                    <div>
                      <input
                        type="text"
                        placeholder="00"
                        className="mission-map-input"
                        // onChange={(e) =>
                        //   handleChangeMission(e.target.value, "early_bonus")
                        // }
                      />
                    </div>
                  </div>

                  {/* معايير النجاح  input*/}
                  <div className="col-start-2 mt-4 mb-4 pl-4 pr-4">
                    <div className="w-full">
                      <select className="select max-w-full bg-left	h-14 ">
                        <option>10%-20%</option>
                        <option>20%-30%</option>
                        <option>30%-40%</option>
                        <option>40%-50%</option>
                        <option>50%-60%</option>
                        <option>60%-70%</option>
                        <option>70%-80%</option>
                        <option>80%-90%</option>
                        <option>90%-100%</option>
                      </select>
                    </div>
                  </div>

                  {/* تفاصيل المسابقة */}
                  <div className="col-start-1 mt-4 mb-4 pr-4">
                    <h3 className="mission-type"> تفاصيل المسابقة</h3>
                  </div>
                  <div className="col-span-full pr-4 pl-4">
                    <div className="txt-area">
                      <textarea
                        style={{ width: "100%" }}
                        className="mission-text-area"
                        placeholder="تفاصيل المسابقة"
                        // onChange={(e) => handleChangeMission(e.target.value, "details")}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="add-actions p-5 ">
                <div className="grid grid-cols-2">
                  <div className="col-start-1">
                    <div className="flex gap-4">
                      <button className="add-btn" type="submit">
                        التالي
                      </button>
                      <div className="cancel-btn" onClick={() => navigate(-1)}>
                        الغاء
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </>
        )}
      </Formik>
    </div>
  );
};
