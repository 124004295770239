import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  selectedBreedType: 0,
  selectedBreedTypeName: "",
  breederTypeChanged: false,
  selectedVentilationType: 0,
  selectedVentilationName: "",
  selectedFacilityTypeId: 0,
  selectedFacilityTypeName: "",
  selectedGovernorateId: 0,
  selectedBreederGovernorateId: 0,
  selectedGovernorateName: "",
  selectedBreederGovernorateName: "",
  selectedCityId: 0,
  selectedBreederCityId: 0,
  selectedBreederCityName: "",
  selectedCityName: "",
  selectedFarmingTypeId: 0,
  selectedFarmingTypeName: "",
  selectedCompanyId: 0,
  selectedCompanyText: "",
  companyChanged: false,
  henTypeChanged: false,
  selectedHealtStatusId: 0,
  selectedHealthStatusText: "",
  facilitiesIds: [],
  breedersFilterData: [],
  breedersIsFilters: false,
  breedersFilterTotalCount: 0,
  selectedFarmHeader: 1,
  governorateChanged: false,
  breederGovernorateChanged: false,
  cityChanged: false,
  breederCityChanged: false,
  farmingTypeChanged: false,
  ventilationChanged: false,
  displayTypeId: 1,
  headerType: 0,
  filterDataObj: [],
  isFiltered: false,
  filterTotalCount: 0,
  filterUrl: "",
  selectedFarmId: 0,
  farmChanged: false,
  farmStoppedSubmited: false,
  isActivateShown: false,
  suspendFarmData: [],
  governorateModule: 0,
  cityModule: 0,
};
export const BreederSlice = createSlice({
  name: "farm",
  initialState,
  reducers: {
    setSelectedBreedType: (state, action) => {
      state.selectedBreedType = action.payload.breedTypeId;
      state.selectedBreedTypeName = action.payload.breedTypeName;
      state.breederTypeChanged = action.payload.isChanged;
    },
    setSelectedVentilationType: (state, action) => {
      state.selectedVentilationType = action.payload.ventilationTypeId;
      state.selectedVentilationName = action.payload.ventilationTypeName;
      state.ventilationChanged = action.payload.isChanged;
    },
    setSelectedFacilityType: (state, action) => {
      state.selectedFacilityTypeId = action.payload.facilityId;
      state.selectedFacilityTypeName = action.payload.facilityName;
      state.facilitiesIds = action.payload.selectedIds;
    },
    setSelectedBreederGovernorates: (state, action) => {
      state.selectedBreederGovernorateId = action.payload.governorateId;
      state.selectedBreederGovernorateName = action.payload.governorateName;
      state.breederGovernorateChanged = action.payload.isChanged;
      state.governorateModule = action.payload.module;
    },
    setSelectedBreederCity: (state, action) => {
      state.selectedBreederCityId = action.payload.cityId;
      state.selectedBreederCityName = action.payload.cityName;
      state.breederCityChanged = action.payload.isChanged;
      state.cityModule = action.payload.module;
    },
    setSelectedFarmingType: (state, action) => {
      state.selectedFarmingTypeId = action.payload.farmingTypeId;
      state.selectedFarmingTypeName = action.payload.farmingTypeName;
      state.farmingTypeChanged = action.payload.isChanged;
    },
    setBreadersSearchResult: (state, action) => {
      state.breedersFilterData = action.payload.searchResult;
      state.breedersIsFilters = action.payload.isFiltered;
      state.breedersFilterTotalCount = action.payload.totalCount;
    },
    handleSubmitFilterResult: (state, action) => {
      state.headerType = action.payload.type;
      state.filterDataObj = action.payload.filterObj;
      state.isFiltered = action.payload.isFiltered;
      state.filterTotalCount = action.payload.totalCount;
      state.filterUrl = action.payload.filterUrl;
    },
  },
});
export const {
  setSelectedBreedType,
  setSelectedVentilationType,
  setSelectedFacilityType,
  setSelectedBreederGovernorates,
  setSelectedBreederCity,
  setSelectedFarmingType,
  setBreadersSearchResult,
  handleSubmitFilterResult,
} = BreederSlice.actions;

export default BreederSlice.reducer;
