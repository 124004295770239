import { EvaluationMissionPopUp } from "../../Components/Missions/EvaluationMission/EvaluationMissionPopUp";
import { FinishedPopUp } from "../../Components/Missions/FinishedMission/FinishedPopUp";
import { DetailsPopUp } from "../../Components/Missions/InProgressMission/DetailsPopUp";
import { LatePopUp } from "../../Components/Missions/LateMissions/LatePopUp";
import { MissionDetails } from "../../Components/Missions/PendingMission/MissionDetails";
import { useSelector } from "react-redux";
import { Sidebar } from "../../Components/Sidebar/Sidebar";
import { MainHeader } from "../../Components/MainHeader/MainHeader";
import { ModuleHeader } from "../../Components/ModuleHeader/ModuleHeader";
import { PendingMission } from "../../Components/Missions/PendingMission/PendingMission";
import { InProgressMission } from "../../Components/Missions/InProgressMission/InProgressMission";
import { LateMissions } from "../../Components/Missions/LateMissions/LateMissions";
import { EvaluationMission } from "../../Components/Missions/EvaluationMission/EvaluationMission";
import { FinishedMission } from "../../Components/Missions/FinishedMission/FinishedMission";
import { RefusedMissionPopUp } from "../../Components/Missions/RefusedMission/RefusedMissionPopUp";
import { RefusedMission } from "../../Components/Missions/RefusedMission/RefusedMission";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { AllMissions } from "../../Components/Missions/AllMissions/AllMissions";

export const MissionsPage = () => {
  const location = useLocation();
  const stateFromMission = useSelector((state: any) => state.missions);

  return (
    <div className="flex flex-row " style={{ direction: "rtl" }}>
      {location.pathname.includes("Pending") ? (
        <>
          {stateFromMission.showDetailsPopUp === false ? null : (
            <MissionDetails />
          )}
        </>
      ) : location.pathname.includes("InProgress") ? (
        <>
          {stateFromMission.showDetailsPopUp === false ? null : (
            <DetailsPopUp />
          )}
        </>
      ) : location.pathname.includes("Late") ? (
        <>
          {stateFromMission.showDetailsPopUp === false ? null : <LatePopUp />}
        </>
      ) : location.pathname.includes("Evaluation") ? (
        <>
          {stateFromMission.showDetailsPopUp === false ? null : (
            <EvaluationMissionPopUp />
          )}
        </>
      ) : location.pathname.includes("Finished") ? (
        <>
          {stateFromMission.showDetailsPopUp === false ? null : (
            <FinishedPopUp />
          )}
        </>
      ) : location.pathname.includes("Refused") ? (
        <>
          {stateFromMission.showDetailsPopUp === false ? null : (
            <RefusedMissionPopUp />
          )}
        </>
      ) : null}
      <div className="lg:w-full md:w-full sm:w-auto sm:max-w-[18rem]">
        <input
          type="checkbox"
          id="sidebar-mobile-fixed"
          className="sidebar-state"
        />
        <label
          htmlFor="sidebar-mobile-fixed"
          className="sidebar-overlay"
        ></label>
        {/* Side bar*/}
        <Sidebar />
      </div>
      <div className="flex w-full flex-col p-4 bg-white">
        <div className="page-container" style={{ backgroundColor: "#FFF" }}>
          <div className="col-span-12 row-span-1">
            <MainHeader />
            {/* Second Header */}
            {stateFromMission.showAddMission === true ||
            stateFromMission.showEditMission === true ? null : (
              <>
                <ModuleHeader />
              </>
            )}
          </div>
        </div>
        {/* Content Will Be Here */}

        <>
          <div
            className="h-full"
            style={{ backgroundColor: "var(--Greyscale-50, #F8FAFC)" }}
          >
            {location.pathname.includes("Pending") ? (
              <>
                <PendingMission />
              </>
            ) : location.pathname.includes("InProgress") ? (
              <>
                <InProgressMission />
              </>
            ) : location.pathname.includes("Late") ? (
              <>
                <LateMissions />
              </>
            ) : location.pathname.includes("Evaluation") ? (
              <>
                <EvaluationMission />
              </>
            ) : location.pathname.includes("Finished") ? (
              <>
                <FinishedMission />
              </>
            ) : location.pathname.includes("Refused") ? (
              <>
                <RefusedMission />
              </>
            ) : (
              <>
                <AllMissions />
              </>
            )}
          </div>
        </>
      </div>
    </div>
  );
};
