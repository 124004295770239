import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./BonusDatePicker.scss";
import { setBonusDate } from "../../../../redux/Slices/MissionSlice";

type DatePickerComponentProps = {
  activation: boolean;
  isEdit: boolean;
  bonus_date?: Date;
};
export const BonusDatePicker: React.FC<DatePickerComponentProps> = ({
  activation,
  isEdit,
  bonus_date = new Date(),
}) => {
  const [startDate, setStartDate] = useState(new Date());
  const [changed, setChanged] = useState(false);
  const dispatch = useDispatch();
  const handleChangeDate = (date: any) => {
    setStartDate(date);
    setChanged(true);
    let bonusDate = date;
    let isBonusDateChanged = true;
    dispatch(setBonusDate({ bonusDate, isBonusDateChanged }));
  };
  return (
    <div className="date-picker-container">
      <DatePicker
        selected={isEdit === true && changed === false ? bonus_date : startDate}
        onChange={(event) => handleChangeDate(event)}
        className="date-picker-input"
        disabled={activation}
        minDate={new Date()}
      />
    </div>
  );
};
