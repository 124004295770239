import "./EditMissionSections.scss";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import API from "../../../Api";
import { Formik, FormikHelpers } from "formik";
import {
  selectedMissionType,
  setSelectedAssignTo,
  setSelectedWorkAreas,
  toggleShowEditMission,
} from "../../../redux/Slices/MissionSlice";
import { setMainHeaderName } from "../../../redux/Slices/MainHeaderSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { EditBreederMission } from "./SubComponents/EditBreederMission/EditBreederMission";
import { EditFarmMission } from "./SubComponents/EditFarmMission/EditFarmMission";
import { EditHousingCycleMission } from "./SubComponents/EditHousingCycleMission/EditHousingCycleMission";
import { EditDetailsMission } from "./SubComponents/EditDetailsMission/EditDetailsMission";

interface ApiResponse {
  id: string;
  created_at: string;
  updated_at: string;
  full_address: string;
  latitude: string;
  longitude: string;
  maps_url: string;
  name: string;
  audience: string;
  status: string;
  details: string;
  reward: number;
  due_at: string;
  early_bonus: number;
  early_bonus_due_at: string;
  accepted_at: string | null;
  req_review_at: string | null;
  completed_at: string | null;
  salesman_id: string | null;
  housing_id: string | null;
  farm_id: string;
  farmer_id: string;
  trader_id: string | null;
  salesman: {
    id: string;
    name: string;
    village: string;
    governorate_id: string;
    city_id: string;
    full_address: string;
    city: string;
  };
  type: {
    id: string;
    name: string;
  };
  workAreas: {
    id: string;
    name: string;
  }[];
  assignedUsers: {
    id: string;
    name: string;
  }[];
  assignedSpecialties: any[]; // Adjust the type accordingly
  breeder: {
    id: string;
    name: string;
    mobile_number: string;
    city: {
      id: string;
      name: string;
      governorate_id: string;
      governorate: {
        id: string;
        name: string;
      };
    };
  };
  farm: {
    id: string;
    name: string;
    latitude: number;
    longitude: number;
    governorate: {
      created_at: string;
      updated_at: string;
      id: string;
      name: string;
    };
    city: {
      id: string;
      name: string;
      governorate_id: string;
    };
  };
  count: number;
}
export const EditMissionSections = () => {
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState<ApiResponse | null>(null);
  const [data, setData] = useState<{}>({});

  const [activeType, setActiveType] = useState(1);
  const [showTypePopUp, setShowTypePopUp] = useState(false);
  const [showRangePopUp, setShowRangePopUp] = useState(false);
  const [hasBonus, setHasBonus] = useState(false);
  const [showMissionAssignPopUp, setShowMissionAssignPopUp] = useState(false);
  const { MissionId } = useParams<{ MissionId: string }>();
  const stateFromMissionSlice = useSelector((state: any) => state.missions);
  const stateFromBreedersModalSlice = useSelector(
    (state: any) => state.breedersModal
  );
  const stateFromFarmsModalSlice = useSelector(
    (state: any) => state.FarmsModal
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [previousPath, setPreviousPath] = useState<string | null>(null);

  useEffect(() => {
    moment.locale("en");

    setPreviousPath(location.state.from);

    let assignedIds: number[] = [];
    let assignedType = "";
    let assignedText = "";
    let isChanged = false;
    let rangeIds: number[] = [];
    let workAreaChanged = false;
    let rangeTitle = "";

    dispatch(setSelectedWorkAreas({ rangeIds, rangeTitle, workAreaChanged }));
    dispatch(
      setSelectedAssignTo({
        assignedIds,
        assignedType,
        assignedText,
        isChanged,
      })
    );
    setIsLoading(true);
    let mainHeaderName = "تعديل مهمة";
    dispatch(setMainHeaderName({ mainHeaderName }));
    API.get(`dashboard/missions/${MissionId}`).then((res) => {
      if (res) {
        if (res.status === 403) {
          toast.error(" عفوا انت ليس لديك صلاحية الوصول لهذه الصفحة ");
          navigate(-1);
          setIsLoading(false);
        } else {
          // Set the locale to Arabic
          moment.locale("ar");

          setApiResponse(res.data);

          if (res.data.early_bonus === null || res.data.early_bonus === 0) {
            setHasBonus(false);
          } else {
            setHasBonus(true);
          }
          setData(res.data);
          setIsLoading(false);
        }
      }
    });
  }, []);

  return (
    <div className="EditMissionSections">
      <div className="breeder-header grid grid-cols-2">
        <div className="col-start-1  pr-4">
          <div className="flex lg:flex-row md:flex-col sm:flex-col xs:flex-col lg:gap-4 md:gap-2 sm:gap-2 xs:gap-2">
            <div
              className={
                activeType === 1 ? "breeder-item-active" : "breeder-item"
              }
              onClick={() => setActiveType(1)}
            >
              المربي
            </div>
            <div
              className={
                activeType === 2 ? "breeder-item-active" : "breeder-item"
              }
              onClick={() => setActiveType(2)}
            >
              العنبر
            </div>
            {apiResponse?.housing_id !== undefined &&
            apiResponse.housing_id !== null ? (
              <div
                className={
                  activeType === 3 ? "breeder-item-active" : "breeder-item"
                }
                onClick={() => setActiveType(3)}
              >
                دورة التسكين
              </div>
            ) : null}

            <div
              className={
                activeType === 4 ? "breeder-item-active" : "breeder-item"
              }
              onClick={() => setActiveType(4)}
            >
              تفاصيل المهمة
            </div>
          </div>
        </div>
        {/* Delete Icon & Close Icon*/}
        <div className="col-start-2 pl-4 pt-4">
          <div className="flex justify-end items-center gap-4">
            <svg
              onClick={() => navigate(-1)}
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="16"
              viewBox="0 0 13 12"
              fill="none"
            >
              <path
                d="M11.4473 1.21143L1.54777 11.1109"
                stroke="#64748B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1.54688 1.21143L11.4464 11.1109"
                stroke="#64748B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>

      {/* Content */}
      {apiResponse != null ? (
        <>
          {activeType === 1 ? (
            <EditBreederMission breederId={Number(apiResponse?.farmer_id)} />
          ) : activeType === 2 ? (
            <EditFarmMission
              farmId={Number(apiResponse?.farm_id)}
              breederId={Number(
                apiResponse?.breeder != null ? apiResponse?.breeder.id : 0
              )}
            />
          ) : activeType === 3 ? (
            <EditHousingCycleMission
              farmId={Number(apiResponse?.farm_id)}
              housingId={Number(apiResponse?.housing_id)}
            />
          ) : (
            <EditDetailsMission />
          )}
        </>
      ) : null}
    </div>
  );
};
