import "./MarketNewsDetails.scss";
import moment from "moment";
import { useDispatch } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { setMainHeaderName } from "../../../redux/Slices/MainHeaderSlice";
import { useParams } from "react-router-dom";
import { Loading } from "../../Loading/Loading";
import API, { URL } from "../../../Api";
import chicken from "../../../Assets/Icons/Chicken.jpg";
import mapImg from "../../../Assets/Icons/Mapsicle Map.png";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

interface ApiResponse {
  id: string;
  user_id: string;
  name: string;
  area: number;
  license_number: number;
  photo: string;
  gov_id: string;
  city_id: string;
  village: string;
  full_address: string;
  rented: number;
  latitude: number;
  longitude: number;
  dajin_breed_id: string;
  created_at: string;
  updated_at: string;
  user: {
    id: string;
    name: string;
    mobile_number: string;
  };
  dajinBreed: string;
  status: {
    id: string;
    status: string;
  };
  capacity: {
    id: string;
    capacity: string;
  };
  farmingType: {
    id: string;
    type: string;
  };
  ventilationType: {
    id: string;
    type: string;
  };
  governorate: {
    id: string;
    name: string;
  };
  city: {
    id: string;
    name: string;
  };
  creator: {
    id: string;
    name: string;
    mobile_number: string;
  };
}
export const MarketNewsDetails = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState<ApiResponse | null>(null);
  const { farmId } = useParams<{ farmId: string }>();
  const [rangeText, setRangeText] = useState("");
  const [totalRows, setHousingTotalRows] = useState(0);
  const [housingData, setHousingData] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);
    moment.locale("en");
    let mainHeaderName = "تفاصيل الخبر";
    dispatch(setMainHeaderName({ mainHeaderName }));
    setIsLoading(false);
  }, []);

  const handleImageError = (event: any) => {
    // Set a fallback image source when the original image fails to load
    event.target.src = chicken;
  };
  return (
    <div className="MarketNewsDetails">
      {isLoading === true ? <Loading /> : null}
      <div className="grid grid-cols-2 content">
        {/* News Details */}
        <div className="col-start-1">
          <p className="farm-detials">تفاصيل الخبر</p>
        </div>
        {/*  Icons */}
        <div className="col-start-2 flex justify-end items-center gap-6 pl-4">
          {/* Delete */}
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M5 8V18C5 20.2091 6.79086 22 9 22H15C17.2091 22 19 20.2091 19 18V8M14 11V17M10 11L10 17M16 5L14.5937 2.8906C14.2228 2.3342 13.5983 2 12.9296 2H11.0704C10.4017 2 9.7772 2.3342 9.40627 2.8906L8 5M16 5H8M16 5H21M8 5H3"
                stroke="#EB001B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          {/* Edit */}
          <div onClick={() => navigate(`/MarketNews/${1}/Edit`)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
            >
              <path
                d="M22 12.267V18.4006C22 20.6589 20.2091 22.4896 18 22.4896H6C3.79086 22.4896 2 20.6589 2 18.4006V6.13346C2 3.87515 3.79086 2.04443 6 2.04443H12M15.6864 4.1122C15.6864 4.1122 15.6864 5.57434 17.1167 7.03647C18.547 8.4986 19.9773 8.4986 19.9773 8.4986M9.15467 16.3454L12.1583 15.9067C12.5916 15.8435 12.9931 15.6382 13.3025 15.3219L21.4076 7.03646C22.1975 6.22895 22.1975 4.91971 21.4076 4.1122L19.9773 2.65007C19.1873 1.84256 17.9066 1.84256 17.1167 2.65007L9.01164 10.9355C8.70217 11.2518 8.50142 11.6623 8.43952 12.1052L8.01044 15.1757C7.91508 15.858 8.4872 16.4428 9.15467 16.3454Z"
                stroke="#28303F"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </svg>
          </div>
          {/* Close */}
          <svg
            onClick={() => navigate(-1)}
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="16"
            viewBox="0 0 13 12"
            fill="none"
          >
            <path
              d="M11.4473 1.21143L1.54777 11.1109"
              stroke="#64748B"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1.54688 1.21143L11.4464 11.1109"
              stroke="#64748B"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        {/* Image and content */}
        <div className="col-span-full grid grid-cols-4 gap-4 mt-4">
          <div className="col-start-1">
            <>
              <img
                src={chicken}
                className="farm-img"
                alt="farm-img"
                onError={handleImageError}
              />
            </>
          </div>

          <div className="farm-info col-start-2">
            <p className="farm-name w-full mb-4">مزارع نور الطيارة</p>
            <div className="flex mb-4">
              <span className="title">القسم :</span>
              <span className="title-content">زراعة</span>
            </div>
            <div className="flex mb-4">
              <span className="title">تاريخ النشر :</span>
              <span className="title-content">24/01/2024</span>
            </div>
            <div className="flex mb-4">
              <span className="title">الموافقة علي نشر التعليقات: </span>
              <span className="title-content">نعم</span>
            </div>

            <div className="flow-root mb-4">
              <span className="title">الخبر :</span>
              <span className="title-content">
                لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج
                أليايت,سيت دو أيوسمود تيمبور أنكايديديونتيوت لابوري ات دولار
                ماجنا أليكيوا . يوت انيم أد مينيم فينايم,كيواس نوستريد أكسير
                سيتتاشن يللأمكو لابورأس نيسي يت أليكيوب أكس أيا كوممودو
                كونسيكيوات . ديواس أيوتي أريري دولار إن ريبريهينديرأيت
                فوليوبتاتي فيلايت أيسسي كايلليوم دولار أيو فيجايت نيولا
                باراياتيور. أيكسسيبتيور ساينت أوككايكات كيوبايداتات نون
                بروايدينت ,سيونت ان كيولبا
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
