import "./ListContent.scss";

import closeIcon from "../../Assets/Icons/closeicon.svg";
type ListContentProps = {
  title: string;
  data: {}[];
};
export const ListContent = (props: ListContentProps) => {
  return (
    <div className="ListContent">
      <div className="MissionType">
        <input
          className="modal-state"
          id={`modal-${props.title}`}
          type="checkbox"
        />
        <div className="modal w-full">
          <label
            className="modal-overlay"
            htmlFor={`modal-${props.title}`}
          ></label>
          <div
            className="modal-content flex flex-col gap-5"
            style={{
              backgroundColor: "white",
              width: "2500px",
            }}
          >
            <div className="grid grid-cols-2">
              <div className="col-start-1">
                <h2 className="flex justify-start mission-type-txt">
                  {props.title}
                </h2>
              </div>

              <div className="col-start-2 flex justify-end items-center">
                <div className="flex gap-4">
                  <label htmlFor="modal-ListContent">
                    <img src={closeIcon} alt="close" />
                  </label>
                </div>
              </div>

              <div className="col-span-full">
                <div className="divider"></div>
              </div>

              <div className="col-span-full mb-4 pr-4 pl-4 h-40 overflow-y-auto">
                <div className="types-list">
                  <ul className="list-none scrollable-list  pr-2">
                    {props.data.map((item: any) => {
                      return (
                        <>
                          <li className="text-right py-2 head">
                            {props.title === "نوع الداجن" ||
                            props.title === "الشركات"
                              ? item.type
                              : props.title === "نوع السلالة"
                              ? item.breed_type
                              : item.name}
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
