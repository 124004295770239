import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setMainHeaderName } from "../../../redux/Slices/MainHeaderSlice";
import { useEffect, useState } from "react";
import "./AddTraders.scss";
import arrow from "../../../Assets/Icons/arrow.jpeg";
import { Loading } from "../../Loading/Loading";
import { Formik } from "formik";
import { toast } from "react-toastify";
import API from "../../../Api";

import * as Yup from "yup";
import { WorkRange } from "../../Users/SubComponents/work-range/WorkRange";
import MultiSelectDropdown from "../../../SubComponents/MultiSelect/MultiSelectDropdown";
import { Governorates } from "../../../SubComponents/Governorates/Governorates";
import { Cities } from "../../../SubComponents/Cities/Cities";
import { setSelectedTraderWorkAreas } from "../../../redux/Slices/TraderSlice";

// Define the validation schema
const validationSchema = Yup.object().shape({
  whatsapp: Yup.string()
    .matches(/^01\d{9}$/, "من فضلك قم بإدخال رقم واتساب مكون من 11 رقم")
    .required("من فضلك قم بإدخال رقم الواتساب"),
  mobile: Yup.string()
    .matches(/^01\d{9}$/, "من فضلك قم بإدخال رقم هاتف مكون من 11 رقم")
    .required("من فضلك قم بإدخال رقم الهاتف"),
  name: Yup.string().required("من فضلك قم بإدخال اسم التاجر"),
  daily_loading_capacity: Yup.string()
    .required("من فضلك قم بإدخال سعة التحميل اليومية")
    .matches(/^\d+$/, "من فضلك قم بإدخال قيمة صحيحة لسعة التحميل اليومية"),
});
const Days = [
  { key: "الأحد", value: 2 },
  { key: "الأثنين", value: 3 },
  { key: "الثلاثاء", value: 4 },
  { key: "الأربعاء", value: 5 },
  { key: "الخميس", value: 6 },
  { key: "الجمعة", value: 7 },
  { key: "السبت", value: 1 },
];
export const AddTraders = () => {
  const [showGovernoratePopUp, setShowGovernoratePopUp] = useState(false);
  const [showCityPopUp, setShowCityPopUp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showWorkRangePopUp, setShowWorkRangePopUp] = useState(false);
  const [selectedWorkDays, setSelectedWorkDays] = useState([]);
  //المحافظة
  const [selectedGovernorateValue, setSelectedGovernorateValue] =
    useState<string>("0");
  const [selectedGovernorateName, setSelectedGovernorateName] =
    useState<string>("قم بأختيار المحافظة");
  //المدينة
  const [selectedCityValue, setSelectedCityValue] = useState<string>("0");
  const [selectedCityName, setSelectedCityName] =
    useState<string>("قم بأختيار المدينة");

  const [addObject, setAddObject] = useState({
    name: "",
    mobile: "",
    email: "",
    whatsapp: "",
    daily_loading_capacity: "",
    work_area_ids: [],
    workday_ids: [],
    created_by_id: 0,
    cityId: 0,
  });
  const stateFromTradersSlice = useSelector((state: any) => state.Traders);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    let mainHeaderName = "اضافة تاجر";
    dispatch(setMainHeaderName({ mainHeaderName }));
    return () => {
      let rangeIds: any = [];
      let rangeTitle = "";
      let workAreaChanged = false;
      dispatch(
        setSelectedTraderWorkAreas({ rangeIds, rangeTitle, workAreaChanged })
      );
    };
  }, []);

  const handleGovernoratePopUp = () => {
    setShowGovernoratePopUp(!showGovernoratePopUp);
  };
  const handleCityPopUp = () => {
    setShowCityPopUp(!showCityPopUp);
  };
  const handleShowWorkRangePopup = () => {
    setShowWorkRangePopUp(!showWorkRangePopUp);
  };
  const handleChangeValues = (value: any, fieldName: string) => {
    // Update addObject with the new value
    setAddObject((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleSelectGovernorateChange = (value: string, name: string) => {
    setSelectedGovernorateValue(value);
    setSelectedGovernorateName(name);
    setSelectedCityName("اختر المدينة");
    setSelectedCityValue("0");
  };

  const handleSelectCityChange = (value: string, name: string) => {
    setSelectedCityValue(value);
    setSelectedCityName(name);
  };
  const confirmAddTrader = (values: any) => {
    setIsLoading(true);
    const arrayData = stateFromTradersSlice.workAreas_ids;
    const workDaysArray = selectedWorkDays.map((item: any) => {
      // Extract the values from the objects
      const value = Object.values(item)[0];
      return value;
    });
    addObject.cityId = Number(stateFromTradersSlice.selectedTraderCityId);
    if (workDaysArray === undefined || workDaysArray.length === 0) {
      toast.error("من فضلك قم بأختيار ايام العمل");
      setIsLoading(false);
    } else if (arrayData !== undefined && arrayData.length === 0) {
      toast.error("من فضلك قم بأختيار نطاق العمل");
      setIsLoading(false);
    } else {
      let requestObject: {
        name: string;
        mobile: string;
        email?: string;
        whatsapp?: string;
        created_by_id?: number;
        cityId: number;
        daily_loading_capacity: string;
        work_area_ids: Number[];
        workday_ids: any;
      } = {
        name: addObject.name,
        mobile: addObject.mobile,
        email: addObject.email,
        whatsapp: addObject.whatsapp,
        created_by_id: Number(localStorage.getItem("id")),
        cityId: Number(selectedCityValue),
        daily_loading_capacity: addObject.daily_loading_capacity,
        work_area_ids: arrayData.map((item: any) => Number(item)),
        workday_ids: workDaysArray,
      };

      if (addObject.email.length === 0) {
        delete requestObject.email;
      }

      if (addObject.whatsapp.length === 0) {
        delete requestObject.whatsapp;
      }

      API.post(`traders`, requestObject)
        .then((res: any) => {
          if (res.status === 201) {
            toast.success("تمت اضافة التاجر بنجاح");

            navigate(-1);
            setIsLoading(false);
          } else {
            toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
            setIsLoading(false);
          }
        })
        .catch((error) => {
          if (error.message === "المستخدم موحود مسبقا") {
            toast.error("هذا المستخدم موجود مسبقا");
            setIsLoading(false);
            console.log(error);
          } else {
            toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
            console.log(error);
            setIsLoading(false);
          }
        });
    }
  };

  return (
    <div className="AddTraders">
      {showGovernoratePopUp === true ? (
        <Governorates
          isEdit={false}
          selectedValue={selectedGovernorateValue}
          onSelectChange={handleSelectGovernorateChange}
        />
      ) : null}
      {showCityPopUp === true ? (
        <Cities
          isEdit={false}
          selectedValue={selectedCityValue}
          onSelectChange={handleSelectCityChange}
          govId={Number(selectedGovernorateValue)}
        />
      ) : null}

      {isLoading === true ? <Loading /> : null}
      {showWorkRangePopUp === true ? <WorkRange type={1} /> : null}
      <Formik
        onSubmit={(values) => confirmAddTrader(values)}
        initialValues={addObject}
        validationSchema={validationSchema}
        key={`AddTrader`}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              {/* المعلومات الرئيسية */}
              <div className="main-info-section  grid grid-cols-2 gap-6">
                {/* Header */}
                <div className="col-start-1 pr-4 pt-4 flex items-center ">
                  <div className="main-info-title w-1/2">
                    المعلومات الرئيسية
                  </div>
                </div>
                <div className="col-span-full">
                  <div className="divider"></div>
                </div>

                {/* الأسم ثلاثي */}
                <div className="col-start-1 pr-4 ">
                  <p className="title">الاسم ثلاثي</p>
                </div>
                {/* البريد الالكتروني */}
                <div className="col-start-2 pr-4 ">
                  <p className="title">البريد الالكتروني</p>
                </div>

                {/* الاسم الثلاثي input */}
                <div className="col-start-1 pr-4 ">
                  <div className="form-control relative">
                    <input
                      placeholder="أسم التاجر"
                      type="text"
                      id="name"
                      name="name"
                      className={
                        errors.name && touched.name
                          ? "input-err"
                          : "title-content"
                      }
                      onChange={(e) => {
                        handleChange(e);
                        handleChangeValues(e.target.value, "name");
                      }}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                    {errors.name && touched.name && (
                      <div
                        className="error err-msg"
                        style={{
                          position: "absolute",
                          bottom: "-20px",
                          left: "0",
                          width: "100%",
                        }}
                      >
                        {errors.name}
                      </div>
                    )}
                  </div>
                </div>

                {/* البريد الالكتروني input */}
                <div className="col-start-2 pr-4 pl-4 ">
                  <input
                    className="title-content"
                    type="email"
                    placeholder="البريد الألكتروني للتاجر"
                    onChange={(e) =>
                      handleChangeValues(e.target.value, "email")
                    }
                  />
                </div>

                {/* رقم الهاتف */}
                <div className="col-start-1 pr-4 ">
                  <p className="title">رقم الهاتف</p>
                </div>

                {/* رقم الواتساب */}
                <div className="col-start-2 pr-4 ">
                  <p className="title">رقم الواتساب</p>
                </div>

                {/* رقم الهاتف input */}
                <div className="col-start-1 pr-4 ">
                  <div className="form-control relative">
                    <input
                      type="text"
                      id="mobile"
                      name="mobile"
                      className={
                        errors.mobile && touched.mobile
                          ? "input-err"
                          : "title-content"
                      }
                      onChange={(e) => {
                        handleChange(e);
                        handleChangeValues(e.target.value, "mobile");
                      }}
                      onBlur={handleBlur}
                      value={values.mobile}
                      placeholder="رقم الهاتف"
                    />

                    {errors.mobile && touched.mobile && (
                      <div
                        className="error err-msg"
                        style={{
                          position: "absolute",
                          bottom: "-20px",
                          left: "0",
                          width: "100%",
                        }}
                      >
                        {errors.mobile}
                      </div>
                    )}
                  </div>
                </div>

                {/* رقم الواتساب input */}
                <div className="col-start-2 pr-4 pl-4">
                  <div className="form-control relative">
                    <input
                      type="text"
                      id="whatsapp"
                      name="whatsapp"
                      className={
                        errors.whatsapp && touched.whatsapp
                          ? "input-err"
                          : "title-content"
                      }
                      onChange={(e) => {
                        handleChange(e);
                        handleChangeValues(e.target.value, "whatsapp");
                      }}
                      onBlur={handleBlur}
                      value={values.whatsapp}
                      placeholder="رقم الواتساب"
                    />
                    {errors.whatsapp && touched.whatsapp && (
                      <div
                        className="error err-msg"
                        style={{
                          position: "absolute",
                          bottom: "-20px",
                          left: "0",
                          width: "100%",
                        }}
                      >
                        {errors.whatsapp}
                      </div>
                    )}
                  </div>
                </div>
                {/*  سعة التحميل اليومية  */}
                <div className="col-start-1 pr-4">
                  <p className="title">سعة التحميل اليومية </p>
                </div>

                {/* أيام العمل */}
                <div className="col-start-2 pr-4 ">
                  <p className="title">أيام العمل</p>
                </div>

                {/*  سعة التحميل اليومية  :input */}
                <div className="col-start-1 pr-4 ">
                  <div className="form-control relative">
                    <input
                      type="text"
                      placeholder="سعة التحميل اليومية"
                      id="daily_loading_capacity"
                      name="daily_loading_capacity"
                      className={
                        errors.daily_loading_capacity &&
                        touched.daily_loading_capacity
                          ? "input-err"
                          : "mission-address-input"
                      }
                      onChange={(e) => {
                        handleChange(e);
                        handleChangeValues(
                          e.target.value,
                          "daily_loading_capacity"
                        );
                      }}
                      onBlur={handleBlur}
                      value={values.daily_loading_capacity}
                    />
                    {errors.daily_loading_capacity &&
                      touched.daily_loading_capacity && (
                        <div
                          className="error err-msg"
                          style={{
                            position: "absolute",
                            bottom: "-20px",
                            left: "0",
                            width: "100%",
                          }}
                        >
                          {errors.daily_loading_capacity}
                        </div>
                      )}
                  </div>
                </div>
                {/* بأختيار أيام العمل */}
                <div className="col-start-2 pr-4 pl-4">
                  <MultiSelectDropdown
                    formFieldName={"Days"}
                    options={Days}
                    onChange={(selectedDays: any) => {
                      // console.debug("selectedCountries", selectedDays);
                      setSelectedWorkDays(selectedDays);
                      console.log("SelectedDays:", selectedDays);
                    }}
                    prompt="قم بأختيار أيام العمل"
                  />
                </div>
              </div>
              {/* العنوان */}
              <div className="location-section mt-2 grid grid-cols-2 gap-4">
                {/* Header */}
                <div className="col-start-1 pr-4 pt-4 flex items-center ">
                  <div className="farm-info-title w-1/2">العنوان</div>
                </div>
                <div className="col-span-full">
                  <div className="divider"></div>
                </div>

                {/*  المحافظة */}
                <div className="col-start-1 pr-4 ">
                  <p className="title">المحافظة</p>
                </div>

                {/*  المدينة */}
                <div className="col-start-2 pr-4 ">
                  <p className="title">المدينة</p>
                </div>

                {/* المحافظة  input   */}
                <div className="col-start-1 pr-4 ">
                  <label htmlFor="modal-Governorates">
                    <div
                      className="flex justify-between select-mission-container"
                      onClick={() => handleGovernoratePopUp()}
                    >
                      {/* {governorateText} */}
                      <span className="select-mission-type">
                        {selectedGovernorateName}
                      </span>
                      <div className="arrow">
                        <img src={arrow} alt="arrow" />
                      </div>
                    </div>
                  </label>
                </div>
                {/*  المدينة :input */}
                <div className="col-start-2 pr-4 pl-4">
                  <label htmlFor="modal-Cities">
                    <div
                      className="flex justify-between select-mission-container"
                      onClick={() => handleCityPopUp()}
                    >
                      <span className="select-mission-type">
                        {selectedCityName}
                      </span>

                      <div className="arrow">
                        <img src={arrow} alt="arrow" />
                      </div>
                    </div>
                  </label>
                </div>

                {/*  حي / قرية */}
                {/* <div className="col-start-1 pr-4 ">
                  <p className="title">حي / قرية</p>
                </div> */}

                {/* حي / قرية  input   */}
                {/* <div className="col-start-1 pr-4 ">
                  <input
                    type="text"
                    placeholder="القرية"
                    className="mission-address-input"
                    id="address"
                    name="address"
                    onChange={(e) =>
                      handleChangeValues(e.target.value, "address")
                    }
                  />
                </div> */}
              </div>

              <div className="activites-section mt-2 grid grid-cols-2 gap-4">
                {/* Header */}
                <div className="col-start-1 pr-4 pt-4 flex items-center ">
                  <div className="farm-info-title w-1/2">معلومات النشاط</div>
                </div>
                <div className="col-span-full">
                  <div className="divider"></div>
                </div>

                {/* نطاق العمل */}
                <div className="col-start-1 pr-4">
                  <h3 className="title"> نطاق العمل</h3>
                </div>
                <div className="col-span-full pr-4">
                  <label htmlFor="modal-784">
                    <div
                      className="flex justify-between select-stage-container"
                      onClick={() => handleShowWorkRangePopup()}
                    >
                      <div className="select-stage">
                        {stateFromTradersSlice.workAreas_ids.length !== 0
                          ? stateFromTradersSlice.workAreas_text
                          : "قم بأختيار نطاق العمل"}
                      </div>

                      <div className="arrow">
                        <img className="arrow" src={arrow} alt="arrow" />
                      </div>
                    </div>
                  </label>
                </div>
              </div>

              <div className="add-actions p-5 ">
                <div className="grid grid-cols-2">
                  <div className="col-start-1">
                    <div className="flex gap-4">
                      <button className="add-btn" type="submit">
                        اضافة
                      </button>
                      <div className="cancel-btn" onClick={() => navigate(-1)}>
                        الغاء
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </>
        )}
      </Formik>
    </div>
  );
};
