import "./ScientificArticlesItems.scss";
export const ScientificArticlesItems = () => {
  return (
    <div className="ScientificArticlesItems">
      <div className="grid">
        {/* Header */}
        <div className="col-span-full">
          <div className="grid grid-cols-7 gap-6 mt-5 pt-4">
            <div className="col-start-1 flex justify-center">
              <p className="head_title"> التصنيف</p>
            </div>
            <div className="col-start-3 flex justify-center">
              <p className="head_title"> تاريخ الأنشاء</p>
            </div>
            <div className="col-start-7">
              <p className="head_title"> Action</p>
            </div>
          </div>
        </div>
        <div className="col-span-full data-row rounded-full m-4">
          <div className="grid grid-cols-7">
            <div className="col-start-1 flex justify-center">
              <p className="content"> التكنولوچيا و الإبتكار</p>
            </div>
            <div className="col-start-3 flex justify-center">
              <p className="content"> ١٠\١٢\٢٠٢٢</p>
            </div>

            <div className="col-start-7 flex gap-4 justify-center">
              {/* Edit Icon */}
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M22 12V18C22 20.2091 20.2091 22 18 22H6C3.79086 22 2 20.2091 2 18V6C2 3.79086 3.79086 2 6 2H12M15.6864 4.02275C15.6864 4.02275 15.6864 5.45305 17.1167 6.88334C18.547 8.31364 19.9773 8.31364 19.9773 8.31364M9.15467 15.9896L12.1583 15.5605C12.5916 15.4986 12.9931 15.2978 13.3025 14.9884L21.4076 6.88334C22.1975 6.09341 22.1975 4.81268 21.4076 4.02275L19.9773 2.59245C19.1873 1.80252 17.9066 1.80252 17.1167 2.59245L9.01164 10.6975C8.70217 11.0069 8.50142 11.4084 8.43952 11.8417L8.01044 14.8453C7.91508 15.5128 8.4872 16.0849 9.15467 15.9896Z"
                    stroke="#28303F"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              {/* Delete Icon */}
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M5 8V18C5 20.2091 6.79086 22 9 22H15C17.2091 22 19 20.2091 19 18V8M14 11V17M10 11L10 17M16 5L14.5937 2.8906C14.2228 2.3342 13.5983 2 12.9296 2H11.0704C10.4017 2 9.7772 2.3342 9.40627 2.8906L8 5M16 5H8M16 5H21M8 5H3"
                    stroke="#EB001B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div className="col-span-full data-row rounded-full m-4">
          <div className="grid grid-cols-7">
            <div className="col-start-1 flex justify-center">
              <p className="content"> التكنولوچيا و الإبتكار</p>
            </div>
            <div className="col-start-3 flex justify-center">
              <p className="content"> ١٠\١٢\٢٠٢٢</p>
            </div>

            <div className="col-start-7 flex gap-4 justify-center">
              {/* Edit Icon */}

              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M22 12V18C22 20.2091 20.2091 22 18 22H6C3.79086 22 2 20.2091 2 18V6C2 3.79086 3.79086 2 6 2H12M15.6864 4.02275C15.6864 4.02275 15.6864 5.45305 17.1167 6.88334C18.547 8.31364 19.9773 8.31364 19.9773 8.31364M9.15467 15.9896L12.1583 15.5605C12.5916 15.4986 12.9931 15.2978 13.3025 14.9884L21.4076 6.88334C22.1975 6.09341 22.1975 4.81268 21.4076 4.02275L19.9773 2.59245C19.1873 1.80252 17.9066 1.80252 17.1167 2.59245L9.01164 10.6975C8.70217 11.0069 8.50142 11.4084 8.43952 11.8417L8.01044 14.8453C7.91508 15.5128 8.4872 16.0849 9.15467 15.9896Z"
                    stroke="#28303F"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
              </div>

              {/* Delete Icon */}
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M5 8V18C5 20.2091 6.79086 22 9 22H15C17.2091 22 19 20.2091 19 18V8M14 11V17M10 11L10 17M16 5L14.5937 2.8906C14.2228 2.3342 13.5983 2 12.9296 2H11.0704C10.4017 2 9.7772 2.3342 9.40627 2.8906L8 5M16 5H8M16 5H21M8 5H3"
                    stroke="#EB001B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div className="col-span-full data-row rounded-full m-4">
          <div className="grid grid-cols-7">
            <div className="col-start-1 flex justify-center">
              <p className="content"> التكنولوچيا و الإبتكار</p>
            </div>
            <div className="col-start-3 flex justify-center">
              <p className="content"> ١٠\١٢\٢٠٢٢</p>
            </div>

            <div className="col-start-7 flex gap-4 justify-center">
              {/* Edit Icon */}
              <label htmlFor="modal-3">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M22 12V18C22 20.2091 20.2091 22 18 22H6C3.79086 22 2 20.2091 2 18V6C2 3.79086 3.79086 2 6 2H12M15.6864 4.02275C15.6864 4.02275 15.6864 5.45305 17.1167 6.88334C18.547 8.31364 19.9773 8.31364 19.9773 8.31364M9.15467 15.9896L12.1583 15.5605C12.5916 15.4986 12.9931 15.2978 13.3025 14.9884L21.4076 6.88334C22.1975 6.09341 22.1975 4.81268 21.4076 4.02275L19.9773 2.59245C19.1873 1.80252 17.9066 1.80252 17.1167 2.59245L9.01164 10.6975C8.70217 11.0069 8.50142 11.4084 8.43952 11.8417L8.01044 14.8453C7.91508 15.5128 8.4872 16.0849 9.15467 15.9896Z"
                      stroke="#28303F"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
              </label>
              <input className="modal-state" id="modal-3" type="checkbox" />
              <div className="modal w-screen">
                <label className="modal-overlay" htmlFor="modal-3"></label>
                <div className="modal-content flex flex-col gap-5 max-w-3xl">
                  <div className="grid grid-cols-2">
                    <div className="col-start-1">
                      <h2 className="add_title w-1/2">تعديل تصنيف </h2>
                    </div>
                    <div className="col-start-2 flex items-center justify-end">
                      <label
                        htmlFor="modal-3"
                        className="btn btn-sm btn-circle btn-ghost absolute"
                      >
                        ✕
                      </label>
                    </div>
                    <div className="col-span-full">
                      <div className="divider"></div>
                    </div>
                    <div className="col-start-1 flex justify-start pr-4">
                      <div className="head">اسم التصنيف</div>
                    </div>
                    <div className="col-span-full mt-4 pr-4 pl-4">
                      <input
                        type="text"
                        placeholder="اكتب اسم التصنيف"
                        className="mission-address-input"
                        id="name"
                        name="name"
                      />
                    </div>
                  </div>
                  <span
                    style={{
                      visibility: "hidden",
                    }}
                  >
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Tenetur dolorum voluptate ratione dicta. Maxime cupiditate,
                    est commodi consectetur earum iure, optio, obcaecati in
                    nulla saepe maiores nobis iste quasi alias!
                  </span>
                  <div className="add-actions p-5">
                    <div className="grid grid-cols-2">
                      <div className="col-start-1">
                        <div className="flex gap-4">
                          <button className="add-btn">تعديل</button>
                          <label
                            htmlFor="modal-3"
                            // className="btn btn-sm btn-circle btn-ghost absolute"
                          >
                            <div className="cancel-btn">الغاء</div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Delete Icon */}
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M5 8V18C5 20.2091 6.79086 22 9 22H15C17.2091 22 19 20.2091 19 18V8M14 11V17M10 11L10 17M16 5L14.5937 2.8906C14.2228 2.3342 13.5983 2 12.9296 2H11.0704C10.4017 2 9.7772 2.3342 9.40627 2.8906L8 5M16 5H8M16 5H21M8 5H3"
                    stroke="#EB001B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-full data-row rounded-full m-4">
          <div className="grid grid-cols-7">
            <div className="col-start-1 flex justify-center">
              <p className="content"> التكنولوچيا و الإبتكار</p>
            </div>
            <div className="col-start-3 flex justify-center">
              <p className="content"> ١٠\١٢\٢٠٢٢</p>
            </div>

            <div className="col-start-7 flex gap-4 justify-center">
              {/* Edit Icon */}
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M22 12V18C22 20.2091 20.2091 22 18 22H6C3.79086 22 2 20.2091 2 18V6C2 3.79086 3.79086 2 6 2H12M15.6864 4.02275C15.6864 4.02275 15.6864 5.45305 17.1167 6.88334C18.547 8.31364 19.9773 8.31364 19.9773 8.31364M9.15467 15.9896L12.1583 15.5605C12.5916 15.4986 12.9931 15.2978 13.3025 14.9884L21.4076 6.88334C22.1975 6.09341 22.1975 4.81268 21.4076 4.02275L19.9773 2.59245C19.1873 1.80252 17.9066 1.80252 17.1167 2.59245L9.01164 10.6975C8.70217 11.0069 8.50142 11.4084 8.43952 11.8417L8.01044 14.8453C7.91508 15.5128 8.4872 16.0849 9.15467 15.9896Z"
                    stroke="#28303F"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              {/* Delete Icon */}
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M5 8V18C5 20.2091 6.79086 22 9 22H15C17.2091 22 19 20.2091 19 18V8M14 11V17M10 11L10 17M16 5L14.5937 2.8906C14.2228 2.3342 13.5983 2 12.9296 2H11.0704C10.4017 2 9.7772 2.3342 9.40627 2.8906L8 5M16 5H8M16 5H21M8 5H3"
                    stroke="#EB001B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div className="col-span-full data-row rounded-full m-4">
          <div className="grid grid-cols-7">
            <div className="col-start-1 flex justify-center">
              <p className="content"> التكنولوچيا و الإبتكار</p>
            </div>
            <div className="col-start-3 flex justify-center">
              <p className="content"> ١٠\١٢\٢٠٢٢</p>
            </div>

            <div className="col-start-7 flex gap-4 justify-center">
              {/* Edit Icon */}
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M22 12V18C22 20.2091 20.2091 22 18 22H6C3.79086 22 2 20.2091 2 18V6C2 3.79086 3.79086 2 6 2H12M15.6864 4.02275C15.6864 4.02275 15.6864 5.45305 17.1167 6.88334C18.547 8.31364 19.9773 8.31364 19.9773 8.31364M9.15467 15.9896L12.1583 15.5605C12.5916 15.4986 12.9931 15.2978 13.3025 14.9884L21.4076 6.88334C22.1975 6.09341 22.1975 4.81268 21.4076 4.02275L19.9773 2.59245C19.1873 1.80252 17.9066 1.80252 17.1167 2.59245L9.01164 10.6975C8.70217 11.0069 8.50142 11.4084 8.43952 11.8417L8.01044 14.8453C7.91508 15.5128 8.4872 16.0849 9.15467 15.9896Z"
                    stroke="#28303F"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              {/* Delete Icon */}
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M5 8V18C5 20.2091 6.79086 22 9 22H15C17.2091 22 19 20.2091 19 18V8M14 11V17M10 11L10 17M16 5L14.5937 2.8906C14.2228 2.3342 13.5983 2 12.9296 2H11.0704C10.4017 2 9.7772 2.3342 9.40627 2.8906L8 5M16 5H8M16 5H21M8 5H3"
                    stroke="#EB001B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
