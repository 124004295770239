import "./AddMarketNews.scss";
import arrow from "../../../Assets/Icons/arrow.jpeg";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import API from "../../../Api";
import { toast } from "react-toastify";
import { Loading } from "../../Loading/Loading";
import moment from "moment";
import { setMainHeaderName } from "../../../redux/Slices/MainHeaderSlice";
import { NewsCategories } from "../SubComponent/NewsCategory/NewsCategories";
import DatePicker from "react-datepicker";

const Data: { [key: string]: any } = {
  type_id: 0,
  name: "",
  due_at: "",
  details: "",
  reward: 0,
  maps_url: "",
  early_bonus_due_at: "",
  early_bonus: 0,
  work_area_ids: [],
  assignment: {
    type: [],
    ids: [],
  },
  farm_id: 0,
  farmer_id: 0,
};
export const AddMarketNews = () => {
  const stateFromMarketSlice = useSelector((state: any) => state.MarketNews);

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [publishDate, setPublishDate] = useState(new Date());

  const [showMarketNewsCategories, setShowMarketNewsCategories] =
    useState(false);

  const [file, setFile] = useState<File | null>(null);
  const [imageUploadWrapClass, setImageUploadWrapClass] =
    useState("image-upload-wrap");
  const [fileUploadContentVisible, setFileUploadContentVisible] =
    useState(false);

  useEffect(() => {
    let mainHeaderName = "اضافة خبر";
    dispatch(setMainHeaderName({ mainHeaderName }));
  }, []);

  const handleAddNewMarketObject = () => {
    setIsLoading(true);
  };

  const removeUpload = () => {
    setFile(null);
    setImageUploadWrapClass("image-upload-wrap");
    setFileUploadContentVisible(false);
  };
  const readURL = (input: any) => {
    if (input.files && input.files[0]) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setImageUploadWrapClass("image-upload-wrap image-dropping");
        setFileUploadContentVisible(true);
        setFile(input.files[0]);
      };

      reader.readAsDataURL(input.files[0]);
    } else {
      removeUpload();
    }
  };

  const handleDragOver = () => {
    setImageUploadWrapClass("image-upload-wrap image-dropping");
  };

  const handleDragLeave = () => {
    setImageUploadWrapClass("image-upload-wrap");
  };

  const handleChangeMission = (e: any, field: string) => {
    if (field === "name") {
      Data.name = e;
    } else if (field === "details") {
      Data.details = e;
    } else if (field === "reward") {
      Data.reward = Number(e);
    } else if (field === "early_bonus") {
      Data.early_bonus = Number(e);
    } else if (field === "maps_url") {
      Data.maps_url = e;
    }
  };

  const handleShowMarketNewsCategories = () => {
    setShowMarketNewsCategories(!showMarketNewsCategories);
  };

  const handleChangePublishDate = (date: any) => {
    setPublishDate(date);
  };

  return (
    <div className="AddMarketNews">
      {isLoading === true ? (
        <>
          <Loading />
        </>
      ) : null}
      {showMarketNewsCategories === true ? (
        <NewsCategories isEdit={false} />
      ) : null}

      {/* First Section [New Details] */}
      <div className="add-section mb-4">
        <div className="grid grid-cols-2 gap-4">
          <div className="col-start-1 mt-4 pr-4">
            <h2 className="flex justify-start mission-details">تفاصيل الخبر</h2>
          </div>
          <div className="col-span-full">
            <div className="divider"></div>
          </div>
          {/* Upload Image */}
          <div className="col-span-full flex gap-8 pr-4 items-center">
            <div className={`${imageUploadWrapClass}`}>
              <input
                onDragOver={() => handleDragOver()}
                onDragLeave={() => handleDragLeave()}
                className="file-upload-input"
                type="file"
                onChange={(e) => readURL(e.target)}
                accept="image/*"
              />
              <div className="drag-text">
                <h3 className="add-photo-txt">ارفق صورة الخبر </h3>
                <p className="">دعم جميع الملفات، الحجم الأقصى 60 ميجابايت</p>
              </div>
            </div>
            <div onClick={() => removeUpload()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M5 8V18C5 20.2091 6.79086 22 9 22H15C17.2091 22 19 20.2091 19 18V8M14 11V17M10 11L10 17M16 5L14.5937 2.8906C14.2228 2.3342 13.5983 2 12.9296 2H11.0704C10.4017 2 9.7772 2.3342 9.40627 2.8906L8 5M16 5H8M16 5H21M8 5H3"
                  stroke="#EB001B"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
          <div className="col-span-full">
            {fileUploadContentVisible && file && (
              <div className="row mb-3">
                <div className="file-upload-content">
                  <img
                    className="file-upload-image"
                    src={URL.createObjectURL(file)}
                    alt="your"
                  />
                </div>
              </div>
            )}
          </div>
          <div className="col-span-full pr-4"></div>

          <div className="col-start-1 mt-4  pr-4">
            <h3 className="mission-type"> عنوان الخبر</h3>
          </div>
          <div className="col-start-2 mt-4  pr-4">
            <h3 className="mission-type"> القسم</h3>
          </div>

          <div className="col-start-1 mb-4 pr-4">
            <div>
              <input
                type="text"
                placeholder="عنوان الخبر"
                className="mission-address-input"
                onChange={(e) => handleChangeMission(e.target.value, "name")}
              />
            </div>
          </div>

          <div className="col-start-2 pr-4 pl-4">
            <label htmlFor="modal-14568">
              <div className="flex justify-between select-mission-container">
                <input
                  className="select-mission-type pr-4"
                  value={
                    stateFromMarketSlice.selectedCategoryId === 0
                      ? "اختر القسم"
                      : stateFromMarketSlice.selectedCategoryName
                  }
                  disabled
                  id="govId"
                  name="govId"
                />

                <div
                  className="arrow"
                  onClick={() => handleShowMarketNewsCategories()}
                >
                  <img src={arrow} style={{ height: "16px" }} alt="arrow" />
                </div>
              </div>
            </label>
          </div>

          <div className="col-start-1  pr-4">
            <h3 className="mission-type"> الخبر</h3>
          </div>
          <div className="col-span-full pr-4 pl-4">
            <div className="txt-area">
              <textarea
                style={{ width: "100%" }}
                className="mission-text-area"
                placeholder="اكتب تفاصيل الخبر"
                onChange={(e) => handleChangeMission(e.target.value, "details")}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="add-section mb-4">
        <div className="grid grid-cols-2 gap-4">
          <div className="col-start-1 mt-4 pr-4">
            <h2 className="flex justify-start mission-details">
              اعدادات الخبر
            </h2>
          </div>
          <div className="col-span-full">
            <div className="divider"></div>
          </div>
          <div className="col-start-1 mt-4  pr-4">
            <h3 className="mission-type"> الموافقة علي نشر التعليقات</h3>
          </div>
          <div className="col-start-2 mt-4  pr-4">
            <h3 className="mission-type"> تاريخ النشر</h3>
          </div>

          <div className="col-start-1 mb-4 pr-4">
            <label htmlFor="modal-14568">
              <div className="flex justify-between select-mission-container">
                <input
                  className="select-mission-type pr-4"
                  value={
                    stateFromMarketSlice.selectedCategoryId === 0
                      ? "اختر "
                      : stateFromMarketSlice.selectedCategoryName
                  }
                  disabled
                  id="govId"
                  name="govId"
                />

                <div
                  className="arrow"
                  onClick={() => handleShowMarketNewsCategories()}
                >
                  <img src={arrow} style={{ height: "16px" }} alt="arrow" />
                </div>
              </div>
            </label>
          </div>

          <div className="col-start-2 pr-4 pl-4">
            <DatePicker
              selected={publishDate}
              onChange={(event) => handleChangePublishDate(event)}
              className="date-picker-input"
            />
          </div>
        </div>
      </div>

      {/* Fourth Section [Done Or cancel] */}
      <div className="add-actions p-5">
        <div className="grid grid-cols-2">
          <div className="col-start-1">
            <div className="flex gap-4">
              <button className="add-btn" type="submit">
                اضافة
              </button>
              <div className="cancel-btn" onClick={() => navigate(-1)}>
                الغاء
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
