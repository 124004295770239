import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import API from "../../../../../Api";
import "./AccountFarms.scss";
import { Loading } from "../../../../Loading/Loading";
import chicken from "../../../../../Assets/Icons/Chicken.jpg";
import { useParams } from "react-router-dom";
import moment from "moment";
import { HousingCycleHistory } from "../../../../Farms/SubComponents/HousingCycleHistory/HousingCycleHistory";
import { toast } from "react-toastify";
interface HousingData {
  id: string;
  other_brooder_co: string;
  other_feed_co: string;
  company_id: string;
  feed_co_id: string;
  dajin_breed_id: string;
  company: {
    id: string;
    type: string;
  };
  feedCompany: {
    id: string;
    name: string;
  };
  dajinBreed: {
    id: string;
    dajin_id: string;
    breed_type: string;
    dajinType: {
      id: string;
      type: string;
    };
  };
}
export const AccountFarms = () => {
  const { breederId } = useParams<{ breederId: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [farms, setFarms] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [showHousingModal, setShowHousingModal] = useState(false);
  const [housingListData, setHousingListData] = useState([]); // Initialize with null or appropriate initial value
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    API.get(`breeders/${breederId}/farms`).then((res) => {
      if (res) {
        setTotalRows(res.data.totalCount);
        const updatedFarmersData = res.data.items.map((farmer: any) => ({
          ...farmer,
          photo: farmer.photo !== null ? farmer.photo : { chicken },
        }));
        setFarms(updatedFarmersData);

        setIsLoading(false);
      }
    });
  }, [dispatch, pageNumber, pageSize, setFarms]);

  const handleImageError = (event: any) => {
    // Set a fallback image source when the original image fails to load
    event.target.src = chicken;
  };

  const handleShowHousingHistoryModal = (id: number) => {
    //Get All Housing List Data From Farms State
    setIsLoading(true);
    let data = farms.find((c: any) => c.id === id);
    if (data !== undefined) {
      let anyData = data as any;
      if (anyData.housingList.length === 0) {
        toast.warn("لا يوجد سجل لدورات التسكين لهذا العنبر");
        setIsLoading(false);
      } else {
        setHousingListData(anyData.housingList);
        setIsLoading(false);
        setShowHousingModal(!showHousingModal);
      }
    } else {
      toast.warn("لا يوجد سجل لدورات التسكين لهذا العنبر");
      setIsLoading(false);
    }
  };
  return (
    <div className="AccountFarms pt-5 pr-5 pl-5">
      {showHousingModal === true ? (
        <HousingCycleHistory data={housingListData} />
      ) : null}
      {isLoading === true ? (
        <>
          <Loading />
        </>
      ) : null}
      <div className="grid lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-6 mt-5 pr-4 pl-4">
        {farms.map((item: any, index: any) => {
          return (
            <div className="big-section mb-2 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300">
              <div className="course-cover relative">
                <>
                  {item.status_id === "1" ? (
                    <>
                      <div className="status-revision flex absolute bottom-4 left-3  p-2">
                        <span> قيد المراجعة </span>
                      </div>
                    </>
                  ) : item.status_id === "2" ? (
                    <>
                      <div className="status-empty flex absolute bottom-4 left-3  p-2">
                        <span> فارغ </span>
                      </div>
                    </>
                  ) : item.status_id === "3" ? (
                    <>
                      <div className="status-inprogress flex absolute bottom-4 left-3  p-2">
                        <span> في التشغيل </span>
                      </div>
                    </>
                  ) : item.status_id === "4" ? (
                    <>
                      <div className="status-sale flex absolute bottom-4 left-3  p-2">
                        <span> معروض للبيع </span>
                      </div>
                    </>
                  ) : item.status_id === "5" ? (
                    <>
                      <div className="status-refused flex absolute bottom-4 left-3  p-2">
                        <span> مرفوض </span>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </>
                <img
                  src={item.photo}
                  alt="course-cover"
                  onClick={() => navigate(`/AllFarms/${item.id}`)}
                  className="course-img"
                  onError={handleImageError}
                  loading="lazy"
                />
              </div>
              <div className="grid grid-cols-2 pl-4 ">
                <div className="col-start-1">
                  <p
                    className="farm-name mt-2 cursor-pointer"
                    onClick={() => navigate(`/AllFarms/${item.id}`)}
                  >
                    {item.user != null ? item.user.name : ""}-{item.name}-{" "}
                    {item.city != null && item.governorate != null ? (
                      <>
                        {item.governorate.name}-{item.city.name}
                      </>
                    ) : null}
                  </p>
                </div>
                <div className="col-start-2 flex justify-end">
                  <p className="farm-name  mt-2">{item.id}</p>
                </div>
              </div>
              <div className="col-span-full">
                <div className="divider"></div>
              </div>

              <div className="flex gap-2">
                <span className="title">تمت الاضافة بواسطة:</span>
                <span className="title-data cursor-pointer">
                  {item.creator != null ? (
                    <>
                      <Link
                        to={`/Users/Profile/PersonalData/${item.creator.id}`}
                      >
                        <a>{item.creator.name}</a>
                      </Link>
                    </>
                  ) : (
                    ""
                  )}
                </span>
              </div>
              <div className="grid grid-cols-2 pl-4 mt-2">
                <div className="col-span-full">
                  <div className="flex gap-2">
                    <span className="title">المساحة :</span>
                    <span className="title-data">{item.area}</span>
                  </div>
                </div>
                <div className="col-span-full  mt-2 ">
                  <div className=" gap-2">
                    <span className="title">السعة :</span>
                    <span className="title-data">
                      {item.capacity != null ? item.capacity.capacity : ""}
                    </span>
                  </div>
                </div>

                <div className="col-span-full mt-2">
                  <div className="flex gap-2">
                    <span className="title">نوع التربية :</span>
                    <span className="title-data">
                      {item.farmingType != null ? item.farmingType.type : ""}
                    </span>
                  </div>
                </div>
                <div className="col-span-full mt-2">
                  <div className="flex gap-2">
                    <span className="title">نوع التهوئة :</span>
                    <span className="title-data">
                      {item.ventilationType != null
                        ? item.ventilationType.type
                        : ""}
                    </span>
                  </div>
                </div>

                <div className="col-span-full mt-2">
                  <div className="flex gap-2">
                    <span className="title">السعة الحالية :</span>
                    <span className="title-data">
                      {item.currentHousing != null
                        ? item.currentHousing.count
                        : ""}
                    </span>
                  </div>
                </div>
                <div className="col-span-full mt-2">
                  <div className="gap-2">
                    <span className="title">تاريخ البيع المتوقع :</span>
                    <span className="title-data">
                      {item.currentHousing != null
                        ? moment(item.currentHousing.expected_sale_date).format(
                            "DD MMM YYYY"
                          )
                        : ""}
                    </span>
                  </div>
                </div>
                <div className="col-span-full mt-2">
                  <div className="flex gap-2">
                    <span className="title">عدد دورات التسكين :</span>
                    <span className="title-data">
                      {item.housing_count != null ? item.housing_count : 0}
                    </span>
                  </div>
                </div>
                <div className="col-span-full mt-2">
                  <label htmlFor="modal-2">
                    <div
                      className="filter-section flex"
                      onClick={() => handleShowHousingHistoryModal(item.id)}
                    >
                      <span className="title-data cursor-pointer">
                        سجل دورات التسكين
                      </span>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
