import Fuse from "fuse.js";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import API from "../../../Api";
import "../../Missions/AddMission/SubComponents/MissionType.scss";
import { useEffect, useState } from "react";
import closeIcon from "../../../Assets/Icons/closeicon.svg";
import { setSelectedStoppedFarm } from "../../../redux/Slices/FarmSlice";
import { Loading } from "../../Loading/Loading";
import DatePicker from "react-datepicker";
import moment from "moment";

type HenType = {
  farmId: any;
};
export const EmptyFarm = (props: HenType) => {
  const [isLoading, setIsLoading] = useState(false);

  const [selectedDate, setSelectedDate] = useState(new Date());

  const dispatch = useDispatch();

  useEffect(() => {
    moment.locale("en");
  }, []);

  const handleChangeDate = (date: any) => {
    setSelectedDate(date);
  };
  const submitChoise = () => {
    setIsLoading(true);
    let requestBody = {
      expected_sale_date: moment(selectedDate).format("YYYY-MM-DD"),
    };
    API.post(`/farms/${props.farmId}/empty`, requestBody)
      .then((res) => {
        if (res.status === 201) {
          toast.success("تم تفريغ العنبر بنجاح");
          setIsLoading(false);
        } else {
          toast.error("حدث خطأ  ما يرجي التواصل مع المسؤولين");
          setIsLoading(false);
        }
      })
      .catch((err) => {
        toast.error("حدث خطأ  ما يرجي التواصل مع المسؤولين");
        setIsLoading(false);

        console.log(err);
      });
  };
  return (
    <div className="MissionType">
      {isLoading === true ? <Loading /> : null}
      <input className="modal-state" id="modal-EmptyFarm" type="checkbox" />
      <div className="modal w-full">
        <label className="modal-overlay" htmlFor="modal-EmptyFarm"></label>
        <div
          className="modal-content flex flex-col gap-5"
          style={{
            backgroundColor: "white",
            width: "2500px",
          }}
        >
          <div className="grid grid-cols-2">
            <div className="col-start-1">
              <h2 className="flex justify-start mission-type-txt">
                تفريغ العنبر وقتيا
              </h2>
            </div>
            <div className="col-start-2 flex justify-end">
              <div className="flex gap-4 items-center">
                <label htmlFor="modal-EmptyFarm">
                  <img src={closeIcon} alt="close" />
                </label>
              </div>
            </div>
            <div className="col-span-full">
              <div className="divider"></div>
            </div>
            <div className="col-start-1 mt-4 mb-4 pr-4">
              <div className="flex justify-start select-mission-type">
                اختر تاريخ تفريغ العنبر
              </div>
            </div>

            <div className="col-start-1 mt-4 mb-4 pr-4 flex justify-start">
              <DatePicker
                selected={selectedDate}
                onChange={(event) => handleChangeDate(event)}
                className="date-picker-input"
              />
            </div>

            <div className="col-span-full">
              <div className="flex actions gap-4">
                <label htmlFor="modal-EmptyFarm">
                  <div className="done" onClick={() => submitChoise()}>
                    تم
                  </div>
                </label>
                <label htmlFor="modal-EmptyFarm">
                  <div className="cancel">الغاء</div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
