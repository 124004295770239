import { useEffect, useState } from "react";
import "./Traders.scss";
import { Loading } from "../Loading/Loading";
import { useNavigate } from "react-router";
import moment from "moment";
import "moment/locale/ar"; // Import the Arabic locale
import { ar, enUS } from "date-fns/locale";
import { format } from "date-fns";
import API from "../../Api";
import { setMainHeaderName } from "../../redux/Slices/MainHeaderSlice";
import { useDispatch, useSelector } from "react-redux";
import { TotalCount } from "../../SubComponents/TotalCount/TotalCount";
import { Link } from "react-router-dom";
import Pagination from "../../SubComponents/Paginator/Pagination";
import { toast } from "react-toastify";
import { ExportExcel } from "../../SubComponents/Export/ExcelExport";

export const Traders = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [Traders, setTraders] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isSorted, setIsSorted] = useState(false);
  const [sortedKey, setSortedKey] = useState("&sort_by=id&sort_order=DESC");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);
    let mainHeaderName = "التجار";
    dispatch(setMainHeaderName({ mainHeaderName }));
    moment.locale("ar");

    setPageNumber(1);
    API.get(`traders?page=${pageNumber}&limit=${pageSize}`).then((res) => {
      if (res) {
        setTraders(res.data.items);
        setTotalRows(res.data.totalCount);
        setTotalPages(Math.ceil(res.data.totalCount / pageSize));
        setIsLoading(false);
      }
    });
  }, []);

  const hanldeChangePage = (targetPN: number) => {
    setIsLoading(true);

    API.get(`traders?page=${targetPN}&limit=${pageSize}`).then((res) => {
      if (res) {
        if (res.status === 403) {
          toast.error(" عفوا انت ليس لديك صلاحية الوصول لهذه الصفحة ");
        } else {
          setTraders(res.data.items);
          setTotalRows(res.data.totalCount);
          setTotalPages(Math.ceil(res.data.totalCount / pageSize));
          setPageNumber(targetPN);
          setIsLoading(false);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }
      }
    });
  };

  const sortData = (property: string) => {
    setIsLoading(true);
    setIsSorted(true);

    switch (property) {
      case "approx_breeding_size_desc":
        setSortedKey(`sort_by=approx_breeding_size&sort_order=DESC`);
        API.get(
          `traders?page=${pageNumber}&limit=${pageSize}&sort_by=approx_breeding_size&sort_order=DESC`
        ).then((res) => {
          if (res.status === 200) {
            setTraders(res.data.items);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          }
        });
        break;

      case "approx_breeding_size_asc":
        setSortedKey(`sort_by=approx_breeding_size&sort_order=ASC`);
        API.get(
          `traders?page=${pageNumber}&limit=${pageSize}&sort_by=approx_breeding_size&sort_order=ASC`
        ).then((res) => {
          if (res.status === 200) {
            setTraders(res.data.items);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          }
        });
        break;

      case "farms_count_desc":
        setSortedKey(`sort_by=farms_count&sort_order=DESC`);
        API.get(
          `traders?page=${pageNumber}&limit=${pageSize}&sort_by=farms_count&sort_order=DESC`
        ).then((res) => {
          if (res.status === 200) {
            setTraders(res.data.items);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          }
        });
        break;

      case "farms_count_asc":
        setSortedKey(`sort_by=farms_count&sort_order=ASC`);
        API.get(
          `traders?page=${pageNumber}&limit=${pageSize}&sort_by=farms_count&sort_order=ASC`
        ).then((res) => {
          if (res.status === 200) {
            setTraders(res.data.items);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          }
        });
        break;

      case "home_breeding_desc":
        setSortedKey(`sort_by=home_breeding&sort_order=DESC`);
        API.get(
          `traders?page=${pageNumber}&limit=${pageSize}&sort_by=home_breeding&sort_order=DESC`
        ).then((res) => {
          if (res.status === 200) {
            setTraders(res.data.items);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          }
        });
        break;

      case "incubator_desc":
        setSortedKey(`sort_by=incubator&sort_order=DESC`);
        API.get(
          `traders?page=${pageNumber}&limit=${pageSize}&sort_by=incubator&sort_order=DESC`
        ).then((res) => {
          if (res.status === 200) {
            setTraders(res.data.items);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          }
        });
        break;

      case "pay_method_desc":
        setSortedKey(`sort_by=pay_method&sort_order=DESC`);
        API.get(
          `traders?page=${pageNumber}&limit=${pageSize}&sort_by=pay_method&sort_order=DESC`
        ).then((res) => {
          if (res.status === 200) {
            setTraders(res.data.items);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          }
        });
        break;

      case "farms_capacity_id_desc":
        setSortedKey(`sort_by=farms_capacity_id&sort_order=DESC`);
        API.get(
          `traders?page=${pageNumber}&limit=${pageSize}&sort_by=farms_capacity_id&sort_order=DESC`
        ).then((res) => {
          if (res.status === 200) {
            setTraders(res.data.items);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          }
        });
        break;

      case "farms_capacity_id_asc":
        setSortedKey(`sort_by=farms_capacity_id&sort_order=ASC`);
        API.get(
          `traders?page=${pageNumber}&limit=${pageSize}&sort_by=farms_capacity_id&sort_order=ASC`
        ).then((res) => {
          if (res.status === 200) {
            setTraders(res.data.items);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          }
        });
        break;

      default:
        break;
    }
  };

  const loadCreatorName = (item: any) => {
    let name =
      item.created_by_role === 900 ? (
        <>
          {item.creator != null ? (
            <>
              <Link to={`/Users/Profile/PersonalData/${item.creator.id}`}>
                {item?.creator != null ? item?.creator.name : ""} - (مندوب)
              </Link>
            </>
          ) : (
            ""
          )}
        </>
      ) : item.created_by_role === 1000 ? (
        <>
          {item.creatorAdmin != null ? (
            <>
              {item.creatorAdmin != null ? item.creatorAdmin.name : ""} - (ادمن)
            </>
          ) : (
            ""
          )}
        </>
      ) : null;
    return name;
  };

  return (
    <div className="Traders grid grid-cols-2">
      <div className="mt-4">
        {/* Total Count */}
        <TotalCount count={totalRows} title="التجار" />
      </div>
      <div className="flex gap-4 justify-end items-center mt-4">
        {/* <div className="dropdown">
          <label
            tabIndex={0}
            className="flex items-center justify-end gap-1 rounded-full bg-white p-3"
          >
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M16.0686 15H7.9313C7.32548 15 7.02257 15 6.88231 15.1198C6.76061 15.2238 6.69602 15.3797 6.70858 15.5393C6.72305 15.7232 6.93724 15.9374 7.36561 16.3657L11.4342 20.4344C11.6323 20.6324 11.7313 20.7314 11.8454 20.7685C11.9458 20.8011 12.054 20.8011 12.1544 20.7685C12.2686 20.7314 12.3676 20.6324 12.5656 20.4344L16.6342 16.3657C17.0626 15.9374 17.2768 15.7232 17.2913 15.5393C17.3038 15.3797 17.2392 15.2238 17.1175 15.1198C16.9773 15 16.6744 15 16.0686 15Z"
                  stroke="#949494"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>{" "}
                <path
                  d="M7.9313 9.00005H16.0686C16.6744 9.00005 16.9773 9.00005 17.1175 8.88025C17.2393 8.7763 17.3038 8.62038 17.2913 8.46082C17.2768 8.27693 17.0626 8.06274 16.6342 7.63436L12.5656 3.56573C12.3676 3.36772 12.2686 3.26872 12.1544 3.23163C12.054 3.199 11.9458 3.199 11.8454 3.23163C11.7313 3.26872 11.6323 3.36772 11.4342 3.56573L7.36561 7.63436C6.93724 8.06273 6.72305 8.27693 6.70858 8.46082C6.69602 8.62038 6.76061 8.7763 6.88231 8.88025C7.02257 9.00005 7.32548 9.00005 7.9313 9.00005Z"
                  stroke="#949494"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>{" "}
              </g>
            </svg>
            <span className="sorting">الترتيب حسب</span>
          </label>
          <div className="dropdown-menu bg-white z-50">
            <a
              tabIndex={-1}
              className={`dropdown-item cursor-pointer rounded-md border-2 p-1 mt-2 w-full text-sm text-right ${
                sortedKey == "sort_by=approx_breeding_size&sort_order=DESC"
                  ? "sorting-item-active"
                  : "sorting-item"
              } `}
              onClick={() => sortData("approx_breeding_size_desc")}
            >
              حجم التربية التقريبي الأكبر
            </a>

            <a
              tabIndex={-1}
              className={`dropdown-item cursor-pointer rounded-md border-2 p-1 mt-2 w-full text-sm text-right ${
                sortedKey == "sort_by=approx_breeding_size&sort_order=ASC"
                  ? "sorting-item-active"
                  : "sorting-item"
              } `}
              onClick={() => sortData("approx_breeding_size_asc")}
            >
              حجم التربية التقريبي الأصغر
            </a>

            <a
              tabIndex={-1}
              className={`dropdown-item cursor-pointer rounded-md border-2 p-1 mt-2 w-full text-sm text-right ${
                sortedKey == "sort_by=farms_count&sort_order=DESC"
                  ? "sorting-item-active"
                  : "sorting-item"
              } `}
              onClick={() => sortData("farms_count_desc")}
            >
              عدد العنابر الأكبر
            </a>

            <a
              tabIndex={-1}
              className={`dropdown-item cursor-pointer rounded-md border-2 p-1 mt-2 w-full text-sm text-right ${
                sortedKey == "sort_by=farms_count&sort_order=ASC"
                  ? "sorting-item-active"
                  : "sorting-item"
              } `}
              onClick={() => sortData("farms_count_asc")}
            >
              عدد العنابر الأصغر
            </a>
            <a
              tabIndex={-1}
              className={`dropdown-item cursor-pointer rounded-md border-2 p-1 mt-2 w-full text-sm text-right ${
                sortedKey == "sort_by=home_breeding&sort_order=DESC"
                  ? "sorting-item-active"
                  : "sorting-item"
              } `}
              onClick={() => sortData("home_breeding_desc")}
            >
              تربية منزلية
            </a>
            <a
              tabIndex={-1}
              className={`dropdown-item cursor-pointer rounded-md border-2 p-1 mt-2 w-full text-sm text-right ${
                sortedKey == "sort_by=incubator&sort_order=DESC"
                  ? "sorting-item-active"
                  : "sorting-item"
              } `}
              onClick={() => sortData("incubator_desc")}
            >
              مربي أعمار
            </a>

            <a
              tabIndex={-1}
              className={`dropdown-item cursor-pointer rounded-md border-2 p-1 mt-2 w-full text-sm text-right ${
                sortedKey == "sort_by=pay_method&sort_order=DESC"
                  ? "sorting-item-active"
                  : "sorting-item"
              } `}
              onClick={() => sortData("pay_method_desc")}
            >
              طريقة الدفع
            </a>

            <a
              tabIndex={-1}
              className={`dropdown-item cursor-pointer rounded-md border-2 p-1 mt-2 w-full text-sm text-right ${
                sortedKey == "sort_by=farms_capacity_id&sort_order=DESC"
                  ? "sorting-item-active"
                  : "sorting-item"
              } `}
              onClick={() => sortData("farms_capacity_id_desc")}
            >
              سعة العنابر الأكبر{" "}
            </a>
            <a
              tabIndex={-1}
              className={`dropdown-item cursor-pointer rounded-md border-2 p-1 mt-2 w-full text-sm text-right ${
                sortedKey == "sort_by=farms_capacity_id&sort_order=ASC"
                  ? "sorting-item-active"
                  : "sorting-item"
              } `}
              onClick={() => sortData("farms_capacity_id_asc")}
            >
              سعة العنابر الأصغر
            </a>
          </div>
        </div> */}

        <ExportExcel
          url={`traders?page=1&limit=10000000&sort_by=id&sort_order=DESC`}
          fileName="التجار"
          page="Traders"
        />
      </div>

      {isLoading === true ? (
        <>
          <Loading />
        </>
      ) : null}

      {Traders.map((item: any) => {
        const createdAtDate = moment(item.created_at);
        moment.locale("ar");
        const createdAtDate_Arabic = createdAtDate.format("DD MMM YYYY");
        return (
          <>
            <div className="grid grid-cols-2 col-span-full mb-4 mr-4 ml-4 mt-4  mission-content">
              <div className="col-span-full grid grid-cols-2 p-4 mb-2 rounded-md mr-4 border-2 w-3/4">
                <div className="col-start-1">
                  {/* <Link to={`/Breeders/${item.id}/AccountDetails`}> */}
                  <span className="title">{item.name}</span>
                  {/* </Link> */}
                </div>
                <div className="col-start-2 pl-4 flex ">
                  <span className="title">{item.id}</span>
                </div>
              </div>
              <div className="col-span-full grid grid-cols-2 p-4 mb-2 rounded-md mr-4 border-2 w-3/4">
                {/* المحافظة */}
                <div className="col-start-1 flex gap-4 justify-start mt-2">
                  <div className="inline-flex gap-2">
                    <span className="title">المحافظة :</span>
                    <span className="mission-type">
                      {item.governorate != null ? item.governorate.name : ""}
                    </span>
                  </div>
                </div>
                {/* رقم الهاتف */}
                <div className="col-start-2 pl-4 flex ">
                  <div className="inline-flex justify-start gap-2">
                    <span className="title">رقم الهاتف :</span>
                    <span className="mission-type">{item.mobile_number}</span>
                  </div>
                </div>
                {/* المدينة */}
                <div className="col-start-1 flex gap-4 justify-start mt-2">
                  <div className="inline-flex gap-2">
                    <span className="title">المدينة :</span>
                    <span className="mission-type">
                      {item.city != null ? item.city.name : ""}
                    </span>
                  </div>
                </div>

                {/* رقم الواتساب */}
                <div className="col-start-2 pl-4 flex ">
                  <div className="inline-flex justify-start gap-2">
                    <span className="title">رقم الواتساب :</span>
                    <span className="mission-type">{item.whatsapp_number}</span>
                  </div>
                </div>
              </div>

              <div className="col-span-full grid grid-cols-2 p-4 mb-2 rounded-md mr-4 border-2 w-3/4">
                {/* توثيق رقم الهاتف */}
                <div className="col-start-1 flex gap-4 justify-start mt-2">
                  <div className="inline-flex gap-2">
                    <span className="title">توثيق رقم الهاتف :</span>

                    <span className="mission-type">
                      {" "}
                      {item.mobile_verified === 0 ? "غير موثق" : "موثق"}
                    </span>
                  </div>
                </div>

                {/* سعة التحميل اليومية */}
                <div className="col-start-2 pl-4 flex ">
                  <div className="inline-flex justify-start gap-2">
                    <span className="title">سعة التحميل اليومية :</span>
                    <span className="mission-type">
                      {item.daily_loading_capacity}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-span-full grid grid-cols-2 p-4 mb-2 rounded-md mr-4 border-2 w-3/4">
                <div className="col-start-1 flex gap-4 justify-between mt-2">
                  <div className="flex gap-2 items-center">
                    <span className="title">الحالة :</span>
                    <span
                      className={
                        item.account_status === 2
                          ? "breeder-active"
                          : "breeder-disable"
                      }
                    >
                      {item.account_status === 2 ? "مفعل" : "غير مفعل"}
                    </span>
                  </div>
                  {/* <div className="">
                    {item.account_status === 2 ? (
                      <button
                        onClick={() => handleSuspendAccount(Number(item.id))}
                        type="button"
                        className="suspend_btn"
                      >
                        الغاء التفعيل
                      </button>
                    ) : (
                      <button
                        onClick={() => handleActivateAccount(Number(item.id))}
                        type="button"
                        className="activate_btn"
                      >
                        تفعيل
                      </button>
                    )}
                  </div> */}
                </div>
              </div>
              <div className="col-span-full grid grid-cols-2 p-3 mb-2 rounded-md mr-4 border-2 w-3/4">
                <div className="col-start-1 mt-2">
                  <div className="flex">
                    <div className="created-at">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="16"
                        viewBox="0 0 15 16"
                        fill="none"
                      >
                        <path
                          d="M1.875 6.6875C1.875 4.47836 3.66586 2.6875 5.875 2.6875H9.125C11.3341 2.6875 13.125 4.47836 13.125 6.6875V10.25C13.125 12.4591 11.3341 14.25 9.125 14.25H5.875C3.66586 14.25 1.875 12.4591 1.875 10.25V6.6875Z"
                          stroke="#9BA0B1"
                          stroke-width="1.5"
                        />
                        <path
                          d="M1.875 6.125H13.125"
                          stroke="#9BA0B1"
                          stroke-width="1.5"
                          stroke-linecap="round"
                        />
                        <path
                          d="M5 1.75L5 3.625"
                          stroke="#9BA0B1"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M10 1.75V3.625"
                          stroke="#9BA0B1"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <circle cx="7.5" cy="9.875" r="0.625" fill="#9BA0B1" />
                        <circle cx="10" cy="9.875" r="0.625" fill="#9BA0B1" />
                        <circle cx="5" cy="9.875" r="0.625" fill="#9BA0B1" />
                      </svg>
                      أنشأ في : {createdAtDate_Arabic}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      })}

      {/* Pagination */}

      <div className="col-start-2 flex justify-end pt-4 pl-12">
        <Pagination
          currentPage={pageNumber}
          lastPage={totalPages}
          maxLength={7}
          setCurrentPage={hanldeChangePage}
        />
      </div>
    </div>
  );
};
