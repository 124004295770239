import "./FarmDetails.scss";
import chicken from "../../../Assets/Icons/Chicken.jpg";
import mapImg from "../../../Assets/Icons/Mapsicle Map.png";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import API, { URL } from "../../../Api";
import { Loading } from "../../Loading/Loading";
import { useParams } from "react-router-dom";
import { setMainHeaderName } from "../../../redux/Slices/MainHeaderSlice";
import { useDispatch } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import moment from "moment";
import PrintFarmDetails from "../../../SubComponents/Export/PrintFarmDetails";
import { EmptyFarm } from "../SubComponents/EmptyFarm";

interface ApiResponse {
  id: string;
  user_id: string;
  name: string;
  area: number;
  housing_count: number;
  license_number: number;
  photo: string;
  gov_id: string;
  city_id: string;
  village: string;
  full_address: string;
  rented: number;
  latitude: number;
  longitude: number;
  dajin_breed_id: string;
  created_at: string;
  updated_at: string;
  created_by_role: number;
  creatorAdmin: {
    id: string;
    name: string;
    type: number;
    mobile_number: string;
  };
  user: {
    id: string;
    name: string;
    mobile_number: string;
  };
  dajinBreed: string;
  status: {
    id: string;
    status: string;
  };
  capacity: {
    id: string;
    capacity: string;
  };
  farmingType: {
    id: string;
    type: string;
  };
  ventilationType: {
    id: string;
    type: string;
  };
  governorate: {
    id: string;
    name: string;
  };
  city: {
    id: string;
    name: string;
  };
  creator: {
    id: string;
    name: string;
    mobile_number: string;
  };
  currentHousing: {
    id: string;
    other_brooder_co: string;
    other_feed_co: string;
    count: number;
    housing_date: string;
    expected_sale_date: string;
    company: {
      id: string;
      type: string;
    };
    feedCompany: {
      id: string;
      name: string;
    };
    dajinBreed: {
      id: string;
      dajin_id: string;
      breed_type: string;
      poultry_farming_average: number;
      poultry_farming_maximum: number;
      created_at: string;
      updated_at: string;
      dajinType: {
        id: string;
        type: string;
        trader_commission: number;
        farmer_commission: number;
        image: string;
        deleted_at: string;
        created_at: string;
        updated_at: string;
      };
    };
  };
}
export const FarmDetails = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState<ApiResponse | null>(null);
  const { farmId } = useParams<{ farmId: string }>();
  const [rangeText, setRangeText] = useState("");
  const [totalRows, setHousingTotalRows] = useState(0);
  const [housingData, setHousingData] = useState([]);
  const [showStopFarmModal, setShowStopFarmModal] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    setIsLoading(true);
    moment.locale("ar");
    let mainHeaderName = "تفاصيل العنبر";
    dispatch(setMainHeaderName({ mainHeaderName }));

    API.get(`farms/${farmId}`).then((res) => {
      if (res) {
        setApiResponse(res.data);

        setIsLoading(false);
      }
    });
    API.get(`farms/${farmId}/housing`).then((res) => {
      setIsLoading(true);
      setHousingData(res.data.items);
      setHousingTotalRows(res.data.totalCount);
      setIsLoading(false);
    });
  }, []);
  const handleImageError = (event: any) => {
    // Set a fallback image source when the original image fails to load
    event.target.src = chicken;
  };
  const handleShowEmptyFarmModal = () => {
    setShowStopFarmModal(true);
  };
  return (
    <div className="grid grid-cols-2 my-4 FarmDetails">
      {showStopFarmModal === true ? (
        <EmptyFarm farmId={Number(farmId)} />
      ) : null}
      {isLoading === true ? <Loading /> : null}
      <div className="grid grid-cols-2 col-span-full content">
        {/* Farm Details */}
        <div className="col-start-1">
          <p className="farm-detials">تفاصيل العنبر - {apiResponse?.id}</p>
        </div>
        {/*  Icons */}
        <div className="lg:col-start-2 md:col-span-full sm:col-span-full xs:col-span-full xs:mt-2 flex lg:flex-row md:flex-row sm:flex-row xs:flex-row xs:items-center  justify-end items-center lg:gap-6 md:gap-4 sm:gap-4 xs:gap-4 pl-4">
          {/* Print PDF */}
          {apiResponse != null ? (
            <PrintFarmDetails
              dataToPrint={apiResponse}
              housingData={housingData}
            />
          ) : null}
          {/* Movement History */}
          <div
            className="tooltip tooltip-top"
            data-tooltip="تفاصيل سجل العنبر"
            onClick={() =>
              navigate(`/AllFarms/${apiResponse?.id}/MovementRecord`)
            }
          >
            <svg
              width="34px"
              height="35px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M18.05 6.08C16.4776 4.51428 14.349 3.63523 12.13 3.63523C9.91101 3.63523 7.78239 4.51428 6.21 6.08L5 7.29V4.38C5 4.18109 4.92098 3.99033 4.78033 3.84967C4.63968 3.70902 4.44891 3.63 4.25 3.63C4.05109 3.63 3.86032 3.70902 3.71967 3.84967C3.57902 3.99033 3.5 4.18109 3.5 4.38V9.12C3.49998 9.31985 3.57868 9.51166 3.71905 9.6539C3.85942 9.79614 4.05017 9.87738 4.25 9.88H9C9.19891 9.88 9.38968 9.80099 9.53033 9.66033C9.67098 9.51968 9.75 9.32892 9.75 9.13C9.75 8.93109 9.67098 8.74033 9.53033 8.59967C9.38968 8.45902 9.19891 8.38 9 8.38H6L7.27 7.14C8.55985 5.85275 10.3077 5.12979 12.13 5.12979C13.9523 5.12979 15.7001 5.85275 16.99 7.14C23.18 13.83 13.99 23.05 7.27 16.86C7.12937 16.7196 6.93875 16.6407 6.74 16.6407C6.54125 16.6407 6.35063 16.7196 6.21 16.86C6.13924 16.929 6.08301 17.0114 6.04461 17.1024C6.00621 17.1934 5.98643 17.2912 5.98643 17.39C5.98643 17.4888 6.00621 17.5866 6.04461 17.6776C6.08301 17.7686 6.13924 17.8511 6.21 17.92C7.78008 19.4901 9.90957 20.3722 12.13 20.3722C14.3504 20.3722 16.4799 19.4901 18.05 17.92C19.6201 16.3499 20.5021 14.2204 20.5021 12C20.5021 9.77957 19.6201 7.65009 18.05 6.08Z"
                  fill="#28303F"
                ></path>{" "}
                <path
                  d="M12 7.75C11.8019 7.75259 11.6126 7.83244 11.4725 7.97253C11.3324 8.11263 11.2526 8.30189 11.25 8.5V12C11.2502 12.1988 11.3293 12.3895 11.47 12.53L14 15C14.0692 15.0701 14.1518 15.1257 14.2428 15.1635C14.3338 15.2012 14.4315 15.2205 14.53 15.22C14.6617 15.2008 14.7859 15.1469 14.8899 15.0639C14.9939 14.9809 15.0739 14.8716 15.1218 14.7474C15.1696 14.6233 15.1836 14.4886 15.1622 14.3572C15.1408 14.2259 15.0848 14.1026 15 14L12.72 11.72V8.5C12.7177 8.30691 12.642 8.12193 12.5083 7.98263C12.3746 7.84332 12.1928 7.76015 12 7.75Z"
                  fill="#28303F"
                ></path>{" "}
              </g>
            </svg>
          </div>
          {Number(apiResponse?.status?.id) === 3 ? (
            <>
              {/* Make Farm Empty */}
              <label htmlFor="modal-EmptyFarm">
                <div
                  className="tooltip tooltip-top"
                  data-tooltip="تفريغ العنبر"
                  onClick={() => handleShowEmptyFarmModal()}
                >
                  <svg
                    version="1.1"
                    id="Icons"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 32 32"
                    xmlBase="preserve"
                    width="34px"
                    height="35px"
                    fill="#28303F"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <style type="text/css"></style>{" "}
                      <path
                        className="st0"
                        d="M19,6.8L19,6.8V3.9c0-0.6-0.6-1.2-1.1-0.8L12,8.8l5.9,5.7c0.4,0.4,1.1,0.1,1.1-0.5v-3.1h0c3.8,0,7.2,2.5,8,6 l0,0.1v-2.7C27,10.1,23.3,6.8,19,6.8z"
                      ></path>{" "}
                      <path
                        className="st0"
                        d="M13,25.2L13,25.2v2.8c0,0.6,0.6,1.2,1.1,0.8l5.9-5.7l-5.9-5.7C13.6,17.1,13,17.4,13,18v3.1h0 c-3.8,0-7.2-2.5-8-6L5,15v2.7C5,21.9,8.7,25.2,13,25.2z"
                      ></path>{" "}
                    </g>
                  </svg>
                </div>
              </label>
            </>
          ) : null}
          {/* Delete */}
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M5 8V18C5 20.2091 6.79086 22 9 22H15C17.2091 22 19 20.2091 19 18V8M14 11V17M10 11L10 17M16 5L14.5937 2.8906C14.2228 2.3342 13.5983 2 12.9296 2H11.0704C10.4017 2 9.7772 2.3342 9.40627 2.8906L8 5M16 5H8M16 5H21M8 5H3"
                stroke="#EB001B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          {/* Edit */}
          <div onClick={() => navigate(`/AllFarms/${apiResponse?.id}/Edit`)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
            >
              <path
                d="M22 12.267V18.4006C22 20.6589 20.2091 22.4896 18 22.4896H6C3.79086 22.4896 2 20.6589 2 18.4006V6.13346C2 3.87515 3.79086 2.04443 6 2.04443H12M15.6864 4.1122C15.6864 4.1122 15.6864 5.57434 17.1167 7.03647C18.547 8.4986 19.9773 8.4986 19.9773 8.4986M9.15467 16.3454L12.1583 15.9067C12.5916 15.8435 12.9931 15.6382 13.3025 15.3219L21.4076 7.03646C22.1975 6.22895 22.1975 4.91971 21.4076 4.1122L19.9773 2.65007C19.1873 1.84256 17.9066 1.84256 17.1167 2.65007L9.01164 10.9355C8.70217 11.2518 8.50142 11.6623 8.43952 12.1052L8.01044 15.1757C7.91508 15.858 8.4872 16.4428 9.15467 16.3454Z"
                stroke="#28303F"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </svg>
          </div>
          {/* Close */}
          <svg
            onClick={() => navigate(-1)}
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="16"
            viewBox="0 0 13 12"
            fill="none"
          >
            <path
              d="M11.4473 1.21143L1.54777 11.1109"
              stroke="#64748B"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1.54688 1.21143L11.4464 11.1109"
              stroke="#64748B"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        {/* Image and content */}
        <div className="col-span-full grid grid-cols-4 gap-4 mt-4">
          <div className="lg:col-start-1 w-auto md:col-span-full sm:col-span-full xs:col-span-full">
            {apiResponse?.photo === null ? (
              <>
                <img
                  src={chicken}
                  className="farm-img"
                  alt="farm-img"
                  onError={handleImageError}
                />
              </>
            ) : (
              <>
                <a href={apiResponse?.photo} target="_blank" rel="noreferrer">
                  <img
                    src={apiResponse?.photo}
                    className="farm-img"
                    alt="farm-img"
                    onError={handleImageError}
                  />
                </a>
              </>
            )}
          </div>

          <div className="farm-info lg:col-start-1 md:col-start-1 sm:col-start-1 xs:col-start-1">
            <p className="farm-name w-full mb-4">{apiResponse?.name}</p>
          </div>

          <div className="status w-auto col-start-4">
            {apiResponse?.status.status}
          </div>
        </div>
        {/* الترخيص */}
        <div className="col-start-1 mt-4">
          <span className="title">الترخيص</span>
        </div>
        {/* المرافق */}
        <div className="col-start-2 mt-4">
          <span className="title">المرافق</span>
        </div>

        {/* الترخيص input */}
        <div className="col-start-1">
          <span className="title-content">
            {apiResponse?.license_number === null
              ? "غير مرخص"
              : apiResponse?.license_number}
          </span>
        </div>

        {/* المرافق input */}
        <div className="col-start-2">
          <span className="title-content"></span>
        </div>
        {/* تبعية “ملكية” العنبر */}
        <div className="col-start-1 mt-4">
          <span className="title wrap">تبعية “ملكية” العنبر </span>
        </div>

        {/* مساحة العنبر */}
        <div className="col-start-2 mt-4">
          <span className="title wrap">مساحة العنبر </span>
        </div>

        {/* تبعية “ملكية” العنبر input */}
        <div className="col-start-1">
          <span className="title-content"></span>
        </div>
        {/* مساحة العنبر  input*/}

        <div className="col-start-2">
          <span className="title-content wrap">{apiResponse?.area}</span>
        </div>

        {/* النطاق */}
        <div className="col-start-1 mt-4">
          <span className="title">النطاق</span>
        </div>
        {/*  سعة العنبر */}
        <div className="col-start-2 mt-4">
          <span className="title">سعة العنبر</span>
        </div>
        {/* النطاق input */}
        <div className="col-start-1">
          <span className="title-content">
            {apiResponse?.governorate != null
              ? apiResponse?.governorate.name
              : ""}
            |{apiResponse?.city != null ? apiResponse?.city.name : ""}
          </span>
        </div>
        {/* سعة العنبر input */}
        <div className="col-start-2">
          <span className="title-content">
            {apiResponse?.capacity.capacity}
          </span>
        </div>
        {/* نوع التهوئة */}
        <div className="col-start-1 mt-4">
          <span className="title">نوع التهوئة</span>
        </div>
        {/*  نوع التربية */}
        <div className="col-start-2 mt-4">
          <span className="title">نوع التربية</span>
        </div>
        {/* نوع التهوئة input */}
        <div className="col-start-1">
          <span className="title-content">
            {apiResponse?.ventilationType.type}
          </span>
        </div>
        {/* نوع التربية input */}
        <div className="col-start-2">
          <span className="title-content">{apiResponse?.farmingType.type}</span>
        </div>

        {/* ######## */}
        {/* السعة الحالية */}
        <div className="col-start-1 mt-4">
          <span className="title">السعة الحالية</span>
        </div>

        {/* تم الاضافة بواسطة */}
        <div className="col-start-2 mt-4">
          <span className="title">تمت الأضافة بواسطة</span>
        </div>

        {/* السعة الحالية input */}
        <div className="col-start-1">
          <span className="title-content">
            {apiResponse?.currentHousing != null
              ? apiResponse?.currentHousing.count
              : ""}
          </span>
        </div>

        {/* تم الاضافة بواسطة input */}
        <div className="col-start-2">
          <span className="title-content cursor-pointer">
            {apiResponse?.created_by_role === 900 ? (
              <>
                {apiResponse?.creator != null ? (
                  <>
                    <Link
                      to={`/Users/Profile/PersonalData/${apiResponse?.creator.id}`}
                    >
                      {apiResponse?.creator != null
                        ? apiResponse?.creator.name
                        : ""}{" "}
                      - (مندوب)
                    </Link>
                  </>
                ) : (
                  ""
                )}
              </>
            ) : apiResponse?.created_by_role === 1000 ? (
              <>
                {apiResponse?.creatorAdmin != null ? (
                  <>
                    {/* <Link to={`/Users/Profile/${apiResponse?.creatorAdmin.id}`}> */}
                    {apiResponse?.creatorAdmin != null
                      ? apiResponse?.creatorAdmin.name
                      : ""}{" "}
                    - (ادمن)
                    {/* </Link> */}
                  </>
                ) : (
                  ""
                )}
              </>
            ) : null}
          </span>
        </div>

        {/* ######## */}
        {/* تاريخ التسكين */}
        <div className="col-start-1 mt-4">
          <span className="title">تاريخ التسكين</span>
        </div>
        {/*  العدد */}
        <div className="col-start-2 mt-4">
          <span className="title">العدد</span>
        </div>
        {/* تاريخ التسكين input */}
        <div className="col-start-1">
          <span className="title-content">
            {apiResponse?.currentHousing != null
              ? moment(apiResponse?.currentHousing.housing_date).format(
                  "DD MMM YYYY"
                )
              : ""}
          </span>
        </div>
        {/* العدد input */}
        <div className="col-start-2">
          <span className="title-content">
            {apiResponse?.currentHousing != null
              ? apiResponse?.currentHousing.count
              : 0}
          </span>
        </div>

        {/* ######## */}
        {/* تاريخ البيع المتوقع */}
        <div className="col-start-1 mt-4">
          <span className="title">تاريخ البيع المتوقع</span>
        </div>
        {/*  تاريخ بداية دورة التسكين */}
        <div className="col-start-2 mt-4">
          <span className="title wrap">تاريخ بداية دورة التسكين</span>
        </div>
        {/* تاريخ البيع المتوقع input */}
        <div className="col-start-1">
          <span className="title-content">
            {apiResponse?.currentHousing != null
              ? moment(apiResponse?.currentHousing.expected_sale_date).format(
                  "DD MMM YYYY"
                )
              : ""}
          </span>
        </div>
        {/* تاريخ بداية دورة التسكين input */}
        <div className="col-start-2">
          <span className="title-content">
            {apiResponse?.currentHousing != null
              ? moment(apiResponse?.currentHousing.housing_date).format(
                  "DD MMM YYYY"
                )
              : ""}
          </span>
        </div>

        {/* عدد دورات التسكين */}
        <div className="col-start-1 mt-4">
          <span className="title">عدد دورات التسكين</span>
        </div>
        {/*  الكتكوت المستخدم */}
        <div className="col-start-2 mt-4">
          <span className="title">الكتكوت المستخدم</span>
        </div>
        {/* عدد دورات التسكين input */}
        <div className="col-start-1">
          <span className="title-content">
            {apiResponse?.housing_count != null
              ? apiResponse?.housing_count
              : 0}
          </span>
        </div>
        {/* الكتكوت المستخجم input */}
        <div className="col-start-2">
          <span className="title-content">
            {apiResponse?.currentHousing != null ? (
              <>
                {apiResponse?.currentHousing.dajinBreed != null ? (
                  <>
                    {apiResponse?.currentHousing.dajinBreed.dajinType !=
                    null ? (
                      <a>
                        {`${apiResponse?.currentHousing.dajinBreed.dajinType.type} -
                                          ${apiResponse?.currentHousing.dajinBreed.breed_type}`}
                      </a>
                    ) : (
                      <a>{apiResponse?.currentHousing.dajinBreed.breed_type}</a>
                    )}
                  </>
                ) : (
                  ""
                )}
              </>
            ) : null}
          </span>
        </div>

        {/* ######## */}
        {/* العلف المستخدم */}
        <div className="col-start-1 mt-4">
          <span className="title">العلف المستخدم</span>
        </div>

        {/* العلف المستخدم input */}
        <div className="col-start-1">
          <span className="title-content">
            {apiResponse?.currentHousing != null ? (
              <>
                {apiResponse?.currentHousing.feedCompany != null ? (
                  <>
                    <div className="flex gap-4 assigned-to">
                      {apiResponse?.currentHousing.company.id === "14"
                        ? apiResponse?.currentHousing.other_brooder_co
                        : apiResponse?.currentHousing.company.type}
                    </div>
                  </>
                ) : null}
              </>
            ) : null}
          </span>
        </div>

        {/* معلومات المربي */}
        <div className="col-span-full grid grid-cols-2 activity-information-section mt-4">
          <div className="col-start-1 mt-4 pr-4">
            <span className="main-info">معلومات المربي</span>
          </div>
          <div className="col-span-full">
            <div className="divider"></div>
          </div>

          {/* اسم المربي*/}
          <div className="col-start-1 mt-4 pr-4">
            <p className="title">اسم المربي</p>
          </div>

          <div className="col-start-2 mt-4 pr-4">
            <p className="title">رقم الهاتف</p>
          </div>
          {/*  اسم المربي :input*/}
          <div className="col-start-1  mt-2 pr-4">
            {apiResponse?.user != null && apiResponse?.user !== undefined ? (
              <>
                <Link to={`/Breeders/${apiResponse?.user.id}/AccountDetails`}>
                  <span className="cursor-pointer title-content">
                    {apiResponse?.user.name}
                  </span>
                </Link>
              </>
            ) : (
              ""
            )}
          </div>

          <div className="col-start-2  mt-2 pr-4">
            {apiResponse?.user != null ? (
              <>
                <span className="title-content">
                  {apiResponse?.user.mobile_number}
                </span>
              </>
            ) : null}
          </div>
        </div>
        {/* Map URL */}
        <div className="col-span-full mt-4">
          <div className="map-container">
            <a
              href={`https://www.google.com/maps/dir/?api=1&destination=${apiResponse?.latitude},${apiResponse?.longitude}`}
              target="_blank"
              className="w-full"
              rel="noreferrer"
            >
              <img src={mapImg} alt="map" className="map lg:h-full xs:h-14" />
            </a>
          </div>
        </div>
        {/* Actions */}
        <div className="col-start-1 mt-4  xs:col-span-full flex lg:flex-row md:flex-col sm:flex-col xs:flex-col xs:items-center  justify-start items-center lg:gap-6 md:gap-4 sm:gap-4 xs:gap-4">
          <div
            className="add-farm-btn"
            onClick={() =>
              navigate(
                `/AllFarms/${apiResponse?.id}/${apiResponse?.user_id}/Housing`
              )
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
            >
              <path
                d="M10.5049 1.02539C6.02189 1.02539 2.375 4.67228 2.375 9.15527C2.375 13.6383 6.02189 17.2852 10.5049 17.2852C14.9879 17.2852 18.6348 13.6383 18.6348 9.15527C18.6348 4.67228 14.9879 1.02539 10.5049 1.02539ZM14.0617 9.83272H11.1823V12.7121C11.1823 13.0861 10.8789 13.3895 10.5049 13.3895C10.1309 13.3895 9.82743 13.0861 9.82743 12.7121V9.83272H6.94806C6.57404 9.83272 6.27061 9.52929 6.27061 9.15527C6.27061 8.78126 6.57404 8.47782 6.94806 8.47782H9.82743V5.59845C9.82743 5.22443 10.1309 4.921 10.5049 4.921C10.8789 4.921 11.1823 5.22443 11.1823 5.59845V8.47782H14.0617C14.4357 8.47782 14.7392 8.78126 14.7392 9.15527C14.7392 9.52929 14.4357 9.83272 14.0617 9.83272Z"
                fill="white"
              />
            </svg>
            تسكين العنبر
          </div>

          <div className="add-farm-btn" onClick={() => navigate(`SalesOffer`)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
            >
              <path
                d="M10.5049 1.02539C6.02189 1.02539 2.375 4.67228 2.375 9.15527C2.375 13.6383 6.02189 17.2852 10.5049 17.2852C14.9879 17.2852 18.6348 13.6383 18.6348 9.15527C18.6348 4.67228 14.9879 1.02539 10.5049 1.02539ZM14.0617 9.83272H11.1823V12.7121C11.1823 13.0861 10.8789 13.3895 10.5049 13.3895C10.1309 13.3895 9.82743 13.0861 9.82743 12.7121V9.83272H6.94806C6.57404 9.83272 6.27061 9.52929 6.27061 9.15527C6.27061 8.78126 6.57404 8.47782 6.94806 8.47782H9.82743V5.59845C9.82743 5.22443 10.1309 4.921 10.5049 4.921C10.8789 4.921 11.1823 5.22443 11.1823 5.59845V8.47782H14.0617C14.4357 8.47782 14.7392 8.78126 14.7392 9.15527C14.7392 9.52929 14.4357 9.83272 14.0617 9.83272Z"
                fill="white"
              />
            </svg>
            عرض العنبر للبيع
          </div>
        </div>
      </div>
      {/* دورات التسكين */}
      {housingData.map((item: any) => {
        return (
          <>
            <div className="grid grid-cols-2 col-span-full breedingCycle mt-4">
              <div className="col-start-1 pr-4">
                <p className="cycle_title">
                  دورة تسكين بتاريخ{" "}
                  {moment(item.housing_date).format("DD-MM-YYYY")}
                </p>
              </div>
              {/* Edit Button */}
              <div
                className="col-start-2 pl-4 flex justify-end"
                onClick={() =>
                  navigate(
                    `/AllFarms/${apiResponse?.id}/${item.id}/HousingEdit`
                  )
                }
              >
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                  >
                    <path
                      d="M22 12.2671V18.4006C22 20.6589 20.2091 22.4897 18 22.4897H6C3.79086 22.4897 2 20.6589 2 18.4006V6.13355C2 3.87524 3.79086 2.04452 6 2.04452H12M15.6864 4.11229C15.6864 4.11229 15.6864 5.57442 17.1167 7.03655C18.547 8.49868 19.9773 8.49868 19.9773 8.49868M9.15467 16.3455L12.1583 15.9068C12.5916 15.8435 12.9931 15.6383 13.3025 15.322L21.4076 7.03655C22.1975 6.22904 22.1975 4.9198 21.4076 4.11228L19.9773 2.65015C19.1873 1.84264 17.9066 1.84264 17.1167 2.65015L9.01164 10.9356C8.70217 11.2519 8.50142 11.6624 8.43952 12.1053L8.01044 15.1757C7.91508 15.8581 8.4872 16.4429 9.15467 16.3455Z"
                      stroke="#28303F"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
              </div>
              <div className="col-span-full">
                <div className="divider"></div>
              </div>
              <div className="col-start-1 pr-4 mt-4">
                <p className="cycle_title">نوع الداجن</p>
              </div>
              <div className="col-start-2 pl-4 mt-4">
                <p className="cycle_title">نوع السلالة</p>
              </div>
              {/* نوع الداجن input */}
              <div className="col-start-1 pr-4">
                <span className="cycle-content">
                  {item.dajinBreed != null ? (
                    <>
                      {item.dajinBreed.dajinType != null ? (
                        <>{item.dajinBreed.dajinType.type}</>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </span>
              </div>
              {/* نوع السلالة input */}
              <div className="col-start-2 pr-4">
                <span className="cycle-content">
                  {item.dajinBreed != null ? (
                    <>{item.dajinBreed.breed_type}</>
                  ) : (
                    ""
                  )}
                </span>
              </div>

              <div className="col-start-1 pr-4 mt-4">
                <p className="cycle_title">العدد</p>
              </div>

              <div className="col-start-2 pl-4 mt-4">
                <p className="cycle_title">الشركة</p>
              </div>

              {/* العدد input */}
              <div className="col-start-1 pr-4">
                <span className="cycle-content">{item.count}</span>
              </div>
              {/* الشركة input */}
              <div className="col-start-2 pr-4">
                <span className="cycle-content">
                  {item.company != null ? (
                    <>
                      {item.company_id === "14"
                        ? item.other_brooder_co
                        : item.company.type}
                    </>
                  ) : (
                    ""
                  )}
                </span>
              </div>

              <div className="col-start-1 pr-4 mt-4">
                <p className="cycle_title">نوع العلف</p>
              </div>

              {/* نوع العلف input */}
              <div className="col-start-1 pr-4">
                <span className="cycle-content">
                  <>
                    {item.feedCompany != null ? (
                      <>
                        <div className="flex gap-4 assigned-to">
                          {item.feedCompany.id === "14"
                            ? item.other_feed_co
                            : item.feedCompany.name}
                        </div>
                      </>
                    ) : null}
                  </>
                </span>
              </div>
              <div className="col-start-1 pr-4 mt-4">
                <p className="cycle_title">تاريخ الأدخال</p>
              </div>
              <div className="col-start-2 pl-4 mt-4">
                <p className="cycle_title wrap">تاريخ البيع المتوقع</p>
              </div>

              {/* تاريخ الأدخال input */}
              <div className="col-start-1 pr-4">
                <span className="cycle-content">
                  {moment(item.housing_date).format("YYYY-MM-DD")}
                </span>
              </div>
              {/* تاريخ البيع المتوقع input */}
              <div className="col-start-2 pr-4">
                <span className="cycle-content">
                  {moment(item.expected_sale_date).format("YYYY-MM-DD")}
                </span>
              </div>

              {/* تمت الاضافة بواسطة */}
              <div className="col-start-1 pr-4 mt-4">
                <p className="cycle_title">تمت الاضافة بواسطة</p>
              </div>

              {/* تمت الاضافة بواسطة input */}
              <div className="col-start-1 pr-4 ">
                <span className="cycle-content">
                  {item.creator != null ? <>{item.creator.name}</> : null}
                </span>
              </div>
            </div>
          </>
        );
      })}
    </div>
  );
};
