import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  breedersIds: [],
  breedersText: "",
  breedersModalChanged: false,
  breederId: 0,
  breederName: "",
  breederCity: "",
  breederGovernorateCity: "",
  cityId: 0,
  governorateId: 0,
  headerType: 0,
  filterDataObj: [],
  isFiltered: false,
  filterTotalCount: 0,
  filterUrl: "",
};
export const BreedersModalSlice = createSlice({
  name: "breedersModal",
  initialState,
  reducers: {
    setSelectedBreeders: (state, action) => {
      state.breedersIds = action.payload.breedersIds;
      state.breedersText = action.payload.breedersText;
      state.breedersModalChanged = action.payload.isChanged;
    },
    setSelectedOneBreeders: (state, action) => {
      state.breederId = action.payload.breederId;
      state.breederName = action.payload.breederName;
      state.breedersModalChanged = action.payload.isChanged;
      state.breederCity = action.payload.breederCity;
      state.breederGovernorateCity = action.payload.breederGovernorateCity;
      state.cityId = action.payload.cityId;
      state.governorateId = action.payload.governorateId;
    },
    handleSubmitFilterResult: (state, action) => {
      state.headerType = action.payload.type;
      state.filterDataObj = action.payload.filterObj;
      state.isFiltered = action.payload.isFiltered;
      state.filterTotalCount = action.payload.totalCount;
      state.filterUrl = action.payload.filterUrl;
    },
  },
});
export const {
  setSelectedBreeders,
  setSelectedOneBreeders,
  handleSubmitFilterResult,
} = BreedersModalSlice.actions;

export default BreedersModalSlice.reducer;
