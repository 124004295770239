import "./EditHousingCycle.scss";
import { useEffect, useState } from "react";
import { setMainHeaderName } from "../../../../redux/Slices/MainHeaderSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { HenType } from "../../SubComponents/HenType";
import { BreedType } from "../../SubComponents/BreedType";
import { Companies } from "../../SubComponents/Companies";
import arrow from "../../../../Assets/Icons/arrow.jpeg";
import { useParams } from "react-router-dom";
import API from "../../../../Api";
import { toast } from "react-toastify";
import moment from "moment";
import { Loading } from "../../../Loading/Loading";
import { Formik, FormikHelpers } from "formik";
import DatePicker from "react-datepicker";
import { FeedType } from "../../SubComponents/FeedType";

interface HousingResponse {
  badi_price: any;
  feed_co_id: string;
  chick_price: any;
  company: {
    id: string;
    type: string;
  };
  creator: {
    id: string;
    name: string;
    mobile_number: string;
  };
  dajinBreed: {
    id: string;
    breed_type: string;
    dajin_id: string;
    dajinType: {
      id: string;
      image: string;
      type: string;
    };
  };
  expected_sale_date: string;
  farm_id: string;
  farmer_id: string;
  feedCompany: {
    id: string;
    name: string;
  };
  housing_date: string;
  id: string;
  nahi_price: any;
  nami_price: any;
  other_brooder_co: any;
  other_feed_co: any;
  suggested_traders: [];
  count: number;
  farm: {
    id: string;
    user: {
      id: string;
      incubator: number;
    };
  };
}
interface FarmResponse {
  id: string;
  user_id: string;
  name: string;
  area: number;
  housing_count: number;
  license_number: number;
  photo: string;
  gov_id: string;
  city_id: string;
  village: string;
  full_address: string;
  rented: number;
  latitude: number;
  longitude: number;
  dajin_breed_id: string;
  created_at: string;
  updated_at: string;
  created_by_role: number;
  incubator: number;
  creatorAdmin: {
    id: string;
    name: string;
    type: number;
    mobile_number: string;
  };
  user: {
    id: string;
    name: string;
    mobile_number: string;
  };
  dajinBreed: string;
  status: {
    id: string;
    status: string;
  };
  capacity: {
    id: string;
    capacity: string;
  };
  farmingType: {
    id: string;
    type: string;
  };
  ventilationType: {
    id: string;
    type: string;
  };
  governorate: {
    id: string;
    name: string;
  };
  city: {
    id: string;
    name: string;
  };
  creator: {
    id: string;
    name: string;
    mobile_number: string;
  };
  currentHousing: {
    id: string;
    other_brooder_co: string;
    other_feed_co: string;
    count: number;
    housing_date: string;
    expected_sale_date: string;
    company: {
      id: string;
      type: string;
    };
    feedCompany: {
      id: string;
      name: string;
    };
    dajinBreed: {
      id: string;
      dajin_id: string;
      breed_type: string;
      poultry_farming_average: number;
      poultry_farming_maximum: number;
      created_at: string;
      updated_at: string;
      dajinType: {
        id: string;
        type: string;
        trader_commission: number;
        farmer_commission: number;
        image: string;
        deleted_at: string;
        created_at: string;
        updated_at: string;
      };
    };
  };
}
export const EditHousingCycle = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const stateFromFarmsModalSlice = useSelector(
    (state: any) => state.FarmsModal
  );
  const stateFromFarmsSlice = useSelector((state: any) => state.farms);
  const { farmId } = useParams<{ farmId: string }>();
  const { housingId } = useParams<{ housingId: string }>();

  const [housingResponse, setHousingResponse] =
    useState<HousingResponse | null>(null);
  const [farmResponse, setFarmResponse] = useState<FarmResponse | null>(null);

  const [showTypePopUp, setShowTypePopUp] = useState(false);
  const [showDajinPopUp, setShowDajinPopUp] = useState(false);
  const [showBreedTypePopUp, setShowBreedTypePopUp] = useState(false);
  const [showCompanyPopUp, setShowCompanyPopUp] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [expectedDate, setExpectedDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [showFeedTypePopUp, setShowFeedTypePopUp] = useState(false);

  useEffect(() => {
    let mainHeaderName = "تعديل دورة التسكين";
    dispatch(setMainHeaderName({ mainHeaderName }));
    setIsLoading(true);
    API.get(`farms/${farmId}`).then((res) => {
      if (res.status === 200) {
        setFarmResponse(res.data);
        setIsLoading(false);
      }
    });
    API.get(`farms/${farmId}/housing/${housingId}`).then((res) => {
      setHousingResponse(res.data);
      setStartDate(
        res.data.housing_date != null
          ? new Date(res.data.housing_date)
          : new Date()
      );
      setExpectedDate(
        res.data.expected_sale_date != null
          ? new Date(res.data.expected_sale_date)
          : new Date()
      );

      setIsLoading(false);
    });
  }, []);

  const handleCompanyPopUp = () => {
    setShowCompanyPopUp(!showCompanyPopUp);
  };
  const handleShowFeedTypePopUp = () => {
    setShowFeedTypePopUp(!showFeedTypePopUp);
  };
  const handleShowDajinPopUp = () => setShowDajinPopUp(!showDajinPopUp);

  const handleBreedTypePopUp = () => {
    setShowBreedTypePopUp(!showBreedTypePopUp);
  };

  const handleChangeInputDate = (date: any) => {
    setStartDate(date);
  };

  const handleChangeExpectedDate = (date: any) => {
    setExpectedDate(date);
  };

  const handleChangeHousing = (
    value: string | number | boolean,
    field: string,
    setValues: FormikHelpers<any>["setValues"]
  ) => {
    // Update the Formik form state with the changed values

    setValues((prevValues: { housingResponse: HousingResponse }) => ({
      ...prevValues,
      housingResponse: {
        ...prevValues.housingResponse,
        [field]: value,
      },
    }));
    if (housingResponse) {
      // Create a new object with the updated field
      const updateHousingResponse: HousingResponse = {
        ...housingResponse,
        [field]: value, // Replace 'New Name' with the new value
      };

      // Update the state with the new object
      setHousingResponse(updateHousingResponse);
    }
  };
  const calculateDays = (startDate: any, housingDate: any): number => {
    // Convert both dates to milliseconds
    // Parse the date string into a Date object

    startDate = moment(startDate).locale("en").format("YYYY-MM-DD");

    const dateParts = startDate.split("-");
    const year = parseInt(dateParts[0]);
    const month = parseInt(dateParts[1]) - 1; // Months are 0-based in JavaScript
    const day = parseInt(dateParts[2]);
    const parsedDate = new Date(year, month, day);
    const startMilliseconds = parsedDate.getTime();
    const endMilliseconds = new Date(housingDate).getTime();

    // Calculate the difference in milliseconds
    const differenceMilliseconds = Math.abs(
      endMilliseconds - startMilliseconds
    );

    // Convert milliseconds to days
    const millisecondsInADay = 1000 * 60 * 60 * 24;
    const days = Math.floor(differenceMilliseconds / millisecondsInADay);

    return days;
  };
  const handleEditHousing = () => {
    setIsLoading(true);
    var dayRange = calculateDays(startDate, expectedDate);
    //لو العنبر مربي أعمار
    if (farmResponse?.incubator === 1) {
      if (dayRange <= 7 || dayRange >= 20) {
        toast.error(
          "لا يمكن تسكين عنبر لاكثر من 7 ايام ولا اقل من 20 يوم لعنبر مربي أعمار"
        );
        setIsLoading(false);

        return;
      }
    }
    //لو مختار فراخ حمرا او رومي
    else if (stateFromFarmsSlice.selectedHenType === 7) {
      if (dayRange > 90) {
        toast.error(" لا يمكن تسكين عنبر لاكثر من 90 يوم لهذا النوع من الداجن");
        setIsLoading(false);

        return;
      }
    } else {
      if (dayRange < 30 || dayRange > 40) {
        toast.error("لا يمكن تسكين عنبر لاكثر من 40 يوم ولا اقل من 30 يوم");
        setIsLoading(false);

        return;
      }
    }
    moment.locale("en");
    const data = {
      feed_co_id:
        stateFromFarmsSlice.selectedFeedType === 0
          ? Number(housingResponse?.feed_co_id)
          : Number(stateFromFarmsSlice.selectedFeedType),
      other_feed_co:
        Number(stateFromFarmsSlice.selectedFeedType) === 14 ||
        Number(housingResponse?.feed_co_id) === 14
          ? stateFromFarmsSlice.selectedFeedTypeName.length === 0 &&
            housingResponse?.other_feed_co == null
            ? null
            : stateFromFarmsSlice.selectedFeedTypeName.length === 0 &&
              housingResponse?.other_feed_co != null
            ? housingResponse?.other_feed_co
            : stateFromFarmsSlice.selectedFeedTypeName
          : null,
      breedId:
        stateFromFarmsSlice.selectedBreedType === 0
          ? Number(housingResponse?.dajinBreed.id)
          : Number(stateFromFarmsSlice.selectedBreedType),
      companyId:
        stateFromFarmsSlice.selectedCompanyId === 0
          ? Number(housingResponse?.company.id)
          : Number(stateFromFarmsSlice.selectedCompanyId),

      other_brooder_co:
        Number(stateFromFarmsSlice.selectedCompanyId) === 14 ||
        Number(housingResponse?.company.id) === 14
          ? stateFromFarmsSlice.selectedCompanyText.length === 0 &&
            housingResponse?.other_brooder_co == null
            ? null
            : stateFromFarmsSlice.selectedCompanyText.length === 0 &&
              housingResponse?.other_brooder_co != null
            ? housingResponse?.other_brooder_co
            : stateFromFarmsSlice.selectedCompanyText
          : null,
      count: Number(housingResponse?.count),
      housingDate: moment(startDate).format("YYYY-MM-DD"),
      expectedSaleDate: moment(expectedDate).format("YYYY-MM-DD"),
    };

    API.patch(`breed-housing/${housingResponse?.id}`, data)
      .then((res) => {
        setIsLoading(false);

        if (res.status === 200) {
          toast.success("تم تعديل دورة التسكين بنجاح");
          setIsLoading(false);
          navigate(`/AllFarms/${farmId}`);
        } else {
          toast.error(
            "حدث خطأ ما في تعديل دورة التسكين يرجي التواصل مع المسؤولين"
          );
          setIsLoading(false);
        }
      })
      .catch((error) => {
        toast.error(
          "حدث خطأ ما في تعديل دورة التسكين يرجي التواصل مع المسؤولين"
        );
        setIsLoading(false);
        toast.error(
          "حدث خطأ ما في تعديل دورة التسكين  يرجي التواصل مع المسؤولين"
        );
      });
  };

  const dajinTypeText = (
    <div className="select-mission-type bg-white w-full">
      {stateFromFarmsSlice.henTypeChanged === false
        ? housingResponse?.dajinBreed != null
          ? housingResponse?.dajinBreed.dajinType.type
          : "اختر نوع الداجن"
        : stateFromFarmsSlice.selectedHenType !== 0
        ? stateFromFarmsSlice.selectedHenTypeName
        : "اختر نوع الداجن"}
    </div>
  );

  const breederTypeText = (
    <div className="select-mission-type bg-white w-full">
      {stateFromFarmsSlice.breederTypeChanged === false
        ? housingResponse?.dajinBreed != null
          ? housingResponse?.dajinBreed.breed_type
          : "اختر نوع السلالة"
        : stateFromFarmsSlice.selectedBreedType !== 0
        ? stateFromFarmsSlice.selectedBreedTypeName
        : "اختر نوع السلالة"}
    </div>
  );

  const housingCompanyText = (
    <div className="select-mission-type bg-white w-full">
      {stateFromFarmsSlice.companyChanged === false
        ? housingResponse?.company != null
          ? Number(housingResponse?.company.id) === 14
            ? housingResponse?.other_brooder_co
            : housingResponse?.company.type
          : "اختر الشركة"
        : stateFromFarmsSlice.selectedCompanyId !== 0
        ? stateFromFarmsSlice.selectedCompanyText
        : "اختر الشركة"}
    </div>
  );

  const FeedTypeText = (
    <div className="select-mission-type bg-white w-full">
      {stateFromFarmsSlice.feedTypeChanged === false
        ? housingResponse?.feedCompany != null
          ? Number(housingResponse?.feedCompany.id) === 14
            ? housingResponse?.other_feed_co
            : housingResponse?.feedCompany.name
          : "اختر نوع العلف"
        : stateFromFarmsSlice.selectedFeedType !== 0
        ? stateFromFarmsSlice.selectedFeedTypeName
        : "اختر نوع العلف"}
    </div>
  );

  return (
    <>
      <Formik
        onSubmit={() => handleEditHousing()}
        initialValues={{ farmResponse: housingResponse || null }} // Handle null case
        validationSchema={null}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setValues,
        }) => (
          <div className="add-section mb-4 EditHousingCycle">
            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-2 pr-4 pl-4 gap-4">
                <div className="col-span-full mt-4 pr-4">
                  <h2 className="flex justify-start mission-details">
                    تفاصيل دورة التسكين
                  </h2>
                </div>
                <div className="col-span-full">
                  <div className="divider"></div>
                </div>
                {/* نوع الداجن */}
                <div className="col-start-1 pr-4">
                  <h3 className="mission-type"> نوع الداجن</h3>
                </div>
                {/* نوع السلالة */}
                <div className="col-start-2  pr-4">
                  <h3 className="mission-type"> نوع السلالة</h3>
                </div>
                {/* نوع الداجن */}
                <div className="col-start-1 pr-4">
                  <label htmlFor="modal-80">
                    <div
                      className="flex justify-between select-dajin-container"
                      onClick={() => handleShowDajinPopUp()}
                    >
                      {dajinTypeText}
                      <div className="arrow">
                        <img src={arrow} alt="arrow" />
                      </div>
                    </div>
                  </label>
                </div>
                {/* نوع السلالة input */}
                <div className="col-start-2 pr-4 pl-4">
                  <label htmlFor="modal-90">
                    <div
                      className="flex justify-between select-dajin-container"
                      onClick={() => handleBreedTypePopUp()}
                    >
                      {breederTypeText}
                      <div className="arrow">
                        <img src={arrow} alt="arrow" />
                      </div>
                    </div>
                  </label>
                </div>

                {/* نوع العلف  */}
                <div className="col-start-1 pr-4  mt-4">
                  <p className="mission-type">نوع العلف</p>
                </div>
                {/* الشركة  */}
                <div className="col-start-2 pr-4 mt-4">
                  <p className="mission-type">الشركة</p>
                </div>
                {/* نوع العلف  input */}
                <div
                  className="col-start-1 pr-4 "
                  onClick={() => handleShowFeedTypePopUp()}
                >
                  <label htmlFor="modal-feedType">
                    <div
                      className="flex justify-between select-dajin-container"
                      onClick={() => handleShowFeedTypePopUp()}
                    >
                      {FeedTypeText}
                      <div className="arrow">
                        <img src={arrow} alt="arrow" />
                      </div>
                    </div>
                  </label>
                </div>

                {/* الشركة input */}
                <div
                  className="col-start-2 pr-4 pl-4 "
                  onClick={() => handleCompanyPopUp()}
                >
                  <label htmlFor="modal-15">
                    <div
                      className="flex justify-between select-dajin-container"
                      onClick={() => handleCompanyPopUp()}
                    >
                      {housingCompanyText}
                      <div className="arrow">
                        <img src={arrow} alt="arrow" />
                      </div>
                    </div>
                  </label>
                </div>

                {/* العدد */}
                <div className="col-start-1 mt-4   pr-4">
                  <h3 className="mission-type"> العدد</h3>
                </div>

                {/* العدد input */}
                <div className="col-span-full lg:w-1/2 md:w-full sm:w-full xs:w-full pr-4">
                  <div>
                    <input
                      type="number"
                      placeholder="0000"
                      className="mission-address-input"
                      id="count"
                      name="count"
                      value={housingResponse?.count}
                      onChange={(e) =>
                        handleChangeHousing(e.target.value, "count", setValues)
                      }
                    />
                  </div>
                </div>

                {/* تاريخ الأدخال */}
                <div className="col-start-1 pr-4">
                  <h3 className="mission-type"> تاريخ الأدخال</h3>
                </div>
                {/* تاريخ البيع المتوقع */}
                <div className="col-start-2 pr-4">
                  <h3 className="mission-type"> تاريخ البيع المتوقع</h3>
                </div>
                {/* تاريخ الادخال input */}
                <div className="col-start-1 pr-4">
                  <DatePicker
                    selected={startDate}
                    onChange={(event) => handleChangeInputDate(event)}
                    className="date-picker-input"
                    maxDate={new Date()}
                  />
                </div>
                {/* تاريخ البيع المتوقع input */}
                <div className="col-start-2 pr-4">
                  <DatePicker
                    selected={expectedDate}
                    onChange={(event) => handleChangeExpectedDate(event)}
                    className="date-picker-input"
                    minDate={startDate}
                  />
                </div>
                {/* Actions */}

                <div className="col-start-1 pr-4">
                  <button className="add-btn wrap" type="submit">
                    تعديل دورة التسكين
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
      </Formik>
      {isLoading === true ? <Loading /> : null}
      {showDajinPopUp === true ? <HenType isEdit={false} /> : null}
      {showFeedTypePopUp === true ? <FeedType isEdit={false} /> : null}
      {showBreedTypePopUp === true ? (
        <BreedType
          isEdit={false}
          dajinId={Number(
            housingResponse?.dajinBreed === null
              ? "0"
              : housingResponse?.dajinBreed.dajin_id
          )}
        />
      ) : null}
      {showCompanyPopUp === true ? <Companies isEdit={false} /> : null}
    </>
  );
};
