import moment from "moment";
import "moment/locale/ar"; // Import the Arabic locale
import { ar, enUS } from "date-fns/locale";
import { format } from "date-fns";
import API from "../../../../Api";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { setMainHeaderName } from "../../../../redux/Slices/MainHeaderSlice";

interface ApiResponse {
  id: string;
  created_at: string;
  name: string;
  village: string;
  city_id: string;
  governorate_id: string;
  full_address: string;
  mobile_number: string;
  whatsapp_number: string;
  email: string;
  avatar: string;
  account_status: number;
  national_id: string;
  specialty_id: string;
  level_id: string;
  ewallet_number: string;
  wallet_balance: number;
  created_by_role: number;
  creatorAdmin: {
    id: string;
    name: string;
    type: number;
    mobile_number: string;
  };
  activity_type: {
    id: string;
    name: string;
  };
  farms_count: number;
  approx_breeding_size: number;
  creator: {
    id: string;
    name: string;
    mobile_number: string;
  };
  governorate: {
    created_at: string;
    updated_at: string;
    id: string;
    name: string;
  };
  city: {
    id: string;
    name: string;
  };
  home_breeding: 0;
  incubator: 0;
  pay_method: string | null;
}
export const AccountData = () => {
  const { breederId } = useParams<{ breederId: string }>();
  const [apiResponse, setApiResponse] = useState<ApiResponse | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [rangeText, setRangeText] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    moment.locale("ar");

    let mainHeaderName = "تفاصيل مربي";
    dispatch(setMainHeaderName({ mainHeaderName }));
    API.get(`breeders/${breederId}`).then((res) => {
      if (res) {
        const updatedFarmerData = {
          ...res.data,
          avatar: `${URL}${res.data.avatar}`,
        };
        // Set the locale to Arabic

        setApiResponse(updatedFarmerData);

        setIsLoading(false);
      }
    });
  }, []);
  return (
    <>
      {/* المعلومات الرئيسية */}
      <div className="grid grid-cols-2 account-data-section mt-4">
        <div className="col-start-1 mt-4 pr-4">
          <span className="main-info">المعلومات الرئيسية</span>
        </div>
        {/* Edit Icon */}
        <div className="col-start-2 mt-4 flex justify-end pl-4">
          <Link to={`/Breeders/${breederId}/Edit`}>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  d="M22 12.267V18.4006C22 20.6589 20.2091 22.4896 18 22.4896H6C3.79086 22.4896 2 20.6589 2 18.4006V6.13346C2 3.87515 3.79086 2.04443 6 2.04443H12M15.6864 4.1122C15.6864 4.1122 15.6864 5.57434 17.1167 7.03647C18.547 8.4986 19.9773 8.4986 19.9773 8.4986M9.15467 16.3454L12.1583 15.9067C12.5916 15.8435 12.9931 15.6382 13.3025 15.3219L21.4076 7.03646C22.1975 6.22895 22.1975 4.91971 21.4076 4.1122L19.9773 2.65007C19.1873 1.84256 17.9066 1.84256 17.1167 2.65007L9.01164 10.9355C8.70217 11.2518 8.50142 11.6623 8.43952 12.1052L8.01044 15.1757C7.91508 15.858 8.4872 16.4428 9.15467 16.3454Z"
                  stroke="#28303F"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>
            </div>
          </Link>
        </div>

        <div className="col-span-full">
          <div className="divider"></div>
        </div>

        {/* الاسم*/}
        <div className="col-start-1 mt-4 pr-4">
          <p className="title">الاسم</p>
        </div>

        {/*  العنوان*/}
        <div className="col-start-2 mt-4 flex justify-start">
          <p className="title">العنوان</p>
        </div>

        {/*  الاسم :input*/}
        <div className="col-start-1  pr-4">
          <span className="data">{apiResponse?.name}</span>
        </div>

        {/*  العنوان  :input*/}
        <div className="col-start-2 flex justify-start">
          <span className="data">
            {apiResponse?.governorate != null && apiResponse?.city != null
              ? `${apiResponse.governorate.name}-${apiResponse.city.name}`
              : ""}
          </span>
        </div>

        {/* البريد الألكتروني*/}
        <div className="col-start-1 mt-4 pr-4">
          <p className="title">البريد الألكتروني</p>
        </div>

        {/*  رقم الهاتف */}
        <div className="col-start-2 mt-4 flex justify-start">
          <p className="title">رقم الهاتف</p>
        </div>

        {/*  البريد الألكتروني :input*/}
        <div className="col-start-1  pr-4">
          <span className="data">{apiResponse?.email}</span>
        </div>

        {/*  رقم الهاتف  :input*/}
        <div className="col-start-2 flex justify-start">
          <span className="data">{apiResponse?.mobile_number}</span>
        </div>

        {/* رقم الواتساب*/}
        <div className="col-start-1 mt-4 pr-4">
          <p className="title">رقم الواتساب</p>
        </div>

        {/*  تمت الاضافة بواسطة */}
        <div className="col-start-2 mt-4 flex justify-start">
          <p className="title">تمت الاضافة بواسطة</p>
        </div>

        {/*  رقم الواتساب :input*/}
        <div className="col-start-1  pr-4">
          <span className="data">{apiResponse?.whatsapp_number}</span>
        </div>

        {/*  تمت الاضافة بواسطة  :input*/}
        <div className="col-start-2 flex justify-start">
          <span className="data cursor-pointer">
            {apiResponse?.created_by_role === 900 ? (
              <>
                {apiResponse?.creator != null ? (
                  <>
                    <Link
                      to={`/Users/Profile/PersonalData/${apiResponse?.creator.id}`}
                    >
                      {apiResponse?.creator != null
                        ? apiResponse?.creator.name
                        : ""}{" "}
                      - (مندوب)
                    </Link>
                  </>
                ) : (
                  ""
                )}
              </>
            ) : apiResponse?.created_by_role === 1000 ? (
              <>
                {apiResponse?.creatorAdmin != null ? (
                  <>
                    {/* <Link to={`/Users/Profile/${apiResponse?.creatorAdmin.id}`}> */}
                    {apiResponse?.creatorAdmin != null
                      ? apiResponse?.creatorAdmin.name
                      : ""}{" "}
                    - (ادمن)
                    {/* </Link> */}
                  </>
                ) : (
                  ""
                )}
              </>
            ) : null}
          </span>
        </div>
        {/* تربية منزلية ؟ */}
        <div className="col-start-1 mt-4 pr-4">
          <span className="title">تربية منزلية ؟</span>
        </div>

        {/* مربي أعمار*/}
        <div className="col-start-2 mt-4 flex justify-start">
          <span className="title">مربي أعمار ؟</span>
        </div>
        <div className="col-start-1 pr-4">
          <div className="mt-1">
            <input
              type="checkbox"
              className="switch switch-success"
              checked={apiResponse?.home_breeding === 0 ? false : true}
            />
          </div>
        </div>

        <div className="col-start-2 flex justify-start">
          <div className="mt-1">
            <input
              type="checkbox"
              className="switch switch-success"
              checked={apiResponse?.incubator === 0 ? false : true}
            />
          </div>
        </div>

        {apiResponse?.pay_method != null ? (
          <>
            <div className="col-start-1 flex gap-4 justify-start pr-4 mt-2">
              <div className="paymentMethod">
                <div>
                  <svg
                    width="22px"
                    height="22px"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        d="M12 17V17.5V18"
                        stroke="#70D44B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      ></path>{" "}
                      <path
                        d="M12 6V6.5V7"
                        stroke="#70D44B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      ></path>{" "}
                      <path
                        d="M15 9.5C15 8.11929 13.6569 7 12 7C10.3431 7 9 8.11929 9 9.5C9 10.8807 10.3431 12 12 12C13.6569 12 15 13.1193 15 14.5C15 15.8807 13.6569 17 12 17C10.3431 17 9 15.8807 9 14.5"
                        stroke="#70D44B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      ></path>{" "}
                      <path
                        d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7"
                        stroke="#70D44B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      ></path>{" "}
                    </g>
                  </svg>
                </div>
                <span>
                  {" "}
                  {apiResponse?.pay_method === "cash" ? "كاش" : "إئتمان"}{" "}
                </span>
              </div>
            </div>
          </>
        ) : null}
      </div>
      {/* معلومات النشاط */}
      <div className="grid grid-cols-2 activity-information-section mt-4">
        <div className="col-start-1 mt-4 pr-4">
          <span className="main-info">معلومات النشاط</span>
        </div>
        <div className="col-span-full">
          <div className="divider"></div>
        </div>

        {/* توع النشاط*/}
        <div className="col-start-1 mt-4 pr-4">
          <p className="title">نوع النشاط</p>
        </div>

        {/*  حجم التربية التقريبي*/}
        <div className="col-start-2 mt-4 flex justify-start">
          <p className="title">حجم التربية التقريبي</p>
        </div>

        {/*  توع النشاط :input*/}
        <div className="col-start-1  pr-4">
          {apiResponse?.activity_type != null ? (
            <>
              <span className="data">{apiResponse?.activity_type.name}</span>
            </>
          ) : null}
        </div>

        {/*  حجم التربية التقريبي  :input*/}
        <div className="col-start-2 flex justify-start">
          <span className="data">{apiResponse?.approx_breeding_size}</span>
        </div>

        {/* إجمالي عنابر التربية*/}
        <div className="col-start-1 mt-4 pr-4">
          <p className="title">إجمالي عنابر التربية</p>
        </div>
        {/*  إجمالي عنابر التربية :input*/}
        <div className="col-start-1  pr-4">
          <span className="data">{apiResponse?.farms_count}</span>
        </div>
      </div>

      {/* التفعيل */}
      <div className="grid grid-cols-2 activation-section mt-4">
        <div className="col-start-1 mt-4 pr-4">
          <span className="main-info">معلومات اضافية</span>
        </div>
        <div className="col-span-full">
          <div className="divider"></div>
        </div>

        {/* الاهتمامات*/}
        <div className="col-start-1 mt-4 pr-4">
          <p className="title">الاهتمامات</p>
        </div>

        {/*  الاهتمامات :input*/}
        <div className="col-start-1 mt-1 pr-4">
          <div className="flex gap-4">
            <div className="active-interest">إشراف على مدار الساعة</div>
            <div className="active-interest">خدمات الأمن الحيوي</div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2 additional-information-section mt-4">
        <div className="col-start-1 mt-4 pr-4">
          <span className="main-info">التفعيل</span>
        </div>
        <div className="col-span-full">
          <div className="divider"></div>
        </div>

        {/*  التفعيل :input*/}
        <div className="col-start-1 mt-1 pr-4">
          <div className="flex gap-4">
            <div className="flex gap-4 items-center">
              <input
                type="radio"
                className="radio-primary radio"
                checked={apiResponse?.account_status === 2 ? true : false}
              />
              <span className="active-status">مفعل</span>
            </div>

            <div className="flex gap-4 items-center">
              <input
                type="radio"
                className="radio-primary radio"
                checked={apiResponse?.account_status === 1 ? true : false}
              />
              <span className="active-status">غير مفعل</span>
            </div>

            <div className="flex gap-4 items-center">
              <input
                type="radio"
                className="radio-primary radio"
                checked={apiResponse?.account_status === 3 ? true : false}
              />
              <span className="active-status">موقوف</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
