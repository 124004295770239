import "../EditDetailsMission/EditDetailsMission.scss";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Loading } from "../../../../Loading/Loading";
import { toast } from "react-toastify";
import API, { URL } from "../../../../../Api";
import { Formik, FormikHelpers } from "formik";
import { Cities } from "../../../../Farms/SubComponents/Cities";
import { Governorates } from "../../../../Farms/SubComponents/Governorates";
import arrow from "../../../../../Assets/Icons/arrow.jpeg";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { FarmsModal } from "../../../../../SubComponents/FarmsModal/FarmsModal";
import { HenType } from "../../../../Farms/SubComponents/HenType";
import { BreedType } from "../../../../Farms/SubComponents/BreedType";
import { VentilationType } from "../../../../Farms/SubComponents/VentilationType";
import { Facilities } from "../../../../Farms/SubComponents/Facilities";
import { FarmingTypes } from "../../../../Farms/SubComponents/FarmingTypes";
import axios from "axios";
import { setSelectedWorkAreas } from "../../../../../redux/Slices/MissionSlice";

type EditFarmMissionProps = {
  farmId: number;
  breederId: number;
};

interface FarmResponse {
  id: string;
  user_id: string;
  name: string;
  area: number;
  license_number: number;
  photo: string;
  gov_id: string;
  city_id: string;
  village: string;
  full_address: string;
  rented: number;
  latitude: number;
  longitude: number;
  dajin_breed_id: string;
  created_at: string;
  updated_at: string;
  detailedDescription: string;
  ventilation_type_id: string;
  user: {
    id: string;
    name: string;
    mobile_number: string;
  };
  dajinBreed: string;
  status: {
    id: string;
    status: string;
  };
  capacity: {
    id: string;
    capacity: string;
  };
  farmingType: {
    id: string;
    type: string;
  };
  ventilationType: {
    id: string;
    type: string;
  };
  governorate: {
    id: string;
    name: string;
  };
  city: {
    id: string;
    name: string;
  };
  creator: {
    id: string;
    name: string;
    mobile_number: string;
  };
}
export const EditFarmMission = (props: EditFarmMissionProps) => {
  const dispatch = useDispatch();

  const stateFromFarmsModalSlice = useSelector(
    (state: any) => state.FarmsModal
  );
  const stateFromFarmsSlice = useSelector((state: any) => state.farms);
  const stateFromBreedersModalSlice = useSelector(
    (state: any) => state.breedersModal
  );

  const { MissionId } = useParams<{ MissionId: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [showFarmsPopUp, setShowFarmsPopUp] = useState(false);
  const [showTypePopUp, setShowTypePopUp] = useState(false);
  const [showBreedTypePopUp, setShowBreedTypePopUp] = useState(false);
  const [showVentilationTypePopUp, setShowVentilationTypePopUp] =
    useState(false);
  const [showFacilitiesPopUp, setShowFacilitiesPopUp] = useState(false);
  const [showGovernoratePopUp, setShowGovernoratePopUp] = useState(false);
  const [showCityPopUp, setShowCityPopUp] = useState(false);
  const [ownerShipId, setOwnerShipId] = useState(0);
  const [ownerShiptTitle, setOwnerShipTitle] = useState("اختر");
  const [licenseId, setLicenseId] = useState(0);
  const [farmCapacityId, setFarmCapacityId] = useState(0);
  const [farmCapacityText, setFarmCapacityText] = useState("اختر سعة العنبر");
  const [licenseTitle, setLicenseTitle] = useState("اختر الترخيص");
  const [farmResponse, setFarmResponse] = useState<FarmResponse | null>(null);
  const [showFarmingTypePopUp, setShowFarmingTypePopUp] = useState(false);

  const [farmId, setFarmId] = useState(0);

  useEffect(() => {
    setIsLoading(true);
    let farm =
      stateFromFarmsModalSlice.farmsModalChanged === true
        ? stateFromFarmsModalSlice.farmId
        : props.farmId;
    if (farm !== 0 && !isNaN(farm)) {
      setFarmId(Number(farm));
      API.get(`farms/${farm}`).then((res) => {
        if (res) {
          setFarmResponse(res.data);
          if (res.data.farms_capacity_id !== null) {
            if (Number(res.data.farms_capacity_id) === 1) {
              setFarmCapacityText("اقل من 1000");
              setFarmCapacityId(Number(res.data.farms_capacity_id));

              setIsLoading(false);
            } else if (Number(res.data.farms_capacity_id) === 2) {
              setFarmCapacityText("اققل من 5000");
              setFarmCapacityId(Number(res.data.farms_capacity_id));

              setIsLoading(false);
            } else if (Number(res.data.farms_capacity_id) === 3) {
              setFarmCapacityText("اققل من 10000");
              setFarmCapacityId(Number(res.data.farms_capacity_id));
              setIsLoading(false);
            } else if (Number(res.data.farms_capacity_id) === 4) {
              setFarmCapacityText("اققل من 25000");
              setFarmCapacityId(Number(res.data.farms_capacity_id));
              setIsLoading(false);
            } else if (Number(res.data.farms_capacity_id) === 5) {
              setFarmCapacityText("اققل من 50000");
              setFarmCapacityId(Number(res.data.farms_capacity_id));
              setIsLoading(false);
            } else if (Number(res.data.farms_capacity_id) === 6) {
              setFarmCapacityText("اققل من 100000");
              setFarmCapacityId(Number(res.data.farms_capacity_id));
              setIsLoading(false);
            } else if (Number(res.data.farms_capacity_id) === 7) {
              setFarmCapacityText("اققل من 200000");
              setFarmCapacityId(Number(res.data.farms_capacity_id));
              setIsLoading(false);
            } else if (Number(res.data.farms_capacity_id) === 8) {
              setFarmCapacityText("اققل من 500000");
              setFarmCapacityId(Number(res.data.farms_capacity_id));
              setIsLoading(false);
            } else {
              setFarmCapacityText("اختر سعة العنبر");
            }
          }
          setIsLoading(false);
        }
      });
    } else {
      setIsLoading(false);
    }
  }, [stateFromFarmsModalSlice.farmId]);

  const handleShowFarmsPopUp = () => setShowFarmsPopUp(!showFarmsPopUp);

  const handleGovernoratePopUp = () => {
    setShowGovernoratePopUp(!showGovernoratePopUp);
  };

  const handleChangeFarmCapacity = (id: number) => {
    setIsLoading(true);
    setFarmCapacityId(id);
    if (id === 1) {
      setFarmCapacityText("اقل من 1000");
      setIsLoading(false);
    } else if (id === 2) {
      setFarmCapacityText("اققل من 5000");
      setIsLoading(false);
    } else if (id === 3) {
      setFarmCapacityText("اققل من 10000");
      setIsLoading(false);
    } else if (id === 4) {
      setFarmCapacityText("اققل من 25000");
      setIsLoading(false);
    } else if (id === 5) {
      setFarmCapacityText("اققل من 50000");
      setIsLoading(false);
    } else if (id === 6) {
      setFarmCapacityText("اققل من 100000");
      setIsLoading(false);
    } else if (id === 7) {
      setFarmCapacityText("اققل من 200000");
      setIsLoading(false);
    } else if (id === 8) {
      setFarmCapacityText("اققل من 500000");
      setIsLoading(false);
    } else {
      setFarmCapacityText("اختر سعة العنبر");
    }
  };

  const handleVentilationTypePopUp = () => {
    setShowVentilationTypePopUp(!showVentilationTypePopUp);
  };

  const handleCityPopUp = () => setShowCityPopUp(!showCityPopUp);

  const handleFarmingTypePopUp = () => {
    setShowFarmingTypePopUp(!showFarmingTypePopUp);
  };
  const handleChangeFarm = (
    value: string | number | boolean,
    field: string,
    setValues: FormikHelpers<any>["setValues"]
  ) => {
    // Update the Formik form state with the changed values

    setValues((prevValues: { farmResponse: FarmResponse }) => ({
      ...prevValues,
      farmResponse: {
        ...prevValues.farmResponse,
        [field]: value,
      },
    }));
    if (farmResponse) {
      // Create a new object with the updated field
      const updatedFarmResponse: FarmResponse = {
        ...farmResponse,
        [field]: value, // Replace 'New Name' with the new value
      };

      // Update the state with the new object
      setFarmResponse(updatedFarmResponse);
    }
  };

  const handleEditFarm = () => {
    setIsLoading(true);
    let rangeIds: number[] = [];

    moment.locale("en");
    const data = {
      name: farmResponse?.name,
      farmArea: farmResponse?.area,
      cityId:
        stateFromFarmsSlice.cityChanged === true
          ? stateFromFarmsSlice.selectedCityId
          : farmResponse?.city != null
          ? farmResponse.city_id
          : null,
      village: farmResponse?.village,
      detailedDescription: farmResponse?.detailedDescription,
      farmTypeId:
        stateFromFarmsSlice.farmingTypeChanged === true
          ? stateFromFarmsSlice.selectedFarmingTypeId
          : farmResponse?.farmingType != null
          ? farmResponse.farmingType.id
          : null,
      farmCapacityId: farmCapacityId,
      ventilationTypeId:
        stateFromFarmsSlice.ventilationChanged === true
          ? stateFromFarmsSlice.selectedVentilationType
          : farmResponse?.ventilationType != null
          ? farmResponse.ventilationType.id
          : null,
    };

    axios
      .patch(`${URL}farms/${farmId}`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          let values = {
            farm_id: Number(farmId),
          };
          API.patch(`dashboard/missions/${MissionId}`, values).then((res) => {
            if (res.status === 200) {
              toast.success("تمت تعديل العنبر بنجاح");
              /* To Update Mission Work Range immediatly */
              rangeIds.push(data.cityId);

              let workAreaChanged = rangeIds.length > 0 ? true : false;

              let rangeTitle =
                stateFromFarmsSlice.cityChanged === false
                  ? farmResponse?.city != null
                    ? farmResponse?.city.name
                    : "قم بأختيار نطاق المهمة"
                  : stateFromFarmsSlice.selectedCityId !== 0
                  ? stateFromFarmsSlice.selectedCityName
                  : "قم بأختيار نطاق المهمة";
              dispatch(
                setSelectedWorkAreas({ rangeIds, rangeTitle, workAreaChanged })
              );
              setIsLoading(false);
            } else {
              toast.error("حدث خطأ ما يرجي التواصل  مع المسؤولين");
              setIsLoading(false);
            }
          });
        } else {
          toast.error("حدث خطأ ما يرجي التواصل  مع المسؤولين");
          setIsLoading(false);
        }
      });
  };

  const farmText = (
    <div className="select-stage pr-4">
      {stateFromFarmsModalSlice.farmsModalChanged === false
        ? farmResponse?.name
        : stateFromFarmsModalSlice.farmId !== 0
        ? stateFromFarmsModalSlice.farmName
        : "قم بأختيار المزرعة"}
    </div>
  );

  const farmingTypeText =
    stateFromFarmsSlice.farmingTypeChanged === false &&
    farmResponse?.farmingType != null
      ? farmResponse?.farmingType.type
      : stateFromFarmsSlice.selectedFarmingTypeId !== 0
      ? stateFromFarmsSlice.selectedFarmingTypeName
      : "قم بأختيار نوع التربية";

  const ventilationText =
    stateFromFarmsSlice.ventilationChanged === false &&
    farmResponse?.ventilationType != null
      ? farmResponse.ventilationType.type
      : stateFromFarmsSlice.selectedVentilationType !== 0
      ? stateFromFarmsSlice.selectedVentilationName
      : "قم بأختيار  نوع التهوئة";

  const governorateText = (
    <div className="select-mission-type bg-white w-full">
      {stateFromFarmsSlice.governorateChanged === false
        ? farmResponse?.governorate != null
          ? farmResponse?.governorate.name
          : "اختر المحافظة"
        : stateFromFarmsSlice.governorateChanged !== 0 &&
          stateFromFarmsSlice.governorateModule === 2
        ? stateFromFarmsSlice.selectedGovernorateName
        : "اختر المحافظة"}
    </div>
  );

  const farmCityText = (
    <div className="select-stage pr-4">
      {stateFromFarmsSlice.cityChanged === false
        ? farmResponse?.city != null
          ? farmResponse?.city.name
          : "اختر المدينة"
        : stateFromFarmsSlice.selectedCityId !== 0 &&
          stateFromFarmsSlice.cityModule === 2
        ? stateFromFarmsSlice.selectedCityName
        : "اختر المدينة"}
    </div>
  );

  return (
    <div className="EditDetailsMission">
      <Formik
        onSubmit={() => handleEditFarm()}
        initialValues={{ farmResponse: farmResponse || null }} // Handle null case
        validationSchema={null}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setValues,
        }) => (
          <div className="add-section mb-4">
            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-2 gap-4">
                <div className="col-span-full mt-4 pr-4">
                  <h2 className="flex justify-start mission-details w-1/2">
                    تفاصيل العنبر
                  </h2>
                </div>
                <div className="col-span-full">
                  <div className="divider"></div>
                </div>

                <>
                  <div className="col-start-1  pr-4">
                    <h3 className="mission-type"> العنبر </h3>
                  </div>
                  {/* مساحة العنبر */}
                  <div className="col-start-2  pr-4">
                    <h3 className="mission-type"> مساحة العنبر</h3>
                  </div>
                  {/* العنبر input */}
                  <div className="col-start-1 pr-4">
                    <label htmlFor="modal-111">
                      <div
                        className="w-full mission-address-input flex justify-between pl-4 items-center"
                        onClick={() => handleShowFarmsPopUp()}
                      >
                        {farmText}
                        <div className="arrow">
                          <img src={arrow} alt="arrow" />
                        </div>
                      </div>
                    </label>
                  </div>

                  {/* مساحة العنبر input */}
                  <div className="col-start-2 pl-4 pr-4">
                    <input
                      className="w-full mission-address-input flex items-center"
                      value={farmResponse?.area}
                      onChange={(e) =>
                        handleChangeFarm(e.target.value, "area", setValues)
                      }
                    />
                  </div>

                  {/* نوع التربية  */}
                  <div className="col-start-1 pr-4 ">
                    <h3 className="mission-type"> نوع التربية </h3>
                  </div>
                  {/*  نوع التهوئة*/}
                  <div className="col-start-2 pr-4 ">
                    <p className="mission-type">نوع التهوئة</p>
                  </div>

                  {/* نوع التربية input */}
                  <div
                    className="col-start-1 pr-4 "
                    onClick={() => handleFarmingTypePopUp()}
                  >
                    <label htmlFor="modal-14">
                      <div className="w-full mission-address-input flex justify-between pl-4 items-center">
                        <input
                          className="select-mission-type pr-4"
                          value={farmingTypeText}
                          disabled
                          id="farmTypeId"
                          name="farmTypeId"
                        />

                        <div className="arrow">
                          <img src={arrow} alt="arrow" />
                        </div>
                      </div>
                    </label>
                  </div>

                  <>
                    {/* نوع التهوئة  input   */}
                    <div
                      className="col-start-2 pr-l "
                      onClick={() => handleVentilationTypePopUp()}
                    >
                      <label htmlFor="modal-10">
                        <div className="w-full mission-address-input flex justify-between pl-4 items-center">
                          <input
                            className="select-mission-type pr-4"
                            value={ventilationText}
                            disabled
                            id="ventilationTypeId"
                            name="ventilationTypeId"
                          />
                          <div className="arrow">
                            <img src={arrow} alt="arrow" />
                          </div>
                        </div>
                      </label>
                    </div>
                  </>
                  {/* سعة العنبر */}
                  <div className="col-start-1 pr-4">
                    <h3 className="mission-type"> سعة العنبر </h3>
                  </div>
                  {/*  الوصف التفصيلي */}
                  <div className="col-start-2 pl-4">
                    <p className="mission-type">الوصف التفصيلي</p>
                  </div>
                  {/* سعة العنبر input */}
                  <div className="col-start-1 pr-4 ">
                    <div className="dropdown-container">
                      <div className="dropdown w-full">
                        <label tabIndex={0}>
                          <div className="flex justify-between items-center pl-4 mission-address-input">
                            <input
                              className="select-mission-type pr-4 w-full"
                              id="farmCapacityId"
                              name="farmCapacityId"
                              value={farmCapacityText}
                            />

                            <div className="arrow">
                              <img src={arrow} alt="arrow" />
                            </div>
                          </div>
                        </label>
                        <div className="dropdown-menu w-full bg-white">
                          <a
                            className="dropdown-item text-sm text-right"
                            onClick={() => handleChangeFarmCapacity(1)}
                          >
                            اققل من 1000
                          </a>
                          <a
                            className="dropdown-item text-sm text-right"
                            onClick={() => handleChangeFarmCapacity(2)}
                          >
                            اققل من5000
                          </a>

                          <a
                            className="dropdown-item text-sm text-right"
                            onClick={() => handleChangeFarmCapacity(3)}
                          >
                            اققل من 10000
                          </a>

                          <a
                            className="dropdown-item text-sm text-right"
                            onClick={() => handleChangeFarmCapacity(4)}
                          >
                            اققل من 25000
                          </a>

                          <a
                            className="dropdown-item text-sm text-right"
                            onClick={() => handleChangeFarmCapacity(5)}
                          >
                            اققل من 50000
                          </a>

                          <a
                            className="dropdown-item text-sm text-right"
                            onClick={() => handleChangeFarmCapacity(6)}
                          >
                            اققل من 100000
                          </a>
                          <a
                            className="dropdown-item text-sm text-right"
                            onClick={() => handleChangeFarmCapacity(7)}
                          >
                            اققل من 200000
                          </a>

                          <a
                            className="dropdown-item text-sm text-right"
                            onClick={() => handleChangeFarmCapacity(8)}
                          >
                            اققل من 500000
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*  الوصف التفصيلي :input */}
                  <div className="col-start-2 pr-4 ">
                    <textarea
                      placeholder="الوصف التفصيلي"
                      className="mission-address-input"
                      id="detailedDescription"
                      name="detailedDescription"
                      onChange={(e) =>
                        handleChangeFarm(
                          e.target.value,
                          "detailedDescription",
                          setValues
                        )
                      }
                      value={farmResponse?.detailedDescription}
                    />
                  </div>

                  {/*  المحافظة */}
                  <div className="col-start-1 pr-4 ">
                    <p className="mission-type">المحافظة</p>
                  </div>

                  {/*  المدينة */}
                  <div className="col-start-2 pr-4 ">
                    <p className="mission-type">المدينة</p>
                  </div>

                  {/* المحافظة  input   */}
                  <div className="col-start-1 pr-4 ">
                    <label htmlFor="modal-12">
                      <div
                        className="w-full mission-address-input flex justify-between pl-4 items-center"
                        onClick={() => handleGovernoratePopUp()}
                      >
                        {governorateText}

                        <div className="arrow">
                          <img src={arrow} alt="arrow" />
                        </div>
                      </div>
                    </label>
                  </div>
                  {/*  المدينة :input */}
                  <div className="col-start-2 pr-4 ">
                    <label htmlFor="modal-13">
                      <div
                        className="flex justify-between select-mission-container"
                        onClick={() => handleCityPopUp()}
                      >
                        {farmCityText}

                        <div className="arrow">
                          <img src={arrow} alt="arrow" />
                        </div>
                      </div>
                    </label>
                  </div>

                  {/*  حي / قرية */}
                  <div className="col-start-1 pr-4">
                    <p className="mission-type">حي / قرية</p>
                  </div>

                  {/* حي / قرية  input   */}
                  <div className="col-start-1 pr-4 ">
                    <input
                      type="text"
                      placeholder="القرية"
                      className="mission-address-input"
                      id="village"
                      name="village"
                      value={farmResponse?.village}
                      onChange={(e) =>
                        handleChangeFarm(e.target.value, "village", setValues)
                      }
                    />
                  </div>

                  {/* Actions */}
                  <div className="col-start-1 pr-4">
                    <button className="add-btn" type="submit">
                      تعديل العنبر
                    </button>
                  </div>
                </>
              </div>
            </form>
          </div>
        )}
      </Formik>
      {showTypePopUp === true ? <HenType isEdit={false} /> : null}
      {showBreedTypePopUp === true ? <BreedType isEdit={false} /> : null}
      {showVentilationTypePopUp === true ? (
        <VentilationType isEdit={false} />
      ) : null}
      {showFacilitiesPopUp === true ? <Facilities isEdit={false} /> : null}
      {showGovernoratePopUp === true ? (
        <Governorates isEdit={false} module={2} />
      ) : null}
      {showCityPopUp === true ? <Cities isEdit={false} module={2} /> : null}
      {showFarmingTypePopUp === true ? <FarmingTypes isEdit={false} /> : null}

      {showFarmsPopUp === true ? (
        <FarmsModal
          MultiSelect={false}
          url={`breeders/${
            stateFromBreedersModalSlice.breedersModalChanged === true
              ? stateFromBreedersModalSlice.breederId
              : props.breederId
          }/farms`}
        />
      ) : null}
      {isLoading === true ? (
        <>
          <Loading />
        </>
      ) : null}
    </div>
  );
};
