import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  selectedMarketNewsHeader: 1,
  displayTypeId: 1,
  selectedCategoryId: 0,
  selectedCategoryName: "",
  categoryChanged: false,
};
export const MarketNewsSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setSelectedMarketNewsHeader: (state, action) => {
      state.selectedMarketNewsHeader = action.payload.type;
    },
    setSelectedMarketNewsDisplayType: (state, action) => {
      state.displayTypeId = action.payload.displayTypeId;
    },
    setSelectedCategory: (state, action) => {
      state.selectedCategoryId = action.payload.CategoryId;
      state.selectedCategoryName = action.payload.CategoryName;
      state.categoryChanged = action.payload.isChanged;
    },
  },
});
export const {
  setSelectedMarketNewsHeader,
  setSelectedMarketNewsDisplayType,
  setSelectedCategory,
} = MarketNewsSlice.actions;

export default MarketNewsSlice.reducer;
