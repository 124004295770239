import "../AllSuppliersDetails.scss";
import moment from "moment";
import "moment/locale/ar"; // Import the Arabic locale
import { ar, enUS } from "date-fns/locale";
import { format } from "date-fns";
// import API from "../../../../Api";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { setMainHeaderName } from "../../../../../redux/Slices/MainHeaderSlice";

interface ApiResponse {
  id: string;
  created_at: string;
  name: string;
  village: string;
  city_id: string;
  governorate_id: string;
  full_address: string;
  mobile_number: string;
  whatsapp_number: string;
  email: string;
  avatar: string;
  account_status: number;
  national_id: string;
  specialty_id: string;
  level_id: string;
  ewallet_number: string;
  wallet_balance: number;
  activity_type: {
    id: string;
    name: string;
  };
  farms_count: number;
  approx_breeding_size: number;
  creator: {
    id: string;
    name: string;
    mobile_number: string;
  };
  governorate: {
    created_at: string;
    updated_at: string;
    id: string;
    name: string;
  };
  city: {
    id: string;
    name: string;
  };
  home_breeding: 0;
  incubator: 0;
}
export const SupplierAccountData = () => {
  const { supplierId } = useParams<{ supplierId: string }>();
  const [apiResponse, setApiResponse] = useState<ApiResponse | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [rangeText, setRangeText] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    moment.locale("ar");
    let mainHeaderName = "تفاصيل مورد";
    dispatch(setMainHeaderName({ mainHeaderName }));
    // API.get(`breeders/${breederId}`).then((res) => {
    //   if (res) {
    //     const updatedFarmerData = {
    //       ...res.data,
    //       avatar: `${URL}${res.data.avatar}`,
    //     };
    //     // Set the locale to Arabic

    //     setApiResponse(updatedFarmerData);

    //     setIsLoading(false);
    //   }
    // });
  }, []);

  return (
    <>
      {/* المعلومات الرئيسية */}
      <div className="grid grid-cols-2 account-data-section mt-4">
        <div className="col-start-1 mt-4 pr-4">
          <span className="main-info">المعلومات الرئيسية</span>
        </div>
        {/* Edit Icon */}
        <div className="col-start-2 mt-4 flex justify-end pl-4">
          <div onClick={() => navigate("Edit")}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
            >
              <path
                d="M22 12.267V18.4006C22 20.6589 20.2091 22.4896 18 22.4896H6C3.79086 22.4896 2 20.6589 2 18.4006V6.13346C2 3.87515 3.79086 2.04443 6 2.04443H12M15.6864 4.1122C15.6864 4.1122 15.6864 5.57434 17.1167 7.03647C18.547 8.4986 19.9773 8.4986 19.9773 8.4986M9.15467 16.3454L12.1583 15.9067C12.5916 15.8435 12.9931 15.6382 13.3025 15.3219L21.4076 7.03646C22.1975 6.22895 22.1975 4.91971 21.4076 4.1122L19.9773 2.65007C19.1873 1.84256 17.9066 1.84256 17.1167 2.65007L9.01164 10.9355C8.70217 11.2518 8.50142 11.6623 8.43952 12.1052L8.01044 15.1757C7.91508 15.858 8.4872 16.4428 9.15467 16.3454Z"
                stroke="#28303F"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </svg>
          </div>
        </div>

        <div className="col-span-full">
          <div className="divider"></div>
        </div>

        {/* اسم الشركة انجليزي*/}
        <div className="col-start-1 mt-4 pr-4">
          <p className="title">اسم الشركة انجليزي</p>
        </div>

        {/*  اسم الشركة عربي*/}
        <div className="col-start-2 mt-4 flex justify-start">
          <p className="title">اسم الشركة عربي</p>
        </div>

        {/*  اسم الشركة انجليزي :input*/}
        <div className="col-start-1  pr-4">
          <span className="data">{apiResponse?.name}</span>
        </div>

        {/*  اسم الشركة عربي  :input*/}
        <div className="col-start-2 flex justify-start">
          <span className="data"></span>
        </div>

        {/* المحافظة*/}
        <div className="col-start-1 mt-4 pr-4">
          <p className="title">المحافظة</p>
        </div>

        {/*  المركز / القرية */}
        <div className="col-start-2 mt-4 flex justify-start">
          <p className="title">المركز / القرية/المنطقة</p>
        </div>

        {/*  المحافظة :input*/}
        <div className="col-start-1  pr-4">
          <span className="data"></span>
        </div>

        {/*  المركز / القرية*/}
        <div className="col-start-2 flex justify-start">
          <span className="data"></span>
        </div>

        {/* العنوان*/}
        <div className="col-start-1 mt-4 pr-4">
          <p className="title">العنوان</p>
        </div>

        {/*  التصنيف */}
        <div className="col-start-2 mt-4 flex justify-start">
          <p className="title">التصنيف</p>
        </div>

        {/*  العنوان :input*/}
        <div className="col-start-1  pr-4">
          <span className="data"></span>
        </div>

        {/*  التصنيف  :input*/}
        <div className="col-start-2 flex justify-start">
          <span className="data"></span>
        </div>

        {/* المنتجات*/}
        <div className="col-start-1 mt-4 pr-4">
          <p className="title">المنتجات</p>
        </div>

        {/*  البريد الالكتروني */}
        <div className="col-start-2 mt-4 flex justify-start">
          <p className="title">البريد الألكتروني</p>
        </div>

        {/*  المنتجات :input*/}
        <div className="col-start-1  pr-4">
          <span className="data"> </span>
        </div>

        {/*  البريد الالكتروني  :input*/}
        <div className="col-start-2 flex justify-start">
          <span className="data"></span>
        </div>

        {/* الموقع*/}
        <div className="col-start-1 mt-4 pr-4">
          <p className="title">الموقع</p>
        </div>
        {/*  الموقع :input*/}
        <div className="col-start-1  pr-4">
          <span className="data"> </span>
        </div>
      </div>

      {/* المسؤولين */}
      <div className="grid grid-cols-2 activity-information-section mt-4">
        <div className="col-start-1 mt-4 pr-4">
          <span className="main-info">المسؤولين</span>
        </div>
        <div className="col-span-full">
          <div className="divider"></div>
        </div>

        {/* مسؤول 1*/}
        <div className="col-start-1 mt-4 pr-4">
          <p className="title">مسؤول 1</p>
        </div>

        {/*  وظيفة مسؤول 1*/}
        <div className="col-start-2 mt-4 flex justify-start">
          <p className="title">وظيفة مسؤول 1</p>
        </div>

        {/*  مسؤول 1 :input*/}
        <div className="col-start-1  pr-4">
          <span className="data"></span>
        </div>

        {/*  وظيفة مسؤول 1  :input*/}
        <div className="col-start-2 flex justify-start">
          <span className="data"></span>
        </div>

        {/* تليفون 1*/}
        <div className="col-start-1 mt-4 pr-4">
          <p className="title">تليفون 1</p>
        </div>

        {/* مسؤول 2*/}
        <div className="col-start-2 mt-4 flex justify-start">
          <p className="title">مسؤول 2</p>
        </div>
        {/*  تليفون 1 :input*/}
        <div className="col-start-1  pr-4">
          <span className="data"> </span>
        </div>

        {/*  مسؤول 2  :input*/}
        <div className="col-start-2  mt-4 flex justify-start">
          <span className="data"></span>
        </div>

        {/* وظيفة مسؤول 2 */}
        <div className="col-start-1 mt-4 pr-4">
          <p className="title">وظيفة مسؤول 2</p>
        </div>

        {/*  تليفون 2*/}
        <div className="col-start-2 mt-4 flex justify-start">
          <p className="title">تليفون 2</p>
        </div>

        {/*  وظيفة مسؤول 2 :input*/}
        <div className="col-start-1  pr-4">
          <span className="data"></span>
        </div>

        {/*  تليفون 2  :input*/}
        <div className="col-start-2 flex justify-start">
          <span className="data"></span>
        </div>
      </div>

      {/* حالة الحساب */}
      <div className="grid grid-cols-2 activity-information-section mt-4">
        <div className="col-start-1 mt-4 pr-4">
          <span className="main-info">حالة الحساب</span>
        </div>
        <div className="col-span-full">
          <div className="divider"></div>
        </div>

        {/*الأهمية*/}
        <div className="col-start-1 mt-4 pr-4">
          <p className="title">الأهمية</p>
        </div>

        {/*  المصدر*/}
        <div className="col-start-2 mt-4 flex justify-start">
          <p className="title">المصدر</p>
        </div>

        {/* الأهمية :input*/}
        <div className="col-start-1  pr-4">
          <span className="data"></span>
        </div>

        {/*  المصدر  :input*/}
        <div className="col-start-2 flex justify-start">
          <span className="data"></span>
        </div>

        {/*مدير الحساب*/}
        <div className="col-start-1 mt-4 pr-4">
          <p className="title">مدير الحساب</p>
        </div>

        {/*حالة التسجيل*/}
        <div className="col-start-2 mt-4 flex justify-start">
          <p className="title">حالة التسجيل</p>
        </div>
        {/*  مدير الحساب :input*/}
        <div className="col-start-1  pr-4">
          <span className="data"> </span>
        </div>

        {/*  حالة التسجيل  :input*/}
        <div className="col-start-2  mt-4 flex justify-start">
          <span className="data"></span>
        </div>
      </div>
    </>
  );
};
