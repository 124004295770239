import "./DepitTransactions.scss";
import { useEffect, useState } from "react";
import API from "../../../Api";
import moment from "moment";
import { Loading } from "../../Loading/Loading";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setMainHeaderName } from "../../../redux/Slices/MainHeaderSlice";
import Pagination from "../../../SubComponents/Paginator/Pagination";
import { TotalCount } from "../../../SubComponents/TotalCount/TotalCount";
import { ExcelExportDetails } from "../../../SubComponents/Export/ExcelExportDetails";
export const DepitTransactions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const stateFromWalletsSlice = useSelector((state: any) => state.wallets);
  const [totalDebt, setTotalDebt] = useState(0);
  const [totalSettled, setTotalSettled] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [endPoint, setEndPoint] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    let mainHeaderName = "ادارة المحافظ";
    dispatch(setMainHeaderName({ mainHeaderName }));
    setIsLoading(true);
    //الإجماليات
    API.get("dashboard/wallets/stats").then((res) => {
      if (res.status === 200) {
        setTotalDebt(res.data.totalDebt);
        setTotalSettled(res.data.totalSettled);
      }
    });
    if (
      stateFromWalletsSlice.startDate === null ||
      stateFromWalletsSlice.finishDate === null
    ) {
      //Get Current Day
      const currentDate = new Date();
      moment.locale("en");
      // Format the date as a string (you can customize the format as needed)
      const formattedDate = moment(currentDate).format("YYYY-MM-DD");
      let type = encodeURIComponent("مدينة");
      setPageNumber(1);
      let uRL = `dashboard/wallets/transactions?type=${type}&date_from=${formattedDate}&date_to=${formattedDate}`;
      setEndPoint(uRL);

      API.get(`${uRL}&page=${pageNumber}&limit=${pageSize}`).then((res) => {
        if (res) {
          setTransactions(res.data.items);
          setTotalRows(res.data.totalCount);
          setTotalPages(Math.ceil(res.data.totalCount / pageSize));
          setIsLoading(false);
        } else {
          toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          setIsLoading(false);
        }
      });
    } else {
      let type = "مدينة";
      let uRL = `dashboard/wallets/transactions?type=${type}&date_from=${stateFromWalletsSlice.startDate}&date_to=${stateFromWalletsSlice.finishDate}`;
      setPageNumber(1);
      setEndPoint(uRL);
      API.get(`${uRL}&page=${pageNumber}&limit=${pageSize}`).then((res) => {
        if (res) {
          setTransactions(res.data.items);
          setTotalRows(res.data.totalCount);
          setTotalPages(Math.ceil(res.data.totalCount / pageSize));
          setIsLoading(false);
        } else {
          toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          setIsLoading(false);
        }
      });
    }
  }, [stateFromWalletsSlice.startDate]);

  const hanldeChangePage = (targetPN: number) => {
    setIsLoading(true);

    API.get(`${endPoint}&page=${targetPN}&limit=${pageSize}`).then((res) => {
      if (res) {
        if (res.status === 403) {
          toast.error(" عفوا انت ليس لديك صلاحية الوصول لهذه الصفحة ");
        } else {
          setTransactions(res.data.items);
          setTotalRows(res.data.totalCount);
          setTotalPages(Math.ceil(res.data.totalCount / pageSize));
          setPageNumber(targetPN);
          setIsLoading(false);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }
      }
    });
  };
  return (
    <div className="DepitTransactions grid grid-cols-2">
      {isLoading === true ? (
        <>
          <Loading />
        </>
      ) : null}
      {/* Total Count */}
      <TotalCount count={totalRows} title="الحركات المدينة فقط" />

      <ExcelExportDetails
        data={transactions}
        title="Depit Transactions"
        url={`${endPoint}&page=1&limit=100000000000000`}
        type={3}
      />
      {/* Reports */}
      <div className="col-start-1 mt-4">
        <div className="flex gap-4 mb-4 pr-10">
          <div className="report p-8 ">
            <div className="flex items-center gap-4">
              <h3 className="amount">{totalDebt}</h3>
              <span className="coin">جنيه</span>
            </div>
            <div className="report-title">إجمالي المديونية</div>
          </div>
          <div className="report p-8 ">
            <div className="flex items-center gap-4">
              <h3 className="amount">{totalSettled}</h3>
              <span className="coin">جنيه</span>
            </div>
            <div className="report-title">إجمالي المبالغ المدفوعة</div>
          </div>
        </div>
      </div>
      <div className="col-span-full">
        <div className="grid grid-cols-5">
          <div className="bannerText">التاريخ</div>
          <div className="bannerText">المندوب</div>
          <div className="bannerText">مبلغ مدين</div>
          <div className="bannerText">الرصيد</div>
          <div className="bannerText">البيان</div>
        </div>
      </div>

      {transactions.map((item: any) => {
        return (
          <div className="col-span-full data-row rounded-full mt-4 mb-4">
            <div className="grid grid-cols-5">
              <div className="breeder-name cursor-pointer">
                {moment(item.created_at).format("YYYY/MM/DD")}
              </div>
              {item.user != null ? (
                <Link to={`/Users/Profile/AccountStatement/${item.user.id}`}>
                  <div className="breeder-name cursor-pointer">
                    {item.user.name}
                  </div>
                </Link>
              ) : (
                <>
                  <div className="breeder-name cursor-pointer"></div>
                </>
              )}

              {/* مبلغ مدين */}
              <div className="breeder-name cursor-pointer">
                {" "}
                {item.type === "مدينة" ? item.amount : "٠"} جم
              </div>
              <div className="breeder-name cursor-pointer">
                {item.balance} جم
              </div>
              <div className="breeder-name cursor-pointer">
                قيمة مهمة <span className="number">رقم</span>{" "}
                {item.mission !== null ? (
                  <Link to={`/Missions/${item.mission.id}`}>
                    {item.mission.id}
                  </Link>
                ) : (
                  "٠"
                )}
              </div>
            </div>
          </div>
        );
      })}

      {/* Pagination */}
      {totalRows === 0 ? null : (
        <>
          <div className="col-start-2 flex justify-end pt-4 pl-12">
            <Pagination
              currentPage={pageNumber}
              lastPage={totalPages}
              maxLength={7}
              setCurrentPage={hanldeChangePage}
            />
          </div>
        </>
      )}
    </div>
  );
};
