import "./Users.scss";
import userIcon from "../../Assets/Icons/40px.svg";
import { useEffect, useState } from "react";
import API from "../../Api";
import { Loading } from "../Loading/Loading";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveUserData,
  setUserCreationDate,
  setUserId,
  setUserName,
} from "../../redux/Slices/UsersSlice";
import { Link, useNavigate } from "react-router-dom";
import { TotalCount } from "../../SubComponents/TotalCount/TotalCount";
import moment from "moment";
import "moment/locale/ar"; // Import the Arabic locale
import { ar, enUS } from "date-fns/locale";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { ActivatePopUp } from "./SubComponents/Activate/ActivatePopUp";
import Pagination from "../../SubComponents/Paginator/Pagination";
import { ExportExcel } from "../../SubComponents/Export/ExcelExport";
export const StoppedUsers = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const stateFromUserSlice = useSelector((state: any) => state.users);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [showActivatePopUp, setShowActivatePopUp] = useState(false);
  const [isSorted, setIsSorted] = useState(false);
  const [sortedKey, setSortedKey] = useState("&sort_by=id&sort_order=DESC");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);

    moment.locale("ar");
    if (
      stateFromUserSlice.isFiltered === true &&
      stateFromUserSlice.headerType === 3 &&
      stateFromUserSlice.filterDataObj.length > 0
    ) {
      if (stateFromUserSlice.filterDataObj[0].length > 0) {
        setUsers(stateFromUserSlice.filterDataObj[0]);

        setTotalRows(stateFromUserSlice.filterTotalCount);
        setTotalPages(
          Math.ceil(stateFromUserSlice.filterTotalCount / pageSize)
        );
        setIsLoading(false);
      } else {
        setUsers([]);
        setTotalRows(0);
        setTotalPages(1);
        setIsLoading(false);
      }
    } else if (isSorted === false) {
      API.get(
        `dashboard/salesman?account_status=3&page=${pageNumber}&limit=${pageSize}`
      ).then((res) => {
        if (res) {
          setUsers(res.data.items);
          setTotalRows(res.data.totalCount);
          setTotalPages(Math.ceil(res.data.totalCount / pageSize));

          setIsLoading(false);
        }
      });
    }
  }, [
    stateFromUserSlice.filterDataObj,
    stateFromUserSlice.filterTotalCount,
    stateFromUserSlice.headerType,
    stateFromUserSlice.isFiltered,
    stateFromUserSlice.activeUserData,
  ]);

  const hanldeChangePage = (targetPN: number) => {
    setIsLoading(true);
    if (isSorted === true) {
      let key = sortedKey;

      API.get(
        `dashboard/salesman?account_status=3&page=${targetPN}&limit=${pageSize}&${key}`
      ).then((res) => {
        if (res.status === 200) {
          setUsers(res.data.items);
          setPageNumber(targetPN);
          // let activeUserData = res.data.items;
          // dispatch(setActiveUserData({ activeUserData }));
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          setIsLoading(false);
        } else {
          setIsLoading(false);
          toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
        }
      });
    } else {
      API.get(
        `dashboard/salesman?account_status=3&page=${targetPN}&limit=${pageSize}`
      ).then((res) => {
        if (res) {
          setUsers(res.data.items);
          setTotalRows(res.data.totalCount);
          setTotalPages(Math.ceil(res.data.totalCount / pageSize));
          setPageNumber(targetPN);
          setIsLoading(false);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }
      });
    }
  };

  const handleShowActivatePopUp = (userId: number) => {
    dispatch(setUserId({ userId }));
    setShowActivatePopUp(!showActivatePopUp);
  };
  const sortData = (property: string) => {
    setIsLoading(true);
    setIsSorted(true);

    switch (property) {
      case "sales_farms_count_desc":
        setSortedKey(`sort_by=sales_farms_count&sort_order=DESC`);
        API.get(
          `dashboard/salesman?account_status=3&page=${pageNumber}&limit=${pageSize}&sort_by=sales_farms_count&sort_order=DESC`
        ).then((res) => {
          if (res.status === 200) {
            setUsers(res.data.items);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          }
        });
        break;

      case "sales_farms_count_asc":
        setSortedKey(`sort_by=sales_farms_count&sort_order=ASC`);
        API.get(
          `dashboard/salesman?account_status=3&page=${pageNumber}&limit=${pageSize}&sort_by=sales_farms_count&sort_order=ASC`
        ).then((res) => {
          if (res.status === 200) {
            setUsers(res.data.items);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          }
        });
        break;

      case "total_income_desc":
        setSortedKey(`sort_by=total_income&sort_order=DESC`);
        API.get(
          `dashboard/salesman?account_status=3&page=${pageNumber}&limit=${pageSize}&sort_by=total_income&sort_order=DESC`
        ).then((res) => {
          if (res.status === 200) {
            setUsers(res.data.items);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          }
        });
        break;

      case "total_income_asc":
        setSortedKey(`sort_by=total_income&sort_order=ASC`);
        API.get(
          `dashboard/salesman?account_status=3&page=${pageNumber}&limit=${pageSize}&sort_by=total_income&sort_order=ASC`
        ).then((res) => {
          if (res.status === 200) {
            setUsers(res.data.items);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          }
        });
        break;

      case "total_early_bonus_desc":
        setSortedKey(`total_early_bonus&sort_order=DESC`);
        API.get(
          `dashboard/salesman?account_status=3&page=${pageNumber}&limit=${pageSize}&sort_by=total_early_bonus&sort_order=DESC`
        ).then((res) => {
          if (res.status === 200) {
            setUsers(res.data.items);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          }
        });
        break;

      case "total_early_bonus_asc":
        setSortedKey(`total_early_bonus&sort_order=ASC`);
        API.get(
          `dashboard/salesman?account_status=3&page=${pageNumber}&limit=${pageSize}&sort_by=total_early_bonus&sort_order=ASC`
        ).then((res) => {
          if (res.status === 200) {
            setUsers(res.data.items);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          }
        });
        break;

      case "completed_missions_count_desc":
        setSortedKey(`completed_missions_count&sort_order=DESC`);
        API.get(
          `dashboard/salesman?account_status=3&page=${pageNumber}&limit=${pageSize}&sort_by=completed_missions_count&sort_order=DESC`
        ).then((res) => {
          if (res.status === 200) {
            setUsers(res.data.items);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          }
        });
        break;

      case "completed_missions_count_asc":
        setSortedKey(`completed_missions_count&sort_order=ASC`);
        API.get(
          `dashboard/salesman?account_status=3&page=${pageNumber}&limit=${pageSize}&sort_by=completed_missions_count&sort_order=ASC`
        ).then((res) => {
          if (res.status === 200) {
            setUsers(res.data.items);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          }
        });
        break;

      case "inprogress_missions_count_desc":
        setSortedKey(`sort_by=inprogress_missions_count&sort_order=DESC`);
        API.get(
          `dashboard/salesman?account_status=3&page=${pageNumber}&limit=${pageSize}&sort_by=inprogress_missions_count&sort_order=DESC`
        ).then((res) => {
          if (res.status === 200) {
            setUsers(res.data.items);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          }
        });
        break;

      case "inprogress_missions_count_asc":
        setSortedKey(`sort_by=inprogress_missions_count&sort_order=ASC`);
        API.get(
          `dashboard/salesman?account_status=3&page=${pageNumber}&limit=${pageSize}&sort_by=inprogress_missions_count&sort_order=ASC`
        ).then((res) => {
          if (res.status === 200) {
            setUsers(res.data.items);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          }
        });
        break;

      case "late_missions_count_desc":
        setSortedKey(`late_missions_count&sort_order=DESC`);
        API.get(
          `dashboard/salesman?account_status=3&page=${pageNumber}&limit=${pageSize}&sort_by=late_missions_count&sort_order=DESC`
        ).then((res) => {
          if (res.status === 200) {
            setUsers(res.data.items);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          }
        });
        break;

      case "late_missions_count_asc":
        setSortedKey(`late_missions_count&sort_order=ASC`);
        API.get(
          `dashboard/salesman?account_status=3&page=${pageNumber}&limit=${pageSize}&sort_by=late_missions_count&sort_order=ASC`
        ).then((res) => {
          if (res.status === 200) {
            setUsers(res.data.items);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          }
        });
        break;

      case "in_review_missions_count_desc":
        setSortedKey(`in_review_missions_count&sort_order=DESC`);
        API.get(
          `dashboard/salesman?account_status=3&page=${pageNumber}&limit=${pageSize}&sort_by=in_review_missions_count&sort_order=DESC`
        ).then((res) => {
          if (res.status === 200) {
            setUsers(res.data.items);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          }
        });
        break;

      case "in_review_missions_count_asc":
        setSortedKey(`in_review_missions_count&sort_order=ASC`);
        API.get(
          `dashboard/salesman?account_status=3&page=${pageNumber}&limit=${pageSize}&sort_by=in_review_missions_count&sort_order=ASC`
        ).then((res) => {
          if (res.status === 200) {
            setUsers(res.data.items);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          }
        });
        break;

      default:
        break;
    }
  };
  return (
    <>
      <div className="Users  grid grid-cols-2">
        <div className="mt-4">
          <TotalCount count={totalRows} title="الحسابات الغير مفعلة" />
        </div>
        <div className="flex gap-4 justify-end items-center mt-4">
          <div className="dropdown">
            <label
              className="flex items-center justify-end gap-1 rounded-full bg-white p-3 my-2"
              tabIndex={0}
            >
              {" "}
              <svg
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    d="M16.0686 15H7.9313C7.32548 15 7.02257 15 6.88231 15.1198C6.76061 15.2238 6.69602 15.3797 6.70858 15.5393C6.72305 15.7232 6.93724 15.9374 7.36561 16.3657L11.4342 20.4344C11.6323 20.6324 11.7313 20.7314 11.8454 20.7685C11.9458 20.8011 12.054 20.8011 12.1544 20.7685C12.2686 20.7314 12.3676 20.6324 12.5656 20.4344L16.6342 16.3657C17.0626 15.9374 17.2768 15.7232 17.2913 15.5393C17.3038 15.3797 17.2392 15.2238 17.1175 15.1198C16.9773 15 16.6744 15 16.0686 15Z"
                    stroke="#949494"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>{" "}
                  <path
                    d="M7.9313 9.00005H16.0686C16.6744 9.00005 16.9773 9.00005 17.1175 8.88025C17.2393 8.7763 17.3038 8.62038 17.2913 8.46082C17.2768 8.27693 17.0626 8.06274 16.6342 7.63436L12.5656 3.56573C12.3676 3.36772 12.2686 3.26872 12.1544 3.23163C12.054 3.199 11.9458 3.199 11.8454 3.23163C11.7313 3.26872 11.6323 3.36772 11.4342 3.56573L7.36561 7.63436C6.93724 8.06273 6.72305 8.27693 6.70858 8.46082C6.69602 8.62038 6.76061 8.7763 6.88231 8.88025C7.02257 9.00005 7.32548 9.00005 7.9313 9.00005Z"
                    stroke="#949494"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>{" "}
                </g>
              </svg>
              <span className="sorting">الترتيب حسب</span>
            </label>
            <div className="dropdown-menu dropdown-menu-bottom-left">
              <a
                tabIndex={-1}
                className={`dropdown-item cursor-pointer rounded-md border-2 p-1 mt-2 w-full text-sm text-right ${
                  sortedKey == "sort_by=sales_farms_count&sort_order=DESC"
                    ? "sorting-item-active"
                    : "sorting-item"
                } `}
                onClick={() => sortData("sales_farms_count_desc")}
              >
                أكبر عدد من العنابر المسجلة
              </a>

              <a
                tabIndex={-1}
                className={`dropdown-item cursor-pointer rounded-md border-2 p-1 mt-2 w-full text-sm text-right ${
                  sortedKey == "sort_by=sales_farms_count&sort_order=ASC"
                    ? "sorting-item-active"
                    : "sorting-item"
                } `}
                onClick={() => sortData("sales_farms_count_asc")}
              >
                أصغر عدد من العنابر المسجلة
              </a>
              <a
                tabIndex={-1}
                className={`dropdown-item cursor-pointer rounded-md border-2 p-1 mt-2 w-full text-sm text-right ${
                  sortedKey == "completed_missions_count&sort_order=DESC"
                    ? "sorting-item-active"
                    : "sorting-item"
                } `}
                onClick={() => sortData("completed_missions_count_desc")}
              >
                أكبر عدد من المهام التامة
              </a>
              <a
                tabIndex={-1}
                className={`dropdown-item cursor-pointer rounded-md border-2 p-1 mt-2 w-full text-sm text-right ${
                  sortedKey == "completed_missions_count&sort_order=ASC"
                    ? "sorting-item-active"
                    : "sorting-item"
                } `}
                onClick={() => sortData("completed_missions_count_asc")}
              >
                أصغر عدد من المهام التامة
              </a>
              <a
                tabIndex={-1}
                className={`dropdown-item cursor-pointer rounded-md border-2 p-1 mt-2 w-full text-sm text-right ${
                  sortedKey ==
                  "sort_by=inprogress_missions_count&sort_order=DESC"
                    ? "sorting-item-active"
                    : "sorting-item"
                } `}
                onClick={() => sortData("inprogress_missions_count_desc")}
              >
                أكبر عدد من المهام تحت التنفيذ
              </a>
              <a
                tabIndex={-1}
                className={`dropdown-item cursor-pointer rounded-md border-2 p-1 mt-2 w-full text-sm text-right ${
                  sortedKey ==
                  "sort_by=inprogress_missions_count&sort_order=ASC"
                    ? "sorting-item-active"
                    : "sorting-item"
                } `}
                onClick={() => sortData("inprogress_missions_count_asc")}
              >
                أصغر عدد من المهام تحت التنفيذ
              </a>
              <a
                tabIndex={-1}
                className={`dropdown-item cursor-pointer rounded-md border-2 p-1 mt-2 w-full text-sm text-right ${
                  sortedKey == "late_missions_count&sort_order=DESC"
                    ? "sorting-item-active"
                    : "sorting-item"
                } `}
                onClick={() => sortData("late_missions_count_desc")}
              >
                أكبر عدد من المهام المتأخرة
              </a>
              <a
                tabIndex={-1}
                className={`dropdown-item cursor-pointer rounded-md border-2 p-1 mt-2 w-full text-sm text-right ${
                  sortedKey == "late_missions_count&sort_order=ASC"
                    ? "sorting-item-active"
                    : "sorting-item"
                } `}
                onClick={() => sortData("late_missions_count_asc")}
              >
                أصغر عدد من المهام المتأخرة
              </a>

              <a
                tabIndex={-1}
                className={`dropdown-item cursor-pointer rounded-md border-2 p-1 mt-2 w-full text-sm text-right ${
                  sortedKey == "in_review_missions_count&sort_order=DESC"
                    ? "sorting-item-active"
                    : "sorting-item"
                } `}
                onClick={() => sortData("in_review_missions_count_desc")}
              >
                أكبر عدد من المهام بأنتظار التأكيد
              </a>
              <a
                tabIndex={-1}
                className={`dropdown-item cursor-pointer rounded-md border-2 p-1 mt-2 w-full text-sm text-right ${
                  sortedKey == "in_review_missions_count&sort_order=ASC"
                    ? "sorting-item-active"
                    : "sorting-item"
                } `}
                onClick={() => sortData("in_review_missions_count_asc")}
              >
                أصغر عدد من المهام بأنتظار التأكيد
              </a>
              <a
                tabIndex={-1}
                className={`dropdown-item cursor-pointer rounded-md border-2 p-1 mt-2 w-full text-sm text-right ${
                  sortedKey == "sort_by=total_income&sort_order=DESC"
                    ? "sorting-item-active"
                    : "sorting-item"
                } `}
                onClick={() => sortData("total_income_desc")}
              >
                أكبر إجمالي دخل
              </a>

              <a
                tabIndex={-1}
                className={`dropdown-item cursor-pointer rounded-md border-2 p-1 mt-2 w-full text-sm text-right ${
                  sortedKey == "sort_by=total_income&sort_order=ASC"
                    ? "sorting-item-active"
                    : "sorting-item"
                } `}
                onClick={() => sortData("total_income_asc")}
              >
                أصغر إجمالي دخل
              </a>
              <a
                tabIndex={-1}
                className={`dropdown-item cursor-pointer rounded-md border-2 p-1 mt-2 w-full text-sm text-right ${
                  sortedKey == "total_early_bonus&sort_order=DESC"
                    ? "sorting-item-active"
                    : "sorting-item"
                } `}
                onClick={() => sortData("total_early_bonus_desc")}
              >
                أكبر بونص
              </a>
              <a
                tabIndex={-1}
                className={`dropdown-item cursor-pointer rounded-md border-2 p-1 mt-2 w-full text-sm text-right ${
                  sortedKey == "total_early_bonus&sort_order=ASC"
                    ? "sorting-item-active"
                    : "sorting-item"
                } `}
                onClick={() => sortData("total_early_bonus_asc")}
              >
                أصغر بونص
              </a>
            </div>
          </div>

          <ExportExcel
            url={`dashboard/salesman?account_status=3&page=1&limit=1000000`}
            fileName="المستخدمين الموقوفون "
            page="StoppedUsers"
          />
        </div>

        {isLoading === true ? (
          <>
            <Loading />
          </>
        ) : null}
        <div className="grid grid-cols-2 col-span-full mt-4  gap-4">
          {users.map((item: any, index: any) => {
            const createdAtDate = moment(item.created_at);
            const now = moment(); // gets the current date and time

            const differenceInMonths = now.diff(createdAtDate, "months");
            moment.locale("ar");
            const createdAtDate_Arabic = createdAtDate.format("DD MMM YYYY");
            return (
              <>
                <div className="grid grid-cols-2 col-span-full mb-4 mr-4 ml-4 mt-4  mission-content">
                  <div className="col-span-full grid grid-cols-2 p-4 mb-2 rounded-md mr-4 border-2 w-3/4">
                    <div className="col-start-1">
                      <Link to={`/Users/Profile/PersonalData/${item.id}`}>
                        <span className="title cursor-pointer">
                          {item.name}
                        </span>
                      </Link>
                    </div>
                    <div className="col-start-2 pl-4 flex ">
                      <span className="title">{item.id}</span>
                    </div>
                  </div>
                  <div className="col-span-full grid grid-cols-2 p-4 mb-2 rounded-md mr-4 border-2 w-3/4">
                    {/* رقم الهاتف */}

                    <div className="col-start-1 flex gap-4 justify-start mt-2">
                      <div className="inline-flex gap-2">
                        <span className="title">رقم الهاتف :</span>
                        <span className="mission-type">
                          <span className="mission-type">
                            {item.mobile_number}
                          </span>
                        </span>
                      </div>
                    </div>

                    {/* رقم الواتساب */}
                    <div className="col-start-2 pl-4 flex ">
                      <div className="inline-flex justify-start gap-2">
                        <span className="title">رقم الواتساب :</span>
                        <span className="mission-type">
                          {item.whatsapp_number}
                        </span>
                      </div>
                    </div>
                    {/* المحافظة */}
                    <div className="col-start-1 flex gap-4 justify-start mt-2">
                      <div className="inline-flex gap-2">
                        <span className="title">المحافظة :</span>
                        <span className="mission-type">
                          {item.governorate != null
                            ? item.governorate.name
                            : ""}
                        </span>
                      </div>
                    </div>

                    {/* المدينة */}
                    <div className="col-start-2 pl-4 flex  gap-4 justify-start  ">
                      <div className="inline-flex gap-2">
                        <span className="title">المدينة :</span>
                        <span className="mission-type">
                          {item.city != null ? item.city.name : ""}
                        </span>
                      </div>
                    </div>
                    {/* التخصص */}

                    <div className="col-start-1 flex gap-4 justify-start mt-2">
                      <div className="inline-flex gap-2">
                        <span className="title">التخصص :</span>
                        <span className="mission-type">
                          <span className="mission-type">
                            {item.specialty != null ? item.specialty.name : ""}
                          </span>
                        </span>
                      </div>
                    </div>

                    {/* انشأ في */}
                    <div className="col-start-2 pl-4 flex ">
                      <div className="inline-flex justify-start gap-2">
                        <span className="title">انشأ في :</span>
                        <span className="mission-type">
                          {createdAtDate_Arabic} ({differenceInMonths} شهر)
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-span-full grid grid-cols-2 p-4 mb-2 rounded-md mr-4 border-2 w-3/4">
                    {/* إجمالي الدخل */}
                    <div className="col-start-1 flex gap-4 justify-start mt-2">
                      <div className="inline-flex gap-2">
                        <span
                          className="tooltip tooltip-top title"
                          data-tooltip="إجمالي رصيد المحفظة + الرصيد المدفوع."
                        >
                          إجمالي الدخل :
                        </span>

                        <span className="mission-type">
                          {" "}
                          {item.total_income}
                        </span>
                      </div>
                    </div>

                    {/* مجموع الأموال المستحقة له */}
                    <div className="col-start-2 pl-4 flex ">
                      <div className="inline-flex justify-start gap-2">
                        <span className="title">
                          مجموع الأموال المستحقة له :
                        </span>
                        <span className="mission-type">
                          {item.settled_balance}
                        </span>
                      </div>
                    </div>

                    {/* إجمالي البونص لجميع المهام */}
                    <div className="col-start-1 flex gap-4 justify-start mt-2">
                      <div className="inline-flex gap-2">
                        <span className="title">
                          إجمالي البونص لجميع المهام :
                        </span>

                        <span className="mission-type">
                          {item.total_early_bonus}
                        </span>
                      </div>
                    </div>

                    {/* إجمالي عدد المهام التامة */}
                    <div className="col-start-2 pl-4 flex ">
                      <div className="inline-flex justify-start gap-2">
                        <span className="title">
                          إجمالي عدد المهام التامة :
                        </span>
                        <span className="mission-type">
                          {item.completed_missions_count}
                        </span>
                      </div>
                    </div>

                    {/* إجمالي عدد المهام التي انشئها */}
                    <div className="col-start-1 flex gap-4 justify-start mt-2">
                      <div className="inline-flex gap-2">
                        <span className="title">
                          إجمالي عدد المهام التي أنشئها:
                        </span>

                        <span className="mission-type">
                          {item.created_missions_count}
                        </span>
                      </div>
                    </div>
                    {/* إجمالي عدد المهام تحت التنفيذ */}
                    <div className="col-start-2 pl-4 flex ">
                      <div className="inline-flex justify-start gap-2">
                        <span className="title">
                          إجمالي عدد المهام تحت التنفيذ :
                        </span>
                        <span className="mission-type">
                          {item.inprogress_missions_count}
                        </span>
                      </div>
                    </div>

                    {/* إجمالي عدد المهام المتأخرة */}
                    <div className="col-start-1 flex gap-4 justify-start mt-2">
                      <div className="inline-flex gap-2">
                        <span className="title">
                          إجمالي عدد المهام المتأخرة:
                        </span>

                        <span className="mission-type">
                          {item.late_missions_count}
                        </span>
                      </div>
                    </div>
                    {/* إجمالي عدد المهام بأنتظار التأكيد */}
                    <div className="col-start-2 pl-4 flex ">
                      <div className="inline-flex justify-start gap-2">
                        <span className="title">
                          إجمالي عدد المهام بأنتظار التأكيد :
                        </span>
                        <span className="mission-type">
                          {item.in_review_missions_count}
                        </span>
                      </div>
                    </div>
                    {/* عدد العنابر المسجلة */}
                    <div className="col-start-1 flex gap-4 justify-start mt-2">
                      <div className="inline-flex justify-start gap-2">
                        <span className="title">عدد العنابر المسجلة :</span>
                        <span className="mission-type">
                          {item.sales_farms_count}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-full grid grid-cols-2 p-4 mb-2 rounded-md mr-4 border-2 w-3/4">
                    <div className="col-start-1 flex gap-4 justify-between mt-2">
                      <div className="flex gap-2 items-center">
                        <span className="title">الحالة :</span>
                        <span className={"breeder-disable"}>غير مفعل</span>
                      </div>
                    </div>
                    <div className="col-start-2">
                      <label htmlFor="modal-ActivateUsersPopUp">
                        <div
                          onClick={() =>
                            handleShowActivatePopUp(Number(item.id))
                          }
                          className="activate_btn"
                        >
                          التفعيل
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </>
            );
          })}

          {/* Pagination */}
          {stateFromUserSlice.headerType === 3 &&
          stateFromUserSlice.filterDataObj.length > 0 ? null : (
            <div className="col-start-2 flex justify-end pt-4 pl-12">
              <Pagination
                currentPage={pageNumber}
                lastPage={totalPages}
                maxLength={7}
                setCurrentPage={hanldeChangePage}
              />
            </div>
          )}
        </div>
      </div>
      {showActivatePopUp === true ? <ActivatePopUp data={users} /> : null}
    </>
  );
};
