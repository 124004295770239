import "./AllSuppliersDetails.scss";
import { useNavigate, useLocation } from "react-router";
import moment from "moment";
import "moment/locale/ar"; // Import the Arabic locale
import { ar, enUS } from "date-fns/locale";
import { format } from "date-fns";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { setMainHeaderName } from "../../../../redux/Slices/MainHeaderSlice";
import userIcon from "../../../../Assets/Icons/40px.svg";
import { SupplierAccountData } from "./SupplierAccountData/SupplierAccountData";
import { SupplierPaperDocs } from "./SupplierPaperDocs/SupplierPaperDocs";
import { SupplierCallsHistory } from "./SupplierCallsHistory/SupplierCallsHistory";

interface ApiResponse {
  id: string;
  created_at: string;
  name: string;
  village: string;
  city_id: string;
  governorate_id: string;
  full_address: string;
  mobile_number: string;
  whatsapp_number: string;
  email: string;
  avatar: string;
  account_status: number;
  national_id: string;
  specialty_id: string;
  level_id: string;
  ewallet_number: string;
  wallet_balance: number;
  activity_type: {
    id: string;
    name: string;
  };
  farms_count: number;
  approx_breeding_size: number;
}
export const AllSuppliersDetails = () => {
  const { supplierId } = useParams<{ supplierId: string }>();
  const [apiResponse, setApiResponse] = useState<ApiResponse | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [rangeText, setRangeText] = useState("");
  const [activeType, setActiveType] = useState(1);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    moment.locale("ar");
    let mainHeaderName = "تفاصيل مورد";
    dispatch(setMainHeaderName({ mainHeaderName }));
  }, []);

  const handleImageError = (event: any) => {
    // Set a fallback image source when the original image fails to load
    event.target.src = userIcon;
  };

  const handleActiveElement = (id: number) => {
    setActiveType(id);

    switch (id) {
      case 1:
        navigate("/AllSuppliers/AccountData/1");
        break;
      case 2:
        navigate("/AllSuppliers/Papers&Documents/1");
        break;

      case 3:
        navigate("/AllSuppliers/CallsHistory/1");
        break;

      default:
        break;
    }
  };
  return (
    <div className="AllSuppliersDetails">
      <div className="breeder-header grid grid-cols-2">
        <div className="col-start-1 pr-4 pt-4">
          <div className="flex gap-4">
            <div>
              <img src={userIcon} alt="user" onError={handleImageError} />
            </div>
            <div className="grid">
              <p className="user-name">محمود النبوي</p>
              <div className="flex justify-between gap-4">
                <div className="flex items-center gap-2">
                  <span className="evalu">التقييم :</span>
                  <div className="flex gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="13"
                      viewBox="0 0 12 13"
                      fill="none"
                    >
                      <path
                        d="M6 0.5L8.03951 4.18183L12 5.08359L9.3 8.26085L9.7082 12.5L6 10.7818L2.2918 12.5L2.7 8.26085L0 5.08359L3.96049 4.18183L6 0.5Z"
                        fill="#FFCB45"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="13"
                      viewBox="0 0 12 13"
                      fill="none"
                    >
                      <path
                        d="M6 0.5L8.03951 4.18183L12 5.08359L9.3 8.26085L9.7082 12.5L6 10.7818L2.2918 12.5L2.7 8.26085L0 5.08359L3.96049 4.18183L6 0.5Z"
                        fill="#FFCB45"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="13"
                      viewBox="0 0 12 13"
                      fill="none"
                    >
                      <path
                        d="M6 0.5L8.03951 4.18183L12 5.08359L9.3 8.26085L9.7082 12.5L6 10.7818L2.2918 12.5L2.7 8.26085L0 5.08359L3.96049 4.18183L6 0.5Z"
                        fill="#FFCB45"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="13"
                      viewBox="0 0 12 13"
                      fill="none"
                    >
                      <path
                        d="M6 0.5L8.03951 4.18183L12 5.08359L9.3 8.26085L9.7082 12.5L6 10.7818L2.2918 12.5L2.7 8.26085L0 5.08359L3.96049 4.18183L6 0.5Z"
                        fill="#FFCB45"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="13"
                      viewBox="0 0 12 13"
                      fill="none"
                    >
                      <path
                        d="M6 0.5L8.03951 4.18183L12 5.08359L9.3 8.26085L9.7082 12.5L6 10.7818L2.2918 12.5L2.7 8.26085L0 5.08359L3.96049 4.18183L6 0.5Z"
                        fill="#FFCB45"
                      />
                    </svg>
                  </div>
                </div>
                <span className="member-since">
                  عضو منذ{" "}
                  {moment(apiResponse?.created_at).format("DD MMMM YYYY")}
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* Delete Icon & Close Icon*/}
        <div className="col-start-2 pl-4 pt-4">
          <div className="flex justify-end items-center gap-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M5 8V18C5 20.2091 6.79086 22 9 22H15C17.2091 22 19 20.2091 19 18V8M14 11V17M10 11L10 17M16 5L14.5937 2.8906C14.2228 2.3342 13.5983 2 12.9296 2H11.0704C10.4017 2 9.7772 2.3342 9.40627 2.8906L8 5M16 5H8M16 5H21M8 5H3"
                stroke="#EB001B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <svg
              onClick={() => navigate("/AllSuppliers")}
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="16"
              viewBox="0 0 13 12"
              fill="none"
            >
              <path
                d="M11.4473 1.21143L1.54777 11.1109"
                stroke="#64748B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1.54688 1.21143L11.4464 11.1109"
                stroke="#64748B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>

        <div className="col-start-1 mt-4 pr-4">
          <div className="flex gap-4">
            <div
              className={
                location.pathname.includes("AllSuppliers/AccountData")
                  ? "breeder-item-active"
                  : "breeder-item"
              }
              onClick={() => handleActiveElement(1)}
            >
              بيانات الحساب
            </div>
            <div
              className={
                location.pathname.includes("/AllSuppliers/Papers&Documents")
                  ? "breeder-item-active"
                  : "breeder-item"
              }
              onClick={() => handleActiveElement(2)}
            >
              اوراق ومستندات
            </div>
            <div
              className={
                location.pathname.includes("/AllSuppliers/CallsHistory")
                  ? "breeder-item-active"
                  : "breeder-item"
              }
              onClick={() => handleActiveElement(3)}
            >
              سجل المكالمات والزيارات
            </div>
          </div>
        </div>
      </div>
      {/* Content */}
      {location.pathname.includes("AllSuppliers/AccountData") ? (
        <SupplierAccountData />
      ) : location.pathname.includes("/AllSuppliers/Papers&Documents") ? (
        <SupplierPaperDocs />
      ) : location.pathname.includes("AllSuppliers/CallsHistory") ? (
        <SupplierCallsHistory />
      ) : null}
    </div>
  );
};
