import "./BreederFilter.scss";
import Fuse from "fuse.js";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import API from "../../../Api";
import { Loading } from "../../Loading/Loading";
import DatePicker from "react-datepicker";
import moment from "moment";
import { handleSubmitFilterResult } from "../../../redux/Slices/BreedersModalSlice";
interface FilterObject {
  pay_method: string;
  name: string;
  home_breeding?: boolean;
  incubator?: boolean;
  mobile: number;
  gov_ids: number[];
  city_ids: number[];
  account_status: number;
  created_by_role: number[];
  created_by_id: number;
  is_efb: boolean;
}
export const BreederFilter = () => {
  const [filterObject, setFilterObject] = useState<{
    pay_method: string;
    name: string;
    home_breeding?: boolean;
    incubator?: boolean;
    mobile: number;
    gov_ids: number[];
    city_ids: number[];
    account_status: number;
    created_by_role: number[];
    created_by_id: number;
    is_efb: boolean;
  }>({
    pay_method: "",
    name: "",
    home_breeding: false,
    incubator: false,
    mobile: 0,
    gov_ids: [],
    city_ids: [],

    account_status: 0,
    created_by_role: [],
    created_by_id: 0,
    is_efb: false,
  });
  const [data, setLocationData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTypeId, setSelectedTypeId] = useState(0);
  const [selectedItemId, setSelectedItemId] = useState(1);
  const [locationSearchResults, setLocationSearchResults] = useState<any[]>([
    {},
  ]);
  const [selectedHomeBreedingTypeId, setSelectedHomeBreedingTypeId] =
    useState(0);
  const [selectedHomeBreedingTypeName, setSelectedHomeBreedingTypeName] =
    useState("");
  const [salesManSearchResults, setSalesManSearchResults] = useState<any[]>([
    {},
  ]);
  const [salesManData, setSalesManData] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState(1);
  const [selectedAccountStatusId, setSelectedAccountStatusId] = useState(1);
  const [selectedCreatedRoleId, setSelectedCreatedRoleId] = useState(1);
  const [selectedItemName, setSelectedItemName] = useState("");
  const [showSalesManList, setShowSalesManList] = useState(false);
  const [isEfb, setIsEfb] = useState(false);

  const [selectedSalesManId, setSelectedSalesManId] = useState(0);
  const [selectedIncubatorTypeId, setSelectedIncubatorTypeId] = useState(0);
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(0);
  const options = {
    includeScore: true,
    includeMatches: true,
    threshold: 0.2,
    keys: ["name"],
  };

  const fuse = new Fuse(locationSearchResults, options);

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    //governorates API
    API.get(`governorates`).then((res) => {
      if (res) {
        if (res.status === 403) {
          toast.error(" عفوا انت ليس لديك صلاحية الوصول لهذه الصفحة ");
        } else {
          setLocationSearchResults(res.data);
          setLocationData(res.data);
          setIsLoading(false);
        }
      }
    });
    //Sales Man Api
    API.get("salesman?page=1&limit=500000000").then((res) => {
      setIsLoading(true);
      if (res) {
        if (res.status === 403) {
          toast.error(" عفوا انت ليس لديك صلاحية الوصول لهذه الصفحة ");
        } else {
          setSalesManSearchResults(res.data.items);
          setSalesManData(res.data.items);

          setIsLoading(false);
        }
      }
    });
  }, []);

  // Define a function to generate query parameters
  const generateParam = (key: string, value: any): string => {
    if (Array.isArray(value)) {
      const params = value.map((val) => `${key}=${val}`).join("&");
      return params ? `${params}&` : "";
    }
    return value !== 0 && value !== "" && value !== false
      ? `${key}=${value}&`
      : "";
  };
  // Define a function to generate the filter URL
  // Define a function to generate the filter URL
  const generateFilterUrl = (
    filterObject: FilterObject,
    isEfb: boolean
  ): string => {
    const params: string[] = [
      generateParam("home_breeding", filterObject.home_breeding),
      generateParam("incubator", filterObject.incubator),
      generateParam("pay_method", filterObject.pay_method),
      generateParam("name", filterObject.name),
      generateParam("mobile", filterObject.mobile),
      generateParam("account_status", filterObject.account_status),
      generateParam("gov_ids", filterObject.gov_ids),
      generateParam("city_ids", filterObject.city_ids),
      isEfb ? "is_efb=true&" : "",
      generateParam("created_by_role", filterObject.created_by_role),
      filterObject.created_by_role.length > 0
        ? generateParam("created_by_id", filterObject.created_by_id)
        : "",
    ];

    // Filter out empty parameters and join them with '&'
    const queryParams = params.filter((param) => param !== "").join("");

    return `breeders?sort_by=id&sort_order=DESC&${queryParams}`;
  };

  const handleChangeHomeBreeding = (itemId: number, itemName: string) => {
    setSelectedHomeBreedingTypeId(
      itemId === selectedHomeBreedingTypeId ? 0 : itemId
    );

    switch (itemId) {
      case 1:
        setFilterObject((prevState) => ({
          ...prevState,
          ["home_breeding"]: true,
        }));
        break;

      case 2:
        setFilterObject((prevState) => ({
          ...prevState,
          ["home_breeding"]: false,
        }));
        break;

      default:
        break;
    }
  };

  const handleSalesManSearch = (event: any) => {
    setIsLoading(true);
    const { value } = event.target;

    // If the user searched for an empty string,
    // display all data.
    if (value.length === 0) {
      setIsLoading(false);
      setSalesManSearchResults(salesManData);
      return;
    }
    API.get(
      `dashboard/salesman?sort_by=id&sort_order=DESC&page=1&limit=10000000000&search=${event.target.value}`
    ).then((res) => {
      setSalesManSearchResults(res.data.items);
      setIsLoading(false);
    });
  };
  const handleChangeLocationType = (itemId: number, itemName: string) => {
    setIsLoading(true);
    setSelectedLocationId(0);
    setSelectedItemId(itemId === selectedItemId ? 0 : itemId);
    setSelectedItemName(itemId === selectedItemId ? "null" : itemName);
    if (itemId === 2) {
      API.get(`cities`).then((res) => {
        if (res) {
          if (res.status === 403) {
            toast.error(" عفوا انت ليس لديك صلاحية الوصول لهذه الصفحة ");
          } else {
            setLocationSearchResults(res.data);
            setLocationData(res.data);
            setIsLoading(false);
          }
        }
      });
    } else {
      API.get(`governorates`).then((res) => {
        if (res) {
          if (res.status === 403) {
            toast.error(" عفوا انت ليس لديك صلاحية الوصول لهذه الصفحة ");
          } else {
            setLocationSearchResults(res.data);
            setLocationData(res.data);
            setIsLoading(false);
          }
        }
      });
    }
  };

  const handleChangeLocationContent = (itemId: number, itemName: string) => {
    const isSelected = itemId === selectedLocationId;
    setSelectedLocationId(isSelected ? 0 : itemId);
    if (selectedItemId === 1) {
      //محافظات
      setFilterObject((prevState) => ({
        ...prevState,
        gov_ids: isSelected
          ? prevState.gov_ids.filter((id) => id !== itemId)
          : [...prevState.gov_ids, itemId],
      }));
    } else {
      // //مناطق
      setFilterObject((prevState) => ({
        ...prevState,
        city_ids: isSelected
          ? prevState.city_ids.filter((id) => id !== itemId)
          : [...prevState.city_ids, itemId],
        gov_id: [],
      }));
    }
  };
  const handleLocationSearch = (event: any) => {
    const { value } = event.target;

    // If the user searched for an empty string,
    // display all data.
    if (value.length === 0) {
      setLocationSearchResults(data);
      return;
    }

    const results = fuse.search(value);
    const items = results.map((result) => result.item);
    setLocationSearchResults(items);
  };

  const handleChangeBreederStatus = (itemId: number, itemName: string) => {
    setSelectedAccountStatusId(itemId === selectedAccountStatusId ? 0 : itemId);
    setFilterObject((prevState) => ({
      ...prevState,
      ["account_status"]: Number(itemId),
    }));
  };

  const handleChangeBreederCreatedByRole = (
    itemId: number,
    itemName: string
  ) => {
    const isSelected = itemId === selectedCreatedRoleId;
    setSelectedCreatedRoleId(isSelected ? 0 : itemId);

    setFilterObject((prevState) => {
      const updatedRoles = isSelected
        ? prevState.created_by_role.filter((id) => id !== itemId)
        : [...prevState.created_by_role, itemId];

      if (updatedRoles.includes(900)) {
        setShowSalesManList(true);
      } else {
        setShowSalesManList(false);
      }

      return {
        ...prevState,
        created_by_role: updatedRoles,
      };
    });

    // if (
    //   filterObject.created_by_role.includes(900) ||
    //   (itemId === 900 && isSelected === false)
    // ) {
    //   setShowSalesManList(true);
    // } else {
    //   setShowSalesManList(false);
    // }
  };

  const handleChangeIncubator = (itemId: number, itemName: string) => {
    setSelectedIncubatorTypeId(itemId === selectedIncubatorTypeId ? 0 : itemId);

    switch (itemId) {
      case 1:
        setFilterObject((prevState) => ({
          ...prevState,
          ["incubator"]: true,
        }));
        break;

      case 2:
        setFilterObject((prevState) => ({
          ...prevState,
          ["incubator"]: false,
        }));
        break;

      default:
        break;
    }
  };

  const handleChangePaymentMethod = (itemId: number, itemName: string) => {
    setSelectedPaymentMethodId(itemId === selectedPaymentMethodId ? 0 : itemId);

    switch (itemId) {
      case 1:
        setFilterObject((prevState) => ({
          ...prevState,
          ["pay_method"]: "cash",
        }));
        break;

      case 2:
        setFilterObject((prevState) => ({
          ...prevState,
          ["pay_method"]: "credit",
        }));
        break;

      default:
        break;
    }
  };

  const handleChangeBreederNumber = (Number: number) => {
    setFilterObject((prevState) => ({
      ...prevState,
      ["mobile"]: Number,
    }));
  };

  const handleChangeSalesManContent = (itemId: number, itemName: string) => {
    setSelectedSalesManId(itemId === selectedSalesManId ? 0 : itemId);
    setFilterObject((prevState) => ({
      ...prevState,
      ["created_by_id"]: Number(itemId),
    }));
  };

  const handleSubmitFilter = () => {
    setIsLoading(true);

    let type = 1;
    let filterObj: {}[] = [];
    let isFiltered = true;
    let totalCount = 0;

    const filterUrl = generateFilterUrl(filterObject, isEfb);
    API.get(filterUrl).then((res: any) => {
      totalCount = res.data.totalCount;
      filterObj.push(res.data.items);
      setIsLoading(false);
      dispatch(
        handleSubmitFilterResult({
          type,
          filterObj,
          isFiltered,
          totalCount,
          filterUrl,
        })
      );
    });
  };

  return (
    <div className="BreederFilter">
      {isLoading === true ? <Loading /> : null}
      <input className="modal-state" id="modal-breeder" type="checkbox" />
      <div className="modal w-screen " style={{ direction: "rtl" }}>
        <label className="modal-overlay" htmlFor="modal-breeder"></label>
        <div
          className="modal-content flex flex-col gap-5 max-w-3xl "
          style={{ width: "580px" }}
        >
          <div className="accordion-group">
            {/* المكان */}
            <div className="accordion">
              <input
                type="checkbox"
                id="accordion-location"
                className="accordion-toggle"
              />
              <label htmlFor="accordion-location" className="head mt-2">
                المكان
              </label>
              <div className="divider"></div>
              <div className="accordion-content">
                <div className="min-h-0">
                  <div className="flex gap-8 items-center">
                    <div className="flex gap-3 items-center">
                      <input
                        type="checkbox"
                        className="checkbox checkbox-bordered-success"
                        onChange={() =>
                          handleChangeLocationType(1, "governorate")
                        }
                        checked={1 === selectedItemId}
                      />
                      <span className="txt">المحافظة</span>
                    </div>

                    <div className="flex gap-3 items-center">
                      <input
                        type="checkbox"
                        className="checkbox checkbox-bordered-success"
                        onChange={() => handleChangeLocationType(2, "city")}
                        checked={2 === selectedItemId}
                      />
                      <span className="txt">المنطقة</span>
                    </div>
                  </div>

                  <div className="form-control relative w-full mt-2">
                    <input
                      type="text"
                      className="input input-lg max-w-full input-search"
                      onChange={handleLocationSearch}
                      placeholder={
                        selectedItemId === 1
                          ? "ابحث عن المحافظة"
                          : "ابحث عن المنطقة"
                      }
                    />

                    <span className="absolute inset-y-0 right-4 inline-flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
                          stroke="#64748B"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M17.4998 17.4998L14.1665 14.1665"
                          stroke="#64748B"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                  <div className=" h-32 overflow-y-auto mt-4">
                    <ul className="list-none scrollable-list ">
                      {locationSearchResults.map((item) => {
                        return (
                          <>
                            {isLoading === true ? (
                              <div className="skeleton-pulse h-5 rounded-md mb-2"></div>
                            ) : (
                              <li className="flex justify-between pl-4 py-2">
                                <span className="list-text">{item.name}</span>
                                <div>
                                  <input
                                    type="checkbox"
                                    className="checkbox checkbox-bordered-success"
                                    onChange={() =>
                                      handleChangeLocationContent(
                                        item.id,
                                        item.name
                                      )
                                    }
                                    checked={
                                      selectedItemId === 1
                                        ? filterObject.gov_ids.includes(item.id)
                                        : filterObject.city_ids.includes(
                                            item.id
                                          )
                                    }
                                  />
                                </div>
                              </li>
                            )}
                          </>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* تربية منزليه */}
            <div className="accordion">
              <input
                type="checkbox"
                id="accordion-1"
                className="accordion-toggle"
              />
              <label htmlFor="accordion-1" className="head ">
                تربية منزلية
              </label>
              <div className="divider"></div>

              <div className="accordion-content">
                <div className="min-h-0">
                  <div className="flex gap-8 items-center">
                    <div className="flex gap-3 items-center">
                      <input
                        type="checkbox"
                        className="checkbox checkbox-bordered-success"
                        onChange={() => handleChangeHomeBreeding(1, "true")}
                        checked={1 === selectedHomeBreedingTypeId}
                      />
                      <span className="txt">نعم</span>
                    </div>
                    <div className="flex gap-3 items-center">
                      <input
                        type="checkbox"
                        className="checkbox checkbox-bordered-success"
                        onChange={() => handleChangeHomeBreeding(2, "false")}
                        checked={2 === selectedHomeBreedingTypeId}
                      />
                      <span className="txt">لا</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* مربي أعمار */}
            <div className="accordion">
              <input
                type="checkbox"
                id="accordion-2"
                className="accordion-toggle"
              />
              <label htmlFor="accordion-2" className="head mt-2">
                مربي أعمار
              </label>
              <div className="divider"></div>
              <div className="accordion-content">
                <div className="min-h-0">
                  <div className="flex gap-8 items-center">
                    <div className="flex gap-3 items-center">
                      <input
                        type="checkbox"
                        className="checkbox checkbox-bordered-success"
                        onChange={() => handleChangeIncubator(1, "true")}
                        checked={1 === selectedIncubatorTypeId}
                      />
                      <span className="txt">نعم</span>
                    </div>
                    <div className="flex gap-3 items-center">
                      <input
                        type="checkbox"
                        className="checkbox checkbox-bordered-success"
                        onChange={() => handleChangeIncubator(2, "false")}
                        checked={2 === selectedIncubatorTypeId}
                      />
                      <span className="txt">لا</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* وسيلة الدفع */}
            <div className="accordion">
              <input
                type="checkbox"
                id="accordion-3"
                className="accordion-toggle"
              />
              <label htmlFor="accordion-3" className="head ">
                وسيله الدفع
              </label>
              <div className="divider"></div>

              <div className="accordion-content">
                <div className="min-h-0">
                  <div className="flex gap-8 items-center">
                    <div className="flex gap-3 items-center">
                      <input
                        type="checkbox"
                        className="checkbox checkbox-bordered-success"
                        onChange={() => handleChangePaymentMethod(1, "cash")}
                        checked={1 === selectedPaymentMethodId}
                      />
                      <span className="txt">كاش</span>
                    </div>
                    <div className="flex gap-3 items-center">
                      <input
                        type="checkbox"
                        className="checkbox checkbox-bordered-success"
                        onChange={() => handleChangePaymentMethod(2, "credit")}
                        checked={2 === selectedPaymentMethodId}
                      />
                      <span className="txt">إئتمان</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* الحالة */}
            <div className="accordion mb-2">
              <input
                type="checkbox"
                id="accordion-status"
                className="accordion-toggle"
              />
              <label htmlFor="accordion-status" className="head ">
                حالة التفعيل
              </label>
              <div className="divider"></div>

              <div className="accordion-content">
                <div className="min-h-0">
                  <div className="flex gap-8 items-center">
                    <div className="flex gap-3 items-center">
                      <input
                        type="checkbox"
                        className="checkbox checkbox-bordered-success"
                        onChange={() => handleChangeBreederStatus(2, "مفعل")}
                        checked={1 === selectedAccountStatusId}
                      />
                      <span className="txt">مفعل</span>
                    </div>
                    <div className="flex gap-3 items-center">
                      <input
                        type="checkbox"
                        className="checkbox checkbox-bordered-success"
                        onChange={() =>
                          handleChangeBreederStatus(1, "غير مفعل")
                        }
                        checked={2 === selectedAccountStatusId}
                      />
                      <span className="txt">غير مفعل</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* مصدر المربي */}
            <div className="accordion mb-2">
              <input
                type="checkbox"
                id="accordion-source"
                className="accordion-toggle"
              />
              <label htmlFor="accordion-source" className="head ">
                مصدر المربي
              </label>
              <div className="divider"></div>

              <div className="accordion-content">
                <div className="min-h-0">
                  <div className="flex gap-8 items-center">
                    <div className="flex gap-3 items-center">
                      <input
                        type="checkbox"
                        className="checkbox checkbox-bordered-success"
                        onChange={() =>
                          handleChangeBreederCreatedByRole(2, "ORGANIC")
                        }
                        checked={filterObject.created_by_role.includes(2)}
                      />
                      <span className="txt">اورجانيك</span>
                    </div>
                    <div className="flex gap-3 items-center">
                      <input
                        type="checkbox"
                        className="checkbox checkbox-bordered-success"
                        onChange={() =>
                          handleChangeBreederCreatedByRole(900, "SALES_REP")
                        }
                        checked={filterObject.created_by_role.includes(900)}
                      />
                      <span className="txt">سيلز</span>
                    </div>
                  </div>
                  {showSalesManList === true ? (
                    <>
                      <div className="form-control relative w-full mt-2">
                        <input
                          type="text"
                          className="input input-lg max-w-full input-search"
                          onChange={handleSalesManSearch}
                          placeholder="ابحث عن المندوب"
                        />

                        <span className="absolute inset-y-0 right-4 inline-flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
                              stroke="#64748B"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M17.4998 17.4998L14.1665 14.1665"
                              stroke="#64748B"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                      </div>
                      <div className="h-32 overflow-y-auto mt-4">
                        <ul className="list-none scrollable-list">
                          {salesManSearchResults.map((item) => {
                            return (
                              <>
                                {isLoading === true ? (
                                  <div className="skeleton-pulse h-5 rounded-md mb-2"></div>
                                ) : (
                                  <li className="flex justify-between pl-4 py-2">
                                    <span className="list-text">
                                      {item.name}
                                    </span>
                                    <div>
                                      <input
                                        type="checkbox"
                                        className="checkbox checkbox-bordered-success"
                                        onChange={() =>
                                          handleChangeSalesManContent(
                                            item.id,
                                            item.name
                                          )
                                        }
                                        checked={item.id === selectedSalesManId}
                                      />
                                    </div>
                                  </li>
                                )}
                              </>
                            );
                          })}
                        </ul>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>

            {/* بنك الطعام */}
            <div className="accordion">
              <input
                type="checkbox"
                id="accordion-is_efb"
                className="accordion-toggle"
              />
              <label htmlFor="accordion-is_efb" className="head mt-2">
                بنك الطعام{" "}
              </label>
              <div className="divider"></div>
              <div className="accordion-content">
                <div className="min-h-0">
                  <div className="flex gap-8 items-center">
                    <div className="flex gap-3 items-center">
                      <input
                        type="checkbox"
                        className="checkbox checkbox-bordered-success"
                        onChange={() => setIsEfb(!isEfb)}
                        checked={isEfb}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* رقم الهاتف */}
            <div className="accordion">
              <input
                type="checkbox"
                id="accordion-4"
                className="accordion-toggle"
              />
              <label htmlFor="accordion-4" className="head ">
                رقم الهاتف
              </label>
              <div className="divider"></div>

              <div className="accordion-content">
                <div className="min-h-0">
                  <div className="flex gap-2 items-center">
                    <span className="txt">رقم المربي :</span>

                    <div className="flex gap-3 items-center">
                      <input
                        type="number"
                        className="mission-map-input"
                        onChange={(e) =>
                          handleChangeBreederNumber(Number(e.target.value))
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-4">
            <div className="grid grid-cols-2">
              <div className="col-span-full">
                <label htmlFor="modal-breeder">
                  <div
                    className="add-btn w-full p-4"
                    onClick={() => handleSubmitFilter()}
                  >
                    تأكيد
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
