import "./UsersFilter.scss";
import Fuse from "fuse.js";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import API from "../../../Api";
import { Loading } from "../../Loading/Loading";
import { handleSubmitUsersFilterResult } from "../../../redux/Slices/UsersSlice";

interface FilterObject {
  gov_id: number[];
  city_id: number[];
  work_area_ids: number[];
  specialty_id: number;
  mobile: number;
  name: string;
}

export const UsersFilter = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setLocationData] = useState([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [workRangeData, setWorkRangeData] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState(1);
  const [selectedItemName, setSelectedItemName] = useState("");
  const [selectedTypeId, setSelectedTypeId] = useState(0);
  const [selectedTypeName, setSelectedTypeName] = useState("");
  const [result, setResult] = useState<any[]>([{}]);
  const [groupedItems, setGroupedItems] = useState<Record<string, any>>({});
  const [workRangeSearchResults, setWorkRangecSearchResults] = useState<any[]>([
    {},
  ]);
  const [specializationSearchResults, setSpecializationSearchResults] =
    useState<any[]>([{}]);
  const [selectedItemId, setSelectedItemId] = useState(1);
  const [locationSearchResults, setLocationSearchResults] = useState<any[]>([
    {},
  ]);
  const [filterObject, setFilterObject] = useState<{
    gov_id: number[];
    city_id: number[];
    work_area_ids: number[];
    name: string;
    mobile: number;
    specialty_id: number;
  }>({
    gov_id: [],
    city_id: [],
    work_area_ids: [],
    name: "",
    mobile: 0,

    specialty_id: 0,
  });

  const options = {
    includeScore: true,
    includeMatches: true,
    threshold: 0.2,
    keys: ["name"],
  };

  const workRangeOptions = {
    includeScore: true,
    includeMatches: true,
    threshold: 0.2,
    keys: ["name"],
  };

  const Specialization_Options = {
    includeScore: true,
    includeMatches: true,
    threshold: 0.2,
    keys: ["name"],
  };
  const fuse = new Fuse(locationSearchResults, options);
  const Specialization_Fuse = new Fuse(
    specializationSearchResults,
    Specialization_Options
  );

  const work_Range_Fuse = new Fuse(workRangeSearchResults, workRangeOptions);

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    setIsLoading(true);
    //governorates API
    API.get(`governorates`).then((res) => {
      if (res) {
        if (res.status === 403) {
          toast.error(" عفوا انت ليس لديك صلاحية الوصول لهذه الصفحة ");
        } else {
          setLocationSearchResults(res.data);
          setLocationData(res.data);
          setIsLoading(false);
        }
      }
    });
    //specialties API
    API.get(`specialties`).then((res) => {
      setIsLoading(true);

      if (res) {
        if (res.status === 403) {
          toast.error(" عفوا انت ليس لديك صلاحية الوصول لهذه الصفحة ");
        } else {
          setSpecializationSearchResults(res.data);

          setIsLoading(false);
        }
      }
    });
    //Work Range End Point
    API.get(`work-areas`).then((res) => {
      setIsLoading(true);

      if (res) {
        if (res.status === 403) {
          toast.error(" عفوا انت ليس لديك صلاحية الوصول لهذه الصفحة ");
        } else {
          setResult(res.data);
          const grouped = res.data.reduce(
            (grouped: Record<string, any>, item: any) => {
              const { id, name, governorate } = item;
              const governorateId = governorate.id;

              if (!grouped[governorateId]) {
                grouped[governorateId] = {
                  governorate: governorate.name,
                  items: [],
                };
              }
              const modifiedId = `${id}_city`;

              grouped[governorateId].items.push({ id: modifiedId, name });

              return grouped;
            },
            {}
          );

          setGroupedItems(grouped);
          setWorkRangecSearchResults(res.data);
          setWorkRangeData(grouped);
          setIsLoading(false);
        }
      }
    });
  }, []);

  const handleSpecializationSearch = (event: any) => {
    const { value } = event.target;

    // If the user searched for an empty string,
    // display all data.
    if (value.length === 0) {
      setSpecializationSearchResults(data);
      return;
    }

    const results = Specialization_Fuse.search(value);
    const items = results.map((result) => result.item);
    setSpecializationSearchResults(items);
  };

  const handleWorkRangeSearch = (event: any) => {
    setIsLoading(true);

    const { value } = event.target;

    if (value.length === 0) {
      var defaulList = groupedItems;
      setGroupedItems(defaulList);
      setIsLoading(false);
      return;
    }

    const results = work_Range_Fuse.search(value);
    const items = results.map((result) => result.item);
    const grouped = items.reduce((grouped: Record<string, any>, item: any) => {
      const { id, name, governorate } = item;
      const governorateId = governorate.id;

      if (!grouped[governorateId]) {
        grouped[governorateId] = { governorate: governorate.name, items: [] };
      }
      const modifiedId = `${id}_city`;

      grouped[governorateId].items.push({ id: modifiedId, name });

      return grouped;
    }, {});
    setGroupedItems(grouped);

    setIsLoading(false);
  };
  const handleChangeType = (itemId: number, itemName: string) => {
    setSelectedItemId(itemId === selectedItemId ? 0 : itemId);
    setSelectedItemName(itemId === selectedItemId ? "null" : itemName);
    setFilterObject((prevState) => ({
      ...prevState,
      ["specialty_id"]: Number(itemId),
    }));
  };

  const handleChangeLocationType = (itemId: number, itemName: string) => {
    setIsLoading(true);
    setSelectedLocationId(0);
    setSelectedItemId(itemId === selectedItemId ? 0 : itemId);
    setSelectedItemName(itemId === selectedItemId ? "null" : itemName);
    if (itemId === 2) {
      API.get(`cities`).then((res) => {
        if (res) {
          if (res.status === 403) {
            toast.error(" عفوا انت ليس لديك صلاحية الوصول لهذه الصفحة ");
          } else {
            setLocationSearchResults(res.data);
            setLocationData(res.data);
            setIsLoading(false);
          }
        }
      });
    } else {
      API.get(`governorates`).then((res) => {
        if (res) {
          if (res.status === 403) {
            toast.error(" عفوا انت ليس لديك صلاحية الوصول لهذه الصفحة ");
          } else {
            setLocationSearchResults(res.data);
            setLocationData(res.data);
            setIsLoading(false);
          }
        }
      });
    }
  };

  const handleLocationSearch = (event: any) => {
    const { value } = event.target;

    // If the user searched for an empty string,
    // display all data.
    if (value.length === 0) {
      setLocationSearchResults(data);
      return;
    }

    const results = fuse.search(value);
    const items = results.map((result) => result.item);
    setLocationSearchResults(items);
  };
  const handleChangeUserNumber = (Number: number) => {
    setFilterObject((prevState) => ({
      ...prevState,
      ["mobile"]: Number,
    }));
  };

  const handleChangeUserName = (item: string) => {
    setFilterObject((prevState) => ({
      ...prevState,
      ["name"]: item,
    }));
  };

  const handleItemToggle = (itemId: string) => {
    if (selectedItems.includes(itemId)) {
      setSelectedItems(selectedItems.filter((id) => id !== itemId));
    } else {
      setSelectedItems([...selectedItems, itemId]);
    }
  };

  const handleParentToggle = (governorateId: string) => {
    const itemsToAdd =
      groupedItems[governorateId]?.items.map((item: any) => item.id) || [];

    if (selectedItems.includes(governorateId)) {
      let newSelected = selectedItems.filter((item) => item !== governorateId);

      setSelectedItems(newSelected.filter((id) => !itemsToAdd.includes(id)));
    } else {
      setSelectedItems([...selectedItems, ...itemsToAdd, governorateId]);
    }
  };

  const handleChangeLocationContent = (itemId: number, itemName: string) => {
    const isSelected = itemId === selectedLocationId;
    setSelectedLocationId(isSelected ? 0 : itemId);

    if (selectedItemId === 1) {
      // محافظات
      setFilterObject((prevState) => ({
        ...prevState,
        gov_id: isSelected
          ? prevState.gov_id.filter((id) => id !== itemId)
          : [...prevState.gov_id, itemId],
        city_id: [],
      }));
    } else {
      // مناطق
      setFilterObject((prevState) => ({
        ...prevState,
        city_id: isSelected
          ? prevState.city_id.filter((id) => id !== itemId)
          : [...prevState.city_id, itemId],
        gov_id: [],
      }));
    }
  };
  const generateParam = (key: string, value: any): string => {
    if (Array.isArray(value)) {
      const params = value.map((val) => `${key}=${val}`).join("&");
      return params ? `${params}&` : "";
    }
    return value !== 0 && value !== "" && value !== false
      ? `${key}=${value}&`
      : "";
  };
  // Define a function to generate the filter URL
  // Define a function to generate the filter URL
  const generateFilterUrl = (
    status: string,
    filterObject: FilterObject
  ): string => {
    const params: string[] = [
      generateParam("specialty_id", filterObject.specialty_id),
      generateParam("gov_ids", filterObject.gov_id),
      generateParam("city_ids", filterObject.city_id),
      generateParam("work_area_ids", filterObject.work_area_ids),
      generateParam("name", filterObject.name),
      generateParam("mobile", filterObject.mobile),
    ];

    // Filter out empty parameters and join them with '&'
    const queryParams = params.filter((param) => param !== "").join("");

    return `dashboard/salesman?account_status=${status}&sort_by=id&sort_order=DESC&${queryParams}`;
  };

  const handleSubmitFilter = () => {
    setIsLoading(true);

    let status = `2`;
    let type = 0;
    let filterObj: {}[] = [];
    let isFiltered = true;
    let totalCount = 0;
    const cityValues = selectedItems
      .filter((value) => value.includes("_city"))
      .map((value) => value.replace("_city", ""));
    filterObject.work_area_ids = cityValues.map((item: any) => Number(item));

    if (location.pathname.includes("ActiveSalesMan")) {
      type = 1;
      status = `2`;
      const filterUrl = generateFilterUrl(status, filterObject);

      API.get(`${filterUrl}`).then((res: any) => {
        totalCount = res.data.totalCount;
        filterObj.push(res.data.items);
        setIsLoading(false);
        dispatch(
          handleSubmitUsersFilterResult({
            type,
            filterObj,
            isFiltered,
            totalCount,
          })
        );
      });
    } else if (location.pathname.includes("DisabledSalesMan")) {
      type = 2;
      status = `1`;

      const filterUrl = generateFilterUrl(status, filterObject);

      API.get(`${filterUrl}`).then((res: any) => {
        totalCount = res.data.totalCount;
        filterObj.push(res.data.items);
        setIsLoading(false);
        dispatch(
          handleSubmitUsersFilterResult({
            type,
            filterObj,
            isFiltered,
            totalCount,
          })
        );
      });
    } else if (location.pathname.includes("StoppedSalesMan")) {
      type = 3;
      status = `3`;

      const filterUrl = generateFilterUrl(status, filterObject);

      API.get(`${filterUrl}`).then((res: any) => {
        totalCount = res.data.totalCount;
        filterObj.push(res.data.items);
        setIsLoading(false);
        dispatch(
          handleSubmitUsersFilterResult({
            type,
            filterObj,
            isFiltered,
            totalCount,
          })
        );
      });
    }
  };

  return (
    <div className="UsersFilter">
      {isLoading === true ? <Loading /> : null}
      <input className="modal-state" id="modal-2200" type="checkbox" />
      <div className="modal w-screen " style={{ direction: "rtl" }}>
        <label className="modal-overlay" htmlFor="modal-2200"></label>
        <div
          className="modal-content flex flex-col gap-5 max-w-3xl "
          style={{ width: "580px" }}
        >
          <div className="accordion-group">
            {/* أسم المندوب */}
            <div className="accordion">
              <input
                type="checkbox"
                id="accordion-name"
                className="accordion-toggle"
              />
              <label htmlFor="accordion-name" className="head ">
                أسم المندوب
              </label>
              <div className="divider"></div>

              <div className="accordion-content">
                <div className="min-h-0">
                  <div className="flex gap-2 items-center">
                    <span className="txt">أسم المندوب :</span>

                    <div className="flex gap-3 items-center">
                      <input
                        type="text"
                        className="mission-map-input"
                        onChange={(e) => handleChangeUserName(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* رقم الهاتف */}
            <div className="accordion">
              <input
                type="checkbox"
                id="accordion-mobile"
                className="accordion-toggle"
              />
              <label htmlFor="accordion-mobile" className="head ">
                رقم الهاتف
              </label>
              <div className="divider"></div>

              <div className="accordion-content">
                <div className="min-h-0">
                  <div className="flex gap-2 items-center">
                    <span className="txt">رقم المندوب :</span>

                    <div className="flex gap-3 items-center">
                      <input
                        type="number"
                        className="mission-map-input"
                        onChange={(e) =>
                          handleChangeUserNumber(Number(e.target.value))
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* المكان */}
            <div className="accordion">
              <input
                type="checkbox"
                id="accordion-2"
                className="accordion-toggle"
              />
              <label htmlFor="accordion-2" className="head mt-2">
                المكان
              </label>
              <div className="divider"></div>
              <div className="accordion-content">
                <div className="min-h-0">
                  <div className="flex gap-8 items-center">
                    <div className="flex gap-3 items-center">
                      <input
                        type="checkbox"
                        className="checkbox checkbox-bordered-success"
                        onChange={() =>
                          handleChangeLocationType(1, "governorate")
                        }
                        checked={1 === selectedItemId}
                      />
                      <span className="txt">المحافظة</span>
                    </div>

                    <div className="flex gap-3 items-center">
                      <input
                        type="checkbox"
                        className="checkbox checkbox-bordered-success"
                        onChange={() => handleChangeLocationType(2, "city")}
                        checked={2 === selectedItemId}
                      />
                      <span className="txt">المنطقة</span>
                    </div>
                  </div>

                  <div className="form-control relative w-full mt-2">
                    <input
                      type="text"
                      className="input input-lg max-w-full input-search"
                      onChange={handleLocationSearch}
                      placeholder={
                        selectedItemId === 1
                          ? "ابحث عن المحافظة"
                          : "ابحث عن المنطقة"
                      }
                    />

                    <span className="absolute inset-y-0 right-4 inline-flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
                          stroke="#64748B"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M17.4998 17.4998L14.1665 14.1665"
                          stroke="#64748B"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                  <div className=" h-32 overflow-y-auto mt-4">
                    <ul className="list-none scrollable-list ">
                      {locationSearchResults.map((item) => {
                        return (
                          <>
                            {isLoading === true ? (
                              <div className="skeleton-pulse h-5 rounded-md mb-2"></div>
                            ) : (
                              <li className="flex justify-between pl-4 py-2">
                                <span className="list-text">{item.name}</span>
                                <div>
                                  <input
                                    type="checkbox"
                                    className="checkbox checkbox-bordered-success"
                                    onChange={() =>
                                      handleChangeLocationContent(
                                        item.id,
                                        item.name
                                      )
                                    }
                                    checked={
                                      selectedItemId === 1
                                        ? filterObject.gov_id.includes(item.id)
                                        : filterObject.city_id.includes(item.id)
                                    }
                                  />
                                </div>
                              </li>
                            )}
                          </>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* التخصصات */}
            <div className="accordion">
              <input
                type="checkbox"
                id="accordion-3"
                className="accordion-toggle"
              />
              <label htmlFor="accordion-3" className="head mt-2">
                التخصص
              </label>
              <div className="divider"></div>
              <div className="accordion-content">
                <div className="min-h-0">
                  <div className="form-control relative w-full">
                    <input
                      type="text"
                      className="input input-lg max-w-full input-search"
                      onChange={handleSpecializationSearch}
                      placeholder="ابحث عن تخصص"
                    />

                    <span className="absolute inset-y-0 right-4 inline-flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
                          stroke="#64748B"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M17.4998 17.4998L14.1665 14.1665"
                          stroke="#64748B"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                  <div className="h-32 overflow-y-auto mt-4">
                    <ul className="list-none scrollable-list">
                      {specializationSearchResults.map((item) => {
                        return (
                          <>
                            {isLoading === true ? (
                              <div className="skeleton-pulse h-5 rounded-md mb-2"></div>
                            ) : (
                              <li className="flex justify-between pl-4 py-2">
                                <span className="list-text">{item.name}</span>
                                <div>
                                  <input
                                    type="checkbox"
                                    className="checkbox checkbox-bordered-success"
                                    onChange={() =>
                                      handleChangeType(item.id, item.name)
                                    }
                                    checked={item.id === selectedItemId}
                                  />
                                </div>
                              </li>
                            )}
                          </>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* نطاق العمل */}
            <div className="accordion">
              <input
                type="checkbox"
                id="accordion-4"
                className="accordion-toggle"
              />
              <label htmlFor="accordion-4" className="head mt-2">
                نطاق العمل
              </label>
              <div className="divider"></div>
              <div className="accordion-content">
                <div className="min-h-0">
                  <div className="form-control relative w-full">
                    <input
                      type="text"
                      className="input input-lg max-w-full input-search"
                      onChange={handleWorkRangeSearch}
                      placeholder="ابحث عن نطاق العمل"
                    />

                    <span className="absolute inset-y-0 right-4 inline-flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
                          stroke="#64748B"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M17.4998 17.4998L14.1665 14.1665"
                          stroke="#64748B"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                  <div className="h-32 overflow-y-auto mt-4">
                    <ul className="list-none scrollable-list">
                      {Object.entries(groupedItems).map(
                        ([governorateId, { governorate, items }]) => (
                          <>
                            <li
                              key={governorateId}
                              id={governorateId}
                              className="flex justify-between pl-4 py-2"
                            >
                              <span className="list-text">{governorate}</span>
                              <input
                                type="checkbox"
                                className="checkbox checkbox-bordered-success"
                                checked={selectedItems.includes(governorateId)}
                                onChange={() =>
                                  handleParentToggle(governorateId)
                                }
                              />

                              <ul className="list-none scrollable-list ">
                                {items.map((item: any) => (
                                  <li
                                    id={item.id}
                                    key={item.id}
                                    className="flex justify-between pl-4 py-2"
                                  >
                                    <span className="list-text">
                                      {item.name}
                                    </span>
                                    <div>
                                      <input
                                        type="checkbox"
                                        className="checkbox checkbox-bordered-success"
                                        checked={selectedItems.includes(
                                          item.id
                                        )}
                                        onChange={() =>
                                          handleItemToggle(item.id)
                                        }
                                      />
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </li>
                            <div className="divider"></div>
                          </>
                        )
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-4">
            <div className="grid grid-cols-2">
              <div className="col-span-full">
                <label htmlFor="modal-2200">
                  <div
                    className="add-btn w-full p-4"
                    onClick={() => handleSubmitFilter()}
                  >
                    تأكيد
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
