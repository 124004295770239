import "./EditBreeders.scss";
import eye from "../../../Assets/Icons/eye.svg";
import arrow from "../../../Assets/Icons/arrow.jpeg";
import { Cities } from "../../../SubComponents/Cities/Cities";
import { Governorates } from "../../../SubComponents/Governorates/Governorates";
import { Loading } from "../../Loading/Loading";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setMainHeaderName } from "../../../redux/Slices/MainHeaderSlice";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import API from "../../../Api";
import { Formik, FormikHelpers } from "formik";
import { toast } from "react-toastify";
import moment from "moment";
import * as Yup from "yup";

// Define the validation schema
const validationSchema = Yup.object().shape({
  mobile_number: Yup.string()
    .matches(/^01\d{9}$/, "من فضلك قم بإدخال رقم هاتف مكون من 11 رقم")
    .required("من فضلك قم بإدخال رقم الهاتف"),
  name: Yup.string().required("من فضلك قم بإدخال اسم المربي"),
  full_address: Yup.string().required("من فضلك قم بإدخال عنوان المربي"),
});
interface ApiResponse {
  id: string;
  created_at: string;
  name: string;
  village: string;
  city_id: string;
  full_address: string;
  mobile_number: string;
  whatsapp_number: string;
  facebook_account: string;
  email: string;
  avatar: string;
  approx_breeding_size: number;
  farms_count: number;
  activity_type_id: string;
  account_status: number;
  is_efb: boolean;
  national_id: string;
  specialty_id: string;
  level_id: string;
  ewallet_number: string;
  wallet_balance: number;
  activity_type: {
    id: string;
    name: string;
  };
  governorate: {
    created_at: string;
    updated_at: string;
    id: string;
    name: string;
  };
  city: {
    id: string;
    name: string;
  };
  password: string | null;
  re_password: string | null;
  pay_method: string | null;
}
export const EditBreeders = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stateFromFarmsSlice = useSelector((state: any) => state.farms);

  const { breederId } = useParams<{ breederId: string }>();
  const [apiResponse, setApiResponse] = useState<ApiResponse | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [showGovernoratePopUp, setShowGovernoratePopUp] = useState(false);
  const [showCityPopUp, setShowCityPopUp] = useState(false);

  const [activityType, setActivityType] = useState(1);
  const [activityText, setActivityText] = useState("اختر نوع النشاط");
  const [paymentMethod, setPaymentMethod] = useState(0);
  const [paymentMethodText, setPaymentMethodText] = useState("اختر");
  const [isHomeBreeding, setHomeBreeding] = useState(false);
  const [isIncubator, setIncubator] = useState(false);
  //المحافظة
  const [selectedGovernorateValue, setSelectedGovernorateValue] =
    useState<string>("0");
  const [selectedGovernorateName, setSelectedGovernorateName] =
    useState<string>("قم بأختيار المحافظة");
  //المدينة
  const [selectedCityValue, setSelectedCityValue] = useState<string>("0");
  const [selectedCityName, setSelectedCityName] =
    useState<string>("قم بأختيار المدينة");

  useEffect(() => {
    setIsLoading(true);
    let mainHeaderName = "تعديل مربي";
    dispatch(setMainHeaderName({ mainHeaderName }));
    API.get(`breeders/${breederId}`).then((res) => {
      if (res.status === 200) {
        setApiResponse(res.data);
        if (res.data.governorate !== null) {
          setSelectedGovernorateName(res.data.governorate.name);
          setSelectedGovernorateValue(res.data.governorate.id);
          if (res.data.city != null) {
            setSelectedCityName(res.data.city.name);
            setSelectedCityValue(res.data.city.id);
          }
        }
        if (res.data.pay_method !== null) {
          setPaymentMethodText(
            res.data.pay_method === "credit" ? "إئتمان" : "كاش"
          );
          setPaymentMethod(res.data.pay_method === "credit" ? 1 : 2);
        }
        if (res.data.activity_type !== null) {
          setActivityText(res.data.activity_type.name);
        }
        if (res.data.home_breeding === null || res.data.home_breeding === 0) {
          setHomeBreeding(false);
        } else {
          setHomeBreeding(true);
        }
        if (res.data.incubator === null || res.data.incubator === 0) {
          setIncubator(false);
        } else {
          setIncubator(true);
        }
        setIsLoading(false);
      }
    });
  }, []);

  const handleGovernoratePopUp = () => {
    setShowGovernoratePopUp(!showGovernoratePopUp);
  };
  const handleCityPopUp = () => {
    setShowCityPopUp(!showCityPopUp);
  };
  const handleChangeActivityType = (id: number) => {
    setActivityType(id);
    if (id === 1) {
      setActivityText("فردي");
    } else if (id === 2) {
      setActivityText("مؤسسي");
    } else {
      setActivityText("اختر");
    }
  };

  const handleChangePaymentMethod = (id: number) => {
    setPaymentMethod(id);
    if (id === 1) {
      setPaymentMethodText("إئتمان");
    } else if (id === 2) {
      setPaymentMethodText("كاش");
    } else {
      setPaymentMethodText("كاش");
    }
  };

  const handleChangeBreeder = (
    value: string | number | boolean,
    field: string,
    setValues: FormikHelpers<any>["setValues"]
  ) => {
    // Update the Formik form state with the changed values

    setValues((prevValues: { apiResponse: ApiResponse }) => ({
      ...prevValues,
      apiResponse: {
        ...prevValues.apiResponse,
        [field]: value,
      },
    }));
    if (apiResponse) {
      // Create a new object with the updated field
      const updatedApiResponse: ApiResponse = {
        ...apiResponse,
        [field]: value, // Replace 'New Name' with the new value
      };

      // Update the state with the new object
      setApiResponse(updatedApiResponse);
    }
  };
  const handleEditBreeder = () => {
    setIsLoading(true);
    if (selectedGovernorateValue === "0" || selectedCityValue === "0") {
      toast.warn("من فضلك قم بأختيار المدينة");
    } else {
      moment.locale("en");
      let values = {
        name: apiResponse?.name,
        email: apiResponse?.email,
        whatsapp_number: apiResponse?.whatsapp_number,
        full_address: apiResponse?.full_address,
        city_id: Number(selectedCityValue),

        activityTypeId: activityType,
        approximateBreedingSize: apiResponse?.approx_breeding_size,
        farmsCount: apiResponse?.farms_count,
        home_breeding: isHomeBreeding,
        incubator: isIncubator,
        pay_method: paymentMethodText === "كاش" ? "cash" : "credit",
        mobile_number: apiResponse?.mobile_number,
      };

      API.patch(`breeders/${breederId}`, values)
        .then((response) => {
          if (response) {
            toast.success("تمت تعديل المربي بنجاح");

            setIsLoading(false);
            navigate(-1);
          }
        })
        .catch((error) => {
          console.log("error:", error);
          setIsLoading(false);

          toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
        });
    }
  };

  const handleSelectGovernorateChange = (value: string, name: string) => {
    setSelectedGovernorateValue(value);
    setSelectedGovernorateName(name);
    setSelectedCityName("اختر المدينة");
    setSelectedCityValue("0");
  };

  const handleSelectCityChange = (value: string, name: string) => {
    setSelectedCityValue(value);
    setSelectedCityName(name);
  };

  return (
    <div className="EditBreeders">
      {showGovernoratePopUp === true ? (
        <Governorates
          isEdit={false}
          selectedValue={selectedGovernorateValue}
          onSelectChange={handleSelectGovernorateChange}
        />
      ) : null}
      {showCityPopUp === true ? (
        <Cities
          isEdit={false}
          selectedValue={selectedCityValue}
          onSelectChange={handleSelectCityChange}
          govId={Number(selectedGovernorateValue)}
        />
      ) : null}
      {isLoading === true ? <Loading /> : null}

      {/* المعلومات الرئيسية */}
      <Formik
        onSubmit={() => handleEditBreeder()}
        enableReinitialize
        initialValues={{
          name: apiResponse?.name,
          email: apiResponse?.email,
          whatsapp_number: apiResponse?.whatsapp_number,
          full_address: apiResponse?.full_address,
          city_id: Number(selectedCityValue),
          activityTypeId: activityType,
          approximateBreedingSize: apiResponse?.approx_breeding_size,
          farmsCount: apiResponse?.farms_count,
          home_breeding: isHomeBreeding,
          incubator: isIncubator,
          pay_method: paymentMethodText === "كاش" ? "cash" : "credit",
          mobile_number: apiResponse?.mobile_number,
        }}
        validationSchema={validationSchema}
        key={`EditBreeder`}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setValues,
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              <div className="main-info-section mt-4">
                <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 xs:grid-cols-2 gap-4 px-4">
                  {/* Header */}
                  <div className="col-span-full pr-4 pt-4 flex items-center">
                    <div className="main-info-title w-1/2">
                      المعلومات الرئيسية
                    </div>
                  </div>
                  <div className="col-span-full">
                    <div className="divider"></div>
                  </div>

                  {/* الأسم ثلاثي */}
                  <div className="flex flex-col lg:col-span-1 md:col-span-full sm:col-span-full xs:col-span-full pr-4">
                    <p className="title">الاسم ثلاثي</p>
                    <div className="form-control relative mt-4">
                      <input
                        name="name"
                        id="name"
                        placeholder="أسم المربي"
                        type="text"
                        className={
                          errors.name && touched.name
                            ? "input-err"
                            : "mission-address-input"
                        }
                        onChange={(e) => {
                          handleChange(e);

                          handleChangeBreeder(
                            e.target.value,
                            "name",
                            setValues
                          );
                        }}
                        onBlur={handleBlur}
                        value={values.name}
                      />
                      {errors.name && touched.name && (
                        <div
                          className="error err-msg"
                          style={{
                            position: "absolute",
                            bottom: "-20px",
                            left: "0",
                            width: "100%",
                          }}
                        >
                          {errors.name}
                        </div>
                      )}
                    </div>
                  </div>
                  {/* البريد الالكتروني */}
                  <div className="flex flex-col lg:col-span-1 md:col-span-full sm:col-span-full xs:col-span-full pr-4">
                    <p className="title">البريد الالكتروني</p>
                    <input
                      className="mission-address-input mt-4"
                      type="text"
                      placeholder="البريد الالكتروني"
                      value={apiResponse?.email}
                      onChange={(e) =>
                        handleChangeBreeder(e.target.value, "email", setValues)
                      }
                    />
                  </div>

                  {/* رقم الهاتف */}
                  <div className="flex flex-col lg:col-span-1 md:col-span-full sm:col-span-full xs:col-span-full pr-4">
                    <p className="title">رقم الهاتف</p>
                    <div className="form-control relative mt-4">
                      <input
                        type="tel"
                        placeholder="رقم الهاتف"
                        className={
                          errors.mobile_number && touched.mobile_number
                            ? "input-err"
                            : "mission-address-input"
                        }
                        onBlur={handleBlur}
                        value={values.mobile_number}
                        name="mobile_number"
                        id="mobile_number"
                        onChange={(e) => {
                          handleChange(e);
                          handleChangeBreeder(
                            e.target.value,
                            "mobile_number",
                            setValues
                          );
                        }}
                      />

                      {errors.mobile_number && touched.mobile_number && (
                        <div
                          className="error err-msg"
                          style={{
                            position: "absolute",
                            bottom: "-20px",
                            left: "0",
                            width: "100%",
                          }}
                        >
                          {errors.mobile_number}
                        </div>
                      )}
                    </div>
                  </div>

                  {/* رقم الواتساب */}
                  <div className="flex flex-col lg:col-span-1 md:col-span-full sm:col-span-full xs:col-span-full pr-4">
                    <p className="title">رقم الواتساب</p>
                    <div className="form-control relative mt-4">
                      <input
                        type="tel"
                        className="input input-lg max-w-full search-input bg-white"
                        placeholder="رقم الواتساب"
                        value={apiResponse?.whatsapp_number}
                        onChange={(e) =>
                          handleChangeBreeder(
                            e.target.value,
                            "whatsapp_number",
                            setValues
                          )
                        }
                      />

                      <span className="absolute inset-y-0 right-4 inline-flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="19"
                          viewBox="0 0 18 19"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_3071_13332)">
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M14.8926 3.12334C13.258 1.48729 11.0849 0.585567 8.7698 0.584839C3.99918 0.584839 0.116465 4.46683 0.114282 9.23962C0.113554 10.7651 0.512379 12.2541 1.27 13.567L0.0415039 18.0516L4.62944 16.8479C5.8936 17.5378 7.31714 17.901 8.76543 17.9017H8.76907C13.539 17.9017 17.4224 14.019 17.4246 9.24617C17.426 6.93401 16.5265 4.75867 14.8926 3.12334ZM8.7698 16.4396H8.76688C7.4758 16.4396 6.21019 16.0924 5.10541 15.4367L4.84268 15.2809L2.12005 15.9949L2.8471 13.3399L2.67608 13.0678C1.95557 11.9222 1.57567 10.5984 1.5764 9.23962C1.57785 5.27321 4.80557 2.04623 8.77343 2.04623C10.6948 2.04623 12.5011 2.79584 13.8592 4.15534C15.2172 5.51556 15.9647 7.32265 15.9639 9.24472C15.9617 13.2126 12.7348 16.4396 8.7698 16.4396ZM12.7158 11.0518C12.4997 10.9434 11.4364 10.4201 11.2377 10.348C11.0397 10.276 10.8956 10.2396 10.7508 10.4557C10.606 10.6719 10.1926 11.1595 10.066 11.3043C9.94007 11.4484 9.81344 11.4666 9.59728 11.3582C9.38113 11.2498 8.68392 11.022 7.85788 10.2847C7.21525 9.71123 6.78077 9.0031 6.65486 8.78622C6.52895 8.56934 6.64176 8.45289 6.74947 8.34518C6.847 8.24839 6.96562 8.09264 7.07406 7.96601C7.18323 7.84083 7.21889 7.75058 7.29167 7.60575C7.36372 7.46165 7.32806 7.33502 7.27348 7.22658C7.21889 7.11887 6.78659 6.05412 6.60683 5.62109C6.43143 5.19898 6.25313 5.25647 6.11994 5.24992C5.99403 5.24337 5.84993 5.24264 5.7051 5.24264C5.561 5.24264 5.32666 5.2965 5.1287 5.51338C4.93074 5.73026 4.37181 6.25353 4.37181 7.31755C4.37181 8.3823 5.1469 9.41065 5.25461 9.55475C5.36232 9.69886 6.77931 11.8837 8.94883 12.8203C9.46483 13.043 9.86802 13.1762 10.1817 13.2759C10.6999 13.4404 11.1715 13.4171 11.5441 13.3618C11.9597 13.2999 12.8235 12.8385 13.004 12.3334C13.1845 11.8283 13.1845 11.3946 13.1299 11.3051C13.0761 11.2141 12.932 11.1602 12.7158 11.0518Z"
                              fill="#70D44B"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_3071_13332">
                              <rect
                                width="17.4668"
                                height="17.4668"
                                fill="white"
                                transform="translate(0 0.584595)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                    </div>
                  </div>

                  {/*  عدد العنابر */}
                  <div className="flex flex-col lg:col-span-1 md:col-span-full sm:col-span-full xs:col-span-full pr-4">
                    <p className="title">عدد العنابر</p>
                    <input
                      type="number"
                      placeholder="عدد العنابر"
                      className="mission-address-input mt-4"
                      id="farmsCount"
                      name="farmsCount"
                      value={apiResponse?.farms_count}
                      onChange={(e) =>
                        handleChangeBreeder(
                          e.target.value,
                          "farms_count",
                          setValues
                        )
                      }
                    />
                  </div>

                  {/* نوع عملية الدفع */}
                  <div className="flex flex-col lg:col-span-1 md:col-span-full sm:col-span-full xs:col-span-full pr-4">
                    <p className="title">نوع عملية الدفع</p>

                    <div className="dropdown-container">
                      <div className="dropdown w-full mt-4">
                        <label tabIndex={0}>
                          <div className="flex justify-between select-mission-container">
                            <input
                              className="select-mission-type pr-4"
                              id="ownership"
                              name="ownership"
                              value={paymentMethodText}
                              disabled
                            />

                            <div className="arrow">
                              <img src={arrow} alt="arrow" />
                            </div>
                          </div>
                        </label>
                        <div className="dropdown-menu w-full bg-white">
                          <a
                            className="dropdown-item text-sm text-right"
                            onClick={() => handleChangePaymentMethod(1)}
                          >
                            إئتمان
                          </a>
                          <a
                            className="dropdown-item text-sm text-right"
                            onClick={() => handleChangePaymentMethod(2)}
                          >
                            كاش
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* تربية منزليه */}
                  <div className="flex flex-col lg:col-span-1 md:col-span-full sm:col-span-full xs:col-span-full pr-4">
                    <div className="flex gap-8 items-center">
                      <span className="title">تربية منزلية ؟</span>
                      <div className="ml-">
                        <input
                          type="checkbox"
                          className="switch switch-success"
                          checked={isHomeBreeding}
                          onChange={() => setHomeBreeding(!isHomeBreeding)}
                        />
                      </div>
                    </div>
                  </div>

                  {/* مربي اعمار */}
                  <div className="flex flex-col lg:col-span-1 md:col-span-full sm:col-span-full xs:col-span-full pr-4">
                    <div className="flex gap-8 items-center">
                      <span className="title">مربي اعمار ؟</span>
                      <div className="ml-">
                        <input
                          type="checkbox"
                          className="switch switch-success"
                          checked={isIncubator}
                          onChange={() => setIncubator(!isIncubator)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* العنوان */}
              <div className="location-section mt-4">
                <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 xs:grid-cols-2 gap-4 px-4">
                  {/* Header */}
                  <div className="col-span-full pr-4 pt-4 flex items-center">
                    <div className="farm-info-title w-1/2">العنوان</div>
                  </div>
                  <div className="col-span-full">
                    <div className="divider"></div>
                  </div>

                  {/*  المحافظة */}
                  <div className="flex flex-col lg:col-span-1 md:col-span-full sm:col-span-full xs:col-span-full pr-4">
                    <p className="title">المحافظة</p>
                    <label htmlFor="modal-Governorates" className="mt-4">
                      <div
                        className="flex justify-between select-mission-container"
                        onClick={() => handleGovernoratePopUp()}
                      >
                        {/* {governorateText} */}
                        <span className="select-mission-type">
                          {selectedGovernorateName}
                        </span>
                        <div className="arrow">
                          <img src={arrow} alt="arrow" />
                        </div>
                      </div>
                    </label>
                  </div>

                  {/*  المدينة */}
                  <div className="flex flex-col lg:col-span-1 md:col-span-full sm:col-span-full xs:col-span-full pr-4">
                    <p className="title">المدينة</p>
                    <label htmlFor="modal-Cities" className="mt-4">
                      <div
                        className="flex justify-between select-mission-container"
                        onClick={() => handleCityPopUp()}
                      >
                        <span className="select-mission-type">
                          {selectedCityName}
                        </span>

                        <div className="arrow">
                          <img src={arrow} alt="arrow" />
                        </div>
                      </div>
                    </label>
                  </div>

                  {/*  حي / قرية */}
                  <div className="flex flex-col lg:col-span-1 md:col-span-full sm:col-span-full xs:col-span-full pr-4">
                    <p className="title">حي / قرية</p>
                    <div className="form-control relative mt-4">
                      <input
                        type="text"
                        placeholder="القرية"
                        id="full_address"
                        name="full_address"
                        className={
                          errors.full_address && touched.full_address
                            ? "input-err"
                            : "mission-address-input"
                        }
                        onChange={(e) => {
                          handleChange(e);
                          handleChangeBreeder(
                            e.target.value,
                            "full_address",
                            setValues
                          );
                        }}
                        onBlur={handleBlur}
                        value={values.full_address}
                      />
                      {errors.full_address && touched.full_address && (
                        <div
                          className="error err-msg"
                          style={{
                            position: "absolute",
                            bottom: "-20px",
                            left: "0",
                            width: "100%",
                          }}
                        >
                          {errors.full_address}
                        </div>
                      )}
                    </div>
                  </div>

                  {/*  الوصف التفصيلي */}
                  <div className="flex flex-col lg:col-span-1 md:col-span-full sm:col-span-full xs:col-span-full pr-4">
                    <p className="title">الوصف التفصيلي</p>

                    <textarea
                      placeholder="الوصف التفصيلي"
                      className="description-input mt-4"
                      id="detailedDescription"
                      name="detailedDescription"
                    />
                  </div>
                </div>
              </div>

              <div className="activites-section mt-4">
                <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 xs:grid-cols-2 gap-4 px-4">
                  {/* Header */}
                  <div className="col-span-full pr-4 pt-4 flex items-center">
                    <div className="farm-info-title w-1/2">معلومات النشاط</div>
                  </div>
                  <div className="col-span-full">
                    <div className="divider"></div>
                  </div>

                  {/*  نوع النشاط */}
                  <div className="flex flex-col lg:col-span-1 md:col-span-full sm:col-span-full xs:col-span-full pr-4">
                    <p className="title">نوع النشاط</p>
                    <div className="dropdown-container mt-4">
                      <div className="dropdown w-full">
                        <label tabIndex={0}>
                          <div className="flex justify-between select-mission-container">
                            <input
                              className="select-mission-type pr-4 w-full"
                              id="ownership"
                              name="ownership"
                              value={activityText}
                            />

                            <div className="arrow">
                              <img src={arrow} alt="arrow" />
                            </div>
                          </div>
                        </label>
                        <div className="dropdown-menu w-full bg-white">
                          <a
                            className="dropdown-item text-sm text-right"
                            onClick={() => handleChangeActivityType(1)}
                          >
                            فردي
                          </a>
                          <a
                            className="dropdown-item text-sm text-right"
                            onClick={() => handleChangeActivityType(2)}
                          >
                            مؤسسي
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*  حجم التربية التقريبي */}
                  <div className="flex flex-col lg:col-span-1 md:col-span-full sm:col-span-full xs:col-span-full pr-4">
                    <p className="title">حجم التربية التقريبي</p>
                    <input
                      type="number"
                      placeholder="0000"
                      className="mission-address-input mt-4"
                      id="count"
                      name="count"
                      value={apiResponse?.approx_breeding_size}
                      onChange={(e) =>
                        handleChangeBreeder(
                          e.target.value,
                          "approx_breeding_size",
                          setValues
                        )
                      }
                    />
                  </div>
                </div>
              </div>

              {/* Fourth Section [Done Or cancel] */}
              <div className="add-actions p-5">
                <div className="grid grid-cols-2">
                  <div className="col-start-1">
                    <div className="flex gap-4">
                      <button className="add-btn" type="submit">
                        تعديل
                      </button>
                      <div className="cancel-btn" onClick={() => navigate(-1)}>
                        الغاء
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </>
        )}
      </Formik>
    </div>
  );
};
