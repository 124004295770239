import { Farms } from "../../Components/Farms/Farms";
import { MovementRecord } from "../../Components/Farms/MovementRecord/MovementRecord";
import { MainHeader } from "../../Components/MainHeader/MainHeader";
import { ModuleHeader } from "../../Components/ModuleHeader/ModuleHeader";
import { Sidebar } from "../../Components/Sidebar/Sidebar";

export const FarmMovementRecordPage = () => {
  return (
    <div className="flex flex-row " style={{ direction: "rtl" }}>
      <div className="sm:w-full sm:max-w-[18rem]">
        <input
          type="checkbox"
          id="sidebar-mobile-fixed"
          className="sidebar-state"
        />
        <label
          htmlFor="sidebar-mobile-fixed"
          className="sidebar-overlay"
        ></label>
        {/* Side bar*/}
        <Sidebar />
      </div>
      <div className="flex w-full flex-col p-4 bg-white">
        <div className="page-container" style={{ backgroundColor: "#FFF" }}>
          <div className="col-span-12 row-span-1">
            {/* Second Header */}
            <MainHeader />
          </div>
        </div>
        {/* Content Will Be Here */}

        <>
          <div className="h-full">
            <MovementRecord />
          </div>
        </>
      </div>
    </div>
  );
};
