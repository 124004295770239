import { MainHeader } from "../../Components/MainHeader/MainHeader";
import { ModuleHeader } from "../../Components/ModuleHeader/ModuleHeader";
import { Sidebar } from "../../Components/Sidebar/Sidebar";
import { useSelector } from "react-redux";
import { AllTransactions } from "../../Components/Wallets/All-Transactions/AllTransactions";
import { CreditTransactions } from "../../Components/Wallets/Credit-transactions/CreditTransactions";
import { DepitTransactions } from "../../Components/Wallets/Depit-Transactions/DepitTransactions";
import { CreditBalanceOnly } from "../../Components/Wallets/Credit-Balance-Only/CreditBalanceOnly";
import { Settlements } from "../../Components/Wallets/Settlements/Settlements";
import { useLocation } from "react-router-dom";
import { SettledUsers } from "../../Components/Wallets/Settled-Users/SettledUsers";

export const Wallets = () => {
  const stateFromWalletsSlice = useSelector((state: any) => state.wallets);
  const location = useLocation();

  return (
    <div className="flex flex-row " style={{ direction: "rtl" }}>
      <div className="sm:w-full sm:max-w-[18rem]">
        <input
          type="checkbox"
          id="sidebar-mobile-fixed"
          className="sidebar-state"
        />
        <label
          htmlFor="sidebar-mobile-fixed"
          className="sidebar-overlay"
        ></label>
        {/* Side bar*/}
        <Sidebar />
      </div>
      <div className="flex w-full flex-col p-4 bg-white">
        <div className="page-container" style={{ backgroundColor: "#FFF" }}>
          <div className="col-span-12 row-span-1">
            <MainHeader />

            {stateFromWalletsSlice.showSettlements === true ? null : (
              <>
                <ModuleHeader />
              </>
            )}
          </div>
        </div>
        {/* Content Will Be Here */}
        {stateFromWalletsSlice.showSettlements === true ? (
          <>
            <Settlements />
          </>
        ) : (
          <>
            <div
              className="h-full"
              style={{ backgroundColor: "var(--Greyscale-50, #F8FAFC)" }}
            >
              <>
                {location.pathname === `/Wallets/AllTransactions` ? (
                  <AllTransactions />
                ) : location.pathname === `/Wallets/DepitTransactions` ? (
                  <DepitTransactions />
                ) : location.pathname === `/Wallets/CreditTransactions` ? (
                  <CreditTransactions />
                ) : location.pathname === `/Wallets/CreditBalanceOnly` ? (
                  <CreditBalanceOnly />
                ) : location.pathname === `/Wallets/SettledUsers` ? (
                  <SettledUsers />
                ) : null}
              </>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
