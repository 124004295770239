import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import API from "../../../../Api";
import { Loading } from "../../../Loading/Loading";
import arrow from "../../../../Assets/Icons/arrow.jpeg";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router-dom";
import { Formik } from "formik";
import { toast } from "react-toastify";
import moment from "moment";
import "moment/locale/ar"; // Import the Arabic locale
import { ar, enUS } from "date-fns/locale";
import { format } from "date-fns";

interface AddBreederNote {
  farm_id: number;
  note_type_id: number;
  noteTitle: string;
  details: string;
}

export const AddAccountNotes = () => {
  const { breederId } = useParams<{ breederId: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [farms, setFarms] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [selectedFarmId, setSelectedFarmId] = useState(0);
  const [selectedFarmName, setSelectedFarmName] = useState("اختر العنبر");
  const [selectedNoteType, setSelectedNoteType] = useState("اختر نوع الملاحظة");
  const [selectedNoteTypeId, setSelectedNoteTypeId] = useState(0);
  const [notesTypes, setNotesTypes] = useState([]);
  const [addObject, setAddObject] = useState({
    farm_id: 0,
    note_type_id: 0,
    noteTitle: "",
    details: "",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    moment.locale("ar");

    API.get(`notes-types`).then((res) => {
      setIsLoading(true);
      if (res.status === 200) {
        setIsLoading(false);
        setNotesTypes(res.data);
      }
    });
    API.get(`breeders/${breederId}/farms`).then((res) => {
      setIsLoading(true);
      if (res.status === 200) {
        setTotalRows(res.data.totalCount);
        setFarms(res.data.items);
        setIsLoading(false);
      }
    });
  }, []);

  const handleChangeValues = (value: any, fieldName: string) => {
    // Update addObject with the new value
    setAddObject((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleNotesSubmit = () => {
    setIsLoading(true);
    if (selectedNoteTypeId === 0) {
      toast.warn("من فضلك قم بأختيار نوع الملاحظة");
      setIsLoading(false);
    } else if (addObject.noteTitle === "") {
      toast.warn("من فضلك قم بأدخال عنوان الملاحظة");
      setIsLoading(false);
    } else {
      ConfirmAddNotes();
    }
  };

  const ConfirmAddNotes = () => {
    setIsLoading(true);

    let requestObject: {
      farm_id?: number;
      note_type_id: number;
      noteTitle?: string;
      details?: string;
    } = {
      farm_id: Number(selectedFarmId),
      note_type_id: Number(selectedNoteTypeId),
      noteTitle: addObject.noteTitle,
      details: addObject.details,
    };

    if (selectedFarmId === 0) {
      delete requestObject.farm_id;
    }

    API.post(`breeders/${breederId}/notes`, requestObject)
      .then((res) => {
        if (res.status === 201) {
          toast.success("تمت أضافة الملاحظة بنجاح");
          setIsLoading(false);
          navigate(`/Breeders/${breederId}/Notes`);
        } else {
          setIsLoading(false);
          toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
      });
  };

  const handleChangeSelectedFarm = (id: number, name: string) => {
    setIsLoading(true);
    setSelectedFarmId(id);
    setSelectedFarmName(name);
    setIsLoading(false);
  };

  const handleChangeSelectedNote = (id: number, name: string) => {
    setIsLoading(true);
    setSelectedNoteTypeId(id);
    setSelectedNoteType(name);
    setIsLoading(false);
  };
  return (
    <div>
      {isLoading === true ? (
        <>
          <Loading />
        </>
      ) : null}
      <Formik
        onSubmit={() => handleNotesSubmit()}
        initialValues={addObject}
        validationSchema={null}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              <div className="account-data-section mt-4">
                <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 xs:grid-cols-2 gap-4 px-4">
                  <div className="col-span-full pr-4 pt-4 flex items-center">
                    <span className="main-info-notes">اضافة ملاحظة جديدة</span>
                  </div>
                  <div className="col-span-full">
                    <div className="divider"></div>
                  </div>

                  {/* عنوان الملاحظة */}
                  <div className="flex flex-col lg:col-span-1 md:col-span-full sm:col-span-full xs:col-span-full pr-4">
                    <p className="notes_title">عنوان الملاحظة</p>
                    <input
                      className="title-content mt-4"
                      type="text"
                      placeholder="ادخل عنوان الملاحظة"
                      onChange={(e) =>
                        handleChangeValues(e.target.value, "noteTitle")
                      }
                    />
                  </div>
                  {/* نوع الملاحظة */}
                  <div className="flex flex-col lg:col-span-1 md:col-span-full sm:col-span-full xs:col-span-full pr-4">
                    <p className="notes_title">نوع الملاحظة</p>
                    <div className="dropdown-container mt-4">
                      <div className="dropdown w-full">
                        <label tabIndex={0}>
                          <div className="flex justify-between select-mission-container">
                            <input
                              className="select-mission-type pr-4"
                              value={selectedNoteType}
                              disabled
                              id="farmId"
                              name="farmId"
                            />

                            <div className="arrow">
                              <img src={arrow} alt="arrow" />
                            </div>
                          </div>
                        </label>
                        <div className="dropdown-menu w-full bg-white">
                          <>
                            {notesTypes.map((item: any) => {
                              return (
                                <p
                                  className="dropdown-item text-sm text-right"
                                  onClick={() =>
                                    handleChangeSelectedNote(
                                      Number(item.id),
                                      item.note
                                    )
                                  }
                                >
                                  {item.note}
                                </p>
                              );
                            })}
                          </>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* العنبر */}
                  <div className="flex flex-col lg:col-span-1 md:col-span-full sm:col-span-full xs:col-span-full pr-4">
                    <p className="notes_title">العنبر</p>
                    <div className="dropdown-container mt-4">
                      <div className="dropdown w-full">
                        <label tabIndex={0}>
                          <div className="flex justify-between select-mission-container">
                            <input
                              className="select-mission-type pr-4"
                              value={selectedFarmName}
                              disabled
                              id="farmId"
                              name="farmId"
                            />

                            <div className="arrow">
                              <img src={arrow} alt="arrow" />
                            </div>
                          </div>
                        </label>
                        <div className="dropdown-menu w-full bg-white">
                          {farms.map((item: any) => {
                            return (
                              <p
                                className="dropdown-item text-sm text-right"
                                onClick={() =>
                                  handleChangeSelectedFarm(
                                    Number(item.id),
                                    item.name
                                  )
                                }
                              >
                                {item.name}
                              </p>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* تفاصيل الملاحظة */}
                  <div className="flex flex-col lg:col-span-1 md:col-span-full sm:col-span-full xs:col-span-full pr-4">
                    <p className="notes_title">تفاصيل الملاحظة</p>
                    <textarea
                      placeholder="اكتب تفاصيل الملاحظة"
                      className="notes-txt-area w-full mt-4"
                      onChange={(e) =>
                        handleChangeValues(e.target.value, "details")
                      }
                    />
                  </div>

                  <div className="col-span-full pl-4 pr-4 pt-4">
                    <div className="add-actions p-5">
                      <div className="grid grid-cols-2">
                        <div className="col-start-1">
                          <div className="flex gap-4">
                            <button className="add-btn" type="submit">
                              اضافة
                            </button>
                            <div
                              className="cancel-btn"
                              onClick={() =>
                                navigate(`/Breeders/${breederId}/Notes`)
                              }
                            >
                              الغاء
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </>
        )}
      </Formik>
    </div>
  );
};
