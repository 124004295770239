import "./AddUser.scss";
import moment from "moment";
import { toast } from "react-toastify";
import API, { URL } from "../../../Api";
import { Loading } from "../../Loading/Loading";
import { useSelector, useDispatch } from "react-redux";
import { setMainHeaderName } from "../../../redux/Slices/MainHeaderSlice";
import { Governorates } from "../../Farms/SubComponents/Governorates";
import { Cities } from "../../Farms/SubComponents/Cities";
import { useEffect, useState } from "react";
import arrow from "../../../Assets/Icons/arrow.jpeg";
import { Stages } from "../SubComponents/Stages/StagesPopUp";
import { Specialization } from "../SubComponents/Specialization/Specialization";
import axios from "axios";
import { WorkRange } from "../SubComponents/work-range/WorkRange";
import { Formik } from "formik";
import * as Yup from "yup";

import { useNavigate } from "react-router-dom";
import {
  setSelectedUserCity,
  setSelectedUserGovernorates,
} from "../../../redux/Slices/UsersSlice";

// Define the validation schema
const validationSchema = Yup.object().shape({
  whatsapp: Yup.string()
    .matches(/^01\d{9}$/, "من فضلك قم بإدخال رقم واتساب مكون من 11 رقم")
    .required("من فضلك قم بإدخال رقم الواتساب"),
  mobile: Yup.string()
    .matches(/^01\d{9}$/, "من فضلك قم بإدخال رقم هاتف مكون من 11 رقم")
    .required("من فضلك قم بإدخال رقم الهاتف"),
  name: Yup.string().required("من فضلك قم بإدخال اسم المستخدم"),
  national_id: Yup.string()
    .matches(
      /^(?!^(\d)\1{13}$)\d{14}$/,
      "من فضلك قم  بإدخال رقم قومي مكون من 14 رقم"
    )
    .required("من فضلك قم بإدخال قيمة الرقم القومي"),
  password: Yup.string().required(
    "من فضلك قم بإدخال كلمة المرور للمستخدم الجديد"
  ),
});
export const AddUser = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showStagesPopUp, setShowStagesPopUp] = useState(false);
  const [showWorkRangePopUp, setShowWorkRangePopUp] = useState(false);
  const [showSpecialtiesPopUp, setShowSpecialtiesPopUp] = useState(false);
  const [showGovernoratePopUp, setShowGovernoratePopUp] = useState(false);
  const [showCityPopUp, setShowCityPopUp] = useState(false);
  const [addObject, setAddObject] = useState({
    name: "",
    mobile: "",
    email: "",
    whatsapp: "",
    national_id: "",
    specialty_id: "",
    level_id: "",
    ewallet_number: "",
    password: "",
    cityId: 0,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const stateFromFarmsSlice = useSelector((state: any) => state.farms);
  const stateFromUserSlice = useSelector((state: any) => state.users);

  useEffect(() => {
    setIsLoading(true);
    let mainHeaderName = "اضافة مستخدم";
    dispatch(setMainHeaderName({ mainHeaderName }));
    setIsLoading(false);
  }, []);

  const handleShowStagesPopUp = () => {
    setShowStagesPopUp(!showStagesPopUp);
  };
  const handleShowWorkRangePopup = () => {
    setShowWorkRangePopUp(!showWorkRangePopUp);
  };
  const handleShowSpecialtiesPopUp = () => {
    setShowSpecialtiesPopUp(!showSpecialtiesPopUp);
  };
  const handleGovernoratePopUp = () => {
    setShowGovernoratePopUp(!showGovernoratePopUp);
  };
  const handleCityPopUp = () => {
    setShowCityPopUp(!showCityPopUp);
  };

  const handleChangeValues = (value: any, fieldName: string) => {
    setAddObject((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const confirmAddUser = (values: any) => {
    setIsLoading(true);
    if (stateFromUserSlice.levelId === 0) {
      toast.error("من فضلك قم بأختيار المرحلة");
      setIsLoading(false);
    } else if (stateFromUserSlice.specialtiesId === 0) {
      toast.error("من فضلك قم بأختيارالتخصص");
      setIsLoading(false);
    } else if (stateFromUserSlice.workAreas_ids.length === 0) {
      toast.error("من فضلك قم بأختيار نطاق العمل");
      setIsLoading(false);
    } else if (stateFromUserSlice.selectedCityId === 0) {
      toast.warn("من فضلك قم بأختيار المدينة");
      setIsLoading(false);
    } else if (stateFromUserSlice.selectedGovernorateId === 0) {
      toast.warn("من فضلك قم بأختيار المحافظة");
      setIsLoading(false);
    } else {
      addObject.level_id = stateFromUserSlice.levelId;
      addObject.specialty_id = stateFromUserSlice.specialtiesId;
      addObject.cityId = Number(stateFromUserSlice.selectedCityId);

      const arrayData = stateFromUserSlice.workAreas_ids;

      let requestObject: {
        name: string;
        mobile: string;
        email?: string;
        whatsapp: string;
        national_id: string;
        specialty_id: Number;
        level_id: Number;
        password: string;
        work_area_ids: Number[];
        ewallet_number?: string;
        cityId: Number;
      } = {
        name: addObject.name,
        mobile: addObject.mobile,
        email: addObject.email,
        whatsapp: addObject.whatsapp,
        national_id: addObject.national_id,
        specialty_id: Number(stateFromUserSlice.specialtiesId),
        level_id: Number(stateFromUserSlice.levelId),
        password: addObject.password,
        work_area_ids: arrayData.map((item: any) => Number(item)),
        ewallet_number: addObject.ewallet_number,
        cityId: addObject.cityId,
      };
      if (addObject.email.length === 0) {
        delete requestObject.email;
      }
      if (addObject.ewallet_number.length === 0) {
        delete requestObject.ewallet_number;
      }

      axios
        .post(`${URL}dashboard/salesman`, requestObject, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.status === 201) {
            toast.success("تمت اضافة المستخدم بنجاح");

            navigate(-1);
            let isChanged = false;
            let governorateId = 0;
            let governorateName = "أختر المحافظة";

            dispatch(
              setSelectedUserGovernorates({
                governorateId,
                governorateName,
                isChanged,
              })
            );
            let cityId = 0;
            let cityName = "اختر المدينة";
            dispatch(setSelectedUserCity({ cityId, cityName, isChanged }));
            setIsLoading(false);
          } else if (res.status === 400) {
            toast.error("هذا المستخدم موجود من قبل");
            setIsLoading(false);
          } else {
            toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
            setIsLoading(false);
          }
        })
        .catch((error) => {
          toast.error("هذا المستخدم موجود من قبل");
          setIsLoading(false);
        });
    }
  };

  return (
    <div className="AddUser">
      {isLoading === true ? (
        <>
          <Loading />
        </>
      ) : null}
      {showStagesPopUp === true ? <Stages /> : null}
      {showSpecialtiesPopUp === true ? <Specialization /> : null}
      {showWorkRangePopUp === true ? <WorkRange /> : null}
      {showGovernoratePopUp === true ? <Governorates isEdit={false} /> : null}
      {showCityPopUp === true ? <Cities isEdit={false} /> : null}
      <Formik
        onSubmit={(values) => confirmAddUser(values)}
        initialValues={addObject}
        validationSchema={validationSchema}
        key={`AddUser`}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              <div className="add-section mb-4">
                <div className="grid grid-cols-2">
                  <div className="col-start-1 mt-4 mb-4 pr-4">
                    <h2 className="flex justify-start add-details">
                      اضافة مستخدم
                    </h2>
                  </div>
                  <div className="col-span-full mb-4">
                    <div className="divider"></div>
                  </div>
                  {/* الاسم الاول */}
                  <div className="col-start-1 mt-4 mb-4 pr-4">
                    <h3 className="first-name">اسم المستخدم</h3>
                  </div>
                  <div className="col-start-2 mt-4 mb-4 pr-4">
                    <h3 className="first-name"> البريد الألكتروني </h3>
                  </div>

                  {/*Name Input*/}
                  <div className="col-start-1  mb-4 pr-4">
                    <div className="form-control relative">
                      <input
                        type="text"
                        placeholder="اسم المستخدم"
                        id="name"
                        name="name"
                        className={
                          errors.name && touched.name
                            ? "input-err"
                            : "first-name-input"
                        }
                        onChange={(e) => {
                          handleChange(e);
                          handleChangeValues(e.target.value, "name");
                        }}
                        onBlur={handleBlur}
                        value={values.name}
                      />
                      {errors.name && touched.name && (
                        <div
                          className="error err-msg"
                          style={{
                            position: "absolute",
                            bottom: "-20px",
                            left: "0",
                            width: "100%",
                          }}
                        >
                          {errors.name}
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Email Input */}
                  <div className="col-start-2  mb-4 pr-4">
                    <div>
                      <input
                        type="email"
                        placeholder="البريد الألكتروني"
                        className="first-name-input"
                        onChange={(e) =>
                          handleChangeValues(e.target.value, "email")
                        }
                      />
                    </div>
                  </div>

                  <div className="col-start-1 mt-4 mb-4 pr-4">
                    <h3 className="last-name"> الرقم القومي</h3>
                  </div>

                  <div className="col-start-2 mt-4 mb-4 pr-4">
                    <h3 className="last-name"> رقم الهاتف</h3>
                  </div>

                  {/* National Id Input*/}
                  <div className="col-start-1  mb-4 pr-4">
                    <div className="form-control relative">
                      <input
                        type="text"
                        placeholder="الرقم القومي"
                        id="national_id"
                        name="national_id"
                        className={
                          errors.national_id && touched.national_id
                            ? "input-err"
                            : "first-name-input"
                        }
                        onChange={(e) => {
                          handleChange(e);
                          handleChangeValues(e.target.value, "national_id");
                        }}
                        onBlur={handleBlur}
                        value={values.national_id}
                      />
                      {errors.national_id && touched.national_id && (
                        <div
                          className="error err-msg"
                          style={{
                            position: "absolute",
                            bottom: "-20px",
                            left: "0",
                            width: "100%",
                          }}
                        >
                          {errors.national_id}
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Phone Number input */}
                  <div className="col-start-2  mb-4 pr-4">
                    <div className="form-control relative">
                      <input
                        type="text"
                        placeholder="رقم الهاتف"
                        id="mobile"
                        name="mobile"
                        className={
                          errors.mobile && touched.mobile
                            ? "input-err"
                            : "first-name-input"
                        }
                        onChange={(e) => {
                          handleChange(e);
                          handleChangeValues(e.target.value, "mobile");
                        }}
                        onBlur={handleBlur}
                        value={values.mobile}
                      />
                      {errors.mobile && touched.mobile && (
                        <div
                          className="error err-msg"
                          style={{
                            position: "absolute",
                            bottom: "-20px",
                            left: "0",
                            width: "100%",
                          }}
                        >
                          {errors.mobile}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-start-1 mt-4 mb-4 pr-4">
                    <h3 className="last-name"> رقم الواتساب</h3>
                  </div>

                  <div className="col-start-2 mt-4 mb-4 pr-4">
                    <h3 className="last-name"> رقم المحفظة</h3>
                  </div>

                  {/* What's app number input */}
                  <div className="col-start-1  mb-4 pr-4">
                    <div className="form-control relative">
                      <input
                        type="text"
                        placeholder="رقم الواتساب"
                        id="whatsapp"
                        name="whatsapp"
                        className={
                          errors.whatsapp && touched.whatsapp
                            ? "input-err"
                            : "first-name-input"
                        }
                        onChange={(e) => {
                          handleChange(e);
                          handleChangeValues(e.target.value, "whatsapp");
                        }}
                        onBlur={handleBlur}
                        value={values.whatsapp}
                      />
                      {errors.whatsapp && touched.whatsapp && (
                        <div
                          className="error err-msg"
                          style={{
                            position: "absolute",
                            bottom: "-20px",
                            left: "0",
                            width: "100%",
                          }}
                        >
                          {errors.whatsapp}
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Wallet Number Input */}
                  <div className="col-start-2  mb-4 pr-4">
                    <div>
                      <input
                        type="number"
                        placeholder="رقم المحفظة"
                        className="first-name-input"
                        onChange={(e) =>
                          handleChangeValues(e.target.value, "ewallet_number")
                        }
                      />
                    </div>
                  </div>

                  {/*  المحافظة */}
                  <div className="col-start-1 mt-4 mb-4 pr-4 ">
                    <p className="last-name">المحافظة</p>
                  </div>

                  {/*  المدينة */}
                  <div className="col-start-2 mt-4 mb-4 pr-4 ">
                    <p className="last-name">المدينة</p>
                  </div>

                  {/* المحافظة  input   */}
                  <div className="col-start-1 mb-4 pr-4 ">
                    <label htmlFor="modal-12">
                      <div
                        className="flex justify-between select-mission-container"
                        onClick={() => handleGovernoratePopUp()}
                      >
                        <input
                          className="select-mission-type pr-4"
                          value={
                            stateFromUserSlice.selectedGovernorateId === 0
                              ? "اختر المحافظة"
                              : stateFromUserSlice.selectedGovernorateName
                          }
                          disabled
                          id="govId"
                          name="govId"
                        />

                        <div className="arrow">
                          <img src={arrow} alt="arrow" />
                        </div>
                      </div>
                    </label>
                  </div>
                  {/*  المدينة :input */}
                  <div className="col-start-2 mb-4 pr-4 pl-4">
                    <label htmlFor="modal-13">
                      <div
                        className="flex justify-between select-mission-container"
                        onClick={() => handleCityPopUp()}
                      >
                        <input
                          className="select-mission-type pr-4"
                          value={
                            stateFromUserSlice.selectedCityId === 0
                              ? "اختر المدينة"
                              : stateFromUserSlice.selectedCityName
                          }
                          disabled
                          id="cityId"
                          name="cityId"
                        />

                        <div className="arrow">
                          <img src={arrow} alt="arrow" />
                        </div>
                      </div>
                    </label>
                  </div>

                  {/* Password */}
                  <div className="col-start-1 mb-4 pr-4">
                    <h3 className="first-name">كلمة المرور</h3>
                  </div>
                  {/* Password Input */}
                  <div className="col-start-1   mb-4 pr-4">
                    <div className="form-control relative">
                      <input
                        type="text"
                        placeholder="كلمة المرور"
                        id="password"
                        name="password"
                        className={
                          errors.password && touched.password
                            ? "input-err"
                            : "first-name-input"
                        }
                        onChange={(e) => {
                          handleChange(e);
                          handleChangeValues(e.target.value, "password");
                        }}
                        onBlur={handleBlur}
                        value={values.password}
                      />
                      {errors.password && touched.password && (
                        <div
                          className="error err-msg"
                          style={{
                            position: "absolute",
                            bottom: "-20px",
                            left: "0",
                            width: "100%",
                          }}
                        >
                          {errors.password}
                        </div>
                      )}
                    </div>
                  </div>
                  {/* المرحلة */}
                  <div className="col-start-1 mt-4 mb-4 pr-4">
                    <h3 className="last-name"> المرحلة</h3>
                  </div>
                  <div className="col-span-full pr-4">
                    <label htmlFor="modal-8">
                      <div
                        className="flex justify-between select-stage-container"
                        onClick={() => handleShowStagesPopUp()}
                      >
                        <div className="select-stage pr-4">
                          {stateFromUserSlice.levelId !== 0
                            ? stateFromUserSlice.levelName
                            : "قم بأختيار المرحلة"}
                        </div>
                        <div className="arrow">
                          <img src={arrow} alt="arrow" />
                        </div>
                      </div>
                    </label>
                  </div>
                  {/* التخصص */}
                  <div className="col-start-1 mt-4 mb-4 pr-4">
                    <h3 className="last-name"> التخصص</h3>
                  </div>
                  <div className="col-span-full pr-4">
                    <label htmlFor="modal-9">
                      <div
                        className="flex justify-between select-stage-container"
                        onClick={() => handleShowSpecialtiesPopUp()}
                      >
                        <div className="select-stage pr-4">
                          {stateFromUserSlice.specialtiesId !== 0
                            ? stateFromUserSlice.specialtiesName
                            : "قم بأختيار التخصص"}
                        </div>
                        <div className="arrow">
                          <img src={arrow} alt="arrow" />
                        </div>
                      </div>
                    </label>
                  </div>

                  {/* نطاق العمل */}
                  <div className="col-start-1 mt-4 mb-4 pr-4">
                    <h3 className="last-name"> نطاق العمل</h3>
                  </div>
                  <div className="col-span-full pr-4">
                    <label htmlFor="modal-784">
                      <div
                        className="flex justify-between select-stage-container"
                        onClick={() => handleShowWorkRangePopup()}
                      >
                        <div className="select-stage pr-4">
                          {stateFromUserSlice.workAreas_ids.length !== 0
                            ? stateFromUserSlice.workAreas_text
                            : "قم بأختيار نطاق العمل"}
                        </div>
                        <div className="arrow">
                          <img src={arrow} alt="arrow" />
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              {/* Actions */}
              <div className="add-actions mt-4 mb-4 p-5">
                <div className="grid grid-cols-2">
                  <div className="col-start-1">
                    <div className="flex gap-4">
                      <button className="add-btn" type="submit">
                        اضافة
                      </button>
                      <button
                        className="cancel-btn"
                        onClick={() => navigate(-1)}
                      >
                        الغاء
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </>
        )}
      </Formik>
    </div>
  );
};
