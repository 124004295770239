import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setMainHeaderName } from "../../../redux/Slices/MainHeaderSlice";
import { Loading } from "../../Loading/Loading";
import "./EditSupplier.scss";
import { Formik, FormikHelpers } from "formik";
import API from "../../../Api";
import arrow from "../../../Assets/Icons/arrow.jpeg";
import { Cities } from "../../Farms/SubComponents/Cities";
import { Governorates } from "../../Farms/SubComponents/Governorates";
import DatePicker from "react-datepicker";
interface ApiResponse {
  id: string;
  created_at: string;
  name: string;
  village: string;
  city_id: string;
  full_address: string;
  mobile_number: string;
  whatsapp_number: string;
  facebook_account: string;
  email: string;
  avatar: string;
  approx_breeding_size: number;
  farms_count: number;
  activity_type_id: string;
  account_status: number;
  is_efb: boolean;
  national_id: string;
  specialty_id: string;
  level_id: string;
  ewallet_number: string;
  wallet_balance: number;
  activity_type: {
    id: string;
    name: string;
  };
  governorate: {
    created_at: string;
    updated_at: string;
    id: string;
    name: string;
  };
  city: {
    id: string;
    name: string;
  };
  password: string | null;
  re_password: string | null;
  pay_method: string | null;
}
export const EditSupplier = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [apiResponse, setApiResponse] = useState<ApiResponse | null>(null);
  const [showGovernoratePopUp, setShowGovernoratePopUp] = useState(false);
  const [showCityPopUp, setShowCityPopUp] = useState(false);
  const [addObject, setAddObject] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const stateFromFarmsSlice = useSelector((state: any) => state.farms);
  const [visitDate, setVisitDate] = useState(new Date());
  const [file, setFile] = useState<File | null>(null);
  const [imageUploadWrapClass, setImageUploadWrapClass] =
    useState("image-upload-wrap");
  const [fileUploadContentVisible, setFileUploadContentVisible] =
    useState(false);

  useEffect(() => {
    let mainHeaderName = "تعديل مورد";
    dispatch(setMainHeaderName({ mainHeaderName }));
  }, []);

  const handleGovernoratePopUp = () => {
    setShowGovernoratePopUp(!showGovernoratePopUp);
  };
  const handleCityPopUp = () => {
    setShowCityPopUp(!showCityPopUp);
  };
  const handleChangeVisitDate = (date: any) => {
    setVisitDate(date);
  };

  const removeUpload = () => {
    setFile(null);
    setImageUploadWrapClass("image-upload-wrap");
    setFileUploadContentVisible(false);
  };
  const readURL = (input: any) => {
    if (input.files && input.files[0]) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setImageUploadWrapClass("image-upload-wrap image-dropping");
        setFileUploadContentVisible(true);
        setFile(input.files[0]);
      };

      reader.readAsDataURL(input.files[0]);
    } else {
      removeUpload();
    }
  };

  const handleDragOver = () => {
    setImageUploadWrapClass("image-upload-wrap image-dropping");
  };

  const handleDragLeave = () => {
    setImageUploadWrapClass("image-upload-wrap");
  };

  const handleChangeSupplier = (
    value: string | number | boolean,
    field: string,
    setValues: FormikHelpers<any>["setValues"]
  ) => {
    // Update the Formik form state with the changed values

    setValues((prevValues: { apiResponse: ApiResponse }) => ({
      ...prevValues,
      apiResponse: {
        ...prevValues.apiResponse,
        [field]: value,
      },
    }));
    if (apiResponse) {
      // Create a new object with the updated field
      const updatedApiResponse: ApiResponse = {
        ...apiResponse,
        [field]: value, // Replace 'New Name' with the new value
      };

      // Update the state with the new object
      setApiResponse(updatedApiResponse);
    }
  };
  const handleSubmitSupplier = () => {};

  const governorateText = (
    <div className="select-mission-type bg-white w-full">
      {stateFromFarmsSlice.governorateChanged === false
        ? apiResponse?.governorate != null
          ? apiResponse?.governorate.name
          : "اختر المحافظة"
        : stateFromFarmsSlice.governorateChanged !== 0
        ? stateFromFarmsSlice.selectedGovernorateName
        : "اختر المحافظة"}
    </div>
  );
  const cityText = (
    <div className="select-mission-type bg-white w-full">
      {stateFromFarmsSlice.cityChanged === false
        ? apiResponse?.city != null
          ? apiResponse?.city.name
          : "اختر المدينة"
        : stateFromFarmsSlice.selectedCityId !== 0
        ? stateFromFarmsSlice.selectedCityName
        : "اختر المدينة"}
    </div>
  );

  return (
    <div className="EditSupplier">
      {isLoading === true ? <Loading /> : null}
      {showGovernoratePopUp === true ? <Governorates isEdit={false} /> : null}
      {showCityPopUp === true ? <Cities isEdit={false} /> : null}
      <Formik
        onSubmit={() => handleSubmitSupplier()}
        initialValues={{ apiResponse: apiResponse || null }} // Handle null case
        validationSchema={null}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setValues,
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              {/* بيانات الحساب */}
              <div className="main-info-section  grid grid-cols-2 gap-6 mb-4">
                {/* Header */}
                <div className="col-start-1 pr-4 pt-4 flex items-center ">
                  <div className="main-info-title w-1/2">بيانات الحساب</div>
                </div>
                <div className="col-span-full">
                  <div className="divider"></div>
                </div>

                {/* اسم الشركة انجليزي */}
                <div className="col-start-1 pr-4 ">
                  <p className="title">الاسم الشركة انجليزي</p>
                </div>
                {/* اسم الشركة عربي */}
                <div className="col-start-2 pr-4 ">
                  <p className="title">اسم الشركة عربي</p>
                </div>

                {/* الاسم الشركة انجليزي input */}
                <div className="col-start-1 pr-4 ">
                  <input
                    className="title-content"
                    placeholder="أسم الشركة انجليزي"
                    type="text"
                    onChange={(e) =>
                      handleChangeSupplier(e.target.value, "name", setValues)
                    }
                    required
                  />
                </div>

                {/* اسم الشركة عربي input */}
                <div className="col-start-2 pr-4 pl-4 ">
                  <input
                    className="title-content"
                    placeholder="أسم الشركة عربي"
                    type="text"
                    onChange={(e) =>
                      handleChangeSupplier(e.target.value, "name", setValues)
                    }
                    required
                  />
                </div>
                {/*  المحافظة */}
                <div className="col-start-1 pr-4 ">
                  <p className="title">المحافظة</p>
                </div>

                {/*  المركز/القرية/المنطقة  */}
                <div className="col-start-2 pr-4 ">
                  <p className="title">المركز/القرية/المنطقة </p>
                </div>

                {/* المحافظة  input   */}
                <div className="col-start-1 pr-4 ">
                  <label htmlFor="modal-12">
                    <div
                      className="flex justify-between select-mission-container"
                      onClick={() => handleGovernoratePopUp()}
                    >
                      <input
                        className="select-mission-type pr-4"
                        value={
                          stateFromFarmsSlice.selectedGovernorateId === 0
                            ? "اختر المحافظة"
                            : stateFromFarmsSlice.selectedGovernorateName
                        }
                        disabled
                        id="govId"
                        name="govId"
                      />

                      <div className="arrow">
                        <img src={arrow} alt="arrow" />
                      </div>
                    </div>
                  </label>
                </div>
                {/*  المركز/القرية/المنطقة * :input */}
                <div className="col-start-2 pr-4 pl-4">
                  <label htmlFor="modal-13">
                    <div
                      className="flex justify-between select-mission-container"
                      onClick={() => handleCityPopUp()}
                    >
                      <input
                        className="select-mission-type pr-4"
                        value={
                          stateFromFarmsSlice.selectedCityId === 0
                            ? "المركز/القرية/المنطقة"
                            : stateFromFarmsSlice.selectedCityName
                        }
                        disabled
                        id="cityId"
                        name="cityId"
                      />

                      <div className="arrow">
                        <img src={arrow} alt="arrow" />
                      </div>
                    </div>
                  </label>
                </div>

                {/* العنوان */}
                <div className="col-start-1 pr-4 ">
                  <p className="title">العنوان</p>
                </div>
                {/* التصنيف */}
                <div className="col-start-2 pr-4 ">
                  <p className="title">التصنيف</p>
                </div>

                {/* العنوان input */}
                <div className="col-start-1 pr-4 ">
                  <input
                    className="title-content"
                    placeholder="العنوان"
                    type="text"
                    onChange={(e) =>
                      handleChangeSupplier(e.target.value, "name", setValues)
                    }
                    required
                  />
                </div>

                {/*  التصنيف * :input */}
                <div className="col-start-2 pr-4 pl-4">
                  <label htmlFor="modal-13">
                    <div
                      className="flex justify-between select-mission-container"
                      onClick={() => handleCityPopUp()}
                    >
                      <input
                        className="select-mission-type pr-4"
                        value={
                          stateFromFarmsSlice.selectedCityId === 0
                            ? "التصنيف"
                            : stateFromFarmsSlice.selectedCityName
                        }
                        disabled
                        id="cityId"
                        name="cityId"
                      />

                      <div className="arrow">
                        <img src={arrow} alt="arrow" />
                      </div>
                    </div>
                  </label>
                </div>

                {/* المنتجات */}
                <div className="col-start-1 pr-4 ">
                  <p className="title">المنتجات</p>
                </div>
                {/* البريد الألكتروني */}
                <div className="col-start-2 pr-4 ">
                  <p className="title">البريد الألكتروني</p>
                </div>

                {/* المنتجات  input   */}
                <div className="col-start-1 pr-4 ">
                  <label htmlFor="modal-12">
                    <div
                      className="flex justify-between select-mission-container"
                      onClick={() => handleGovernoratePopUp()}
                    >
                      <input
                        className="select-mission-type pr-4"
                        value={
                          stateFromFarmsSlice.selectedGovernorateId === 0
                            ? "المنتجات"
                            : stateFromFarmsSlice.selectedGovernorateName
                        }
                        disabled
                        id="govId"
                        name="govId"
                      />

                      <div className="arrow">
                        <img src={arrow} alt="arrow" />
                      </div>
                    </div>
                  </label>
                </div>

                {/* البريد الالكتروني input */}
                <div className="col-start-2 pr-4 pl-4 ">
                  <input
                    className="title-content"
                    type="email"
                    placeholder="اكتب البريد الألكتروني"
                    required
                    onChange={(e) =>
                      handleChangeSupplier(e.target.value, "name", setValues)
                    }
                  />
                </div>

                {/* الموقع */}
                <div className="col-start-1 pr-4 ">
                  <p className="title">الموقع</p>
                </div>

                {/* الموقع input */}
                <div className="col-start-1 pr-4 ">
                  <input
                    className="title-content"
                    placeholder="اكتب الموقع"
                    type="text"
                    onChange={(e) =>
                      handleChangeSupplier(e.target.value, "name", setValues)
                    }
                    required
                  />
                </div>
              </div>
              {/* المسؤولين */}
              <div className="main-info-section  grid grid-cols-2 gap-6 mb-4">
                {/* Header */}
                <div className="col-start-1 pr-4 pt-4 flex items-center ">
                  <div className="main-info-title w-1/2">المسؤولين</div>
                </div>
                <div className="col-span-full">
                  <div className="divider"></div>
                </div>

                {/* المسؤول */}
                <div className="col-start-1 pr-4 ">
                  <p className="title">المسؤول</p>
                </div>
                {/* وظيفة المسؤول 1 */}
                <div className="col-start-2 pr-4 ">
                  <p className="title">وظيفة المسؤول 1</p>
                </div>

                {/* المسؤول input */}
                <div className="col-start-1 pr-4 ">
                  <input
                    className="title-content"
                    placeholder="اكتب اسم المسئول"
                    type="text"
                    onChange={(e) =>
                      handleChangeSupplier(e.target.value, "name", setValues)
                    }
                    required
                  />
                </div>

                {/* وظيفة المسؤول input */}
                <div className="col-start-2 pr-4 pl-4">
                  <label htmlFor="modal-13">
                    <div
                      className="flex justify-between select-mission-container"
                      onClick={() => handleCityPopUp()}
                    >
                      <input
                        className="select-mission-type pr-4"
                        value={
                          stateFromFarmsSlice.selectedCityId === 0
                            ? "اختر وظيفة المسؤول"
                            : stateFromFarmsSlice.selectedCityName
                        }
                        disabled
                        id="cityId"
                        name="cityId"
                      />

                      <div className="arrow">
                        <img src={arrow} alt="arrow" />
                      </div>
                    </div>
                  </label>
                </div>
                {/*  الموبايل */}
                <div className="col-start-1 pr-4 ">
                  <p className="title">الموبايل</p>
                </div>

                {/* الموبايل input */}
                <div className="col-start-1 pr-4 ">
                  <input
                    className="title-content"
                    placeholder="الموبايل"
                    type="text"
                    onChange={(e) =>
                      handleChangeSupplier(e.target.value, "name", setValues)
                    }
                    required
                  />
                </div>
                {/* Add New Administrator */}
                <div className="col-start-2 pr-4 pl-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="55"
                    height="56"
                    viewBox="0 0 55 56"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_187_27359)">
                      <path
                        d="M3.36275 0.951172H51.6372C52.562 0.951172 53.4025 1.32939 54.0122 1.93901C54.6218 2.54862 55 3.3892 55 4.31392V52.5884C55 53.5131 54.6213 54.3537 54.0122 54.9633C53.403 55.573 52.5624 55.9512 51.6372 55.9512H3.36275C2.43803 55.9512 1.59701 55.573 0.987834 54.9633C0.378215 54.3542 0 53.5136 0 52.5884V4.31392C0 3.3892 0.378215 2.54818 0.987834 1.93901C1.59745 1.32983 2.43803 0.951172 3.36275 0.951172ZM11.918 29.5939C12.2103 29.8862 12.6145 30.0674 13.0607 30.0674H25.8238H25.8837V30.1274V42.8905C25.8837 43.3367 26.0646 43.7409 26.3573 44.0336C26.65 44.3263 27.0538 44.5072 27.5 44.5072C27.9462 44.5072 28.3504 44.3263 28.6431 44.0336C28.9359 43.7409 29.1163 43.3367 29.1163 42.8905V30.1274V30.0674H29.1762H41.9397C42.386 30.0674 42.7902 29.8866 43.0829 29.5939C43.3756 29.3011 43.5564 28.897 43.5564 28.4507C43.5564 28.0049 43.3756 27.6007 43.0829 27.308C42.7902 27.0153 42.386 26.8349 41.9397 26.8349H29.1758H29.1158V26.7749V14.0119C29.1158 13.5656 28.9354 13.1615 28.6427 12.8692C28.35 12.5769 27.9458 12.3956 27.4996 12.3956C27.0533 12.3956 26.6496 12.5765 26.3569 12.8692C26.0641 13.1619 25.8833 13.5656 25.8833 14.0119V26.7749V26.8349H25.8233H13.0607C12.6145 26.8349 12.2103 27.0157 11.918 27.308C11.6257 27.6003 11.4445 28.0049 11.4445 28.4507C11.4445 28.8974 11.6257 29.3016 11.918 29.5939Z"
                        fill="#7D7D7D"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_187_27359">
                        <rect
                          y="0.951172"
                          width="55"
                          height="55"
                          rx="8"
                          fill="white"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>

                {/* Adminstrators Table */}
                <div className="col-start-1 pr-4 grid grid-cols-4">
                  <div className="col-start-1 pr-4">
                    <h2 className="Adminstrators_Name">مسئول 1</h2>
                  </div>
                  <div className="col-start-2 pr-4">
                    <h2 className="Adminstrators_Name">وظيفة مسئول 1</h2>
                  </div>
                  <div className="col-start-3 pr-4">
                    <h2 className="Adminstrators_Name">تليفون 1</h2>
                  </div>
                  <div className="col-start-4 flex justify-end items-center">
                    {/* Edit Icon */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="29"
                      viewBox="0 0 25 29"
                      fill="none"
                    >
                      <path
                        d="M11.9883 6.78451H6.98828C5.88371 6.78451 4.98828 7.82918 4.98828 9.11784V21.9512C4.98828 23.2398 5.88371 24.2845 6.98828 24.2845H17.9883C19.0929 24.2845 19.9883 23.2398 19.9883 21.9512V16.1178M18.5741 5.13459C19.3551 4.22337 20.6214 4.22337 21.4025 5.13459C22.1835 6.04581 22.1835 7.5232 21.4025 8.43442L12.8167 18.4512H9.98828L9.98828 15.1513L18.5741 5.13459Z"
                        stroke="#7D7D7D"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    {/* Delete Icon */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="29"
                      height="29"
                      viewBox="0 0 29 29"
                      fill="none"
                    >
                      <path
                        d="M10.832 5.98242H10.6133C10.7336 5.98242 10.832 5.88398 10.832 5.76367V5.98242H19.1445V5.76367C19.1445 5.88398 19.243 5.98242 19.3633 5.98242H19.1445V7.95117H21.1133V5.76367C21.1133 4.79844 20.3285 4.01367 19.3633 4.01367H10.6133C9.64805 4.01367 8.86328 4.79844 8.86328 5.76367V7.95117H10.832V5.98242ZM24.6133 7.95117H5.36328C4.8793 7.95117 4.48828 8.34219 4.48828 8.82617V9.70117C4.48828 9.82148 4.58672 9.91992 4.70703 9.91992H6.35859L7.03398 24.2207C7.07773 25.1531 7.84883 25.8887 8.78125 25.8887H21.1953C22.1305 25.8887 22.8988 25.1559 22.9426 24.2207L23.618 9.91992H25.2695C25.3898 9.91992 25.4883 9.82148 25.4883 9.70117V8.82617C25.4883 8.34219 25.0973 7.95117 24.6133 7.95117ZM20.9848 23.9199H8.9918L8.33008 9.91992H21.6465L20.9848 23.9199Z"
                        fill="#7D7D7D"
                      />
                    </svg>
                  </div>
                </div>
                <div className="col-start-1 pr-4 grid grid-cols-4">
                  <div className="col-start-1 pr-4">
                    <h2 className="Adminstrators_Name_Data">محمود اشرف</h2>
                  </div>
                  <div className="col-start-2 pr-4">
                    <h2 className="Adminstrators_Name_Data">صاحب الشركة</h2>
                  </div>
                  <div className="col-start-3 pr-4">
                    <h2 className="Adminstrators_Name_Data">012xxxxxxx</h2>
                  </div>
                </div>
              </div>

              {/* حالة الحساب */}
              <div className="main-info-section  grid grid-cols-2 gap-6 mb-4">
                {/* Header */}
                <div className="col-start-1 pr-4 pt-4 flex items-center ">
                  <div className="main-info-title w-1/2">حالة الحساب</div>
                </div>
                <div className="col-span-full">
                  <div className="divider"></div>
                </div>

                {/* الأهمية* */}
                <div className="col-start-1 pr-4 ">
                  <p className="title">الأهمية</p>
                </div>
                {/* المصدر */}
                <div className="col-start-2 pr-4 ">
                  <p className="title">المصدر</p>
                </div>

                {/* الأهمية  input   */}
                <div className="col-start-1 pr-4 ">
                  <label htmlFor="modal-12">
                    <div
                      className="flex justify-between select-mission-container"
                      onClick={() => handleGovernoratePopUp()}
                    >
                      <input
                        className="select-mission-type pr-4"
                        value={
                          stateFromFarmsSlice.selectedGovernorateId === 0
                            ? "اختر الاهمية"
                            : stateFromFarmsSlice.selectedGovernorateName
                        }
                        disabled
                        id="govId"
                        name="govId"
                      />

                      <div className="arrow">
                        <img src={arrow} alt="arrow" />
                      </div>
                    </div>
                  </label>
                </div>
                {/*  المصدر * :input */}
                <div className="col-start-2 pr-4 pl-4">
                  <label htmlFor="modal-13">
                    <div
                      className="flex justify-between select-mission-container"
                      onClick={() => handleCityPopUp()}
                    >
                      <input
                        className="select-mission-type pr-4"
                        value={
                          stateFromFarmsSlice.selectedCityId === 0
                            ? "اختر المصدر"
                            : stateFromFarmsSlice.selectedCityName
                        }
                        disabled
                        id="cityId"
                        name="cityId"
                      />

                      <div className="arrow">
                        <img src={arrow} alt="arrow" />
                      </div>
                    </div>
                  </label>
                </div>

                {/* مدير الحساب* */}
                <div className="col-start-1 pr-4 ">
                  <p className="title">مدير الحساب</p>
                </div>
                {/* حالة الحساب* */}
                <div className="col-start-2 pr-4 ">
                  <p className="title">حالة الحساب</p>
                </div>

                {/* مدير الحساب  input   */}
                <div className="col-start-1 pr-4 ">
                  <label htmlFor="modal-12">
                    <div
                      className="flex justify-between select-mission-container"
                      onClick={() => handleGovernoratePopUp()}
                    >
                      <input
                        className="select-mission-type pr-4"
                        value={
                          stateFromFarmsSlice.selectedGovernorateId === 0
                            ? "احتر مدير الحساب"
                            : stateFromFarmsSlice.selectedGovernorateName
                        }
                        disabled
                        id="govId"
                        name="govId"
                      />

                      <div className="arrow">
                        <img src={arrow} alt="arrow" />
                      </div>
                    </div>
                  </label>
                </div>
                {/*  حالة الحساب * :input */}
                <div className="col-start-2 pr-4 pl-4">
                  <label htmlFor="modal-13">
                    <div
                      className="flex justify-between select-mission-container"
                      onClick={() => handleCityPopUp()}
                    >
                      <input
                        className="select-mission-type pr-4"
                        value={
                          stateFromFarmsSlice.selectedCityId === 0
                            ? "اختر حالة الحساب"
                            : stateFromFarmsSlice.selectedCityName
                        }
                        disabled
                        id="cityId"
                        name="cityId"
                      />

                      <div className="arrow">
                        <img src={arrow} alt="arrow" />
                      </div>
                    </div>
                  </label>
                </div>
              </div>

              {/* سجل المكالمات والزيارات */}
              <div className="main-info-section  grid grid-cols-2 gap-6 mb-4">
                {/* Header */}
                <div className="col-start-1 pr-4 pt-4 flex items-center ">
                  <div className="main-info-title w-1/2">
                    سجل المكالمات و الزيارات
                  </div>
                </div>
                <div className="col-span-full">
                  <div className="divider"></div>
                </div>

                {/* مكالمة تعريفية */}
                <div className="col-start-1 pr-4 ">
                  <p className="title">مكالمة تعريفية</p>
                </div>
                {/* نتيجة المكالمة */}
                <div className="col-start-2 pr-4 ">
                  <p className="title">نتيجة المكالمة</p>
                </div>

                {/* اختر حالة المكالمة التعريفية  input   */}
                <div className="col-start-1 pr-4 ">
                  <label htmlFor="modal-12">
                    <div
                      className="flex justify-between select-mission-container"
                      onClick={() => handleGovernoratePopUp()}
                    >
                      <input
                        className="select-mission-type pr-4"
                        value={
                          stateFromFarmsSlice.selectedGovernorateId === 0
                            ? "اختر حالة المكالمة التعريفية"
                            : stateFromFarmsSlice.selectedGovernorateName
                        }
                        disabled
                        id="govId"
                        name="govId"
                      />

                      <div className="arrow">
                        <img src={arrow} alt="arrow" />
                      </div>
                    </div>
                  </label>
                </div>
                {/*  المصدر * :input */}
                <div className="col-start-2 pr-4 pl-4">
                  <label htmlFor="modal-13">
                    <div
                      className="flex justify-between select-mission-container"
                      onClick={() => handleCityPopUp()}
                    >
                      <input
                        className="select-mission-type pr-4"
                        value={
                          stateFromFarmsSlice.selectedCityId === 0
                            ? "اختر نتيجة المكالمة"
                            : stateFromFarmsSlice.selectedCityName
                        }
                        disabled
                        id="cityId"
                        name="cityId"
                      />

                      <div className="arrow">
                        <img src={arrow} alt="arrow" />
                      </div>
                    </div>
                  </label>
                </div>

                {/* تحديد زيارة* */}
                <div className="col-start-1 pr-4 ">
                  <p className="title">تحديد زيارة</p>
                </div>
                {/* حالة الزيارة* */}
                <div className="col-start-2 pr-4 ">
                  <p className="title">حالة الزيارة</p>
                </div>

                {/* تحديد زيارة  input   */}
                <div className="col-start-1 pr-4 ">
                  <DatePicker
                    selected={visitDate}
                    onChange={(event) => handleChangeVisitDate(event)}
                    className="date-picker-input"
                  />
                </div>
                {/*  حالة الزيارة * :input */}
                <div className="col-start-2 pr-4 pl-4">
                  <label htmlFor="modal-13">
                    <div
                      className="flex justify-between select-mission-container"
                      onClick={() => handleCityPopUp()}
                    >
                      <input
                        className="select-mission-type pr-4"
                        value={
                          stateFromFarmsSlice.selectedCityId === 0
                            ? "اختر حالة الزيارة"
                            : stateFromFarmsSlice.selectedCityName
                        }
                        disabled
                        id="cityId"
                        name="cityId"
                      />

                      <div className="arrow">
                        <img src={arrow} alt="arrow" />
                      </div>
                    </div>
                  </label>
                </div>

                {/* نسبة الخصم المحددة* */}
                <div className="col-start-1 pr-4 ">
                  <p className="title">نسبة الخصم المحددة</p>
                </div>
                {/* اختر نسبة الخصم المحددة  input   */}
                <div className="col-start-1 pr-4 ">
                  <label htmlFor="modal-12">
                    <div
                      className="flex justify-between select-mission-container"
                      onClick={() => handleGovernoratePopUp()}
                    >
                      <input
                        className="select-mission-type pr-4"
                        value={
                          stateFromFarmsSlice.selectedGovernorateId === 0
                            ? "اختر نسبة الخصم المحددة"
                            : stateFromFarmsSlice.selectedGovernorateName
                        }
                        disabled
                        id="govId"
                        name="govId"
                      />

                      <div className="arrow">
                        <img src={arrow} alt="arrow" />
                      </div>
                    </div>
                  </label>
                </div>
              </div>

              {/* الاوراق و المستندات */}
              <div className="main-info-section  grid grid-cols-2 gap-6 mb-4">
                {/* Header */}
                <div className="col-start-1 pr-4 pt-4 flex items-center ">
                  <div className="main-info-title w-1/2">
                    الاوراق و المستندات
                  </div>
                </div>
                <div className="col-span-full">
                  <div className="divider"></div>
                </div>

                {/* استلام الأوراق المطلوبة */}
                <div className="col-start-1 pr-4 ">
                  <p className="title">استلام الأوراق المطلوبة</p>
                </div>
                {/* حالة رفع الصور */}
                <div className="col-start-2 pr-4 ">
                  <p className="title">حالة رفع الصور</p>
                </div>

                {/* اختر استلام الأوراق المطلوبة  input   */}
                <div className="col-start-1 pr-4 ">
                  <label htmlFor="modal-12">
                    <div
                      className="flex justify-between select-mission-container"
                      onClick={() => handleGovernoratePopUp()}
                    >
                      <input
                        className="select-mission-type pr-4"
                        value={
                          stateFromFarmsSlice.selectedGovernorateId === 0
                            ? "اختر حالة استلام الاوراق"
                            : stateFromFarmsSlice.selectedGovernorateName
                        }
                        disabled
                        id="govId"
                        name="govId"
                      />

                      <div className="arrow">
                        <img src={arrow} alt="arrow" />
                      </div>
                    </div>
                  </label>
                </div>
                {/*  اختر حالة رفع الصور * :input */}
                <div className="col-start-2 pr-4 pl-4">
                  <label htmlFor="modal-13">
                    <div
                      className="flex justify-between select-mission-container"
                      onClick={() => handleCityPopUp()}
                    >
                      <input
                        className="select-mission-type pr-4"
                        value={
                          stateFromFarmsSlice.selectedCityId === 0
                            ? "اختر حالة رفع الصور"
                            : stateFromFarmsSlice.selectedCityName
                        }
                        disabled
                        id="cityId"
                        name="cityId"
                      />

                      <div className="arrow">
                        <img src={arrow} alt="arrow" />
                      </div>
                    </div>
                  </label>
                </div>

                {/* مستند 1* */}
                <div className="col-start-1 pr-4 ">
                  <p className="title">مستند 1</p>
                </div>
                {/* مستند 2* */}
                <div className="col-start-2 pr-4 ">
                  <p className="title">مستند 2</p>
                </div>

                {/* ارفق مستند 1  input   */}
                <div className="col-start-1 pr-4 ">
                  <div className={`${imageUploadWrapClass}`}>
                    <input
                      onDragOver={() => handleDragOver()}
                      onDragLeave={() => handleDragLeave()}
                      className="file-upload-input"
                      type="file"
                      onChange={(e) => readURL(e.target)}
                      accept="image/*"
                    />
                    <div className="drag-text">
                      <h3 className="add-photo-txt">ارفق مستند 1 </h3>
                      <p className="">
                        دعم جميع الملفات، الحجم الأقصى 60 ميجابايت
                      </p>
                    </div>
                  </div>
                </div>
                {/*  ارفق مستند 2 :input */}
                <div className="col-start-2 pr-4 pl-4">
                  <div className={`${imageUploadWrapClass}`}>
                    <input
                      onDragOver={() => handleDragOver()}
                      onDragLeave={() => handleDragLeave()}
                      className="file-upload-input"
                      type="file"
                      onChange={(e) => readURL(e.target)}
                      accept="image/*"
                    />
                    <div className="drag-text">
                      <h3 className="add-photo-txt">ارفق مستند 1 </h3>
                      <p className="">
                        دعم جميع الملفات، الحجم الأقصى 60 ميجابايت
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* Fourth Section [Done Or cancel] */}
              <div className="add-actions p-5">
                <div className="grid grid-cols-2">
                  <div className="col-start-1">
                    <div className="flex gap-4">
                      <button className="add-btn" type="submit">
                        تعديل
                      </button>
                      <div className="cancel-btn" onClick={() => navigate(-1)}>
                        الغاء
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </>
        )}
      </Formik>
    </div>
  );
};
