import Fuse from "fuse.js";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import API from "../../../Api";
import "../../Missions/AddMission/SubComponents/MissionType.scss";
import { useEffect, useState } from "react";
import closeIcon from "../../../Assets/Icons/closeicon.svg";
import {
  setSelectedFeedType,
  setSelectedStoppedFarm,
  setSuspendFarmsData,
  toggleShowActivateFarmPopUp,
} from "../../../redux/Slices/FarmSlice";
import { Loading } from "../../Loading/Loading";
type ActivateFarmPopUpProps = {
  data: {}[];
  farmId: number;
};
export const ActiveFarm = (props: ActivateFarmPopUpProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(0);
  const [selectedItemName, setSelectedItemName] = useState("");
  const [changed, setIsChanged] = useState(false);
  const [data, setData] = useState([]);
  const [searchResults, setSearchResults] = useState<any[]>([{}]);
  const [showOtherLabel, setShowOtherLabel] = useState(false);
  const [otherReasonText, setOtherReasonText] = useState("");
  const options = {
    includeScore: true,
    includeMatches: true,
    threshold: 0.2,
    keys: ["reason"],
  };
  const fuse = new Fuse(searchResults, options);

  const dispatch = useDispatch();

  useEffect(() => {}, []);

  const handleSearch = (event: any) => {
    const { value } = event.target;

    // If the user searched for an empty string,
    // display all data.
    if (value.length === 0) {
      setSearchResults(data);
      return;
    }

    const results = fuse.search(value);
    const items = results.map((result) => result.item);
    setSearchResults(items);
  };
  const handleChangeType = (itemId: number, itemName: string) => {
    setSelectedItemId(itemId === selectedItemId ? 0 : itemId);
    setSelectedItemName(itemId === selectedItemId ? "null" : itemName);
    if (Number(itemId) === 4 && itemId !== selectedItemId) {
      setShowOtherLabel(true);
    } else {
      setShowOtherLabel(false);
    }
  };

  const handleChangeReasonText = (event: any) => {
    setOtherReasonText(event.target.value);
  };
  const submitChoise = () => {
    setIsLoading(true);
    API.post(`farms/${props.farmId}/activate`).then((res) => {
      if (res.status === 201) {
        let suspendFarmData = [...props.data];
        const indexToDelete = suspendFarmData.findIndex(
          (obj: any) => Number(obj.id) === Number(props.farmId)
        );
        if (indexToDelete !== -1) {
          // If the object with the specified ID is found, remove it
          suspendFarmData.splice(indexToDelete, 1);
        }
        let isActivateShown = false;
        dispatch(toggleShowActivateFarmPopUp({ isActivateShown }));
        dispatch(setSuspendFarmsData({ suspendFarmData }));
        toast.success("تم إعادة تفعيل العنبر بنجاح");

        setIsLoading(false);
      } else {
        toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
        setIsLoading(false);
      }
    });
  };
  return (
    <div className="MissionType">
      {isLoading === true ? <Loading /> : null}
      {isLoading === true ? <Loading /> : null}
      <input className="modal-state" id="modal-ActiveFarm" type="checkbox" />
      <div className="modal w-full">
        <label className="modal-overlay" htmlFor="modal-ActiveFarm"></label>
        <div
          className="modal-content flex flex-col gap-5"
          style={{
            backgroundColor: "white",
            width: "2500px",
          }}
        >
          <div className="grid grid-cols-2">
            <div className="col-start-1">
              <h2 className="flex justify-start mission-type-txt">
                إعادة تنشيط العنبر
              </h2>
            </div>

            <div className="col-start-2 mb-4 flex justify-end">
              <div className="flex gap-4">
                <label htmlFor="modal-ActiveFarm">
                  <img src={closeIcon} alt="close" />
                </label>
              </div>
            </div>
            <div className="col-span-full mb-2">
              <div className="divider"></div>
            </div>
            <div className="col-span-full mb-4">
              <span className="are-you-sure flex justify-start">
                هل انت متاكد من انك تريد إعادة تنشيط هذا العنبر ؟
              </span>
            </div>
            <div className="col-span-full">
              <div className="flex actions gap-4">
                <label htmlFor="modal-ActiveFarm">
                  <div className="done" onClick={() => submitChoise()}>
                    نعم
                  </div>
                </label>
                <label htmlFor="modal-ActiveFarm">
                  <div className="cancel">لا</div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
