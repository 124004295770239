import "./Notes.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router-dom";
import { Formik } from "formik";
import { toast } from "react-toastify";
import moment from "moment";
import "moment/locale/ar"; // Import the Arabic locale
import { ar, enUS } from "date-fns/locale";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import API from "../../../../Api";
import { Loading } from "../../../Loading/Loading";
import arrow from ".././../../../Assets/Icons/arrow.jpeg";
interface AddBreederNote {
  farm_id: number;
  note_type_id: number;
  noteTitle: string;
  details: string;
}
export const AddNotes = () => {
  const { userId } = useParams<{ userId: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [farms, setFarms] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [selectedFarmId, setSelectedFarmId] = useState(0);
  const [selectedFarmName, setSelectedFarmName] = useState("اختر العنبر");
  const [selectedNoteType, setSelectedNoteType] = useState("اختر نوع الملاحظة");
  const [selectedNoteTypeId, setSelectedNoteTypeId] = useState(0);
  const [notesTypes, setNotesTypes] = useState([]);
  const [breederNotes, setBreederNotes] = useState<AddBreederNote[]>([]);
  const [addObject, setAddObject] = useState({
    farm_id: 0,
    note_type_id: 0,
    noteTitle: "",
    details: "",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    moment.locale("ar");

    API.get(`notes-types`).then((res) => {
      setIsLoading(true);
      if (res.status === 200) {
        setIsLoading(false);
        setNotesTypes(res.data);
      }
    });
  }, []);

  const handleChangeValues = (value: any, fieldName: string) => {
    // Update addObject with the new value
    setAddObject((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleNotesSubmit = () => {
    setIsLoading(true);
    if (selectedNoteTypeId === 0) {
      toast.warn("من فضلك قم بأختيار نوع الملاحظة");
      setIsLoading(false);
    } else if (addObject.noteTitle === "") {
      toast.warn("من فضلك قم بأدخال عنوان الملاحظة");
      setIsLoading(false);
    } else {
      ConfirmAddNotes();
    }
  };

  const ConfirmAddNotes = () => {
    setIsLoading(true);
    /* 
        farm_id: 0,
    note_type_id: 0,
    noteTitle: "",
    details: "",
    */
    let requestObject: {
      farm_id?: number;
      note_type_id: number;
      noteTitle?: string;
      details?: string;
    } = {
      farm_id: Number(selectedFarmId),
      note_type_id: Number(selectedNoteTypeId),
      noteTitle: addObject.noteTitle,
      details: addObject.details,
    };

    if (selectedFarmId === 0) {
      delete requestObject.farm_id;
    }

    API.post(`breeders/${userId}/notes`, requestObject)
      .then((res) => {
        if (res.status === 201) {
          toast.success("تمت أضافة الملاحظة بنجاح");
          setIsLoading(false);
          navigate(`/Users/Profile/Notes/${userId}`);
        } else {
          setIsLoading(false);
          toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
      });
  };

  const handleChangeSelectedFarm = (id: number, name: string) => {
    setIsLoading(true);
    setSelectedFarmId(id);
    setSelectedFarmName(name);
    setIsLoading(false);
  };

  const handleChangeSelectedNote = (id: number, name: string) => {
    setIsLoading(true);
    setSelectedNoteTypeId(id);
    setSelectedNoteType(name);
    setIsLoading(false);
  };
  return (
    <div className="Notes">
      <Formik
        onSubmit={() => handleNotesSubmit()}
        initialValues={addObject}
        validationSchema={null}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-2 account-data-section mt-4">
                {isLoading === true ? (
                  <>
                    <Loading />
                  </>
                ) : null}
                <div className="col-start-1 mt-4 pr-4">
                  <span className="main-info-notes">اضافة ملاحظة جديدة</span>
                </div>
                <div className="col-span-full">
                  <div className="divider"></div>
                </div>

                {/* عنوان الملاحظة */}
                <div className="col-start-1 pr-4 mt-4">
                  <p className="notes_title">عنوان الملاحظة</p>
                </div>
                {/* نوع الملاحظة */}
                <div className="col-start-2 pr-4 mt-4">
                  <p className="notes_title">نوع الملاحظة</p>
                </div>

                {/* عنوان الملاحظة input */}
                <div className="col-start-1 pr-4 mt-4">
                  <input
                    className="title-content"
                    type="text"
                    placeholder="ادخل عنوان الملاحظة"
                    onChange={(e) =>
                      handleChangeValues(e.target.value, "noteTitle")
                    }
                  />
                </div>

                {/* نوع الملاحظة input */}
                <div className="col-start-2 pr-4 mt-4">
                  <div className="dropdown-container">
                    <div className="dropdown w-full">
                      <label tabIndex={0}>
                        <div className="flex justify-between select-mission-container">
                          <input
                            className="select-mission-type pr-4"
                            value={selectedNoteType}
                            disabled
                            id="farmId"
                            name="farmId"
                          />

                          <div className="arrow">
                            <img src={arrow} alt="arrow" />
                          </div>
                        </div>
                      </label>
                      <div className="dropdown-menu w-full bg-white">
                        <>
                          {notesTypes.map((item: any) => {
                            return (
                              <p
                                className="dropdown-item text-sm text-right"
                                onClick={() =>
                                  handleChangeSelectedNote(
                                    Number(item.id),
                                    item.note
                                  )
                                }
                              >
                                {item.note}
                              </p>
                            );
                          })}
                        </>
                      </div>
                    </div>
                  </div>
                </div>

                {/* تفاصيل الملاحظة */}
                <div className="col-start-1 pr-4 mt-4">
                  <p className="notes_title">تفاصيل الملاحظة</p>
                </div>

                <div className="col-span-full pr-4 pl-4 mt-4">
                  <textarea
                    placeholder="اكتب تفاصيل الملاحظة"
                    className="notes-txt-area w-full"
                    onChange={(e) =>
                      handleChangeValues(e.target.value, "details")
                    }
                  />
                </div>
                <div className="col-span-full pl-4 pr-4 pt-4">
                  <div className="add-actions p-5">
                    <div className="grid grid-cols-2">
                      <div className="col-start-1">
                        <div className="flex gap-4">
                          <button className="add-btn" type="submit">
                            اضافة
                          </button>
                          <div
                            className="cancel-btn"
                            onClick={() =>
                              navigate(`/Users/Profile/Notes/${userId}`)
                            }
                          >
                            الغاء
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </>
        )}
      </Formik>
    </div>
  );
};
