"use client";
import './MultiSelectDropdown.scss'
import { useState, useEffect, useRef } from "react";

export default function MultiSelectDropdown({
  formFieldName,
  options,
  onChange,
  prompt = "قم بأختيار أيام العمل",
}) {
  const [isJsEnabled, setIsJsEnabled] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const optionsListRef = useRef(null);

  useEffect(() => {
    setIsJsEnabled(true);
  }, []);

  const handleChange = (e, value) => {
    const isChecked = e.target.checked;
    const option = e.target.value;

    // Create a copy of the current selectedOptions
    let updatedOptions = [...selectedOptions];

    if (isChecked) {
        // Check if the option is already in the selectedOptions
        const isOptionInSelectedOptions = updatedOptions.some(optionObj => Object.keys(optionObj)[0] === option);

        if (!isOptionInSelectedOptions) {
            // Add the new option to the selectedOptions
            updatedOptions.push({ [option]: value });
        }
    } else {
        // Remove the option from the selectedOptions
        updatedOptions = updatedOptions.filter(optionObj => Object.keys(optionObj)[0] !== option);
    }

    setSelectedOptions(updatedOptions);
    onChange(updatedOptions);
};

  const isSelectAllEnabled = selectedOptions.length < options.length;

  const handleSelectAllClick = (e) => {
    e.preventDefault();

    const optionsInputs = optionsListRef.current.querySelectorAll("input");
    optionsInputs.forEach((input) => {
      input.checked = true;
    });

    setSelectedOptions([...options]);
    onChange([...options]);
  };

  const isClearSelectionEnabled = selectedOptions.length > 0;

  const handleClearSelectionClick = (e) => {
    e.preventDefault();

    const optionsInputs = optionsListRef.current.querySelectorAll("input");
    optionsInputs.forEach((input) => {
      input.checked = false;
    });

    setSelectedOptions([]);
    onChange([]);
  };

  return (
    <label className="relative z-50 MultiSelectDropdown">
      <input type="checkbox" className="hidden peer" />

      <div className="cursor-pointer flex justify-between after:content-['▼'] after:text-xs after:ml-1 after:inline-flex after:items-center peer-checked:after:-rotate-180 after:transition-transform   items-center border h-14 rounded-xl w-full px-5 py-2">
       <span className="prompt">{prompt}</span> 
        {isJsEnabled && selectedOptions.length > 0 && (
          <span className="selected_txt">{`(${selectedOptions.length} selected)`}</span>
        )}
      </div>

      <div className="absolute bg-white border rounded-xl transition-opacity opacity-0 pointer-events-none peer-checked:opacity-100 peer-checked:pointer-events-auto w-full max-h-60 overflow-y-scroll">
        {isJsEnabled && (
          <ul  >
            <li>
              <button
                onClick={handleSelectAllClick}
                disabled={!isSelectAllEnabled}
                className="w-full text-left px-2 py-1 text-blue-600 disabled:opacity-50"
              >
                {"Select All"}
              </button>
            </li>
            <li>
              <button
                onClick={handleClearSelectionClick}
                disabled={!isClearSelectionEnabled}
                className="w-full text-left px-2 py-1 text-blue-600 disabled:opacity-50"
              >
                Reset
              </button>
            </li>
          </ul>
        )}
        <div className='divider'></div>
        <ul ref={optionsListRef} >
          {options.map((option, i) => {
            return (
                
              <li key={option.value} className='list-text '>
                <label
                  className={`flex gap-3 items-center whitespace-nowrap cursor-pointer px-2 py-1 transition-colors hover:bg-blue-100  `}
                >
                  <input
                    type="checkbox"
                    name={formFieldName}
                    value={option.key}
                    className="checkbox checkbox-bordered-success cursor-pointer"                    
                    onChange={e => handleChange(e,option.value)}
                  />
                  <span className="ml-1">{option.key}</span>
                </label>
              </li>
            );
          })}
        </ul>
      </div>
    </label>
  );
}