import "./SupplierFilter.scss";
import Fuse from "fuse.js";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import API from "../../../Api";
import { Loading } from "../../Loading/Loading";
import { useLocation } from "react-router-dom";
import { handleSubmitFilterResult } from "../../../redux/Slices/FarmSlice";
import DatePicker, { Calendar } from "react-multi-date-picker";
import { DateObject } from "react-multi-date-picker";

import { setFilterDate } from "../../../redux/Slices/WalletsSlice";
import type { Value } from "react-multi-date-picker";

const FarmCapacityList = [
  {
    id: 1,
    name: "اققل من 1000",
  },
  {
    id: 2,
    name: "اققل من 5000",
  },
  {
    id: 3,
    name: "اققل من 10000",
  },
  {
    id: 4,
    name: "اققل من 25000",
  },
  {
    id: 5,
    name: "اققل من 50000",
  },
  {
    id: 6,
    name: "اققل من 100000",
  },
  {
    id: 7,
    name: "اققل من 200000",
  },
  {
    id: 8,
    name: "اققل من 500000",
  },

  {},
];
export const NewSupplierRequestFilter = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setLocationData] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState(1);
  const [selectedLocationId, setSelectedLocationId] = useState(1);
  const [selectedFarmCapacityId, setSelectedFarmCapacityId] = useState(0);
  const [selectedItemName, setSelectedItemName] = useState("");
  const [locationSearchResults, setLocationSearchResults] = useState<any[]>([
    {},
  ]);
  //   const [values, setValues] = useState([new DateObject(), new DateObject()]);
  const [value, setValue] = useState<Value>(new Date());

  const [filterObject, setFilterObject] = useState({
    farmCapacityId: 0,
    gov_id: 0,
    city_id: 0,
  });
  const options = {
    includeScore: true,
    includeMatches: true,
    threshold: 0.2,
    keys: ["name"],
  };
  const fuse = new Fuse(locationSearchResults, options);

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    setIsLoading(true);
    //governorates API
    API.get(`governorates`).then((res) => {
      if (res) {
        if (res.status === 403) {
          toast.error(" عفوا انت ليس لديك صلاحية الوصول لهذه الصفحة ");
        } else {
          setLocationSearchResults(res.data);
          setLocationData(res.data);
          setIsLoading(false);
        }
      }
    });
  }, []);

  const handleDateChange = (newValues: any) => {
    // Handle newValues as needed
    setValue(newValues);
    let fromDate = newValues[0];
    let toDate = newValues[1];
    if (toDate !== undefined) {
      //Todo Change It To Supplier Store
      dispatch(setFilterDate({ fromDate, toDate }));
    }
  };
  const handleLocationSearch = (event: any) => {
    const { value } = event.target;

    // If the user searched for an empty string,
    // display all data.
    if (value.length === 0) {
      setLocationSearchResults(data);
      return;
    }

    const results = fuse.search(value);
    const items = results.map((result) => result.item);
    setLocationSearchResults(items);
  };

  const handleChangeLocationType = (itemId: number, itemName: string) => {
    setIsLoading(true);
    setSelectedLocationId(0);
    setSelectedItemId(itemId === selectedItemId ? 0 : itemId);
    setSelectedItemName(itemId === selectedItemId ? "null" : itemName);
    if (itemId === 2) {
      API.get(`cities`).then((res) => {
        if (res) {
          if (res.status === 403) {
            toast.error(" عفوا انت ليس لديك صلاحية الوصول لهذه الصفحة ");
          } else {
            setLocationSearchResults(res.data);
            setLocationData(res.data);
            setIsLoading(false);
          }
        }
      });
    } else {
      API.get(`governorates`).then((res) => {
        if (res) {
          if (res.status === 403) {
            toast.error(" عفوا انت ليس لديك صلاحية الوصول لهذه الصفحة ");
          } else {
            setLocationSearchResults(res.data);
            setLocationData(res.data);
            setIsLoading(false);
          }
        }
      });
    }
  };

  const handleChangeLocationContent = (itemId: number, itemName: string) => {
    setSelectedLocationId(itemId === selectedLocationId ? 0 : itemId);
    if (selectedItemId === 1) {
      //محافظات
      setFilterObject((prevState) => ({
        ...prevState,
        ["gov_id"]: Number(itemId),
        ["city_id"]: 0,
      }));
    } else {
      //مناطق
      setFilterObject((prevState) => ({
        ...prevState,
        ["city_id"]: Number(itemId),
        ["gov_id"]: 0,
      }));
    }
  };

  const handleChangeFarmCapacity = (itemId: number, itemName: string) => {
    setSelectedFarmCapacityId(itemId === selectedFarmCapacityId ? 0 : itemId);

    setFilterObject((prevState) => ({
      ...prevState,
      ["farmCapacityId"]: Number(itemId),
    }));
  };

  const handleSubmitFilter = () => {
    setIsLoading(true);

    let param1 =
      filterObject.gov_id !== 0 ? `gov_id=${filterObject.gov_id}&` : "";
    let param2 =
      filterObject.city_id !== 0 ? `city_id=${filterObject.city_id}&` : "";
    let param3 =
      filterObject.farmCapacityId !== 0
        ? `farms_capacity_id=${filterObject.farmCapacityId}&`
        : "";
    let type = 0;
    let filterObj: {}[] = [];
    let isFiltered = true;
    let totalCount = 0;
    if (location.pathname.includes("AllFarms")) {
      type = 1;
      let filterUrl = `farms?sort_by=id&sort_order=DESC&${param1}${param2}${param3}`;
      API.get(filterUrl).then((res: any) => {
        totalCount = res.data.meta.totalItems;
        filterObj.push(res.data.items);
        setIsLoading(false);
        dispatch(
          handleSubmitFilterResult({
            type,
            filterObj,
            isFiltered,
            totalCount,
            filterUrl,
          })
        );
      });
    } else if (location.pathname.includes("FarmsUnderRevision")) {
      type = 2;
      let filterUrl = `farms?status_id=1&sort_by=id&sort_order=DESC&${param1}${param2}${param3}`;
      API.get(filterUrl).then((res: any) => {
        totalCount = res.data.totalCount;
        filterObj.push(res.data.items);
        setIsLoading(false);
        dispatch(
          handleSubmitFilterResult({
            type,
            filterObj,
            isFiltered,
            totalCount,
            filterUrl,
          })
        );
      });
    }
  };

  return (
    <div className="SupplierFilter accordion-group">
      {isLoading === true ? <Loading /> : null}

      <input className="modal-state" id="modal-1458" type="checkbox" />
      <div className="modal w-screen ">
        <label className="modal-overlay" htmlFor="modal-1458"></label>
        <div
          className="modal-content flex flex-col gap-5 max-w-3xl"
          style={{ width: "580px" }}
        >
          <div className="grid grid-cols-2">
            {/* التصنيف */}
            <>
              <div className="col-start-1 flex justify-start pr-4">
                <div className="head">التصنيف</div>
              </div>
              <div className="col-span-full mt-4 flex gap-8 items-center">
                <div className="flex gap-3 items-center">
                  <input
                    type="checkbox"
                    className="checkbox checkbox-bordered-success"
                    onChange={() => handleChangeLocationType(1, "governorate")}
                    checked={1 === selectedItemId}
                  />
                  <span className="txt">سوق الخامات</span>
                </div>

                <div className="flex gap-3 items-center">
                  <input
                    type="checkbox"
                    className="checkbox checkbox-bordered-success"
                    onChange={() => handleChangeLocationType(2, "city")}
                    checked={2 === selectedItemId}
                  />
                  <span className="txt">سوق مدخلات الإنتاج</span>
                </div>
                <div className="flex gap-3 items-center">
                  <input
                    type="checkbox"
                    className="checkbox checkbox-bordered-success"
                    onChange={() => handleChangeLocationType(2, "city")}
                    checked={2 === selectedItemId}
                  />
                  <span className="txt">سوق الدواجن المذبوحة</span>
                </div>
              </div>
              {/* اكتب كلمة البحث  */}
              <div className="col-span-full p-4">
                <div className="form-control relative w-full">
                  <input
                    type="text"
                    className="input input-lg max-w-full input-search"
                    onChange={handleLocationSearch}
                    placeholder={"اكتب كلمه البحث"}
                  />

                  <span className="absolute inset-y-0 right-4 inline-flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
                        stroke="#64748B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M17.4998 17.4998L14.1665 14.1665"
                        stroke="#64748B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </div>
              </div>
              {/* قائمة المحافظات */}
              <div className="col-span-full mt-4 p-4 mb-4 h-32 overflow-y-auto ">
                <div className="types-list ">
                  <ul className="list-none scrollable-list">
                    {locationSearchResults.map((item) => {
                      return (
                        <>
                          {isLoading === true ? (
                            <div className="skeleton-pulse h-5 rounded-md mb-2"></div>
                          ) : (
                            <li className="flex justify-between pl-4 py-2">
                              <span className="list-text">{item.name}</span>
                              <div>
                                <input
                                  type="checkbox"
                                  className="checkbox checkbox-bordered-success"
                                  onChange={() =>
                                    handleChangeLocationContent(
                                      item.id,
                                      item.name
                                    )
                                  }
                                  checked={item.id === selectedLocationId}
                                />
                              </div>
                            </li>
                          )}
                        </>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </>
            <div className="col-span-full">
              <div className="divider"></div>
            </div>
            <>
              <div className="col-start-1 flex justify-start pr-4">
                <div className="head">العنوان</div>
              </div>
              <div className="col-span-full mt-4 flex gap-8 items-center">
                <div className="flex gap-3 items-center">
                  <input
                    type="checkbox"
                    className="checkbox checkbox-bordered-success"
                    onChange={() => handleChangeLocationType(1, "governorate")}
                    checked={1 === selectedItemId}
                  />
                  <span className="txt">المحافظة</span>
                </div>

                <div className="flex gap-3 items-center">
                  <input
                    type="checkbox"
                    className="checkbox checkbox-bordered-success"
                    onChange={() => handleChangeLocationType(2, "city")}
                    checked={2 === selectedItemId}
                  />
                  <span className="txt">المركز</span>
                </div>
                <div className="flex gap-3 items-center">
                  <input
                    type="checkbox"
                    className="checkbox checkbox-bordered-success"
                    onChange={() => handleChangeLocationType(2, "city")}
                    checked={2 === selectedItemId}
                  />
                  <span className="txt">القرية</span>
                </div>
              </div>
              {/* اكتب كلمة البحث  */}
              <div className="col-span-full p-4">
                <div className="form-control relative w-full">
                  <input
                    type="text"
                    className="input input-lg max-w-full input-search"
                    onChange={handleLocationSearch}
                    placeholder={"اكتب كلمه البحث"}
                  />

                  <span className="absolute inset-y-0 right-4 inline-flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
                        stroke="#64748B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M17.4998 17.4998L14.1665 14.1665"
                        stroke="#64748B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </div>
              </div>
              {/* قائمة المحافظات */}
              <div className="col-span-full mt-4 p-4 mb-4 h-32 overflow-y-auto ">
                <div className="types-list ">
                  <ul className="list-none scrollable-list">
                    {locationSearchResults.map((item) => {
                      return (
                        <>
                          {isLoading === true ? (
                            <div className="skeleton-pulse h-5 rounded-md mb-2"></div>
                          ) : (
                            <li className="flex justify-between pl-4 py-2">
                              <span className="list-text">{item.name}</span>
                              <div>
                                <input
                                  type="checkbox"
                                  className="checkbox checkbox-bordered-success"
                                  onChange={() =>
                                    handleChangeLocationContent(
                                      item.id,
                                      item.name
                                    )
                                  }
                                  checked={item.id === selectedLocationId}
                                />
                              </div>
                            </li>
                          )}
                        </>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </>
            <div className="col-span-full">
              <div className="divider"></div>
            </div>
            <>
              <div className="col-start-1 flex justify-start pr-4">
                <div className="head">تاريخ الطلب</div>
              </div>
              {/* الاهمية */}
              <div className="col-span-full w-full flex justify-center">
                {/* <DatePicker
                  inputClass="filterWallet"
                  value={values}
                  dateSeparator="الي"
                  onChange={handleDateChange}
                  range
                  // rangeHover
                  format="YYYY-MM-DD"
                /> */}
                <div className="mt-3">
                  <Calendar
                    className="bg-lime-500"
                    monthYearSeparator="|"
                    highlightToday={false}
                    showOtherDays
                    value={value}
                    onChange={handleDateChange}
                    multiple
                    range
                  />
                </div>
              </div>
            </>
          </div>

          <div className="pt-4">
            <div className="grid grid-cols-2">
              <div className="col-span-full">
                <label htmlFor="modal-1458">
                  <div
                    className="add-btn w-full p-4"
                    onClick={() => handleSubmitFilter()}
                  >
                    تأكيد
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
