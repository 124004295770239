import "./MissionAssignPopUp.scss";
import Fuse from "fuse.js";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import closeIcon from "../../../../../Assets/Icons/closeicon.svg";
import { useEffect, useState } from "react";
import API from "../../../../../Api";
import { setSelectedAssignTo } from "../../../../../redux/Slices/MissionSlice";
import { Loading } from "../../../../Loading/Loading";
import axios from "axios";

const requestBody = {
  city_ids: [] as number[], // Initialize city_ids as an empty array of numbers
  gov_ids: [] as number[], // Initialize gov_ids as an empty array of numbers
};
type MissionAssignPopUpProps = {
  farmCityId?: number;
  farmGovernorateId?: number;
  hasFarm?: boolean;
  isEdit?: boolean;
  breederCityId?: number;
  breederGovernorateId?: number;
  AssignFromList?: boolean;
  MissionId?: number;
};
export const MissionAssignPopUp = (props: MissionAssignPopUpProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [searchResults, setSearchResults] = useState<any[]>([{}]);
  const [activeType, setActiveType] = useState(3);
  const [selectedSales, setSelectedSales] = useState<number[]>([]);
  const [selectedNameSales, setSelectedNameSales] = useState<string[]>([]);
  const [selectedNameSpecialties, setSelectedNameSpecialties] = useState<
    string[]
  >([]);

  const [selectedspecialties, setSelectedspecialties] = useState<number[]>([]);
  const options = {
    includeScore: true,
    includeMatches: true,
    threshold: 0.2,
    keys: ["name"],
  };
  const fuse = new Fuse(searchResults, options);

  const dispatch = useDispatch();
  const stateFromMissionSlice = useSelector((state: any) => state.missions);
  const stateFromBreedersSlice = useSelector((state: any) => state.Breeders);
  const stateFromFarmsModalSlice = useSelector(
    (state: any) => state.FarmsModal
  );
  const stateFromFarmsSlice = useSelector((state: any) => state.farms);
  const stateFromBreedersModalSlice = useSelector(
    (state: any) => state.breedersModal
  );

  const handleActiveType = (id: number) => {
    requestBody.city_ids = [];
    requestBody.gov_ids = [];
    setSelectedNameSpecialties([]);
    setSelectedspecialties([]);
    setSelectedSales([]);
    setSelectedNameSales([]);
    setActiveType(id);
  };
  useEffect(() => {
    setIsLoading(true);

    //Governorates for farms
    if (activeType === 3 && props.hasFarm === true) {
      if (
        stateFromFarmsModalSlice.governorateId === 0 &&
        props.farmGovernorateId == null &&
        props.farmGovernorateId !== 0
      ) {
        toast.warn("لا يوجد بيانات للمحافظة الخاصة بهذا العنبر");
      } else {
        console.log(
          "stateFromFarmsModalSlice.governorateId:",
          stateFromFarmsModalSlice.governorateId
        );
        if (props.farmGovernorateId == null) {
          //Add Case
          requestBody.gov_ids.push(
            Number(stateFromFarmsModalSlice.governorateId)
          );
          // requestBody.gov_ids = stateFromFarmsModalSlice.governorateId.map(
          //   (item: any) => Number(item)
          // );
        } else {
          //Edit Case
          requestBody.gov_ids.push(Number(props.farmGovernorateId));
        }
      }
    }
    //Governorated for breeders (Add Case case)
    else if (activeType === 4 && props.isEdit === false) {
      if (
        stateFromBreedersModalSlice.governorateId === 0 &&
        props.breederGovernorateId == null &&
        props.breederGovernorateId !== 0
      ) {
        toast.warn("لا يوجد بيانات للمحافظة الخاصة بهذا المربي");
      } else {
        if (props.breederGovernorateId == null) {
          //Add Case
          requestBody.gov_ids.push(
            Number(stateFromBreedersModalSlice.governorateId)
          );
        } else {
          //Edit Case
          requestBody.gov_ids.push(Number(props.breederGovernorateId));
        }
      }
    }
    //Governorated for breeders (edit case)
    else if (activeType === 4 && props.isEdit === true) {
      if (
        stateFromBreedersSlice.selectedBreederGovernorateId === 0 &&
        props.breederGovernorateId == null &&
        props.breederGovernorateId !== 0
      ) {
        toast.warn("لا يوجد بيانات للمحافظة الخاصة بهذا المربي");
      } else {
        if (props.breederGovernorateId == null) {
          //Add Case
          requestBody.gov_ids =
            stateFromBreedersSlice.selectedBreederGovernorateId.map(
              (item: any) => Number(item)
            );
        } else {
          //Edit Case
          requestBody.gov_ids.push(Number(props.breederGovernorateId));
        }
      }
    }

    //converting array to a string and passing it as a parameter in the query string
    let city_ids_txt = `&city_ids=`;
    let gov_ids_txt = `&gov_ids=`;

    //Cities Params
    for (let index = 0; index < requestBody.city_ids.length; index++) {
      let lastElementIndex = requestBody.city_ids.length - 1;
      if (lastElementIndex === index) {
        const element = requestBody.city_ids[index];
        city_ids_txt += `${element}`;
      } else {
        const element = requestBody.city_ids[index];
        city_ids_txt += `${element}&city_ids=`;
      }
    }
    //Governorates Params
    for (let index = 0; index < requestBody.gov_ids.length; index++) {
      let lastElementIndex = requestBody.gov_ids.length - 1;
      if (lastElementIndex === index) {
        const element = requestBody.gov_ids[index];
        gov_ids_txt += `${element}`;
      } else {
        const element = requestBody.gov_ids[index];
        gov_ids_txt += `${element}&gov_ids=`;
      }
    }

    const salesRelatedToFarmCity = `salesman?page=1&limit=500000000${city_ids_txt}`;
    const salesRelatedToFarmGovernorate = `salesman?page=1&limit=500000000${gov_ids_txt}`;
    const salesRelatedToBreederGovernorate = `salesman?page=1&limit=500000000${gov_ids_txt}`;
    API.get(
      activeType === 1
        ? "salesman?page=1&limit=500000000"
        : activeType === 4
        ? salesRelatedToBreederGovernorate
        : activeType === 3
        ? salesRelatedToFarmGovernorate
        : "specialties?page=1&limit=500000000"
    ).then((res) => {
      if (res) {
        if (res.status === 403) {
          toast.error(" عفوا انت ليس لديك صلاحية الوصول لهذه الصفحة ");
        } else {
          if (activeType === 1 || activeType === 3 || activeType === 4) {
            setSearchResults(res.data.items);
            setData(res.data.items);
          } else {
            setSearchResults(res.data);
            setData(res.data);
          }
          setIsLoading(false);
        }
      }
    });
  }, [
    setSearchResults,
    setActiveType,
    activeType,
    stateFromMissionSlice.workAreasIds,
  ]);
  const handleSearch = (event: any) => {
    const { value } = event.target;

    // If the user searched for an empty string,
    // display all data.
    if (value.length === 0) {
      setSearchResults(data);
      return;
    }

    const results = fuse.search(value);
    const items = results.map((result) => result.item);
    setSearchResults(items);
  };
  const handleSelectItem = (id: number, name: string) => {
    if (activeType === 1) {
      //اشخاص
      const isSelectedBefore = selectedSales.includes(id);
      const isNameSelectedBefore = selectedNameSales.includes(name);
      if (isSelectedBefore || isNameSelectedBefore) {
        const indexToRemove = selectedSales.indexOf(id);
        const nameToRemoveIndex = selectedNameSales.indexOf(name);
        // Remove the ID if it exists in the array
        if (indexToRemove !== -1 && nameToRemoveIndex !== -1) {
          selectedSales.splice(indexToRemove, 1);
          selectedNameSales.splice(nameToRemoveIndex, 1);
          setSelectedSales(selectedSales);
          setSelectedNameSales(selectedNameSales);
        }
      } else {
        selectedSales.push(id);
        selectedNameSales.push(name);
        setSelectedSales(selectedSales);
        setSelectedNameSales(selectedNameSales);
      }
    } else {
      const isSelectedBefore = selectedspecialties.includes(id);
      const isNameSelectedBefore = selectedNameSpecialties.includes(name);

      if (isSelectedBefore || isNameSelectedBefore) {
        const indexToRemove = selectedspecialties.indexOf(id);
        const nameIndexToRemove = selectedNameSpecialties.indexOf(name);
        // Remove the ID if it exists in the array
        if (indexToRemove !== -1 && nameIndexToRemove !== -1) {
          selectedspecialties.splice(indexToRemove, 1);
          selectedNameSpecialties.splice(nameIndexToRemove, 1);
          setSelectedspecialties(selectedspecialties);
          setSelectedNameSpecialties(selectedNameSpecialties);
        }
      } else {
        selectedspecialties.push(id);
        selectedNameSpecialties.push(name);
        setSelectedspecialties(selectedspecialties);
        setSelectedNameSpecialties(selectedNameSpecialties);
      }
    }
  };
  const handleSubmitChoise = () => {
    setIsLoading(true);
    let assignedIds = activeType === 1 ? selectedSales : selectedspecialties;
    let assignedType =
      activeType === 1 || activeType === 3 ? "اشخاص" : "تخصصات";
    let assignedText = "";
    let isChanged = false;
    //Get First object from array
    if (selectedNameSales.length > 0 && activeType === 1) {
      let cityName = selectedNameSales[0];
      let count = selectedNameSales.length - 1;
      if (count === 0) {
        assignedText = `${cityName} `;
      } else {
        assignedText = `${cityName} و ${count} أخري`;
      }
    } else {
      let cityName = selectedNameSpecialties[0];
      let count = selectedNameSpecialties.length - 1;
      let text = `${cityName} و ${count} أخري`;
      assignedText = text;
    }
    isChanged = assignedIds.length > 0 ? true : false;
    if (props.AssignFromList === true && props.MissionId !== null) {
      let requestBody = {
        assignment: {
          type: assignedType,
          ids: assignedIds.map((item: any) => Number(item)),
        },
      };

      API.post(`dashboard/missions/${props.MissionId}/reassign`, requestBody)
        .then((res) => {
          if (res.status === 201) {
            toast.success("تم إعادة تعيين المهمة بنجاح");
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          setIsLoading(false);
        });
    } else {
      dispatch(
        setSelectedAssignTo({
          assignedIds,
          assignedType,
          assignedText,
          isChanged,
        })
      );
      setIsLoading(false);
    }
  };
  return (
    <div className="MissionAssignPopUp">
      {isLoading === true ? (
        <>
          <Loading />
        </>
      ) : null}
      <input className="modal-state" id="modal-10" type="checkbox" />
      <div className="modal w-full">
        <label className="modal-overlay" htmlFor="modal-10"></label>
        <div
          className="modal-content flex flex-col gap-5"
          style={{
            backgroundColor: "white",
            width: "2500px",
          }}
        >
          <div className="grid grid-cols-2">
            <div className="col-start-1">
              <h2 className="flex justify-start mission-type-txt">
                تعيين المهمة ل
              </h2>
            </div>
            <div className="col-start-2 flex justify-end">
              <div className="flex gap-4">
                <label htmlFor="modal-10">
                  <img src={closeIcon} alt="close" />
                </label>
              </div>
            </div>
            <div className="col-span-full">
              <div className="divider"></div>
            </div>
            <div className="col-start-1 mt-4  pr-4">
              <div className="flex justify-start gap-4">
                <div
                  className={
                    activeType === 3 ? "assign-type-active" : "assign-type"
                  }
                  onClick={() => handleActiveType(3)}
                >
                  مناديب في حيز محافظة العنبر
                </div>
              </div>
            </div>
            <div className="col-start-1 mt-3 pr-4">
              <div className="flex justify-start gap-4">
                <div
                  className={
                    activeType === 4 ? "assign-type-active" : "assign-type"
                  }
                  onClick={() => handleActiveType(4)}
                >
                  مناديب في حيز محافظة المربي
                </div>
              </div>
            </div>
            <div className="col-start-1 mt-3 pr-4">
              <div className="flex justify-start gap-4">
                <div
                  className={
                    activeType === 1 ? "assign-type-active" : "assign-type"
                  }
                  onClick={() => handleActiveType(1)}
                >
                  كل المناديب
                </div>
              </div>
            </div>
            <div className="col-start-1 mt-3 pr-4">
              <div className="flex justify-start gap-4">
                <div
                  className={
                    activeType === 2 ? "assign-type-active" : "assign-type"
                  }
                  onClick={() => handleActiveType(2)}
                >
                  لتخصص
                </div>
              </div>
            </div>
            <div className="col-start-1 mt-4 mb-4 pr-4">
              <div className="flex justify-start select-mission-type">
                {activeType === 1
                  ? "اضف الاشخاص"
                  : activeType === 2
                  ? "اختر التخصصات"
                  : "اضف الاشخاص"}
              </div>
            </div>
            <div className="col-span-full mb-4 pr-4 pl-4">
              <div className="form-control relative w-full">
                <input
                  type="text"
                  className="input input-lg max-w-full input-search"
                  placeholder="ابحث"
                  onChange={handleSearch}
                />

                <span className="absolute inset-y-0 right-4 inline-flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
                      stroke="#64748B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M17.4993 17.5L14.166 14.1667"
                      stroke="#64748B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </div>
            </div>

            <div className="col-span-full mb-4 pr-4 pl-4 h-40 overflow-y-auto">
              <div className="types-list">
                <ul className="list-none scrollable-list">
                  {searchResults.map((item) => {
                    return (
                      <>
                        {isLoading === true ? (
                          <>
                            <div className="skeleton-pulse h-5 rounded-md mb-2"></div>{" "}
                          </>
                        ) : (
                          <>
                            <li className="flex justify-between pl-4 py-2">
                              <span className="list-text">{item.name}</span>
                              <div>
                                <input
                                  id={`checkbox ${item.id}`}
                                  type="checkbox"
                                  className="checkbox checkbox-bordered-success"
                                  onChange={(e) =>
                                    handleSelectItem(item.id, item.name)
                                  }
                                />
                              </div>
                            </li>
                          </>
                        )}
                      </>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="col-span-full mb-4">
              <div className="divider"></div>
            </div>
            <div className="col-span-full">
              <div className="flex actions gap-4">
                <label htmlFor="modal-10">
                  <div className="done" onClick={() => handleSubmitChoise()}>
                    تم
                  </div>
                </label>
                <label htmlFor="modal-10">
                  <div className="cancel">الغاء</div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
