import "./AddMission.scss";
import arrow from "../../../Assets/Icons/arrow.jpeg";
import { MissionType } from "./SubComponents/MissionType";
import { useEffect, useState } from "react";
import { DatePickerComponent } from "./SubComponents/DatePicker/DatePickerComponent";
import { MissionRangePopUp } from "./SubComponents/MissionRangePopUp/MissionRangePopUp";
import { MissionAssignPopUp } from "./SubComponents/MissionAssignPopUp/MissionAssignPopUp";
import { useSelector, useDispatch } from "react-redux";
import API from "../../../Api";
import { toast } from "react-toastify";
import { Loading } from "../../Loading/Loading";
import moment from "moment";
import { setMainHeaderName } from "../../../redux/Slices/MainHeaderSlice";
import { BonusDatePicker } from "../EditMission/SubComponents/BonusDatePicker";
import { useNavigate } from "react-router-dom";
import { BreedersModal } from "../../../SubComponents/BreedersModal/BreedersModal";
import { FarmsModal } from "../../../SubComponents/FarmsModal/FarmsModal";
import {
  setSelectedAssignTo,
  setSelectedMissionType,
} from "../../../redux/Slices/MissionSlice";
import { setSelectedOneBreeders } from "../../../redux/Slices/BreedersModalSlice";
import { setSelectedOneFarm } from "../../../redux/Slices/FarmsModalSlice";
import {
  setSelectedCity,
  setSelectedGovernorates,
} from "../../../redux/Slices/FarmSlice";

const missionData: { [key: string]: any } = {
  type_id: 0,
  name: "",
  due_at: "",
  details: "",
  reward: 0,
  maps_url: "",
  early_bonus_due_at: "",
  early_bonus: 0,
  work_area_ids: [],
  assignment: {
    type: [],
    ids: [],
  },
  farm_id: 0,
  farmer_id: 0,
};
export const AddMission = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showTypePopUp, setShowTypePopUp] = useState(false);
  const [showRangePopUp, setShowRangePopUp] = useState(false);
  const [hasBonus, setHasBonus] = useState(false);
  const [showMissionAssignPopUp, setShowMissionAssignPopUp] = useState(false);
  const [showBreedersPopUp, setShowBreedersPopUp] = useState(false);
  const [showFarmsPopUp, setShowFarmsPopUp] = useState(false);

  const stateFromMissionSlice = useSelector((state: any) => state.missions);
  const stateFromBreedersModalSlice = useSelector(
    (state: any) => state.breedersModal
  );
  const stateFromFarmsModalSlice = useSelector(
    (state: any) => state.FarmsModal
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleShowAddComponent = () => {
    let mainHeaderName = "الصفحة الرئيسية";
    dispatch(setMainHeaderName({ mainHeaderName }));
    navigate(-1);
  };

  const handleShowTypePopUp = () => {
    setShowTypePopUp(!showTypePopUp);
  };
  const handleShowRangePopUp = () => {
    setShowRangePopUp(!showRangePopUp);
  };
  const handleShowMissionAssignPopUp = () => {
    setShowMissionAssignPopUp(!showMissionAssignPopUp);
  };

  const handleShowBreedersPopUp = () => {
    setShowBreedersPopUp(!showBreedersPopUp);
  };
  const handleShowFarmsPopUp = () => {
    setShowFarmsPopUp(!showFarmsPopUp);
  };

  useEffect(() => {
    let mainHeaderName = "اضافة مهمة";
    ResetLabels();
    dispatch(setMainHeaderName({ mainHeaderName }));
  }, []);

  const ResetLabels = () => {
    let assignedIds: number[] = [];
    let assignedType = "";
    let assignedText = "";
    let isChanged = false;
    let typeId = 0;
    let typeName = "";
    let breederId = 0;
    let breederName = "";

    let breederCity = "";
    let breederGovernorateCity = "";
    let cityId = 0;
    let governorateId = 0;
    let farmId = 0;
    let farmName = "";
    let farmrCity = "";
    let farmGovernorateCity = "";

    dispatch(
      setSelectedAssignTo({
        assignedIds,
        assignedType,
        assignedText,
        isChanged,
      })
    );
    dispatch(setSelectedMissionType({ typeId, typeName }));
    dispatch(
      setSelectedOneBreeders({
        breederId,
        breederName,
        isChanged,
        breederCity,
        breederGovernorateCity,
        cityId,
        governorateId,
      })
    );
    dispatch(
      setSelectedOneFarm({
        farmId,
        farmName,
        isChanged,
        farmrCity,
        farmGovernorateCity,
        cityId,
        governorateId,
      }),
      setSelectedCity({ isChanged }),
      setSelectedGovernorates({ isChanged })
    );
    isChanged = false;
    dispatch(setSelectedCity({ isChanged }));
    dispatch(setSelectedGovernorates({ isChanged }));
  };
  const handleChangeMission = (e: any, field: string) => {
    if (field === "name") {
      missionData.name = e;
    } else if (field === "details") {
      missionData.details = e;
    } else if (field === "reward") {
      missionData.reward = Number(e);
    } else if (field === "early_bonus") {
      missionData.early_bonus = Number(e);
    } else if (field === "maps_url") {
      missionData.maps_url = e;
    }
  };
  const handleAddNewMission = () => {
    /* Validations */

    setIsLoading(true);
    if (stateFromMissionSlice.selecteTypeId === 0) {
      // نوع المهمة
      toast.error("من فضلك قم بأختيار نوع المهمة");
      setIsLoading(false);
    } else if (missionData.name === "") {
      toast.error("من فضلك قم بأدخال عنوان المهمة");
      setIsLoading(false);
    } else if (stateFromMissionSlice.workAreasIds.length === 0) {
      toast.error("من فضلك قم بأختيار نطاق المهمة");
      setIsLoading(false);
    } else if (stateFromMissionSlice.assignedIds.length === 0) {
      toast.error("من فضلك قم بأختيار لمن سيتم تعيين المهمة");
      setIsLoading(false);
    } else if (missionData.reward === 0 || isNaN(missionData.reward)) {
      if (isNaN(missionData.reward)) {
        toast.error("من فضلك قم بأدخال قيمة صحيحة في المقابل المادي");
        setIsLoading(false);
      } else {
        toast.error("من فضلك قم بأدخال قيمة المقابل المادي");
        setIsLoading(false);
      }
    } else if (stateFromMissionSlice.isDateChanged === false) {
      toast.error("من فضلك قم بأختيار تاريخ الأنتهاء");
      setIsLoading(false);
    } else if (hasBonus === true) {
      if (missionData.early_bonus === 0 || isNaN(missionData.early_bonus)) {
        if (isNaN(missionData.early_bonus)) {
          toast.error(" من فضلك قم بأدخال قيمة الحافز بطريقة صحيحة ");
          setIsLoading(false);
        } else {
          toast.error("من فضلك قم بأدخال قيمة الحافز ");
          setIsLoading(false);
        }
      } else {
        confirmAddMission();
      }
    } else {
      confirmAddMission();
    }
  };
  const confirmAddMission = () => {
    setIsLoading(true);
    moment.locale("en");
    missionData.type_id = Number(stateFromMissionSlice.selecteTypeId);
    missionData.work_area_ids = stateFromMissionSlice.workAreasIds.map(
      (item: any) => Number(item)
    );
    missionData.assignment = {
      type: stateFromMissionSlice.assignedType,
      ids: stateFromMissionSlice.assignedIds.map((item: any) => Number(item)),
    };

    missionData.due_at = moment(stateFromMissionSlice.dueDate).format(
      "YYYY-MM-DD"
    );
    missionData.early_bonus_due_at = moment(
      stateFromMissionSlice.BonusDate
    ).format("YYYY-MM-DD");

    missionData.farm_id = Number(stateFromFarmsModalSlice.farmId);
    missionData.farmer_id = Number(stateFromBreedersModalSlice.breederId);
    if (missionData.maps_url === "") {
      delete missionData.maps_url;
    }
    if (missionData.farmer_id === 0) {
      delete missionData.farmer_id;
    }
    if (missionData.farm_id === 0) {
      delete missionData.farm_id;
    }
    API.post("dashboard/missions", missionData)
      .then((response) => {
        if (response.status !== 500) {
          toast.success("تمت أضافة المهمة بنجاح");
          ResetLabels();
          setIsLoading(false);
          navigate(-1);
        } else {
          toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
        setIsLoading(false);
      });
  };
  return (
    <div className="grid grid-cols-2 my-4 AddMission">
      {isLoading === true ? (
        <>
          <Loading />
        </>
      ) : null}
      {showTypePopUp === true ? <MissionType isEdit={false} /> : null}
      {showRangePopUp === true ? <MissionRangePopUp isEdit={false} /> : null}
      {showMissionAssignPopUp === true ? (
        <MissionAssignPopUp
          hasFarm={Number(stateFromFarmsModalSlice.farmId) === 0 ? false : true}
          isEdit={false}
        />
      ) : null}
      {showBreedersPopUp === true ? (
        <BreedersModal MultiSelect={false} />
      ) : null}
      {showFarmsPopUp === true ? (
        <FarmsModal
          MultiSelect={false}
          url={`breeders/${stateFromBreedersModalSlice.breederId}/farms`}
        />
      ) : null}

      {/* First Section [Mission Details] */}

      <div className="grid grid-cols-2 gap-4 col-span-full add-section">
        <div className="col-start-1 mt-4 pr-4">
          <h2 className="flex justify-start mission-details wrap">
            تفاصيل المهمة
          </h2>
        </div>
        <div className="col-span-full">
          <div className="divider"></div>
        </div>
        {/* نوع المهمة */}
        <div className="col-start-1 pr-4">
          <h3 className="mission-type"> نوع المهمة</h3>
        </div>
        <div className="col-start-1 pr-4">
          <label htmlFor="modal-8">
            <div
              className="flex justify-between items-center  pt-3  pl-3 select-mission-container w-full"
              onClick={() => handleShowTypePopUp()}
            >
              <div className="select-mission-type  pr-4">
                {stateFromMissionSlice.selecteTypeId !== 0
                  ? stateFromMissionSlice.selectedTypeName
                  : "اختر نوع المهمة"}
              </div>
              <div className="arrow  pt-1">
                <img src={arrow} alt="arrow" />
              </div>
            </div>
          </label>
        </div>
        {stateFromMissionSlice.selecteTypeId === "1" ||
        stateFromMissionSlice.selecteTypeId === "3" ? (
          <>
            <div className="col-start-1 mt-4   pr-4">
              <h3 className="mission-type"> المربي</h3>
            </div>
            {/* عنوان المربي */}
            <div className="lg:col-start-2 md:col-start-2 sm:col-start-2 xs:col-start-2 mt-4   pr-4">
              <h3 className="mission-type"> عنوان المربي</h3>
            </div>
            {/* المربي input */}
            <div className="col-start-1 pr-4">
              <label htmlFor="modal-1100">
                <div
                  className="flex justify-between items-center  pt-3  pl-3 select-mission-container w-full"
                  onClick={() => handleShowBreedersPopUp()}
                >
                  <div className="select-mission-type  pr-4">
                    {stateFromBreedersModalSlice.breederId !== 0
                      ? stateFromBreedersModalSlice.breederName
                      : "اختر المربي"}
                  </div>
                  <div className="arrow  pt-1">
                    <img src={arrow} alt="arrow" />
                  </div>
                </div>
              </label>
            </div>
            {/* عنوان المربي input */}
            <div className="col-start-2 pr-4 pl-4">
              <div className="w-full mission-address-input flex items-center">
                {stateFromBreedersModalSlice.cityId !== 0
                  ? `${stateFromBreedersModalSlice.breederGovernorateCity} |  ${stateFromBreedersModalSlice.breederCity}`
                  : ""}
              </div>
            </div>
            {/* ألمزرعة الخاصة بالمربي */}
            {stateFromBreedersModalSlice.breederId !== 0 ? (
              <>
                <div className="col-start-1 mt-4   pr-4">
                  <h3 className="mission-type"> المزرعة</h3>
                </div>
                {/* عنوان المزرعة */}
                <div className="col-start-2 mt-4   pr-4">
                  <h3 className="mission-type"> عنوان المزرعة</h3>
                </div>
                {/* المزرعة input*/}
                <div className="col-start-1  pr-4">
                  <label htmlFor="modal-111">
                    <div
                      className="flex justify-between items-center  pt-3  pl-3 select-mission-container w-full"
                      onClick={() => handleShowFarmsPopUp()}
                    >
                      <div className="select-mission-type  pr-4">
                        {stateFromFarmsModalSlice.farmId !== 0
                          ? stateFromFarmsModalSlice.farmName
                          : "اختر المزرعة "}
                      </div>
                      <div className="arrow  pt-1">
                        <img src={arrow} alt="arrow" />
                      </div>
                    </div>
                  </label>
                </div>

                {/* عنوان المزرعة input */}
                <div className="col-start-2 pl-4 pr-4">
                  <div className="w-full mission-address-input flex items-center">
                    {stateFromFarmsModalSlice.cityId !== 0
                      ? `${stateFromFarmsModalSlice.farmGovernorateCity} |  ${stateFromFarmsModalSlice.farmrCity}`
                      : ""}
                  </div>
                </div>
              </>
            ) : null}
          </>
        ) : null}
        <div className="col-start-1 mt-4  pr-4">
          <h3 className="mission-type"> عنوان المهمة</h3>
        </div>
        <div className="col-start-2 mt-4  pr-4">
          <h3 className="mission-type"> تاريخ الأنتهاء</h3>
        </div>

        <div className="col-start-1   mb-4 pr-4">
          <div>
            <input
              type="text"
              placeholder="عنوان المهمة"
              className="mission-address-input"
              onChange={(e) => handleChangeMission(e.target.value, "name")}
            />
          </div>
        </div>
        <div className="col-start-2   pl-4">
          <DatePickerComponent activation={false} isEdit={false} />
        </div>

        <div className="col-start-1  pr-4">
          <h3 className="mission-type"> تفاصيل المهمة</h3>
        </div>
        <div className="col-span-full pr-4 pl-4">
          <div className="txt-area">
            <textarea
              style={{ width: "100%" }}
              className="mission-text-area"
              placeholder="تفاصيل المهمة"
              onChange={(e) => handleChangeMission(e.target.value, "details")}
            />
          </div>
        </div>
      </div>

      {/* Second Section [Mission Range and Assign Mission] */}

      <div className="grid grid-cols-2 gap-4 col-span-full add-section">
        <div className="col-start-1 mt-4  pr-4">
          <h2 className="flex justify-start mission-details wrap">
            النطاق واسناد المهمة
          </h2>
        </div>
        <div className="col-span-full">
          <div className="divider"></div>
        </div>
        <div className="col-start-1  mb-4 pr-4">
          <h3 className="mission-type"> نطاق المهمة</h3>
        </div>
        <div className="col-span-full pr-4 pl-4">
          <label htmlFor="modal-9">
            <div
              className="flex justify-between items-center  pt-3  pl-3 select-mission-container w-full"
              onClick={() => handleShowRangePopUp()}
            >
              <div className="select-mission-type  pr-4">
                {stateFromMissionSlice.workAreasIds.length !== 0
                  ? stateFromMissionSlice.workAreasTitle
                  : " اختر محافظة او مدينة او اكثر"}
              </div>
              <div className="arrow  pt-1">
                <img src={arrow} alt="arrow" />
              </div>
            </div>
          </label>
        </div>

        <div className="col-start-1 mt-4  pr-4">
          <h3 className="mission-type"> رابط الموقع الجغرافي</h3>
        </div>
        <div className="col-span-full pr-4 pl-4">
          <input
            id="mapUrl"
            type="text"
            placeholder="قم بأدخال الرابط"
            className="mission-map-input"
            onChange={(e) => handleChangeMission(e.target.value, "maps_url")}
          />
        </div>
        <div className="col-start-1 mt-4 mb-4 pr-4">
          <h3 className="mission-type"> تعيين المهمة ل </h3>
        </div>
        <div className="col-span-full pr-4 pl-4">
          <label htmlFor="modal-10">
            <div
              className="flex justify-between items-center  pt-3  pl-3 select-mission-container w-full"
              onClick={() => handleShowMissionAssignPopUp()}
            >
              <div className="select-mission-type pr-4">
                {stateFromMissionSlice.assignedIds.length !== 0
                  ? stateFromMissionSlice.assignedText
                  : "اختر شخص او تخصص او اكثر"}
              </div>
              <div className="arrow  pt-1">
                <img src={arrow} alt="arrow" />
              </div>
            </div>
          </label>
        </div>
      </div>

      {/* Third Section [المقابل المادي] */}

      <div className="grid grid-cols-2 gap-4 col-span-full add-section">
        <div className="col-start-1 mt-4 pr-4">
          <h2 className="flex justify-start mission-details wrap">
            المقابل المادي
          </h2>
        </div>
        <div className="col-span-full ">
          <div className="divider"></div>
        </div>
        <div className="col-start-1 pr-4">
          <h3 className="mission-type"> المقابل المادي</h3>
        </div>
        <div className="col-span-full  pr-4 pl-20">
          <div>
            <input
              type="text"
              placeholder="00"
              className="mission-map-input"
              onChange={(e) => handleChangeMission(e.target.value, "reward")}
            />
          </div>
        </div>

        <div className="col-start-1 pr-4">
          <div className="bonus flex lg:gap-4 md:gap-2 sm:gap-2 xs:gap-1 items-center">
            <span className="bonus-text">حافز للأداء الإستثنائي ؟</span>
            <input
              type="checkbox"
              className="switch switch-success lg:w-14 sm:w-1/2 xs:w-1/2"
              checked={hasBonus}
              onChange={() => setHasBonus(!hasBonus)}
            />
          </div>
        </div>
        {hasBonus === true ? (
          <>
            <div className="col-start-2  pr-4">
              <div className="bonus flex gap-4 items-center">
                <span className="bonus-text">تاريخ الحافز الاستثنائي</span>
              </div>
            </div>
            <div className="col-start-1  pr-4">
              <input
                type="text"
                placeholder="00"
                className="mission-map-input"
                onChange={(e) =>
                  handleChangeMission(e.target.value, "early_bonus")
                }
              />
            </div>
            <div className="col-start-2  mb-4 ">
              <BonusDatePicker activation={false} isEdit={false} />
            </div>
          </>
        ) : null}
      </div>

      {/* Fourth Section [Done Or cancel] */}

      <div className="grid grid-cols-2 gap-4 col-span-full add-section p-5">
        <div className="col-span-full">
          <div className="flex justify-start items-center gap-4">
            <div className="add-btn" onClick={() => handleAddNewMission()}>
              اضافة
            </div>
            <div
              className="cancel-btn"
              onClick={() => handleShowAddComponent()}
            >
              الغاء
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
