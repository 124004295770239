import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isLogged: false,
  userType: 1,
  selectedFeedType: 0,
  selectedFeedTypeName: "",
  feedTypeChanged: false,
  FeedTypeSubmited: false,
  selectedHenType: 0,
  selectedHenTypeName: "",
  submited: false,

  selectedBreedType: 0,
  selectedBreedTypeName: "",
  breederTypeChanged: false,
  selectedVentilationType: 0,
  selectedVentilationName: "",
  selectedFacilityTypeId: 0,
  selectedFacilityTypeName: "",
  selectedGovernorateId: 0,
  selectedGovernorateName: "",
  selectedCityId: 0,
  selectedCityName: "",
  selectedFarmingTypeId: 0,
  selectedFarmingTypeName: "",
  selectedCompanyId: 0,
  selectedCompanyText: "",
  companyChanged: false,
  henTypeChanged: false,
  selectedHealtStatusId: 0,
  selectedHealthStatusText: "",
  facilitiesIds: [],
  breedersFilterData: [],
  breedersIsFilters: false,
  breedersFilterTotalCount: 0,
  selectedFarmHeader: 1,
  governorateChanged: false,
  cityChanged: false,
  farmingTypeChanged: false,
  ventilationChanged: false,
  displayTypeId: 1,
  headerType: 0,
  filterDataObj: [],
  isFiltered: false,
  filterTotalCount: 0,
  filterUrl: "",
  selectedFarmId: 0,
  farmChanged: false,
  farmStoppedSubmited: false,
  isActivateShown: false,
  suspendFarmData: [],
  governorateModule: 0,
  cityModule: 0,
};
export const FarmSlice = createSlice({
  name: "farm",
  initialState,
  reducers: {
    //اختيار عنبر للايقاف
    setSelectedStoppedFarm: (state, action) => {
      state.selectedFarmId = action.payload.farmId;
      state.farmChanged = action.payload.isChanged;
      state.farmStoppedSubmited = action.payload.submited;
    },
    //اختيار نوع العلف
    setSelectedFeedType: (state, action) => {
      state.selectedFeedType = action.payload.feedTypeId;
      state.selectedFeedTypeName = action.payload.feedTypeName;
      state.feedTypeChanged = action.payload.isChanged;
      state.FeedTypeSubmited = action.payload.submited;
    },
    // اختيار نوع الداجن
    setSelectedHenType: (state, action) => {
      state.selectedHenType = action.payload.henTypeId;
      state.selectedHenTypeName = action.payload.henTypeName;
      state.henTypeChanged = action.payload.isChanged;
      state.submited = action.payload.submited;
    },
    setSelectedBreedType: (state, action) => {
      state.selectedBreedType = action.payload.breedTypeId;
      state.selectedBreedTypeName = action.payload.breedTypeName;
      state.breederTypeChanged = action.payload.isChanged;
    },
    setSelectedVentilationType: (state, action) => {
      state.selectedVentilationType = action.payload.ventilationTypeId;
      state.selectedVentilationName = action.payload.ventilationTypeName;
      state.ventilationChanged = action.payload.isChanged;
    },
    setSelectedFacilityType: (state, action) => {
      state.selectedFacilityTypeId = action.payload.facilityId;
      state.selectedFacilityTypeName = action.payload.facilityName;
      state.facilitiesIds = action.payload.selectedIds;
    },
    setSelectedGovernorates: (state, action) => {
      state.selectedGovernorateId = action.payload.governorateId;
      state.selectedGovernorateName = action.payload.governorateName;
      state.governorateChanged = action.payload.isChanged;
      state.governorateModule = action.payload.module;
    },
    setSelectedCity: (state, action) => {
      state.selectedCityId = action.payload.cityId;
      state.selectedCityName = action.payload.cityName;
      state.cityChanged = action.payload.isChanged;
      state.cityModule = action.payload.module;
    },
    setSelectedFarmingType: (state, action) => {
      state.selectedFarmingTypeId = action.payload.farmingTypeId;
      state.selectedFarmingTypeName = action.payload.farmingTypeName;
      state.farmingTypeChanged = action.payload.isChanged;
    },
    setSelectedCompany: (state, action) => {
      state.selectedCompanyId = action.payload.companyId;
      state.selectedCompanyText = action.payload.companyText;
      state.companyChanged = action.payload.isChanged;
    },
    setSelectedHealthStatus: (state, action) => {
      state.selectedHealtStatusId = action.payload.healthStatusId;
      state.selectedHealthStatusText = action.payload.healthStatusName;
    },
    setBreadersSearchResult: (state, action) => {
      state.breedersFilterData = action.payload.searchResult;
      state.breedersIsFilters = action.payload.isFiltered;
      state.breedersFilterTotalCount = action.payload.totalCount;
    },
    setSelectedFarmHeader: (state, action) => {
      state.selectedFarmHeader = action.payload.type;
    },
    setSelectedFarmDisplayType: (state, action) => {
      state.displayTypeId = action.payload.displayTypeId;
    },
    handleSubmitFilterResult: (state, action) => {
      state.headerType = action.payload.type;
      state.filterDataObj = action.payload.filterObj;
      state.isFiltered = action.payload.isFiltered;
      state.filterTotalCount = action.payload.totalCount;
      state.filterUrl = action.payload.filterUrl;
    },
    toggleShowActivateFarmPopUp: (state, action) => {
      state.isActivateShown = action.payload.isActivateShown;
    },
    setSuspendFarmsData: (state, action) => {
      state.suspendFarmData = action.payload.suspendFarmData;
    },
  },
});
export const {
  setSelectedHenType,
  setSelectedBreedType,
  setSelectedVentilationType,
  setSelectedFacilityType,
  setSelectedGovernorates,
  setSelectedCity,
  setSelectedFarmingType,
  setSelectedCompany,
  setSelectedHealthStatus,
  setBreadersSearchResult,
  setSelectedFarmHeader,
  setSelectedFarmDisplayType,
  handleSubmitFilterResult,
  setSelectedFeedType,
  setSelectedStoppedFarm,
  toggleShowActivateFarmPopUp,
  setSuspendFarmsData,
} = FarmSlice.actions;

export default FarmSlice.reducer;
