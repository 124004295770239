import "../EditDetailsMission/EditDetailsMission.scss";

import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Loading } from "../../../../Loading/Loading";
import { toast } from "react-toastify";
import API from "../../../../../Api";
import { Formik, FormikHelpers } from "formik";
import { BreedersModal } from "../../../../../SubComponents/BreedersModal/BreedersModal";
import { Cities } from "../../../../Farms/SubComponents/Cities";
import { Governorates } from "../../../../Farms/SubComponents/Governorates";
import arrow from "../../../../../Assets/Icons/arrow.jpeg";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

type EditBreederMissionProps = {
  breederId: Number;
};

interface BreederResponse {
  id: string;
  created_at: string;
  name: string;
  village: string;
  city_id: string;
  full_address: string;
  mobile_number: string;
  whatsapp_number: string;
  facebook_account: string;
  email: string;
  avatar: string;
  approx_breeding_size: number;
  farms_count: number;
  activity_type_id: string;
  account_status: number;
  is_efb: boolean;
  national_id: string;
  specialty_id: string;
  level_id: string;
  ewallet_number: string;
  wallet_balance: number;
  activity_type: {
    id: string;
    name: string;
  };
  governorate: {
    created_at: string;
    updated_at: string;
    id: string;
    name: string;
  };
  city: {
    id: string;
    name: string;
  };
  password: string | null;
  re_password: string | null;
}

export const EditBreederMission = (props: EditBreederMissionProps) => {
  const stateFromBreedersSlice = useSelector((state: any) => state.Breeders);
  const stateFromBreedersModalSlice = useSelector(
    (state: any) => state.breedersModal
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { MissionId } = useParams<{ MissionId: string }>();

  const [isLoading, setIsLoading] = useState(false);
  const [showGovernoratePopUp, setShowGovernoratePopUp] = useState(false);
  const [showCityPopUp, setShowCityPopUp] = useState(false);
  const [breederActivityType, setBreederActivityType] = useState(1);
  const [breederActivityText, setBreederActivityText] =
    useState("اختر نوع النشاط");
  const [showBreedersPopUp, setShowBreedersPopUp] = useState(false);

  const [breederResponse, setBreederResponse] =
    useState<BreederResponse | null>(null);
  const [isHomeBreeding, setHomeBreeding] = useState(false);
  const [isIncubator, setIncubator] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    let breeder =
      stateFromBreedersModalSlice.breedersModalChanged === true
        ? stateFromBreedersModalSlice.breederId
        : props.breederId;
    if (breeder !== 0 && !isNaN(breeder)) {
      API.get(`breeders/${breeder}`).then((res) => {
        if (res) {
          setBreederResponse(res.data);
          if (res.data.activity_type !== null) {
            setBreederActivityText(res.data.activity_type.name);
          }
          if (res.data.home_breeding === null || res.data.home_breeding === 0) {
            setHomeBreeding(false);
          } else {
            setHomeBreeding(true);
          }
          if (res.data.incubator === null || res.data.incubator === 0) {
            setIncubator(false);
          } else {
            setIncubator(true);
          }
          setIsLoading(false);
        }
      });
    } else {
      setIsLoading(false);
    }
  }, [stateFromBreedersModalSlice.breederId]);

  const handleChangeBreederActivityType = (id: number) => {
    setBreederActivityType(id);
    if (id === 1) {
      setBreederActivityText("فردي");
    } else if (id === 2) {
      setBreederActivityText("مؤسسي");
    } else {
      setBreederActivityText("اختر");
    }
  };

  const handleShowBreedersPopUp = () => {
    setShowBreedersPopUp(!showBreedersPopUp);
  };
  const handleGovernoratePopUp = () => {
    setShowGovernoratePopUp(!showGovernoratePopUp);
  };
  const handleCityPopUp = () => setShowCityPopUp(!showCityPopUp);

  const handleChangeBreeder = (
    value: string | number | boolean,
    field: string,
    setValues: FormikHelpers<any>["setValues"]
  ) => {
    // Update the Formik form state with the changed values

    setValues((prevValues: { breederResponse: BreederResponse }) => ({
      ...prevValues,
      breederResponse: {
        ...prevValues.breederResponse,
        [field]: value,
      },
    }));
    if (breederResponse) {
      // Create a new object with the updated field
      const updatedApiResponse: BreederResponse = {
        ...breederResponse,
        [field]: value, // Replace 'New Name' with the new value
      };

      // Update the state with the new object
      setBreederResponse(updatedApiResponse);
    }
  };
  const handleEditBreeder = () => {
    setIsLoading(true);

    moment.locale("en");
    let values = {
      name: breederResponse?.name,

      whatsapp_number: breederResponse?.whatsapp_number,
      city_id:
        stateFromBreedersSlice.selectedBreederCityId === 0
          ? Number(breederResponse?.city.id)
          : Number(stateFromBreedersSlice.selectedBreederCityId),
      // full_address: "g test breeder address",
      village: breederResponse?.village,
      activityTypeId: breederActivityType,
      approximateBreedingSize: breederResponse?.approx_breeding_size,
      email: breederResponse?.email,
      home_breeding: isHomeBreeding,
      incubator: isIncubator,
    };

    API.patch(`breeders/${breederResponse?.id}`, values)
      .then((response) => {
        if (response) {
          let values = {
            farmer_id: Number(breederResponse?.id),
          };
          API.patch(`dashboard/missions/${MissionId}`, values).then((res) => {
            if (res.status === 200) {
              toast.success("تمت تعديل المربي بنجاح");
              setIsLoading(false);
            }
          });
        }
      })
      .catch((error) => {
        console.log("error:", error);
        setIsLoading(false);

        toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
      });
  };

  const breederText = (
    <>
      <div className="select-stage pr-4">
        {stateFromBreedersModalSlice.breedersModalChanged === false
          ? breederResponse?.name !== undefined
            ? breederResponse?.name
            : "قم بأختيار المربي"
          : stateFromBreedersModalSlice.breederId !== 0
          ? stateFromBreedersModalSlice.breederName
          : "قم بأختيار المربي"}
      </div>
    </>
  );

  const governorateText = (
    <div className="select-mission-type bg-white w-full">
      {stateFromBreedersSlice.breederGovernorateChanged === false
        ? breederResponse?.governorate != null
          ? breederResponse?.governorate.name
          : "اختر المحافظة"
        : stateFromBreedersSlice.breederGovernorateChanged !== 0 &&
          stateFromBreedersSlice.governorateModule === 1
        ? stateFromBreedersSlice.selectedBreederGovernorateName
        : "اختر المحافظة"}
    </div>
  );

  const breederCityText = (
    <div className="select-mission-type bg-white w-full">
      {stateFromBreedersSlice.breederCityChanged === false
        ? breederResponse?.city != null
          ? breederResponse?.city.name
          : "اختر المدينة"
        : stateFromBreedersSlice.selectedBreederCityId !== 0 &&
          stateFromBreedersSlice.cityModule === 1
        ? stateFromBreedersSlice.selectedBreederCityName
        : "اختر المدينة"}
    </div>
  );

  return (
    <div className="EditDetailsMission">
      <Formik
        onSubmit={() => handleEditBreeder()}
        initialValues={{ breederResponse: breederResponse || null }} // Handle null case
        validationSchema={null}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setValues,
        }) => (
          <div className="add-section mb-4 ">
            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-2 gap-4">
                <div className="col-span-full mt-4 pr-4">
                  <h2 className="flex justify-start mission-details w-1/2">
                    تفاصيل المربي
                  </h2>
                </div>
                <div className="col-span-full">
                  <div className="divider"></div>
                </div>

                <>
                  <div className="col-start-1  pr-4">
                    <h3 className="mission-type"> المربي </h3>
                  </div>
                  {/* البريد الالكتروني */}
                  <div className="col-start-2  pr-4">
                    <h3 className="mission-type"> البريد الالكتروني</h3>
                  </div>
                  {/* ألمربي input */}
                  <div className="col-start-1 pr-4">
                    <label htmlFor="modal-1100">
                      <div
                        className="w-full mission-address-input flex justify-between pl-4 items-center"
                        onClick={() => handleShowBreedersPopUp()}
                      >
                        {breederText}
                        <div className="arrow">
                          <img src={arrow} alt="arrow" />
                        </div>
                      </div>
                    </label>
                  </div>

                  {/* البريد الألكتروني input */}
                  <div className="col-start-2 pr-4 pl-4">
                    <input
                      className="mission-address-input mission-address-input-mobile flex items-center"
                      value={breederResponse?.email}
                      onChange={(e) =>
                        handleChangeBreeder(e.target.value, "name", setValues)
                      }
                    />
                  </div>

                  {/*  المحافظة */}
                  <div className="col-start-1 pr-4 ">
                    <p className="mission-type">المحافظة</p>
                  </div>

                  {/*  المدينة */}
                  <div className="col-start-2 pr-4 ">
                    <p className="mission-type">المدينة</p>
                  </div>

                  {/* المحافظة  input   */}
                  <div className="col-start-1 pr-4 ">
                    <label htmlFor="modal-12">
                      <div
                        className="w-full mission-address-input flex justify-between pl-4 items-center"
                        onClick={() => handleGovernoratePopUp()}
                      >
                        {governorateText}

                        <div className="arrow">
                          <img src={arrow} alt="arrow" />
                        </div>
                      </div>
                    </label>
                  </div>
                  {/*  المدينة :input */}
                  <div className="col-start-2 pr-4 ">
                    <label htmlFor="modal-13">
                      <div
                        className="w-full mission-address-input flex justify-between pl-4 items-center"
                        onClick={() => handleCityPopUp()}
                      >
                        {breederCityText}

                        <div className="arrow">
                          <img src={arrow} alt="arrow" />
                        </div>
                      </div>
                    </label>
                  </div>

                  {/* رقم الواتساب للمربي */}
                  <div className="col-start-1  pr-4">
                    <h3 className="mission-type">رقم الواتساب</h3>
                  </div>
                  {/* رقم هاتف المربي */}
                  <div className="col-start-2  pr-4">
                    <h3 className="mission-type"> رقم الهاتف</h3>
                  </div>
                  {/* رقم الواتساب للمربي input */}
                  <div className="col-start-1 pr-4 ">
                    <input
                      className="w-full mission-address-input flex items-center"
                      value={breederResponse?.whatsapp_number}
                      onChange={(e) =>
                        handleChangeBreeder(
                          e.target.value,
                          "whatsapp_number",
                          setValues
                        )
                      }
                    />
                  </div>

                  {/* رقم الهاتف للمربي input */}
                  <div className="col-start-2 pr-4 pl-4">
                    <input
                      className="w-full mission-address-input flex items-center"
                      value={breederResponse?.mobile_number}
                      onChange={(e) =>
                        handleChangeBreeder(
                          e.target.value,
                          "mobile_number",
                          setValues
                        )
                      }
                    />
                  </div>

                  {/* نوع النشاط للمربي */}
                  <div className="col-start-1  pr-4">
                    <h3 className="mission-type"> نوع النشاط </h3>
                  </div>
                  {/* حجم التربية التقريبي للمربي */}
                  <div className="col-start-2  pr-4">
                    <h3 className="mission-type"> حجم التربية التقريبي</h3>
                  </div>

                  {/* نوع النشاط للمربي input */}
                  <div className="col-start-1 pr-4 ">
                    <div className="dropdown-container">
                      <div className="dropdown w-full">
                        <label tabIndex={0}>
                          <div className="flex justify-between items-center pl-4 mission-address-input">
                            <input
                              className="select-mission-type pr-4 w-full"
                              id="ownership"
                              name="ownership"
                              value={breederActivityText}
                            />

                            <div className="arrow">
                              <img src={arrow} alt="arrow" />
                            </div>
                          </div>
                        </label>
                        <div className="dropdown-menu w-full bg-white">
                          <a
                            className="dropdown-item text-sm text-right"
                            onClick={() => handleChangeBreederActivityType(1)}
                          >
                            فردي
                          </a>
                          <a
                            className="dropdown-item text-sm text-right"
                            onClick={() => handleChangeBreederActivityType(2)}
                          >
                            مؤسسي
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* حجم التربية التقريبي للمربي input */}
                  <div className="col-start-2 pr-4 pl-4">
                    <div>
                      <input
                        type="number"
                        placeholder="0000"
                        className="mission-address-input"
                        id="count"
                        name="count"
                        value={breederResponse?.approx_breeding_size}
                        onChange={(e) =>
                          handleChangeBreeder(
                            e.target.value,
                            "approx_breeding_size",
                            setValues
                          )
                        }
                      />
                    </div>
                  </div>

                  {/*  حي / قرية */}
                  <div className="col-start-1 pr-4">
                    <p className="mission-type">حي / قرية</p>
                  </div>
                  {/* حي / قرية  input   */}
                  <div className="col-start-1 pr-4 ">
                    <input
                      type="text"
                      placeholder="القرية"
                      className="mission-address-input"
                      id="village"
                      name="village"
                      value={breederResponse?.village}
                      onChange={(e) =>
                        handleChangeBreeder(
                          e.target.value,
                          "village",
                          setValues
                        )
                      }
                    />
                  </div>
                  {/* تربية منزليه */}
                  <div className="col-start-1 mt-4 pr-4">
                    <div className="flex lg:gap-4 md:gap-2 sm:gap-2 xs:gap-1 items-center">
                      <span className="title">تربية منزلية ؟</span>

                      <input
                        type="checkbox"
                        className="switch switch-success lg:w-14 md:w-1/3 sm:w-1/3 xs:w-1/3"
                        checked={isHomeBreeding}
                        onChange={() => setHomeBreeding(!isHomeBreeding)}
                      />
                    </div>
                  </div>

                  {/* مربي اعمار */}
                  <div className="col-start-2 mt-4 pr-4">
                    <div className="flex lg:gap-4 md:gap-2 sm:gap-2 xs:gap-1 items-center">
                      <span className="title">مربي اعمار ؟</span>

                      <input
                        type="checkbox"
                        className="switch switch-success lg:w-14 md:w-1/3 sm:w-1/3 xs:w-1/3"
                        checked={isIncubator}
                        onChange={() => setIncubator(!isIncubator)}
                      />
                    </div>
                  </div>
                  {/* Actions */}
                  <div className="col-start-1 pr-4">
                    <button className="add-btn" type="submit">
                      تعديل المربي
                    </button>
                  </div>
                </>
              </div>
            </form>
          </div>
        )}
      </Formik>
      {showGovernoratePopUp === true ? (
        <Governorates isEdit={true} module={1} />
      ) : null}
      {showCityPopUp === true ? <Cities isEdit={true} module={1} /> : null}
      {showBreedersPopUp === true ? (
        <BreedersModal MultiSelect={false} />
      ) : null}
      {isLoading === true ? (
        <>
          <Loading />
        </>
      ) : null}
    </div>
  );
};
