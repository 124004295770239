import "./HousingCycleHistory.scss";
type HousingCycleHistoryProps = {
  data: {
    id: string;
    other_brooder_co: string;
    other_feed_co: string;
    company_id: string;
    feed_co_id: string;
    dajin_breed_id: string;
    company: {
      id: string;
      type: string;
    };
    feedCompany: {
      id: string;
      name: string;
    };
    dajinBreed: {
      id: string;
      dajin_id: string;
      breed_type: string;
      dajinType: {
        id: string;
        type: string;
      };
    };
  }[];
};
export const HousingCycleHistory = (props: HousingCycleHistoryProps) => {
  return (
    <div className="HousingCycleHistory accordion-group">
      <input className="modal-state" id="modal-2" type="checkbox" />
      <div className="modal w-screen ">
        <label className="modal-overlay" htmlFor="modal-2"></label>
        <div
          className="modal-content flex flex-col gap-5 max-w-3xl"
          style={{ width: "580px" }}
        >
          <div className="grid grid-cols-2">
            <div className="col-start-1 flex justify-start pr-4">
              <div className="head">سجل دورات التسكين</div>
            </div>
          </div>

          <div className="col-span-full">
            <div className="divider"></div>
          </div>

          <div className="col-span-full grid grid-cols-3 rounded-full">
            <div className="flex justify-start head">الشركة</div>
            <div className="flex justify-start head">نوع الداجن</div>
            <div className="flex justify-start head">نوع السلالة</div>
          </div>
          
          {props.data.map((item: any) => {
            return (
              <div className="col-span-full grid grid-cols-3 rounded-md border p-1">
                <div className="flex justify-start head">
                  {item.company != null ? item.company.type : ""}
                </div>
                <div className="flex justify-start head">
                  {item.dajinBreed != null
                    ? item.dajinBreed.dajinType != null
                      ? item.dajinBreed.dajinType.type
                      : ""
                    : ""}
                </div>
                <div className="flex justify-start head">
                  {" "}
                  {item.dajinBreed != null ? item.dajinBreed.breed_type : ""}
                </div>
              </div>
            );
          })}

          <div className="pt-4">
            <div className="grid grid-cols-2">
              <div className="col-span-full">
                <label htmlFor="modal-2">
                  <div
                    className="add-btn w-full p-4"
                    //   onClick={() => handleSubmitFilter()}
                  >
                    تأكيد
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
