import "./EditUser.scss";
import moment from "moment";
import { toast } from "react-toastify";
import API, { URL } from "../../../Api";
import { Loading } from "../../Loading/Loading";
import { useSelector, useDispatch } from "react-redux";
import { setMainHeaderName } from "../../../redux/Slices/MainHeaderSlice";
import { Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import arrow from "../../../Assets/Icons/arrow.jpeg";
import { Stages } from "../SubComponents/Stages/StagesPopUp";
import { Specialization } from "../SubComponents/Specialization/Specialization";
import axios from "axios";
import { WorkRange } from "../SubComponents/work-range/WorkRange";
import eye from "../../../Assets/Icons/eye.svg";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { Governorates } from "../../Farms/SubComponents/Governorates";
import { Cities } from "../../Farms/SubComponents/Cities";
// Define the validation schema
const validationSchema = Yup.object().shape({
  whatsapp: Yup.string()
    .matches(/^01\d{9}$/, "من فضلك قم بإدخال رقم واتساب مكون من 11 رقم")
    .required("من فضلك قم بإدخال رقم الواتساب"),
  mobile: Yup.string()
    .matches(/^01\d{9}$/, "من فضلك قم بإدخال رقم هاتف مكون من 11 رقم")
    .required("من فضلك قم بإدخال رقم الهاتف"),
  name: Yup.string().required("من فضلك قم بإدخال اسم المستخدم"),
  national_id: Yup.string()
    .matches(
      /^(?!^(\d)\1{13}$)\d{14}$/,
      "من فضلك قم  بإدخال رقم قومي مكون من 14 رقم"
    )
    .required("من فضلك قم بإدخال قيمة الرقم القومي"),
});
interface ApiResponse {
  id: string;
  created_at: string;
  name: string;
  national_id: string;
  specialty_id: string;
  level_id: string;
  city_id: string;
  mobile_number: string;
  email: string;
  whatsapp_number: string;
  ewallet_number: string;
  password: string;
  workAreas: {
    id: string;
    name: string;
    governorate_id: string;
  }[];
  city: {
    id: string;
    name: string;
    governorate: {
      id: string;
      name: string;
    };
  };
}
export const EditUser = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState<ApiResponse | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showStagesPopUp, setShowStagesPopUp] = useState(false);
  const [showSpecialtiesPopUp, setShowSpecialtiesPopUp] = useState(false);
  const [showWorkRangePopUp, setShowWorkRangePopUp] = useState(false);
  const [data, setData] = useState<{}>({});
  const [selectedSpecialties, setSelectedSpecialties] =
    useState("قم بأختيار التخصص");
  const [selectedLevel, setSelectedLevel] = useState("قم بأختيار المرحلة");
  const [showGovernoratePopUp, setShowGovernoratePopUp] = useState(false);
  const [showCityPopUp, setShowCityPopUp] = useState(false);
  const { userId } = useParams<{ userId: string }>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stateFromUserSlice = useSelector((state: any) => state.users);

  useEffect(() => {
    setIsLoading(true);
    let mainHeaderName = "تعديل مستخدم";
    dispatch(setMainHeaderName({ mainHeaderName }));
    API.get(`dashboard/salesman/${userId}`).then((res) => {
      if (res) {
        if (res.status === 403) {
          toast.error(" عفوا انت ليس لديك صلاحية الوصول لهذه الصفحة ");
          setIsLoading(false);
        } else {
          // Set the locale to Arabic
          moment.locale("ar");

          setApiResponse(res.data);
          setData(res.data);
          API.get(`specialties`).then((response) => {
            if (response) {
              if (response.data.length > 0) {
                let selectedName = "";
                if (
                  res.data.specialty_id != null &&
                  res.data.specialty_id !== "0"
                ) {
                  selectedName = response.data.find(
                    (c: any) => c.id === res.data.specialty_id
                  ).name;
                  setSelectedSpecialties(selectedName);
                }
              }
            }
          });
          API.get(`levels`).then((resp) => {
            if (resp) {
              let selectedName = "";
              if (res.data.level_id != null && res.data.level_id !== "0") {
                selectedName = resp.data.find(
                  (item: any) => item.id === res.data.level_id
                ).name;
              }
              setSelectedLevel(selectedName);
            }
          });
          setIsLoading(false);
        }
      }
    });
  }, []);

  const handleShowEditComponent = () => {
    let mainHeaderName = "ادارة المستخدمين";
    dispatch(setMainHeaderName({ mainHeaderName }));
    navigate(-1);
  };
  const handleShowStagesPopUp = () => {
    setShowStagesPopUp(!showStagesPopUp);
  };
  const handleShowWorkRangePopup = () => {
    setShowWorkRangePopUp(!showWorkRangePopUp);
  };
  const handleShowSpecialtiesPopUp = () => {
    setShowSpecialtiesPopUp(!showSpecialtiesPopUp);
  };

  const handleGovernoratePopUp = () => {
    setShowGovernoratePopUp(!showGovernoratePopUp);
  };
  const handleCityPopUp = () => {
    setShowCityPopUp(!showCityPopUp);
  };
  const handleChangeUser = (
    value: string | number | boolean,
    field: string,
    setValues: FormikHelpers<any>["setValues"]
  ) => {
    // Update the Formik form state with the changed values

    setValues((prevValues: { apiResponse: ApiResponse }) => ({
      ...prevValues,
      apiResponse: {
        ...prevValues.apiResponse,
        [field]: value,
      },
    }));
    if (apiResponse) {
      // Create a new object with the updated field
      const updatedApiResponse: ApiResponse = {
        ...apiResponse,
        [field]: value, // Replace 'New Name' with the new value
      };

      // Update the state with the new object
      setApiResponse(updatedApiResponse);
    }
  };
  const handleEditUser = () => {
    setIsLoading(true);
    if (
      (apiResponse?.specialty_id === null ||
        apiResponse?.specialty_id === "0") &&
      stateFromUserSlice.SpecialtiesChanged === false
    ) {
      toast.error("من فضلك قم بأختيار التخصص");
      setIsLoading(false);
    } else if (
      (apiResponse?.level_id === null || apiResponse?.level_id === "0") &&
      stateFromUserSlice.stageChanged === false
    ) {
      toast.error("من فضلك قم بأختيار المرحلة");
      setIsLoading(false);
    } else if (
      apiResponse?.workAreas.length === 0 &&
      stateFromUserSlice.workAreaChanged === false
    ) {
      toast.error("من فضلك قم بأختيار نطاق العمل");
      setIsLoading(false);
    } else {
      submitEditUser();
    }
  };
  const submitEditUser = () => {
    if (apiResponse != null) {
      apiResponse.specialty_id =
        stateFromUserSlice.SpecialtiesChanged === true
          ? stateFromUserSlice.specialtiesId
          : apiResponse.specialty_id;

      apiResponse.level_id =
        stateFromUserSlice.stageChanged === true
          ? stateFromUserSlice.levelId
          : apiResponse.level_id;

      apiResponse.city_id =
        stateFromUserSlice.cityChanged === true
          ? stateFromUserSlice.selectedCityId
          : apiResponse?.city != null
          ? apiResponse.city_id
          : null;
    }

    const arrayData =
      stateFromUserSlice.workAreaChanged === true
        ? stateFromUserSlice.workAreas_ids
        : apiResponse?.workAreas.map((item) => item.id);

    let requestObject: {
      name: string | undefined;
      mobile: string | undefined;
      email?: string | undefined | null;
      whatsapp: string | undefined;
      national_id: string | undefined;
      specialty_id: Number | undefined;
      level_id: Number | undefined;
      password: string | undefined;
      work_area_ids: Number[] | undefined;
      ewallet_number?: string | undefined;
      cityId: Number | undefined;
    } = {
      name: apiResponse?.name,
      mobile: apiResponse?.mobile_number,
      email: apiResponse?.email,
      whatsapp: apiResponse?.whatsapp_number,
      national_id: apiResponse?.national_id,
      specialty_id: Number(apiResponse?.specialty_id),
      level_id: Number(apiResponse?.level_id),
      password: apiResponse?.password,
      work_area_ids: arrayData.map((item: any) => Number(item)),
      ewallet_number: apiResponse?.ewallet_number,
      cityId: Number(apiResponse?.city_id),
    };
    if (apiResponse?.email != null && apiResponse?.email.length === 0) {
      requestObject.email = "";
    }
    if (
      apiResponse?.ewallet_number != null &&
      apiResponse?.ewallet_number.length === 0
    ) {
      delete requestObject.ewallet_number;
    }

    axios
      .patch(`${URL}dashboard/salesman/${userId}`, requestObject, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          toast.success("تمت تعديل المستخدم بنجاح");
          setIsLoading(false);
          handleShowEditComponent();
        } else if (response.status === 400) {
          toast.error("هذا المستخدم موجود من قبل");
          setIsLoading(false);
        } else {
          toast.error("حدث خطأ ما يرجي التواصل مع المسؤولين");
          setIsLoading(false);
          handleShowEditComponent();
        }
      });
  };
  const workRangeText = (
    <div className="select-stage pr-4">
      {stateFromUserSlice.workAreaChanged === false
        ? apiResponse?.workAreas != null && apiResponse.workAreas.length > 0
          ? apiResponse?.workAreas.length > 1
            ? `${apiResponse?.workAreas[0].name} و ${
                apiResponse?.workAreas.length - 1
              } اخري`
            : apiResponse?.workAreas[0].name
          : "قم بأختيار نطاق العمل"
        : stateFromUserSlice.workAreas_ids.length !== 0
        ? stateFromUserSlice.workAreas_text
        : "قم بأختيار نطاق العمل"}
    </div>
  );

  const governorateText = (
    <div className="select-mission-type bg-white w-full">
      {stateFromUserSlice.governorateChanged === false
        ? apiResponse?.city != null
          ? apiResponse?.city.governorate.name
          : "اختر المحافظة"
        : stateFromUserSlice.governorateChanged !== 0
        ? stateFromUserSlice.selectedGovernorateName
        : "اختر المحافظة"}
    </div>
  );

  const CityText = (
    <div className="select-mission-type bg-white w-full">
      {stateFromUserSlice.cityChanged === false
        ? apiResponse?.city != null
          ? apiResponse?.city.name
          : "اختر المدينة"
        : stateFromUserSlice.selectedCityId !== 0
        ? stateFromUserSlice.selectedCityName
        : "اختر المدينة"}
    </div>
  );
  return (
    <div className="EditUser">
      {isLoading === true ? (
        <>
          <Loading />
        </>
      ) : null}
      {showGovernoratePopUp === true ? <Governorates isEdit={false} /> : null}
      {showCityPopUp === true ? <Cities isEdit={false} /> : null}

      {showStagesPopUp === true ? <Stages /> : null}
      {showSpecialtiesPopUp === true ? <Specialization /> : null}
      {showWorkRangePopUp === true ? <WorkRange /> : null}

      <Formik
        onSubmit={() => handleEditUser()}
        enableReinitialize
        initialValues={{
          name: apiResponse?.name,
          mobile: apiResponse?.mobile_number,
          email: apiResponse?.email,
          whatsapp: apiResponse?.whatsapp_number,
          national_id: apiResponse?.national_id,
          specialty_id: Number(apiResponse?.specialty_id),
          level_id: Number(apiResponse?.level_id),
          password: apiResponse?.password,
          work_area_ids: apiResponse?.workAreas.map((item) => item.id),
          ewallet_number: apiResponse?.ewallet_number,
        }}
        validationSchema={validationSchema}
        key={`EditUser`}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setValues,
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              <div className="add-section mb-4">
                <div className="grid grid-cols-2">
                  <div className="col-start-1 mt-4 mb-4 pr-4">
                    <h2 className="flex justify-start add-details">
                      تعديل مستخدم
                    </h2>
                  </div>
                  <div className="col-span-full mb-4">
                    <div className="divider"></div>
                  </div>
                  {/* الاسم الاول */}
                  <div className="col-start-1 mt-4 mb-4 pr-4">
                    <h3 className="first-name"> اسم المستخدم</h3>
                  </div>
                  <div className="col-start-2 mt-4 mb-4 pr-4">
                    <h3 className="first-name"> البريد الألكتروني </h3>
                  </div>

                  {/*Name Input*/}
                  <div className="col-start-1  mb-4 pr-4">
                    <div className="form-control relative">
                      <input
                        type="text"
                        placeholder="اسم المستخدم"
                        className={
                          errors.name && touched.name
                            ? "input-err"
                            : "first-name-input"
                        }
                        name="name"
                        id="name"
                        onChange={(e) => {
                          handleChange(e);

                          handleChangeUser(e.target.value, "name", setValues);
                        }}
                        onBlur={handleBlur}
                        value={values.name}
                      />
                      {errors.name && touched.name && (
                        <div
                          className="error err-msg"
                          style={{
                            position: "absolute",
                            bottom: "-20px",
                            left: "0",
                            width: "100%",
                          }}
                        >
                          {errors.name}
                        </div>
                      )}
                    </div>
                  </div>

                  {/*Email Input*/}
                  <div className="col-start-2  mb-4 pr-4">
                    <div>
                      <input
                        type="text"
                        placeholder="البريد الألكتروني"
                        className="first-name-input"
                        onChange={(e) =>
                          handleChangeUser(e.target.value, "email", setValues)
                        }
                        value={values.email}
                      />
                    </div>
                  </div>

                  <div className="col-start-1 mt-4 mb-4 pr-4">
                    <h3 className="last-name"> الرقم القومي</h3>
                  </div>

                  <div className="col-start-2 mt-4 mb-4 pr-4">
                    <h3 className="last-name"> رقم الهاتف</h3>
                  </div>

                  {/* National Id Input*/}
                  <div className="col-start-1  mb-4 pr-4">
                    <div className="form-control relative">
                      <input
                        type="number"
                        placeholder="الرقم القومي"
                        className={
                          errors.national_id && touched.national_id
                            ? "input-err"
                            : "first-name-input"
                        }
                        value={values.national_id}
                        name="national_id"
                        id="national_id"
                        onChange={(e) => {
                          handleChange(e);
                          handleChangeUser(
                            e.target.value,
                            "national_id",
                            setValues
                          );
                        }}
                        onBlur={handleBlur}
                      />

                      {errors.national_id && touched.national_id && (
                        <div
                          className="error err-msg"
                          style={{
                            position: "absolute",
                            bottom: "-20px",
                            left: "0",
                            width: "100%",
                          }}
                        >
                          {errors.national_id}
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Phone Number input */}
                  <div className="col-start-2  mb-4 pr-4">
                    <div className="form-control relative">
                      <input
                        type="number"
                        placeholder="رقم الهاتف"
                        className={
                          errors.mobile && touched.mobile
                            ? "input-err"
                            : "first-name-input"
                        }
                        onBlur={handleBlur}
                        value={values.mobile}
                        name="mobile"
                        id="mobile"
                        onChange={(e) => {
                          handleChange(e);
                          handleChangeUser(
                            e.target.value,
                            "mobile_number",
                            setValues
                          );
                        }}
                      />
                      {errors.mobile && touched.mobile && (
                        <div
                          className="error err-msg"
                          style={{
                            position: "absolute",
                            bottom: "-20px",
                            left: "0",
                            width: "100%",
                          }}
                        >
                          {errors.mobile}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-start-1 mt-4 mb-4 pr-4">
                    <h3 className="last-name"> رقم الواتساب</h3>
                  </div>

                  <div className="col-start-2 mt-4 mb-4 pr-4">
                    <h3 className="last-name"> رقم المحفظة</h3>
                  </div>
                  {/* What's app number input */}
                  <div className="col-start-1  mb-4 pr-4">
                    <div className="form-control relative">
                      <input
                        type="number"
                        placeholder="رقم الواتساب"
                        className={
                          errors.whatsapp && touched.whatsapp
                            ? "input-err"
                            : "first-name-input"
                        }
                        value={values.whatsapp}
                        name="whatsapp"
                        id="whatsapp"
                        onChange={(e) => {
                          handleChange(e);

                          handleChangeUser(
                            e.target.value,
                            "whatsapp_number",
                            setValues
                          );
                        }}
                        onBlur={handleBlur}
                      />
                      {errors.whatsapp && touched.whatsapp && (
                        <div
                          className="error err-msg"
                          style={{
                            position: "absolute",
                            bottom: "-20px",
                            left: "0",
                            width: "100%",
                          }}
                        >
                          {errors.whatsapp}
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Wallet Number Input */}
                  <div className="col-start-2  mb-4 pr-4">
                    <div>
                      <input
                        type="number"
                        placeholder="رقم المحفظة"
                        className="first-name-input"
                        value={apiResponse?.ewallet_number}
                        onChange={(e) => {
                          handleChangeUser(
                            e.target.value,
                            "ewallet_number",
                            setValues
                          );
                        }}
                      />
                    </div>
                  </div>

                  {/*  المحافظة */}
                  <div className="col-start-1 mt-4 mb-4 pr-4 ">
                    <p className="last-name">المحافظة</p>
                  </div>

                  {/*  المدينة */}
                  <div className="col-start-2 mt-4 mb-4 pr-4 ">
                    <p className="last-name">المدينة</p>
                  </div>

                  {/* المحافظة  input   */}
                  <div className="col-start-1 mb-4 pr-4 ">
                    <label htmlFor="modal-12">
                      <div
                        className="w-full mission-address-input flex justify-between pl-4 items-center"
                        onClick={() => handleGovernoratePopUp()}
                      >
                        {governorateText}

                        <div className="arrow">
                          <img src={arrow} alt="arrow" />
                        </div>
                      </div>
                    </label>
                  </div>
                  {/*  المدينة :input */}
                  <div className="col-start-2 mb-4 pr-4 ">
                    <label htmlFor="modal-13">
                      <div
                        className="w-full mission-address-input flex justify-between pl-4 items-center"
                        onClick={() => handleCityPopUp()}
                      >
                        {CityText}

                        <div className="arrow">
                          <img src={arrow} alt="arrow" />
                        </div>
                      </div>
                    </label>
                  </div>
                  {/* Password */}
                  <div className="col-start-1 mb-4 pr-4">
                    <h3 className="first-name">كلمة المرور</h3>
                  </div>
                  {/* Password Input */}
                  <div className="col-start-1   mb-4 pr-4">
                    <div className="form-field">
                      <input
                        type="text"
                        className="first-name-input"
                        placeholder="كلمة المرور"
                        onChange={(e) =>
                          handleChangeUser(
                            e.target.value,
                            "password",
                            setValues
                          )
                        }
                        value={apiResponse?.password}
                        id="password-user"
                      />
                    </div>
                  </div>
                  {/* المرحلة */}
                  <div className="col-start-1 mt-4 mb-4 pr-4">
                    <h3 className="last-name"> المرحلة</h3>
                  </div>
                  <div className="col-span-full pr-4">
                    <label htmlFor="modal-8">
                      <div
                        className="flex justify-between select-stage-container"
                        onClick={() => handleShowStagesPopUp()}
                      >
                        <div className="select-stage pr-4">
                          {stateFromUserSlice.levelId !== 0
                            ? stateFromUserSlice.levelName
                            : selectedLevel}
                        </div>
                        <div className="arrow">
                          <img src={arrow} alt="arrow" />
                        </div>
                      </div>
                    </label>
                  </div>
                  {/* التخصص */}
                  <div className="col-start-1 mt-4 mb-4 pr-4">
                    <h3 className="last-name"> التخصص</h3>
                  </div>
                  <div className="col-span-full pr-4">
                    <label htmlFor="modal-9">
                      <div
                        className="flex justify-between select-stage-container"
                        onClick={() => handleShowSpecialtiesPopUp()}
                      >
                        <div className="select-stage pr-4">
                          {stateFromUserSlice.specialtiesId !== 0
                            ? stateFromUserSlice.specialtiesName
                            : selectedSpecialties}
                        </div>
                        <div className="arrow">
                          <img src={arrow} alt="arrow" />
                        </div>
                      </div>
                    </label>
                  </div>

                  {/* نطاق العمل */}
                  <div className="col-start-1 mt-4 mb-4 pr-4">
                    <h3 className="last-name"> نطاق العمل</h3>
                  </div>
                  <div className="col-span-full pr-4">
                    <label htmlFor="modal-784">
                      <div
                        className="flex justify-between select-stage-container"
                        onClick={() => handleShowWorkRangePopup()}
                      >
                        {workRangeText}
                        <div className="arrow">
                          <img src={arrow} alt="arrow" />
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              {/* Actions */}
              <div className="add-actions mt-4 mb-4 p-5">
                <div className="grid grid-cols-2">
                  <div className="col-start-1">
                    <div className="flex gap-4">
                      <button type="submit" className="add-btn">
                        تعديل
                      </button>
                      <div
                        className="cancel-btn"
                        onClick={() => handleShowEditComponent()}
                      >
                        الغاء
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </>
        )}
      </Formik>
    </div>
  );
};
