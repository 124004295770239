import "./EditFarm.scss";
import { useEffect, useState } from "react";
import arrow from "../../../Assets/Icons/arrow.jpeg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setMainHeaderName } from "../../../redux/Slices/MainHeaderSlice";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { VentilationType } from "../SubComponents/VentilationType";
import { Governorates } from "../SubComponents/Governorates";
import { Cities } from "../SubComponents/Cities";
import * as Yup from "yup";
import { Formik, FormikHelpers } from "formik";
import { toast } from "react-toastify";
import API, { URL as link } from "../../../Api";
import { useParams } from "react-router-dom";
import { FarmingTypes } from "../SubComponents/FarmingTypes";
import { Loading } from "../../Loading/Loading";
interface ApiResponse {
  id: string;
  user_id: string;
  name: string;
  area: number;
  license_number: number;
  photo: string;
  gov_id: string;
  city_id: string;
  city: {
    id: string;
    name: string;
    governorate_id: string;
  };

  village: string;
  full_address: string;
  rented: number;
  latitude: number;
  longitude: number;
  dajin_breed_id: string;
  created_at: string;
  updated_at: string;
  user: {
    id: string;
    name: string;
    mobile_number: string;
  };
  dajinBreed: {
    id: string;
    breed_type: string;
  };
  farmingType: {
    id: string;
    type: string;
  };
  detailedDescription: string;
  ventilationType: {
    id: string;
    type: string;
  };
  governorate: {
    created_at: string;
    updated_at: string;
    id: string;
    name: string;
  };
  farms_capacity_id: string;
  ventilation_type_id: string;
}
const validationSchema = Yup.object().shape({
  farmArea: Yup.string()
    .matches(/^(?![1-9]$)\d+$/, "من فضلك قم بإدخال رقم اكبر من 9")
    .required("من فضلك قم بإدخال مساحة العنبر"),
});
export const EditFarm = () => {
  const stateFromFarmsSlice = useSelector((state: any) => state.farms);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { breederId } = useParams<{ breederId: string }>();

  const [addObject, setAddObject] = useState({
    name: "",
    farmArea: 0,
    birdTypeId: "",
    breedId: "",
    farmTypeId: "",
    farmCapacityId: 0,
    ventilationTypeId: "",
    facilitiesId: "",
    farmLicense: "",
    cityId: "",
    village: "",
    detailedDescription: "",
    ownership: "",
    userId: "",
  });
  const [file, setFile] = useState<File | null>(null);
  const [imageUploadWrapClass, setImageUploadWrapClass] =
    useState("image-upload-wrap");
  const [fileUploadContentVisible, setFileUploadContentVisible] =
    useState(false);
  const [showVentilationTypePopUp, setShowVentilationTypePopUp] =
    useState(false);
  const [showGovernoratePopUp, setShowGovernoratePopUp] = useState(false);
  const [showCityPopUp, setShowCityPopUp] = useState(false);
  const [farmSizeId, setFarmSizeId] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState<ApiResponse | null>(null);
  const [farmCapacityId, setFarmCapacityId] = useState(0);
  const [farmCapacityText, setFarmCapacityText] = useState("اختر سعة العنبر");
  const { farmId } = useParams<{ farmId: string }>();
  const [showFarmingTypePopUp, setShowFarmingTypePopUp] = useState(false);
  const [isIncubator, setIncubator] = useState(false);
  const [breederName, setBreederName] = useState("");
  const [farmsCount, setFarmsCount] = useState(0);

  useEffect(() => {
    let mainHeaderName = "تعديل عنبر";
    dispatch(setMainHeaderName({ mainHeaderName }));

    API.get(`farms/${farmId}`).then((res) => {
      if (res) {
        const updatedFarmerData = {
          ...res.data,
          photo: `${URL}${res.data.photo}`,
        };
        API.get(`breeders/${res.data.user_id}`).then((res) => {
          if (res.status === 200) {
            setBreederName(res.data.name);
            setFarmsCount(res.data.farmsStatistics.totalFarmsCount);
            setIsLoading(false);
          }
        });

        setApiResponse(updatedFarmerData);
        if (res.data.farms_capacity_id !== null) {
          if (Number(res.data.farms_capacity_id) === 1) {
            setFarmCapacityText("اقل من 1000");
            setFarmCapacityId(Number(res.data.farms_capacity_id));

            setIsLoading(false);
          } else if (Number(res.data.farms_capacity_id) === 2) {
            setFarmCapacityText("اققل من 5000");
            setFarmCapacityId(Number(res.data.farms_capacity_id));

            setIsLoading(false);
          } else if (Number(res.data.farms_capacity_id) === 3) {
            setFarmCapacityText("اققل من 10000");
            setFarmCapacityId(Number(res.data.farms_capacity_id));
            setIsLoading(false);
          } else if (Number(res.data.farms_capacity_id) === 4) {
            setFarmCapacityText("اققل من 25000");
            setFarmCapacityId(Number(res.data.farms_capacity_id));
            setIsLoading(false);
          } else if (Number(res.data.farms_capacity_id) === 5) {
            setFarmCapacityText("اققل من 50000");
            setFarmCapacityId(Number(res.data.farms_capacity_id));
            setIsLoading(false);
          } else if (Number(res.data.farms_capacity_id) === 6) {
            setFarmCapacityText("اققل من 100000");
            setFarmCapacityId(Number(res.data.farms_capacity_id));
            setIsLoading(false);
          } else if (Number(res.data.farms_capacity_id) === 7) {
            setFarmCapacityText("اققل من 200000");
            setFarmCapacityId(Number(res.data.farms_capacity_id));
            setIsLoading(false);
          } else if (Number(res.data.farms_capacity_id) === 8) {
            setFarmCapacityText("اققل من 500000");
            setFarmCapacityId(Number(res.data.farms_capacity_id));
            setIsLoading(false);
          } else {
            setFarmCapacityText("اختر سعة العنبر");
          }
          if (res.data.incubator === null || res.data.incubator === 0) {
            setIncubator(false);
          } else {
            setIncubator(true);
          }
        }
        setIsLoading(false);
      }
    });
  }, []);

  const removeUpload = () => {
    setFile(null);
    setImageUploadWrapClass("image-upload-wrap");
    setFileUploadContentVisible(false);
  };
  const readURL = (input: any) => {
    if (input.files && input.files[0]) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setImageUploadWrapClass("image-upload-wrap image-dropping");
        setFileUploadContentVisible(true);
        setFile(input.files[0]);
      };

      reader.readAsDataURL(input.files[0]);
    } else {
      removeUpload();
    }
  };

  const handleDragOver = () => {
    setImageUploadWrapClass("image-upload-wrap image-dropping");
  };

  const handleDragLeave = () => {
    setImageUploadWrapClass("image-upload-wrap");
  };

  const handleChangeFarmSize = (id: number) => {
    setFarmSizeId(id);
  };

  const handleVentilationTypePopUp = () => {
    setShowVentilationTypePopUp(!showVentilationTypePopUp);
  };

  const handleGovernoratePopUp = () => {
    setShowGovernoratePopUp(!showGovernoratePopUp);
  };

  const handleChangeFarmCapacity = (id: number) => {
    setIsLoading(true);
    setFarmCapacityId(id);
    if (id === 1) {
      setFarmCapacityText("اقل من 1000");
      setIsLoading(false);
    } else if (id === 2) {
      setFarmCapacityText("اققل من 5000");
      setIsLoading(false);
    } else if (id === 3) {
      setFarmCapacityText("اققل من 10000");
      setIsLoading(false);
    } else if (id === 4) {
      setFarmCapacityText("اققل من 25000");
      setIsLoading(false);
    } else if (id === 5) {
      setFarmCapacityText("اققل من 50000");
      setIsLoading(false);
    } else if (id === 6) {
      setFarmCapacityText("اققل من 100000");
      setIsLoading(false);
    } else if (id === 7) {
      setFarmCapacityText("اققل من 200000");
      setIsLoading(false);
    } else if (id === 8) {
      setFarmCapacityText("اققل من 500000");
      setIsLoading(false);
    } else {
      setFarmCapacityText("اختر سعة العنبر");
    }
  };

  const handleCityPopUp = () => {
    setShowCityPopUp(!showCityPopUp);
  };

  const handleFarmingTypePopUp = () => {
    setShowFarmingTypePopUp(!showFarmingTypePopUp);
  };

  const handleChangeFarm = (
    value: string | number | boolean,
    field: string,
    setValues: FormikHelpers<any>["setValues"]
  ) => {
    // Update the Formik form state with the changed values

    setValues((prevValues: { apiResponse: ApiResponse }) => ({
      ...prevValues,
      apiResponse: {
        ...prevValues.apiResponse,
        [field]: value,
      },
    }));
    if (apiResponse) {
      // Create a new object with the updated field
      const updatedFarmResponse: ApiResponse = {
        ...apiResponse,
        [field]: value, // Replace 'New Name' with the new value
      };

      // Update the state with the new object
      setApiResponse(updatedFarmResponse);
    }
  };

  const validations = () => {
    setIsLoading(true);
    if (apiResponse?.area === 0) {
      toast.warn("من فضلك قم بأدخال مساحة العنبر");
      setIsLoading(false);
    } else {
      confirmEditFarm();
    }
  };

  const handleLoginSubmit = () => {
    validations();
  };

  const confirmEditFarm = () => {
    setIsLoading(true);
    if (apiResponse?.name.length === 0) {
      addObject.name = `${breederName}-${farmsCount + 1}`;
    }
    addObject.cityId = stateFromFarmsSlice.selectedCityId;
    addObject.ventilationTypeId = stateFromFarmsSlice.selectedVentilationType;
    const data = {
      name: apiResponse?.name,
      farmArea: apiResponse?.area,
      cityId:
        stateFromFarmsSlice.cityChanged === true
          ? stateFromFarmsSlice.selectedCityId
          : apiResponse?.city != null
          ? apiResponse.city_id
          : null,
      village: apiResponse?.village,
      detailedDescription: apiResponse?.detailedDescription,
      farmTypeId:
        stateFromFarmsSlice.farmingTypeChanged === true
          ? stateFromFarmsSlice.selectedFarmingTypeId
          : apiResponse?.farmingType != null
          ? apiResponse.farmingType.id
          : null,
      farmCapacityId: farmCapacityId,
      ventilationTypeId:
        stateFromFarmsSlice.ventilationChanged === true
          ? stateFromFarmsSlice.selectedVentilationType
          : apiResponse?.ventilationType != null
          ? apiResponse.ventilationType.id
          : null,
      incubator: isIncubator,
    };

    axios
      .patch(`${link}farms/${farmId}`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setIsLoading(true);
        if (res.status === 200) {
          toast.success("تم تعديل العنبر بنجاح");
          navigate(-1);
          setIsLoading(false);
        } else {
          toast.error("حدث خطأ ما يرجي التواصل  مع المسؤولين");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        toast.error("حدث خطأ ما يرجي التواصل  مع المسؤولين");
        console.log(error);
        setIsLoading(false);
      });
  };

  const farmingTypeText =
    stateFromFarmsSlice.farmingTypeChanged === false &&
    apiResponse?.farmingType != null
      ? apiResponse?.farmingType.type
      : stateFromFarmsSlice.selectedFarmingTypeId !== 0
      ? stateFromFarmsSlice.selectedFarmingTypeName
      : "قم بأختيار نوع التربية";

  const ventilationText =
    stateFromFarmsSlice.ventilationChanged === false &&
    apiResponse?.ventilationType != null
      ? apiResponse.ventilationType.type
      : stateFromFarmsSlice.selectedVentilationType !== 0
      ? stateFromFarmsSlice.selectedVentilationName
      : "قم بأختيار  نوع التهوئة";

  const governorateText = (
    <div className="select-mission-type bg-white w-full">
      {stateFromFarmsSlice.governorateChanged === false
        ? apiResponse?.governorate != null
          ? apiResponse?.governorate.name
          : "اختر المحافظة"
        : stateFromFarmsSlice.governorateChanged !== 0
        ? stateFromFarmsSlice.selectedGovernorateName
        : "اختر المحافظة"}
    </div>
  );

  const farmCityText = (
    <div className="select-mission-type bg-white w-full">
      {stateFromFarmsSlice.cityChanged === false
        ? apiResponse?.city != null
          ? apiResponse?.city.name
          : "اختر المدينة"
        : stateFromFarmsSlice.selectedCityId !== 0
        ? stateFromFarmsSlice.selectedCityName
        : "اختر المدينة"}
    </div>
  );
  return (
    <div className="EditFarm">
      {showVentilationTypePopUp === true ? (
        <VentilationType isEdit={false} />
      ) : null}
      {showGovernoratePopUp === true ? <Governorates isEdit={false} /> : null}
      {showCityPopUp === true ? <Cities isEdit={false} /> : null}
      {showFarmingTypePopUp === true ? <FarmingTypes isEdit={false} /> : null}
      {isLoading === true ? <Loading /> : null}
      <Formik
        onSubmit={() => handleLoginSubmit()}
        initialValues={{
          name: apiResponse?.name,
          farmArea: apiResponse?.area,
          farmTypeId: apiResponse?.farmingType?.id,
          farmCapacityId: apiResponse?.farms_capacity_id,
          ventilationTypeId: apiResponse?.ventilation_type_id,
          cityId: apiResponse?.city_id,
          village: apiResponse?.village,
          detailedDescription: apiResponse?.detailedDescription,
        }}
        enableReinitialize
        validationSchema={validationSchema}
        key={`EditFarm`}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setValues,
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              <div className="farm-info-section mt-4 ">
                <div className="grid grid-cols-2 pr-4 pl-4 gap-4">
                  {/* Header */}
                  <div className="lg:col-start-1 md:col-span-full sm:col-span-full xs:col-span-full pr-4 pt-4 flex items-center ">
                    <div className="farm-info-title w-1/2">معلومات العنبر</div>
                  </div>
                  <div className="col-span-full">
                    <div className="divider"></div>
                  </div>

                  {/* الأسم  */}
                  <div className="col-start-1 pr-4  mt-4">
                    <p className="title">الأسم</p>
                  </div>

                  {/* مساحة العنبر   */}
                  <div className="col-start-2 pr-4 mt-4">
                    <p className="title">مساحة العنبر</p>
                  </div>

                  {/* :input الأسم  */}
                  <div className="col-start-1">
                    <div>
                      <input
                        type="text"
                        placeholder="الاسم"
                        className="mission-address-input"
                        id="name"
                        name="name"
                        onChange={(e) =>
                          handleChangeFarm(e.target.value, "name", setValues)
                        }
                        value={apiResponse?.name}
                      />
                    </div>
                  </div>

                  {/* مساحة العنبر  input   */}
                  <div className="col-start-2">
                    <div className="form-control relative">
                      <input
                        type="text"
                        placeholder="مساحة العنبر"
                        className={
                          errors.farmArea && touched.farmArea
                            ? "input-err"
                            : "mission-address-input"
                        }
                        id="farmArea"
                        name="farmArea"
                        onChange={(e) => {
                          handleChange(e);
                          handleChangeFarm(e.target.value, "area", setValues);
                        }}
                        onBlur={handleBlur}
                        value={apiResponse?.area}
                      />
                      {errors.farmArea && touched.farmArea && (
                        <div
                          className="error err-msg"
                          style={{
                            position: "absolute",
                            bottom: "-20px",
                            left: "0",
                            width: "100%",
                          }}
                        >
                          {errors.farmArea}
                        </div>
                      )}
                    </div>
                  </div>
                  {/* نوع التربية */}
                  <div className="col-start-1 pr-4 mt-4">
                    <p className="title">نوع التربية</p>
                  </div>
                  {/* سعة العنبر */}
                  <div className="col-start-2 pr-4 mt-4">
                    <p className="title">سعة العنبر</p>
                  </div>
                  {/* :input نوع التربية  */}
                  <div className="col-start-1  ">
                    <label htmlFor="modal-14">
                      <div
                        className="flex justify-between select-mission-container"
                        onClick={() => handleFarmingTypePopUp()}
                      >
                        <div className="select-mission-type pr-4">
                          {farmingTypeText}
                        </div>

                        <div className="arrow">
                          <img src={arrow} alt="arrow" />
                        </div>
                      </div>
                    </label>
                  </div>
                  {/* سعة العنبر  input   */}
                  <div className="col-start-2">
                    <div className="dropdown-container">
                      <div className="dropdown w-full">
                        <label tabIndex={0}>
                          <div className="flex justify-between items-center select-mission-container">
                            <div className="select-mission-type wrap">
                              {farmCapacityText}
                            </div>

                            <div className="arrow">
                              <img src={arrow} alt="arrow" />
                            </div>
                          </div>
                        </label>
                        <div className="dropdown-menu w-full bg-white">
                          <a
                            className="dropdown-item text-sm text-right"
                            onClick={() => handleChangeFarmCapacity(1)}
                          >
                            اققل من 1000
                          </a>
                          <a
                            className="dropdown-item text-sm text-right"
                            onClick={() => handleChangeFarmCapacity(2)}
                          >
                            اققل من5000
                          </a>

                          <a
                            className="dropdown-item text-sm text-right"
                            onClick={() => handleChangeFarmCapacity(3)}
                          >
                            اققل من 10000
                          </a>

                          <a
                            className="dropdown-item text-sm text-right"
                            onClick={() => handleChangeFarmCapacity(4)}
                          >
                            اققل من 25000
                          </a>

                          <a
                            className="dropdown-item text-sm text-right"
                            onClick={() => handleChangeFarmCapacity(5)}
                          >
                            اققل من 50000
                          </a>

                          <a
                            className="dropdown-item text-sm text-right"
                            onClick={() => handleChangeFarmCapacity(6)}
                          >
                            اققل من 100000
                          </a>
                          <a
                            className="dropdown-item text-sm text-right"
                            onClick={() => handleChangeFarmCapacity(7)}
                          >
                            اققل من 200000
                          </a>

                          <a
                            className="dropdown-item text-sm text-right"
                            onClick={() => handleChangeFarmCapacity(8)}
                          >
                            اققل من 500000
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* نوع التهوئة  */}
                  <div className="col-start-1 pr-4  mt-4">
                    <p className="title">نوع التهوئة</p>
                  </div>

                  {/* :input نوع التهوئة  */}
                  <div className="col-start-1 ">
                    <label htmlFor="modal-10">
                      <div
                        className="flex justify-between select-mission-container"
                        onClick={() => handleVentilationTypePopUp()}
                      >
                        <div className="select-mission-type pr-4">
                          {ventilationText}
                        </div>

                        <div className="arrow">
                          <img src={arrow} alt="arrow" />
                        </div>
                      </div>
                    </label>
                  </div>

                  {/* مربي اعمار */}
                  <div className="col-start-2 mt-4 pr-4">
                    <div className="flex lg:gap-4 md:gap-2 sm:gap-2 xs:gap-1 items-center">
                      <span className="title">مربي اعمار ؟</span>
                      <div>
                        <input
                          type="checkbox"
                          className="switch switch-success"
                          checked={isIncubator}
                          onChange={() => setIncubator(!isIncubator)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* التراخيص */}
              <div className="license-section mt-4">
                <div className="grid grid-cols-2 pr-4 pl-4 gap-4">
                  {/* Header */}
                  <div className="col-span-full pr-4 pt-4 flex items-center ">
                    <div className="farm-info-title w-1/2">ترخيص العنبر</div>
                  </div>
                  <div className="col-span-full">
                    <div className="divider"></div>
                  </div>
                  {/* Upload Image */}
                  <div className="col-span-full flex gap-8 pr-4 items-center">
                    <div className={`${imageUploadWrapClass}`}>
                      <input
                        onDragOver={() => handleDragOver()}
                        onDragLeave={() => handleDragLeave()}
                        className="file-upload-input"
                        type="file"
                        onChange={(e) => readURL(e.target)}
                        accept="image/*"
                      />
                      <div className="drag-text">
                        <h3 className="add-photo-txt">ارفق صورة العنبر </h3>
                        <p className="">
                          دعم جميع الملفات، الحجم الأقصى 60 ميجابايت
                        </p>
                      </div>
                    </div>
                    <div onClick={() => removeUpload()}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M5 8V18C5 20.2091 6.79086 22 9 22H15C17.2091 22 19 20.2091 19 18V8M14 11V17M10 11L10 17M16 5L14.5937 2.8906C14.2228 2.3342 13.5983 2 12.9296 2H11.0704C10.4017 2 9.7772 2.3342 9.40627 2.8906L8 5M16 5H8M16 5H21M8 5H3"
                          stroke="#EB001B"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="col-span-full">
                    {fileUploadContentVisible && file && (
                      <div className="row mb-3">
                        <div className="file-upload-content">
                          <img
                            className="file-upload-image"
                            src={URL.createObjectURL(file)}
                            alt="your"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* العنوان */}
              <div className="location-section mt-4 grid grid-cols-2 gap-4">
                {/* Header */}
                <div className="col-start-1 pr-4 pt-4 flex items-center ">
                  <div className="farm-info-title w-1/2">العنوان</div>
                </div>
                <div className="col-span-full">
                  <div className="divider"></div>
                </div>

                {/*  المحافظة */}
                <div className="col-start-1 pr-4 mt-4">
                  <p className="title">المحافظة</p>
                </div>

                {/*  المدينة */}
                <div className="col-start-2 pr-4 mt-4">
                  <p className="title">المدينة</p>
                </div>

                {/* المحافظة  input   */}
                <div className="col-start-1 pr-4 ">
                  <label htmlFor="modal-12">
                    <div
                      className="w-full mission-address-input flex justify-between pl-4 items-center"
                      onClick={() => handleGovernoratePopUp()}
                    >
                      {governorateText}

                      <div className="arrow">
                        <img src={arrow} alt="arrow" />
                      </div>
                    </div>
                  </label>
                </div>
                {/*  المدينة :input */}
                <div className="col-start-2 pr-4 ">
                  <label htmlFor="modal-13">
                    <div
                      className="w-full mission-address-input flex justify-between pl-4 items-center"
                      onClick={() => handleCityPopUp()}
                    >
                      {farmCityText}

                      <div className="arrow">
                        <img src={arrow} alt="arrow" />
                      </div>
                    </div>
                  </label>
                </div>

                {/*  حي / قرية */}
                <div className="col-start-1 pr-4 mt-4">
                  <p className="title">حي / قرية</p>
                </div>

                {/*  الوصف التفصيلي */}
                <div className="col-start-2 pr-4 mt-4">
                  <p className="title">الوصف التفصيلي</p>
                </div>

                {/* حي / قرية  input   */}
                <div className="col-start-1 pr-4 ">
                  <input
                    type="text"
                    placeholder="القرية"
                    className="mission-address-input"
                    id="village"
                    name="village"
                    onChange={(e) =>
                      handleChangeFarm(e.target.value, "village", setValues)
                    }
                    value={apiResponse?.village}
                  />
                </div>
                {/*  الوصف التفصيلي :input */}
                <div className="col-start-2 pr-4 ">
                  <textarea
                    placeholder="الوصف التفصيلي"
                    className="mission-address-input"
                    id="detailedDescription"
                    name="detailedDescription"
                    onChange={(e) =>
                      handleChangeFarm(
                        e.target.value,
                        "detailedDescription",
                        setValues
                      )
                    }
                    value={apiResponse?.detailedDescription}
                  />
                </div>
              </div>

              {/* Fourth Section [Done Or cancel] */}
              <div className="add-actions p-5">
                <div className="grid grid-cols-2">
                  <div className="col-start-1">
                    <div className="flex gap-4">
                      <button className="add-btn">تعديل</button>
                      <div className="cancel-btn" onClick={() => navigate(-1)}>
                        الغاء
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </>
        )}
      </Formik>
    </div>
  );
};
